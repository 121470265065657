import React from 'react';
import wilsonJPG from 'src/assets/images/wilson.jpg';
import { Box, Button, Typography } from '@mui/material';

export const ErrorBoundaryFallback = () => (
  <Box
    sx={{
      gap: 1,
      padding: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '100vh',
    }}
  >
    <img alt="" src={wilsonJPG} style={{}} />

    <Typography variant="body1">Hubo un error en nuestra web 🥲😔</Typography>

    <Typography variant="body2">
      Ya recolectamos informacion sobre el error y nos podremos a trabajar en
      solucionarlo 🙇🏻‍♀️🧑🏻‍💻
    </Typography>

    <Button
      variant="contained"
      onClick={() => {
        window.location.replace('/');
      }}
    >
      Ir a tus estudios
    </Button>
  </Box>
);
