export const subscriptionStatusesMap = {
  trialing: {
    label: 'Prueba',
    color: '#FFC107',
  },
  active: {
    label: 'Activo',
    color: '#4CAF50',
  },
  past_due: {
    label: 'Vencido',
    color: '#F44336',
  },
  canceled: {
    label: 'Cancelado',
    color: '#9E9E9E',
  },
  unpaid: {
    label: 'Impago',
    color: '#E91E63',
  },
  incomplete: {
    label: 'Incompleto',
    color: '#673AB7',
  },
  incomplete_expired: {
    label: 'Incompleto Expirado',
    color: '#795548',
  },
};
