import React, { useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';

import PermissionGuard from 'src/components/v2/PermissionGuard';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { useCompactColumns } from 'src/pages/Dashboard/Calendar/CalendarAgendaTable/columns';
import Table from 'src/components/v3/Table';
import { getEventRowId } from 'src/utils/dates';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { LSWorkflowIcon } from 'src/components/icons';
import { WorkflowsPopover } from 'src/popovers/WorkflowsPopover';

const sectionTypeByEventType = {
  alerts: 'alert',
  tasks: 'task',
};

export const SingleFilecaseEvents = ({ filecase, type }) => {
  const dispatchEventDialog = useDialog('event');
  const dispatchEventFormDialog = useDialog('eventForm');
  const [anchorEl, setAnchorEl] = useState(null);
  const columns = useCompactColumns();

  const handlePopoverOpen = (clickEvent) => {
    setAnchorEl(clickEvent.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { hasPermissions: hasCalendarAccessPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.ACCESS,
  ]);

  const events = useMemo(
    () =>
      filecase.events.filter(
        (event) => event.type === sectionTypeByEventType[type],
      ),
    [filecase.events, type],
  );

  return (
    <Stack gap={2}>
      {!!events.length && (
        <Table
          hideFooter
          checkboxSelection={false}
          columns={columns}
          getRowId={getEventRowId}
          loading={false}
          pageSize={events.length}
          paginationMode="client"
          rows={events ?? []}
          sortingMode="client"
          sxCard={{
            boxShadow: 'none',
          }}
          onRowClick={(params) =>
            hasCalendarAccessPermissions &&
            dispatchEventDialog(params.row.id, {
              id: params.row.id,
              calendarId: params.row.calendarId,
            })
          }
        />
      )}

      <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.CREATE]}>
        <CreationInfoBanner
          buttonStr={`Crear ${
            sectionTypeByEventType[type] === 'alert' ? 'Alerta' : 'Tarea'
          }`}
          description={`Crea una ${
            sectionTypeByEventType[type] === 'alert' ? 'Alerta' : 'Tarea'
          } de calendario vinculada a tu
          expediente.`}
          secondaryButton={
            sectionTypeByEventType[type] === 'task'
              ? {
                  buttonStr: (
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <LSWorkflowIcon sx={{ fontSize: 18 }} />
                      <Box>Flujo de trabajo</Box>
                    </Stack>
                  ),
                  onClick: handlePopoverOpen,
                }
              : undefined
          }
          title={`Crear ${
            sectionTypeByEventType[type] === 'alert' ? 'Alerta' : 'Tarea'
          }`}
          onClick={() =>
            dispatchEventFormDialog('SingleFilecaseEventsEventsForm', {
              filecase,
              type: type === 'alerts' ? 'alert' : 'task',
            })
          }
        />

        <WorkflowsPopover
          anchorEl={anchorEl}
          filecases={[filecase.id]}
          open={Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
        />
      </PermissionGuard>
    </Stack>
  );
};
