import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

export const CalendarPill = ({ name, color, sx = {} }) => (
  <Tooltip title={name || ''}>
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        width: 3,
        height: '100%',
        bgcolor: color,
        borderRadius: 8,
        ...sx,
      }}
    />
  </Tooltip>
);

const TitleCell = ({ row: event }) => (
  <Box sx={{ position: 'relative' }}>
    <CalendarPill color={event.calendar.color} name={event.calendar.name} />

    <Tooltip title={event.title}>
      <Typography
        sx={{
          fontWeight: '500',
          fontSize: 16,
          textOverflow: 'ellipsis',
          whiteSpace: 'noWrap',
          overflow: 'hidden',
          pl: 1,
        }}
      >
        {event.title}
      </Typography>
    </Tooltip>
  </Box>
);

export default TitleCell;
