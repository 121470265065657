import React from 'react';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useUpdateCalendarEventAssignees } from '@legalsurf/hooks';

const AssignedCell = ({ row: event }) => {
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  ]);

  const [updateCalendarEventAssignees] = useUpdateCalendarEventAssignees();

  const handleAssignedSubmit = async (newAssignees) => {
    await updateCalendarEventAssignees({
      variables: {
        id: event.id,
        calendarId: event.calendarId,
        assigned: newAssignees,
      },
    });
  };

  return (
    <AssigneesPopover
      selectedAssignees={event.assigned}
      touchable={hasPermissions}
      onSubmit={handleAssignedSubmit}
    />
  );
};
export default AssignedCell;
