import React from 'react';

import Page from 'src/components/v2/Page';
import FilecasesTable from 'src/tables/FilecasesTable';

export const Filecases = () => (
  <Page title="Expedientes | LegalSurf">
    <FilecasesTable />
  </Page>
);
