import React from 'react';
import { useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useFilecaseSides } from 'src/autocompletes/SidesAutocomplete';
import { DELETE_SIDE, UPDATE_SIDE } from 'src/graphql/mutations/side';

const Actions = ({ row }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteSide] = useMutation(DELETE_SIDE, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteSide));
      cache.gc();
    },
  });

  return [
    <Tooltip title="Para editar, doble click en los campos editables que deseas actualizar en la tabla">
      <GridActionsCellItem
        color="inherit"
        icon={<LSEdit color="primary" fontSize="small" />}
        label="Edit"
      />
    </Tooltip>,
    <Tooltip title="Eliminar etiqueta">
      <GridActionsCellItem
        color="inherit"
        icon={<LSTrash color="error" fontSize="small" />}
        label="Delete"
        onClick={() =>
          dispatchWarningPromptDialog(`FilecaseSidesTab-${row.name}`, {
            onAccept: () =>
              deleteSide({
                variables: {
                  id: row.id,
                  studioId,
                },
              }),
            title: 'Eliminar etiqueta',
          })
        }
      />
    </Tooltip>,
  ];
};

export const FilecaseSidesTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const { value } = useTabContext();
  const studioId = useStudioId();
  const { sides, loading } = useFilecaseSides({ skip: value !== index });
  const [updateSide] = useMutation(UPDATE_SIDE);

  const handleUpdate = async (val, oldValue) => {
    if (val.name === oldValue.name) {
      return oldValue;
    }

    const res = await updateSide({
      variables: {
        studioId,
        id: val.id,
        name: val.name,
      },
    });

    return res.data.updateSide;
  };

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: true,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 150,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      editable: false,
      getActions: Actions,
    },
  ];

  return (
    <TabPanel value={index}>
      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        editMode="row"
        loading={loading}
        pageSize={10}
        processRowUpdate={handleUpdate}
        rowCount={sides.length}
        rows={sides}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe un estado con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
      />
    </TabPanel>
  );
};
