import React from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import { PopoverBase } from 'src/popovers/PopoverBase';

import { LSMore as LSMoreIcon } from '../../icons';

const MoreButton = ({ children, color = 'primary', popperHeader, ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <IconButton color={color} {...rest} onClick={(event) => setAnchorEl(event.currentTarget)}>
        <LSMoreIcon />
      </IconButton>

      <PopoverBase
        anchorEl={anchorEl} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
      >
        {popperHeader && (
          <Box
            sx={{
              borderBottom: '1px solid',
              borderBottomColor: 'divider',
              px: 3,
              py: 2,
            }}
          >
            <Typography>{popperHeader}</Typography>
          </Box>
        )}

        {children}
      </PopoverBase>
    </>
  );
}

export default MoreButton;
