import React from 'react';
import { Typography, Chip, Stack } from '@mui/material';

const TitleCell = ({ row }) => (
  <Stack
    direction="row"
    gap={0.5}
    sx={{
      maxWidth: '40rem',
      width: '100%',
      overflow: 'hidden',
      flexWrap: 'wrap',
      height: '100%',
      alignItems: 'center',
    }}
  >
    <Typography
      role="button"
      sx={{
        fontWeight: '500',
      }}
    >
      {row.title}
    </Typography>

    {!!row.tags?.length && (
      <Stack direction="row" gap={0.5}>
        {row.tags?.map((tag) => (
          <Chip
            key={tag.name}
            label={tag.name}
            size="small"
            sx={{
              color: 'primary.dark',
              backgroundColor: 'primary.100',
            }}
          />
        ))}
      </Stack>
    )}
  </Stack>
);

export default TitleCell;
