import React, { useRef } from 'react';
import { Formik } from 'formik';
import {
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Grid,
  Box,
  Typography,
} from '@mui/material';

import Form, { TextField } from 'src/components/ui-components/Form';
import { LoadingButton } from '@mui/lab';
import {
  keyWordsBackgrounds,
  specialKeyWords,
} from 'src/utils/notificationTemplates';

import * as Yup from 'yup';

const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue,
    );
    return result;
  }, {});

const groupedKeyWords = groupBy(specialKeyWords, 'model');

const NotificationTemplateFormSchemaValidation = Yup.object({
  name: Yup.string().required('Este campo es requerido.'),
  body: Yup.string().required('Este campo es requerido.'),
});

export const NotificationTemplateForm = ({
  onSubmit,
  onCancel,
  hideAssigned,
  onDeleteClick,
  ...rest
}) => {
  const inputRef = useRef(null);

  return (
    <Formik
      {...rest}
      validationSchema={NotificationTemplateFormSchemaValidation}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
        <>
          <DialogContent dividers>
            <Form>
              <TextField
                label="Nombre de la plantilla"
                name="name"
                placeholder="Nombre..."
              />

              <TextField
                multiline
                label="Cuerpo de la plantilla"
                name="body"
                placeholder="Descripción"
                ref={inputRef}
                rows={6}
              />

              <Grid item>
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  Utiliza campos dinámicos que serán rellenados en el momento de
                  utilizar esta plantilla:
                </Typography>

                <Box sx={{ gap: 3, display: 'flex', flexWrap: 'wrap' }}>
                  {Object.entries(groupedKeyWords).map(([key, group]) => (
                    <Box
                      key={key}
                      sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}
                    >
                      {group.map((keyWord) => (
                        <Chip
                          key={keyWord.key}
                          label={keyWord.key}
                          size="small"
                          sx={{
                            backgroundColor:
                              key === 'event'
                                ? keyWordsBackgrounds[
                                    keyWord.alert ? 'alertEvent' : 'taskEvent'
                                  ]
                                : keyWordsBackgrounds[key],
                          }}
                          onClick={() => {
                            const { selectionStart, selectionEnd } =
                              inputRef.current;

                            setFieldValue(
                              'body',
                              values.body.slice(0, selectionStart) +
                                keyWord.key +
                                values.body.slice(selectionEnd),
                            );

                            inputRef.current.focus();

                            setTimeout(() => {
                              inputRef.current.setSelectionRange(
                                selectionStart + keyWord.key.length,
                                selectionStart + keyWord.key.length,
                              );
                            }, 0);
                          }}
                        />
                      ))}
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Form>
          </DialogContent>

          <DialogActions sx={{ mt: 2 }}>
            <Box
              sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              {values.id && (
                <Button
                  color="error"
                  disabled={isSubmitting}
                  sx={{ mr: 'auto' }}
                  variant="contained"
                  onClick={onDeleteClick}
                >
                  Eliminar plantilla
                </Button>
              )}

              <LoadingButton
                disabled={isSubmitting}
                loading={isSubmitting}
                sx={{ px: 4 }}
                type="button"
                variant="contained"
                onClick={handleSubmit}
              >
                {values.id ? 'Editar plantilla' : 'Crear plantilla'}
              </LoadingButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};
