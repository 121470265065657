import * as React from 'react';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { TabContext, TabList } from '@mui/lab';
import { Typography } from '@mui/material';

import Page from 'src/components/v2/Page';
import bannerCategory from 'src/assets/images/header-categories.jpg';
import { useSearchParams } from 'react-router-dom';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { TagsTab } from './tabs/TagsTab';
import { FilecaseSidesTab } from './tabs/FilecaseSidesTab';
import { FilecaseCategoriesTab } from './tabs/FilecaseCategoriesTab';
import { FilecaseStatusesTab } from './tabs/FilecaseStatusesTab';
import { DirectoryStatusesTab } from './tabs/DirectoryStatusesTab';
import { CurrenciesTab } from './tabs/CurrenciesTab';
import { CustomFieldsTab } from './tabs/CustomFieldsTab';

export const Organizers = () => {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = React.useState(searchParams.get('tab') || 'tags');

  const { hasPermissions: hasTagsPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
  ]);

  const { hasPermissions: hasFilecasesPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
    LS_PERMISSIONS_MAP.FILECASES.READ,
  ]);

  const { hasPermissions: hasDirectoriesPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
    LS_PERMISSIONS_MAP.DIRECTORY.READ,
  ]);

  const { hasPermissions: hasCurrenciesPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
    LS_PERMISSIONS_MAP.SALES.READ,
  ]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page title="Filtros | legalsurf">
      <Box
        sx={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 170,
          borderRadius: 1,
          backgroundImage: `url(${bannerCategory})`,
          padding: 4,
          mb: 2,
        }}
      >
        <Typography
          component="h4"
          sx={{ color: 'primary.100', my: 1 }}
          variant="h3"
        >
          Simplifica, digitaliza y filtra tus expedientes.
        </Typography>

        <Typography
          component="h6"
          sx={{ color: 'primary.100', my: 1 }}
          variant="h6"
        >
          Centraliza y gestiona todo el trabajo de tu equipo legal <br /> en un
          solo lugar
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          borderRadius: 1,
        }}
      >
        <TabContext value={tab}>
          <TabList
            orientation="vertical"
            sx={{
              '.MuiTab-root[aria-selected=true]': {
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
              },
            }}
            onChange={handleChange}
          >
            {hasTagsPermissions && <Tab label="Etiquetas" value="tags" />}

            {hasFilecasesPermissions && (
              <Tab label="Partes de expedientes" value="filecaseSides" />
            )}
            {hasFilecasesPermissions && (
              <Tab
                label="Categorias de expedientes"
                value="filecaseCategories"
              />
            )}
            {hasFilecasesPermissions && (
              <Tab label="Estados de expedientes" value="filecaseStates" />
            )}
            {hasDirectoriesPermissions && (
              <Tab label="Estados de directorios" value="directoryStates" />
            )}

            {hasCurrenciesPermissions && (
              <Tab label="Monedas" value="currencies" />
            )}

            {/* {hasCurrenciesPermissions && ( */}
            <Tab label="Campos Personalizados" value="customFields" />
            {/* )} */}
          </TabList>

          <Box sx={{ width: '100%' }}>
            {hasTagsPermissions && <TagsTab index="tags" />}

            {hasFilecasesPermissions && (
              <FilecaseSidesTab index="filecaseSides" />
            )}

            {hasFilecasesPermissions && (
              <FilecaseCategoriesTab index="filecaseCategories" />
            )}

            {hasFilecasesPermissions && (
              <FilecaseStatusesTab index="filecaseStates" />
            )}

            {hasDirectoriesPermissions && (
              <DirectoryStatusesTab index="directoryStates" />
            )}

            {hasCurrenciesPermissions && <CurrenciesTab index="currencies" />}

            <CustomFieldsTab index="customFields" />
          </Box>
        </TabContext>
      </Box>
    </Page>
  );
};
