import React from 'react';
import { SvgIcon } from '@mui/material';

const LSDocument = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M16.05 3.75C15.3069 3.75 14.6024 4.02882 14.0896 4.51249L6.05236 12.0926C5.2128 12.8844 4.75 13.9486 4.75 15.0483C4.75 16.148 5.2128 17.2123 6.05236 18.0041C6.89341 18.7973 8.04302 19.25 9.25028 19.25C10.4575 19.25 11.6072 18.7973 12.4482 18.0041L20.4854 10.424C20.7868 10.1398 21.2614 10.1537 21.5456 10.455C21.8298 10.7563 21.8159 11.231 21.5146 11.5152L13.4774 19.0953C12.3492 20.1593 10.8279 20.75 9.25028 20.75C7.67262 20.75 6.15137 20.1593 5.02318 19.0953C3.89351 18.0299 3.25 16.5751 3.25 15.0483C3.25 13.5215 3.89351 12.0668 5.02318 11.0013L13.0604 3.42125C13.8604 2.66677 14.9365 2.25 16.05 2.25C17.1635 2.25 18.2396 2.66677 19.0396 3.42125C19.8411 4.17714 20.3002 5.21205 20.3002 6.30111C20.3002 7.39018 19.8411 8.42509 19.0396 9.18097L10.9936 16.7611C10.9935 16.7612 10.9937 16.761 10.9936 16.7611C10.5219 17.2058 9.89076 17.4489 9.24154 17.4489C8.59218 17.4489 7.96122 17.206 7.48945 16.7611C7.01618 16.3147 6.74144 15.6996 6.74144 15.0483C6.74144 14.397 7.01618 13.7819 7.48945 13.3356L14.9148 6.34081C15.2163 6.05679 15.6909 6.07097 15.975 6.37247C16.259 6.67397 16.2448 7.14864 15.9433 7.43266L8.51862 14.4268C8.33547 14.5996 8.24144 14.8241 8.24144 15.0483C8.24144 15.2725 8.33547 15.4971 8.51862 15.6698C8.70326 15.844 8.96258 15.9489 9.24154 15.9489C9.52049 15.9489 9.77981 15.844 9.96445 15.6698L18.0104 8.08974C18.5218 7.60747 18.8002 6.96307 18.8002 6.30111C18.8002 5.63916 18.5218 4.99476 18.0104 4.51249C17.4976 4.02882 16.7931 3.75 16.05 3.75Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSDocument;
