import dayjs from 'dayjs';
import * as Yup from 'yup';

export const toDateFormat = (date, format = 'L') => dayjs(date).format(format);

export const getEventRowId = (event) => event.id + event.calendarId;

export const YupDateValidations = Yup.mixed()
  .required('Debes ingresar una fecha válida')
  .nullable()
  .test('valid-date', 'Debes ingresar una fecha válida', (value) =>
    dayjs(value).isValid(),
  );
