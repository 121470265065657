import React from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent, Box, Grid } from '@mui/material';
import * as Yup from 'yup';

import Form, {
  CommentField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import {
  FilecasesAutocomplete,
  StudioMembersAutocomplete,
} from 'src/autocompletes';

const HoursTrackingFormSchema = Yup.object({
  hours: Yup.number()
    .min(1, 'Debes agregar al menos una hora')
    .notRequired()
    .test(
      'either-hours-or-minutes',
      'Debes agregar horas o minutos',
      (value, context) => {
        const { minutes } = context.parent;

        return !!(value || minutes);
      },
    ),
  minutes: Yup.number().min(1, 'Debes agregar minimo un minuto').notRequired(),
  assigned: Yup.object().nullable().required('El usuario es requerido'),
  filecase: Yup.object().nullable().required('El expediente es requerido'),
});

const defaultValues = {
  hours: '',
  minutes: '',
  concept: '',
  filecase: null,
  assigned: null,
};

const HoursTrackingForm = ({
  initialValues = defaultValues,
  onSubmit,
  onCancel,
  buttonLabel,
  disabledFields = {},
  ...rest
}) => (
  <Formik
    {...rest}
    initialValues={{ ...defaultValues, ...initialValues }}
    validationSchema={HoursTrackingFormSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <>
        <DialogContent>
          <Form id="hoursTrackingForm">
            <Grid item>
              <Grid container gap={2}>
                <TextField
                  gridProps={{ xs: 5, md: 4 }}
                  label="Horas"
                  name="hours"
                  placeholder="00"
                  type="number"
                />

                <TextField
                  gridProps={{ xs: 5, md: 4 }}
                  label="Minutos"
                  name="minutes"
                  placeholder="00"
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ bgcolor: 'background.input', p: 2, borderRadius: 2 }}>
                <Grid container>
                  <CommentField
                    disableUpload
                    name="concept"
                    placeholder="Concepto"
                  />
                </Grid>
              </Box>
            </Grid>

            <StudioMembersAutocomplete
              withSelectAll={false}
              label="Usuario"
              multiple={false}
              name="assigned"
            />

            <FilecasesAutocomplete
              disabled={disabledFields?.filecase}
              label="Expediente"
              name="filecase"
            />
          </Form>
        </DialogContent>

        <DialogActions>
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
              Cancelar
            </Button>

            <SubmitButton
              fullWidth
              form="hoursTrackingForm"
              gridProps={{ sx: { width: '100%' } }}
            >
              {buttonLabel}
            </SubmitButton>
          </Box>
        </DialogActions>
      </>
    )}
  </Formik>
);

export default HoursTrackingForm;
