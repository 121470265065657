import React from 'react';
import { Box, Dialog } from '@mui/material';
import { ExpenseDetail } from 'src/components/v2/ExpenseDetail';

const ExpenseDetailsDialog = ({ expenseId, id, onClose, open, ...props }) => (
  <Dialog
    fullScreen
    open={!!open}
    sx={{ width: ['initial', 700], ml: 'auto' }}
    onClose={onClose}
  >
    <Box sx={{ overflowY: 'scroll' }}>
      <ExpenseDetail expenseId={id || expenseId} onClose={onClose} {...props} />
    </Box>
  </Dialog>
);

export default ExpenseDetailsDialog;
