import { useQuery, QueryHookOptions } from '@apollo/client';
import { DocumentsQuery } from '@legalsurf/queries';

import { DocumentsQueryData, DocumentsQueryVariables } from './types';

export const useDocuments = (
  options: QueryHookOptions<DocumentsQueryData, DocumentsQueryVariables>,
) => {
  const query = useQuery(DocumentsQuery, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return {
    ...query,
    documents: query.data?.documents || [],
  };
};
