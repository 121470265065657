import React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { Box, CircularProgress } from '@mui/material';
import ChartsTooltip from '../ChartTooltip';

const TreasuryReportsLineChart = ({
  data: treasuryStats,
  loading,
  animate,
}) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 200,
        }}
      >
        <CircularProgress color="primary" size={30} />
      </Box>
    );
  }

  return (
    <ResponsiveContainer height={300} width="100%">
      <LineChart
        data={treasuryStats}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="formatedDate" />

        <Tooltip content={<ChartsTooltip />} />

        <Legend />

        <Line
          dataKey="expenses"
          isAnimationActive={animate}
          name="Gastos"
          stroke="red"
          strokeDasharray="3 5"
          strokeWidth={1.75}
          type="monotone"
        />

        <Line
          dataKey="sales"
          isAnimationActive={animate}
          name="Honorarios"
          stroke="#82ca9d"
          strokeWidth={2}
          type="monotone"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TreasuryReportsLineChart;
