import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

const DocumentCell = ({ row: file }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
    <Tooltip
      title={
        file.kind === 'spaces'
          ? 'Previsualizar documento'
          : 'Abrir documento en google drive'
      }
    >
      <Typography
        noWrap
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
          '&:hover': {
            color: 'primary.main',
          },
          maxWidth: 350,
        }}
      >
        {file.name}
      </Typography>
    </Tooltip>
  </Box>
);

export default DocumentCell;
