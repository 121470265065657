import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

const StudiosSkeleton = () => (
  <Grid container spacing={2}>
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <Skeleton height={40} variant="squared" width={600} />
      </Grid>

      <Grid item xs={12}>
        <Skeleton height={20} variant="squared" width={360} />
      </Grid>
    </Grid>

    <Grid container item spacing={2}>
      {Array(3)
        .fill()
        .map((...[, idx]) => (
          <Grid item key={idx}>
            <Box
              sx={{
                my: 3,
                borderRadius: 1,
                overflow: 'hidden',
                width: 290,
                height: 300,
              }}
            >
              <Skeleton height={300} variant="squared" width={290} />
            </Box>
          </Grid>
        ))}
    </Grid>
  </Grid>
);

export default StudiosSkeleton;
