import React from 'react';
import { Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import FilecaseDetail from 'src/components/v2/FilecaseDetail';

export const FilecaseSingle = () => {
  const params = useParams();
  return (
    <Page title="Expediente | LegalSurf">
      <Card sx={{ width: 900, maxWidth: '99%', mx: 'auto' }}>
        <CardContent>
          <FilecaseDetail filecaseId={params.filecaseId} isFullPage />
        </CardContent>
      </Card>
    </Page>
  );
};
