import * as Yup from 'yup';

const StateFormSchema = Yup.object({
  name: Yup.string()
    .min(1, 'Mínimo 4 caracteres')
    .max(36, 'Máximo 36 caraceres')
    .required('Nombre es requerido'),
});

export default StateFormSchema;
