import React from 'react';
import { Dialog, Divider, DialogTitle } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import * as Sentry from '@sentry/react';

import TransactionForm from 'src/forms/TransactionForm';
import { CREATE_SALE, UPDATE_SALE } from 'src/graphql/mutations/studios';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import { SalesQuery } from 'src/pages/Dashboard/Sales/SalesQueries';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDefaultCurrency } from 'src/autocompletes/CurrencyAutocomplete';
import dayjs from 'dayjs';

const TransactionDialog = ({
  transaction,
  open,
  isEdit,
  onClose,
  type,
  entity,
  filecase,
  onSubmit,
  ...rest
}) => {
  const studioId = useStudioId();
  const { currency: defaultCurrency } = useDefaultCurrency();
  const { openSnackbar } = useSnackbar();
  const [createSale, { loading }] = useMutation(CREATE_SALE, {
    refetchQueries: [SalesQuery],
  });
  const [updateSale] = useMutation(UPDATE_SALE);

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = transaction ? updateSale : createSale;

    const total = values.items.reduce((acc, item) => acc + item.amount, 0);

    try {
      const sale = await changeMutation({
        variables: {
          studio: studioId,
          amount: total,
          date: new Date(values.date),
          items: values.items,
          entity: values?.entity?.value,
          currency:
            typeof values?.currency === 'string'
              ? values?.currency
              : values?.currency?.value,
          filecase: values?.filecase?.value,
          hours: Number(values.hours),
          responsible: values?.responsible?.value,
          canBeSeenByCreatorOnly: values.canBeSeenByCreatorOnly,
          saleId: transaction ? transaction.id : undefined,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label),
          comments: values.comment ? [values.comment] : undefined,
          charges:
            values.paid || values.charged === 0
              ? [
                  {
                    amount: values.charged,
                    concept: 'Pagado',
                  },
                ]
              : undefined,
        },
      });

      openSnackbar('Honorario creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });

      if (onSubmit) {
        onSubmit(sale?.data?.createSale);
      }
    } catch (error) {
      Sentry.captureException(error);

      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={loading ? undefined : onClose} {...rest}>
      <DialogTitle>
        {transaction ? 'Editar honorario' : 'Nuevo honorario'}
      </DialogTitle>

      <Divider />

      <TransactionForm
        enableReinitialize
        sale={transaction}
        buttonLabel={transaction ? 'Editar' : 'Guardar'}
        filecase={filecase}
        initialValues={
          transaction
            ? {
                ...transaction,
                date: dayjs(transaction.date),
                activeHours: !!transaction.hours,
                entity: transaction?.entity
                  ? {
                      label: transaction.entity.displayName,
                      value: transaction.entity.id,
                    }
                  : null,
                filecase: transaction?.filecase
                  ? {
                      label: transaction.filecase.title,
                      value: transaction.filecase.id,
                    }
                  : null,
                responsible: transaction?.responsible
                  ? {
                      label: transaction.responsible.name,
                      value: transaction.responsible.id,
                    }
                  : null,
                tags: transaction.tags
                  ? transaction.tags.map((tag) => ({
                      label: tag.name,
                      value: tag.name,
                    }))
                  : [],

                ...(transaction.items?.length
                  ? {
                      amount: transaction.items.reduce(
                        (acc, item) => acc + item.amount,
                        0,
                      ),
                      items: transaction.items.map((tag) => ({
                        description: tag.description,
                        amount: tag.amount,
                      })),
                    }
                  : {}),
                currency: transaction.currency
                  ? {
                      label: transaction.currency.name,
                      value: transaction.currency.name,
                    }
                  : null,
                paymentMethod: DEFAULT_PAYMENT_METHODS.find(
                  (transac) => transac.value === transaction.paymentMethod,
                ),
              }
            : {
                currency: defaultCurrency
                  ? {
                      label: defaultCurrency?.name,
                      value: defaultCurrency?.name,
                    }
                  : null,
                entity: entity
                  ? {
                      label: entity.displayName,
                      value: entity.id,
                    }
                  : null,
                filecase: filecase?.id
                  ? {
                      label: filecase?.title,
                      value: filecase?.id,
                    }
                  : null,
              }
        }
        isEdit={isEdit}
        type={type}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default TransactionDialog;
