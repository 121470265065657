import React from 'react';

import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  AccountCircleOutlined,
  LocationOnOutlined,
  PublicOutlined,
} from '@mui/icons-material';

import { isCustomFieldInitialValue } from 'src/components/ui-components/Form/CustomField';
import { WhatsAppButton } from 'src/popovers/NotificationTemplatesPopover/WhatsappButton';
import { LSMail } from 'src/components/icons';

const MetadataItem = ({ label, value, icon }) => {
  return (
    <Tooltip title={label} placement="left">
      <Stack direction="row" gap={0.5} alignItems="center" height="1.2rem">
        {icon}

        <Typography pl={0.5} fontSize={14} fontWeight={500}>
          {value || '---'}
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export const EntityMetadata = ({ entity }) => (
  <Stack gap={1}>
    <MetadataItem
      label="Telefono"
      value={entity.phone}
      icon={
        <WhatsAppButton
          fontSize={20}
          phone={entity.phone}
          entity={entity}
          sx={{ mr: -0.75 }}
        />
      }
    />

    <MetadataItem
      label="Email"
      value={entity.email}
      icon={
        <IconButton
          size="small"
          href={`mailto:${entity.email}`}
          sx={{ mr: -0.5 }}
        >
          <LSMail color="primary" fontSize="14" />
        </IconButton>
      }
    />

    <MetadataItem
      label="Identificación"
      value={entity.identification}
      icon={
        <AccountCircleOutlined
          color="primary"
          fontSize="small"
          sx={{ ml: 0.5 }}
        />
      }
    />

    {entity.postalCode && (
      <MetadataItem label="Codigo postal" value={entity.postalCode} />
    )}

    {entity.location && (
      <MetadataItem label="Localidad" value={entity.location} />
    )}

    {entity.country && (
      <MetadataItem
        label="Pais"
        value={entity.country}
        icon={
          <PublicOutlined color="primary" fontSize="small" sx={{ ml: 0.5 }} />
        }
      />
    )}

    <MetadataItem
      label="Dirección"
      value={entity.address}
      icon={
        <LocationOnOutlined color="primary" fontSize="small" sx={{ ml: 0.5 }} />
      }
    />
    {entity.departament && (
      <MetadataItem label="Piso / Departamento" value={entity.departament} />
    )}
  </Stack>
);
