import React from 'react';
import { SvgIcon } from '@mui/material';

const LSExpense = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M16.7969 10.0898C16.7969 9.67563 17.1327 9.33984 17.5469 9.33984H22.5469C22.9611 9.33984 23.2969 9.67563 23.2969 10.0898C23.2969 10.5041 22.9611 10.8398 22.5469 10.8398H17.5469C17.1327 10.8398 16.7969 10.5041 16.7969 10.0898Z"
      fill="#4335E8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.3555 5.58984C12.7697 5.58984 13.1055 5.92563 13.1055 6.33984V7.58984H15.3555C15.7697 7.58984 16.1055 7.92563 16.1055 8.33984C16.1055 8.75406 15.7697 9.08984 15.3555 9.08984H11.3555C11.1422 9.08984 10.8055 9.16001 10.5437 9.34849C10.3202 9.50945 10.1055 9.78428 10.1055 10.3398C10.1055 10.8954 10.3202 11.1702 10.5437 11.3312C10.8055 11.5197 11.1422 11.5898 11.3555 11.5898H13.3555C13.8088 11.5898 14.4722 11.7197 15.0437 12.1312C15.6535 12.5702 16.1055 13.2954 16.1055 14.3398C16.1055 15.3843 15.6535 16.1094 15.0437 16.5485C14.4722 16.96 13.8088 17.0898 13.3555 17.0898H13.1055V18.3398C13.1055 18.7541 12.7697 19.0898 12.3555 19.0898C11.9413 19.0898 11.6055 18.7541 11.6055 18.3398V17.0898H9.35547C8.94126 17.0898 8.60547 16.7541 8.60547 16.3398C8.60547 15.9256 8.94126 15.5898 9.35547 15.5898H13.3555C13.5688 15.5898 13.9055 15.5197 14.1672 15.3312C14.3908 15.1702 14.6055 14.8954 14.6055 14.3398C14.6055 13.7843 14.3908 13.5094 14.1672 13.3485C13.9055 13.16 13.5688 13.0898 13.3555 13.0898H11.3555C10.9021 13.0898 10.2388 12.96 9.66724 12.5485C9.05745 12.1094 8.60547 11.3843 8.60547 10.3398C8.60547 9.2954 9.05745 8.57024 9.66724 8.13119C10.2388 7.71968 10.9021 7.58984 11.3555 7.58984H11.6055V6.33984C11.6055 5.92563 11.9413 5.58984 12.3555 5.58984Z"
      fill="#4335E8"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.8261 4.08922C7.27906 4.38106 3.82958 8.30371 4.12141 12.8507C4.41325 17.3977 8.3359 20.8472 12.8829 20.5553C16.4782 20.3246 19.3892 17.8232 20.3044 14.5361C20.4156 14.1371 20.8291 13.9036 21.2281 14.0147C21.6272 14.1258 21.8606 14.5394 21.7495 14.9384C20.6684 18.8211 17.2306 21.7794 12.979 22.0523C7.60525 22.3972 2.96939 18.3205 2.62449 12.9468C2.2796 7.57306 6.35626 2.9372 11.73 2.5923C14.2215 2.43239 16.5561 3.22388 18.3758 4.65369C18.7015 4.9096 18.7581 5.38109 18.5022 5.7068C18.2463 6.0325 17.7748 6.08908 17.4491 5.83318C15.9083 4.62257 13.9352 3.95386 11.8261 4.08922Z"
      fill="#4335E8"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSExpense;
