import React from 'react';
import { Box, Typography, Tooltip, LinearProgress } from '@mui/material';
import dayjs from 'dayjs';

import { toDateFormat } from 'src/utils/dates';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useUpdateCalendarEventAssignees } from '@legalsurf/hooks';

import TaskEventStatusChip from '../TaskEventStatusChip';
import { CalendarEventCardDateDescriptor } from './CalendarEventCardDateDescriptor';
import { AssigneesPopover } from '../AssigneesPopover';

export const getEventTimeDescriptor = (event) => {
  const now = dayjs();
  const start = dayjs(event.allDay ? event.start.split('T')[0] : event.start);
  const end = dayjs(event.allDay ? event.end.split('T')[0] : event.end);

  if (now.isBetween(start, end)) {
    return {
      str: 'Termina',
      dateStr: toDateFormat(end, 'DD/MM/YYYY'),
      timeStr: toDateFormat(end, 'HH:mm'),
      color: 'error',
      dates: [
        {
          dateStr: toDateFormat(start, 'dddd DD/MM/YY'),
          timeStr: toDateFormat(start, 'HH:mm'),
        },
        {
          dateStr: toDateFormat(end, 'dddd DD/MM/YY'),
          timeStr: toDateFormat(end, 'HH:mm'),
        },
      ],
    };
  }

  if (now.isSameOrBefore(start)) {
    return {
      str: 'Comienza',
      color: 'primary',
      dates: [
        {
          dateStr: toDateFormat(start, 'dddd DD/MM/YY'),
          timeStr: toDateFormat(start, 'HH:mm'),
        },
        {
          dateStr: toDateFormat(end, 'dddd DD/MM/YY'),
          timeStr: toDateFormat(end, 'HH:mm'),
        },
      ],
    };
  }

  return {
    str: 'Finalizó',
    color: 'grey.500',

    dates: [
      {
        dateStr: toDateFormat(start, 'dddd DD/MM/YY'),
        timeStr: toDateFormat(start, 'HH:mm'),
      },
      {
        dateStr: toDateFormat(end, 'dddd DD/MM/YY'),
        timeStr: toDateFormat(end, 'HH:mm'),
      },
    ],
  };
};

const CalendarEventCard = ({
  assigned,
  color,
  description,
  end,
  start,
  title,
  type,
  state,
  onClick,
  allDay,
  id,
  calendarId,
  todos,
  ...rest
}) => {
  const timeDescriptor = getEventTimeDescriptor({ allDay, start, end });
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  ]);

  const porcetange = Math.floor(
    (todos.filter((todo) => todo.done).length / todos.length) * 100,
  );

  const porcentageTitle = `${porcetange}%`;

  const [updateCalendarEventAssignees] = useUpdateCalendarEventAssignees();

  const handleAssignedSubmit = async (newAssignees) => {
    await updateCalendarEventAssignees({
      variables: {
        id,
        calendarId,
        assigned: newAssignees,
      },
    });
  };

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        transition: '200ms ease all',
        borderTopRightRadius: 3,
        borderBottomRightRadius: 3,
        overflow: 'hidden',
        borderLeft: `3px solid ${color}`,
        '&:hover': {
          backgroundColor: 'primary.100',
        },

        opacity: timeDescriptor.str === 'Finalizó' ? 0.5 : 1,

        ...rest.sx,
      }}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex' }}>
        <CalendarEventCardDateDescriptor
          isAllDay={allDay}
          sx={{
            minWidth: 360,
            padding: 1.5,
          }}
          timeDescriptor={timeDescriptor}
          type={type}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', px: 2, py: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box>
              <Typography fontSize={16} fontWeight="500">
                {title}
              </Typography>

              <Typography
                // TODO: Sanitize input, XSS attack vulnerable
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
                fontSize={14}
                fontWeight={400}
                sx={{
                  whiteSpace: 'pre-line',
                  maxWidth: 800,
                  maxHeight: 60,
                  overflow: 'hidden',
                }}
                variant="body2"
              />
            </Box>
          </Box>

          {(assigned || state) && (
            <Box
              sx={{ display: 'flex', gap: 1, alignItems: 'center', mt: 0.5 }}
            >
              {state && (
                <TaskEventStatusChip
                  calendarId={calendarId}
                  eventId={id}
                  state={state}
                  touchable={hasPermissions}
                />
              )}

              {todos?.length > 0 && (
                <Tooltip title={porcentageTitle}>
                  <LinearProgress
                    color={porcetange === 100 ? 'success' : 'primary'}
                    sx={{ width: 100, borderRadius: 1, height: 10 }}
                    value={porcetange}
                    variant="determinate"
                  />
                </Tooltip>
              )}

              {!!assigned?.length && (
                <AssigneesPopover
                  selectedAssignees={assigned}
                  touchable={hasPermissions}
                  onSubmit={handleAssignedSubmit}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { CalendarEventCardDateDescriptor };
export default CalendarEventCard;
