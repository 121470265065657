import React, { useState } from 'react';
import {
  Grid,
  Grow,
  Typography,
  Box,
  IconButton,
  Tooltip,
  Fade,
  LinearProgress,
  Link,
  Badge,
} from '@mui/material';
import { Download } from '@mui/icons-material';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import { LSX } from 'src/components/icons';
import {
  measureFileIconBackground,
  measureFileIcon,
  formatBytes,
} from 'src/utils/formatters';
import {
  isDropboxFile,
  isGoogleDriveFile,
  isOneDriveFile,
} from 'src/forms/DocumentsForm/schema';
import GoogleDriveIcon from 'src/pages/Dashboard/Settings/logo_drive_2020q4_color_2x_web_64dp.png';
import DropboxIcon from 'src/pages/Dashboard/Settings/Dropbox_logo.svg';
import OneDriveIcon from 'src/pages/Dashboard/Settings/onedrive.svg';
import { useDialog } from 'src/dialogs/Dialogs';

// FIXME: duplicated code
const badgesByKind = {
  dropbox: DropboxIcon,
  drive: GoogleDriveIcon,
  onedrive: OneDriveIcon,
  spaces: null,
};

const File = ({ document, onRemove, downloable, previewable, loading }) => {
  const { extension, name, size, url, kind, isFolder, id } = document;
  const [hovered, setHovered] = useState(false);
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');

  const ExtensionIcon = measureFileIcon(document);

  const { handleDownload, isDownloading, downloadingProgress } =
    useDigitalOceanSpace();

  const handleClickFileName = () => {
    if (previewable) {
      dispatchDocumentPreviewDialog(`File-${document?.id}`, {
        document,
        downloable,
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Grow in>
        <Box
          sx={{
            position: 'relative',
            p: 2,
            borderRadius: 0.25,
            border: `1px dashed`,
            borderColor: 'divider',
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            overflow: 'hidden',
            ...(loading || isDownloading ? { opacity: 0.75 } : {}),
          }}
          onMouseLeave={() => setHovered(false)}
          onMouseOver={() => setHovered(true)}
        >
          <Fade in={loading || isDownloading}>
            <LinearProgress
              color="primary"
              sx={{
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                height: '2px',
              }}
              {...(isDownloading && downloadingProgress > 5
                ? {
                    variant: 'determinate',
                    value: downloadingProgress,
                  }
                : {})}
            />
          </Fade>

          <Tooltip title={extension}>
            <Box
              sx={{
                p: 1,
                borderRadius: 0.5,
                backgroundColor: measureFileIconBackground(document),
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <Box
                    as="img"
                    src={badgesByKind[kind]}
                    sx={{ width: 12, height: 12, bgcolor: 'transparent' }}
                  />
                }
                invisible={!badgesByKind[kind]}
                overlap={isFolder ? 'circular' : 'rectangular'}
              >
                <ExtensionIcon sx={{ color: 'white' }} />
              </Badge>
            </Box>
          </Tooltip>

          <Box sx={{ maxWidth: '70%' }}>
            <Typography
              {...(isGoogleDriveFile(document) ||
              isOneDriveFile(document) ||
              isDropboxFile(document)
                ? {
                    component: Link,
                    href: url,
                    rel: 'noopener',
                    target: '_blank',
                  }
                : {})}
              color="primary"
              sx={{
                textDecoration: 'none',
                cursor: 'pointer',
              }}
              onClick={handleClickFileName}
            >
              {name}
            </Typography>

            {!!size && !isFolder && (
              <Typography
                sx={{ color: 'grey.200', fontSize: 14 }}
                variant="subtitle2"
              >
                {formatBytes(size)}
              </Typography>
            )}
          </Box>

          {(onRemove || downloable) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                ml: 'auto',
                gap: 2,
              }}
            >
              <Grow unmountOnExit in={downloable && hovered}>
                <IconButton
                  disabled={loading}
                  onClick={() => handleDownload(id)}
                >
                  <Download />
                </IconButton>
              </Grow>

              <Grow
                unmountOnExit
                in={hovered && typeof onRemove === 'function'}
                {...(hovered && typeof onRemove === 'function'
                  ? { timeout: 400 }
                  : {})}
              >
                <IconButton
                  disabled={loading}
                  onClick={() => onRemove(document)}
                >
                  <LSX />
                </IconButton>
              </Grow>
            </Box>
          )}
        </Box>
      </Grow>
    </Grid>
  );
};

export default File;
