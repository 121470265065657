import { useMutation, MutationHookOptions } from '@apollo/client';
import {
  MyStudiosQuery,
  ResolveStudioInvitationMutation,
} from '@legalsurf/queries';

import { Studio } from '../types';
import { MyStudiosQueryData } from '../useMyStudios';

export type ResolveStudioInvitationMutationVariables = {
  studio: string;
  $consent: string;
};

export type ResolveStudioIntivationMutationData = {
  resolveInvitation: Studio;
};

export type ResolveStudioInvitationMutationOptions = MutationHookOptions<
  ResolveStudioIntivationMutationData,
  ResolveStudioInvitationMutationVariables
>;

export const useResolveStudioInvitation = (
  options: ResolveStudioInvitationMutationOptions,
) => {
  return useMutation<
    ResolveStudioIntivationMutationData,
    ResolveStudioInvitationMutationVariables
  >(ResolveStudioInvitationMutation, {
    // En realidad deberia ser una invalidacion pero bueno...
    refetchQueries: [MyStudiosQuery],
    ...options,
  });
};
