import React, { useMemo } from 'react';
import { Stack, useTheme, Box, Typography, Link } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { GET_CURRENT_MEMBER_WITH_PERMISSIONS } from 'src/graphql/queries/studios';
import { SIGN_TOC } from 'src/graphql/mutations/user';
import Page from 'src/components/v2/Page';
import { useBreakpoints } from 'src/utils/hooks/common';
import { NavbarBox } from 'src/components/Navbar';

import BrandLogo from 'src/assets/images/brand-logo.png';
import FolderCoffee from 'src/assets/images/folder-coffee.png';
import { useUser as useClerkUser } from '@clerk/clerk-react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Form, {
  CheckField,
  PhoneField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import { useSnackbar } from 'src/components/v3/Snackbar';

const tocsLink = `${import.meta.env.VITE_LS_LANDING_URL}/terms.html`;

const Welcome = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useBreakpoints();
  const { user: clerkUser } = useClerkUser();

  const shouldSubmitPhoneNumber = !clerkUser?.phoneNumbers?.length;
  const shouldSubmitNames = !clerkUser?.fullName;

  const initialValues = useMemo(
    () => ({
      sign: false,
      name: clerkUser?.fullName,
      phone: (clerkUser.primaryPhoneNumberId
        ? clerkUser?.phoneNumbers?.find(
            (phoneNumber) => phoneNumber.id === clerkUser.primaryPhoneNumberId,
          )
        : clerkUser.phoneNumbers?.[0]
      )?.phoneNumber,
    }),
    [
      clerkUser?.fullName,
      clerkUser.phoneNumbers,
      clerkUser.primaryPhoneNumberId,
    ],
  );

  const { openSnackbar } = useSnackbar();

  const [signToc] = useMutation(SIGN_TOC, {
    refetchQueries: [GET_CURRENT_MEMBER_WITH_PERMISSIONS],
  });

  const handleSubmit = async (values) => {
    const itNeedsPlusSign = values.phone?.[0] !== '+';
    const phone = itNeedsPlusSign ? ['+', values.phone].join('') : values.phone;

    if (shouldSubmitPhoneNumber) {
      try {
        await clerkUser.createPhoneNumber({
          phoneNumber: phone,
        });
      } catch (error) {
        const phoneIsAlreadyTaken =
          error?.errors?.[0]?.code === 'form_identifier_exists';

        openSnackbar(
          phoneIsAlreadyTaken
            ? 'El número de celular ha sido tomado por otro usuario, por favor usa uno distinto o intenta iniciar sesión con ese número de celular'
            : 'Ocurrio un error al guardar su número de telefono, por favor intente de nuevo o pruebe un número distinto',
          {
            severity: 'error',
          },
        );

        return;
      }
    }

    await signToc({
      variables: {
        sign: values.sign,
        phone,
      },
    });

    if (location?.state?.referrer) {
      navigate(location.state.referrer, { replace: true });
      return;
    }

    navigate(`/dashboard/studios`, { replace: true });
  };

  return (
    <NavbarBox>
      <Page
        sx={{
          p: 0,
          background: ['white', theme.palette.primary[600]],
          height: 'calc(100vh - 64px)',
          justifyContent: 'center',
          display: 'flex',
        }}
        title="Bienvenido a LegalSurf"
      >
        {!isMobile && (
          <Box
            sx={{
              flex: 1,
              height: '100%',
              backgroundImage: `url(${FolderCoffee})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              alt="LegalSurf"
              component="img"
              src={BrandLogo}
              sx={{
                position: 'absolute',
                width: 200,
                top: 60,
                left: 40,
              }}
            />
          </Box>
        )}

        <Stack
          direction="column"
          sx={{
            flex: 1.3,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'common.white',
            padding: 4,
          }}
        >
          <Stack
            spacing={2}
            sx={{
              maxWidth: 500,
            }}
          >
            <Typography variant="h2">¡Ya casi estamos listos! 🏄🏼‍♂️</Typography>

            <Typography fontWeight={500} variant="h4">
              Antes de continuar necesitamos completes tus siguientes datos:
            </Typography>

            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Este campo es requerido'),
                phone: Yup.string().required('Este campo es requerido'),
                sign: Yup.bool().oneOf(
                  [true],
                  'Debes aceptar los terminos y condiciones para continuar',
                ),
              })}
              onSubmit={handleSubmit}
            >
              <Form id="toc-form">
                <TextField
                  disabled={!shouldSubmitNames}
                  label="Nombre"
                  name="name"
                />

                <PhoneField
                  disabled={!shouldSubmitPhoneNumber}
                  helperText="Podrás cambiar tu número celular luego"
                  label="Número de celular"
                  name="phone"
                />

                <CheckField
                  label={
                    <Link href={tocsLink} target="_blank">
                      Terminos y condiciones
                    </Link>
                  }
                  name="sign"
                />

                <SubmitButton fullWidth form="toc-form" size="large">
                  Siguiente
                </SubmitButton>
              </Form>
            </Formik>
          </Stack>
        </Stack>
      </Page>
    </NavbarBox>
  );
};

export default Welcome;
