import { tasksStatesObj } from '@legalsurf/common';
import dayjs from 'dayjs';

export const keyWordsBackgrounds = {
  entity: '#FFE9F8',
  taskEvent: '#E8FFF3',
  alertEvent: '#fff8dd',
  filecase: '#E5F3FF',
  sale: '#E5FFE7',
  user: '#FFEAD1',
  studio: '#C5FFF4',
  date: '#fcd1b2',
};

export const getSpecialKeyWordBackground = (keyword) => {
  if (keyWordsBackgrounds[keyword.model]) {
    return keyWordsBackgrounds[keyword.model];
  }

  if (keyword.model === 'event') {
    if (keyword.key.startsWith('[alerta')) {
      return keyWordsBackgrounds.alertEvent;
    }

    return keyWordsBackgrounds.taskEvent;
  }

  return null;
};

const convertLexicalHTMLToText = (html) => {
  const divContainer = document.createElement('div');
  divContainer.innerHTML = html;

  // Remove all [data-legalsurf-document-id] tags
  divContainer
    .querySelectorAll('[data-legalsurf-document-id]')
    .forEach((node) => {
      node.remove();
    });

  return divContainer.textContent || divContainer.innerText || '';
};

export const specialKeyWords = [
  { key: '[persona_nombre]', model: 'entity', path: 'displayName' },
  {
    key: '[persona_estado]',
    model: 'entity',
    path: 'state',
    transform: (state, model) => model?.state?.name,
  },
  {
    key: '[persona_identificacion]',
    model: 'entity',
    path: 'identification',
  },
  {
    key: '[persona_telefono]',
    model: 'entity',
    path: 'phone',
  },
  {
    key: '[persona_email]',
    model: 'entity',
    path: 'email',
  },
  {
    key: '[persona_pais]',
    model: 'entity',
    path: 'country',
  },
  {
    key: '[persona_direccion]',
    model: 'entity',
    path: 'address',
  },
  { alert: true, key: '[alerta_titulo]', model: 'event', path: 'title' },
  {
    alert: true,
    key: '[alerta_descripcion]',
    model: 'event',
    path: 'description',
  },
  {
    alert: true,
    key: '[alerta_dia_inicio]',
    model: 'event',
    path: 'start',
    transform: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : undefined),
  },
  {
    alert: true,
    key: '[alerta_hora_inicio]',
    model: 'event',
    path: 'start',
    transform: (date) => (date ? dayjs(date).format('HH:mm') : undefined),
  },
  {
    alert: true,
    key: '[alerta_dia_fin]',
    model: 'event',
    path: 'end',
    transform: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : undefined),
  },
  {
    alert: true,
    key: '[alerta_hora_fin]',
    model: 'event',
    path: 'end',
    transform: (date) => (date ? dayjs(date).format('HH:mm') : undefined),
  },
  { key: '[expediente_numero]', model: 'filecase', path: 'externalId' },
  { key: '[expediente_titulo]', model: 'filecase', path: 'title' },
  { key: '[expediente_descripcion]', model: 'filecase', path: 'description' },
  {
    key: '[expediente_estado]',
    model: 'filecase',
    path: 'state.name',
    transform: (state, model) => model?.state?.name,
  },
  {
    key: '[expediente_ultima_actuacion]',
    model: 'filecase',
    path: 'latestEntry',
    transform: (entry, model) => {
      if (!model?.latestEntry) {
        return '';
      }

      const date = dayjs(model.latestEntry.date, { locale: 'es' });

      // TODO: bug here, migrate to new lexical format
      return [
        date.isValid() ? date.format('DD/MM/YYYY') : null,
        convertLexicalHTMLToText(model.latestEntry.content),
      ]
        .filter((val) => !!val)
        .join(' - ');
    },
  },
  { task: true, key: '[tarea_titulo]', model: 'event', path: 'title' },
  {
    task: true,
    key: '[tarea_descripcion]',
    model: 'event',
    path: 'description',
  },
  {
    task: true,
    key: '[tarea_dia_inicio]',
    model: 'event',
    path: 'start',
    transform: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : undefined),
  },
  {
    task: true,
    key: '[tarea_hora_inicio]',
    model: 'event',
    path: 'start',
    transform: (date) => (date ? dayjs(date).format('HH:mm') : undefined),
  },
  {
    task: true,
    key: '[tarea_dia_fin]',
    model: 'event',
    path: 'end',
    transform: (date) => (date ? dayjs(date).format('DD/MM/YYYY') : undefined),
  },
  {
    task: true,
    key: '[tarea_hora_fin]',
    model: 'event',
    path: 'end',
    transform: (date) => (date ? dayjs(date).format('HH:mm') : undefined),
  },
  {
    task: true,
    key: '[tarea_estado]',
    model: 'event',
    path: 'state',
    transform: (state, event) => tasksStatesObj?.[event?.state]?.label ?? '',
  },
  { key: '[honorario_saldo_total]', model: 'sale', path: 'amount' },
  {
    key: '[honorario_saldo_deudor]',
    model: 'sale',
    path: 'amount',
    transform: (amount, sale) => (sale ? amount - sale.charged || 0 : ''),
  },
  {
    key: '[honorario_saldo_pagado]',
    model: 'sale',
    path: 'charged',
    transform: (charged, sale) => (sale ? charged || 0 : ''),
  },
  {
    key: '[honorario_fecha]',
    model: 'sale',
    path: 'date',
    transform: (date) => (date ? dayjs(date).format('LLL') : undefined),
  },
  {
    key: '[honorario_concepto]',
    model: 'sale',
    path: 'concept',
    transform: (_, sale) =>
      sale?.items?.map((item) => item.description).join(', ') ?? sale.concept,
  },
  {
    key: '[honorario_expediente]',
    model: 'sale',
    path: 'filecase.title',
    transform: (_, sale) => sale?.filecase?.title,
  },

  { key: '[estudio_nombre]', model: 'studio', path: 'name' },
  {
    key: '[estudio_telefono]',
    model: 'studio',
    path: 'address.phone',
    transform: (_, model) => model?.address?.phone,
  },
  {
    key: '[estudio_direccion]',
    model: 'studio',
    path: 'address.street',
    transform: (_, model) => model?.address?.street,
  },
  {
    key: '[estudio_pais]',
    model: 'studio',
    path: 'address.country',
    transform: (_, model) => model.country || model?.address?.country,
  },
  {
    key: '[fecha]',
    model: 'date',
    transform: () => dayjs().format('DD/MM/YYYY - HH:mm'),
  },
  {
    key: '[fecha_dia]',
    model: 'date',
    transform: () => dayjs().format('DD/MM/YYYY'),
  },
  {
    key: '[fecha_hora]',
    model: 'date',
    transform: () => dayjs().format('HH:mm'),
  },
  {
    key: '[usuario_nombre]',
    model: 'user',
    path: 'name',
    transform: (_, model) => model.name,
  },
  {
    key: '[usuario_email]',
    model: 'user',
    path: 'email',
    transform: (_, model) => model.email,
  },
  {
    key: '[usuario_telefono]',
    model: 'user',
    path: 'phone',
    transform: (_, model) => model.phone,
  },
];

export const lastestEntryTransform = specialKeyWords.find(
  (keyword) => keyword.key === '[expediente_ultima_actuacion]',
).transform;

export const populateSpecialKeyWords = (body, data) =>
  specialKeyWords.reduce((acc, { key, model, path, transform }) => {
    const value = data[model]?.[path] ?? '';

    if (!acc.includes(key)) {
      return acc;
    }

    if (transform) {
      return acc.replaceAll(key, transform(value, data[model]) ?? '');
    }
    return acc.replaceAll(key, value ?? '');
  }, body);

const whatsappBaseURL = 'https://api.whatsapp.com/send';

export const sendWhatsappMsg = (phone, body) => {
  // Generate whatsapp api URL
  const params = new URLSearchParams();
  params.append('phone', phone?.replaceAll(' ', '') || '');
  params.append('text', body, { encode: true });

  const url = `${whatsappBaseURL}?${params.toString()}`;

  // Open whatsapp in new tab
  window.open(url, '_blank');
};

export function isValidWhatsappNumber(phone) {
  const trimmedPhone = phone?.replaceAll(' ', '');

  const patternWithCountry = /^\+\d{1,3}\d{6,14}$/;
  const patternWithoutCountry = /^\d{6,14}$/;

  return (
    patternWithCountry.test(trimmedPhone) ||
    patternWithoutCountry.test(trimmedPhone)
  );
}

export const exampleEntityForWhatsApp = {
  id: 1,
  displayName: 'Hugo Summ (ejemplo)',
  state: {
    name: 'Contactado',
  },
};
