import { useQuery } from '@apollo/client';
import { CalendarsQuery } from '@legalsurf/queries';

export const useCalendars = ({ studioId }) => {
  const query = useQuery(CalendarsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      studio: studioId,
    },
  });

  return {
    ...query,
    data: query.data?.calendars ?? [],
  };
};
