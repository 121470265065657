import {
  categorizeFileExtension,
  humanizeFileCategory,
  humanizedCategories,
} from 'src/utils/formatters';
import { fileTypes } from './file-types';

export const getDocumentTypeOptions = () =>
  Object.entries(humanizedCategories).reduce(
    (acc, [value, label]) => [
      ...acc,
      {
        value,
        label,
      },
    ],
    [],
  );

export const getCategorizedFileTypes = () =>
  Object.keys(fileTypes).reduce((categories, extension) => {
    const fileCategory = categorizeFileExtension({ extension });
    const categoryLabel = humanizeFileCategory(fileCategory);

    if (!categories[fileCategory]) {
      // eslint-disable-next-line no-param-reassign
      categories[fileCategory] = {
        label: categoryLabel,
        value: [extension],
      };
    } else {
      // eslint-disable-next-line no-param-reassign
      categories[fileCategory] = {
        ...categories[fileCategory],
        value: [...categories[fileCategory].value, extension],
      };
    }

    return categories;
  }, {});
