import React, { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import { GET_PAGINATED_ENTITIES } from 'src/graphql/queries/entities';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import Header, { useEntitiesFilters } from './Header';
import { useColumns } from './columns';
import { useEntitiesToExport } from './excel';
import { Stack } from '@mui/material';
import { useDialog } from 'src/dialogs/Dialogs';

const DirectoriesTable = () => {
  const dispatchEntityDialog = useDialog('entity');

  const studioId = useStudioId();
  const { filters } = useEntitiesFilters();
  const [selectedItems, setSelectedItems] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: defaultPageSize,
    orderBy: undefined,
    sortOrder: undefined,
  });

  const queryVariables = {
    studio: studioId,
    limit: pagination.limit,
    offset: pagination.page * pagination.limit,
    orderBy: pagination.orderBy,
    sortOrder: pagination.sortOrder,

    search: filters.search,
    type: filters.type?.value ?? filters.type,
    state: filters.state?.value ?? filters.state,
    tags: filters.tags?.map((tag) => tag?.value ?? tag),
  };

  const {
    data: { paginatedEntities } = { paginatedEntities: {} },
    loading,
    previousData,
  } = useQuery(GET_PAGINATED_ENTITIES, {
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    skip: !studioId,
  });

  const handlePageChange = useCallback(({ pageSize, page }) => {
    setPagination({ page, limit: pageSize });
  }, []);

  const { getEntitiesToExport, loading: loadingEntitiesToExport } =
    useEntitiesToExport();

  const handleDownloadAsExcel = useCallback(async () => {
    const params = { ...queryVariables, ids: selectedItems };

    if (!selectedItems?.length) {
      params.limit = paginatedEntities?.data?.count;
    }

    await getEntitiesToExport(params);
  }, [
    queryVariables,
    selectedItems,
    paginatedEntities?.data?.count,
    getEntitiesToExport,
  ]);

  const columns = useColumns();

  const rowCount =
    (paginatedEntities?.count || previousData?.paginatedEntities?.count) ?? 0;

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        loadingEntitiesToExport={loadingEntitiesToExport}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        subtitle={`Totales ${rowCount}`}
        title="Directorio"
        totalItems={rowCount}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: { variant: 'entities' },
          noRowsOverlay: { variant: 'entities' },
        }}
        loading={loading || loadingEntitiesToExport}
        paginationModel={{ page: pagination.page, pageSize: pagination.limit }}
        rowCount={rowCount}
        rowSelectionModel={selectedItems}
        rows={paginatedEntities?.data ?? []}
        onPaginationModelChange={handlePageChange}
        onRowSelectionModelChange={setSelectedItems}
        onRowClick={(params) =>
          dispatchEntityDialog(params.row.id, { id: params.row.id })
        }
        onSortModelChange={([column]) => {
          setPagination((prev) =>
            column
              ? {
                  ...prev,
                  page: 0,
                  limit: defaultPageSize,
                  orderBy: column.field,
                  sortOrder: column.sort,
                }
              : {
                  ...prev,
                  page: 0,
                  limit: defaultPageSize,
                  orderBy: undefined,
                  sortOrder: undefined,
                },
          );
        }}
      />
    </Stack>
  );
};

export default DirectoriesTable;
