import { parseCustomFieldsToInitialValues } from 'src/components/ui-components/Form/CustomFields';

import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';

import {
  CREATE_FILECASE,
  UPDATE_FILECASE,
} from 'src/graphql/mutations/filecases';
import {
  GET_FILECASE_SINGLE_DATA,
  GET_PAGINATED_FILECASES,
} from 'src/graphql/queries/filecases';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { GET_ENTITY_SINGLE_PAGE_METADATA } from 'src/graphql/queries/entities';
import { HomeFilecaseSectionSummaryQuery } from 'src/pages/Dashboard/Home/FilecasesSection';
import { useStudioMembers } from '@legalsurf/hooks';
import { useDefaultCanBeSeenBy } from 'src/components/v2/FilecaseDetail/SingleFilecaseHeader';

export const useFilecaseForm = ({ filecase, entities = [], onSubmit }) => {
  const studioId = useStudioId();

  const { data: members } = useStudioMembers({
    variables: {
      studio: studioId,
      status: ['ACTIVE'],
    },
  });

  const { fixedCanBeSeenByIds, defaultCanBeSeenBy } = useDefaultCanBeSeenBy(
    filecase?.canBeSeenBy || [],
    filecase?.assigned || [],
  );

  const [createFilecase] = useMutation(CREATE_FILECASE, {
    refetchQueries: () => {
      let defaultRefetchQueries = [
        HomeFilecaseSectionSummaryQuery,
        GET_PAGINATED_FILECASES,
      ];

      if (entities?.length) {
        defaultRefetchQueries = [
          ...defaultRefetchQueries,
          ...entities.map((entityId) => ({
            query: GET_ENTITY_SINGLE_PAGE_METADATA,
            variables: {
              studio: studioId,
              entity: entityId,
            },
          })),
        ];
      }

      return defaultRefetchQueries;
    },
  });

  const [updateFilecase] = useMutation(UPDATE_FILECASE, {
    refetchQueries: [GET_FILECASE_SINGLE_DATA],
  });

  const mapMemberToOption = (member) => ({
    label: member.name,
    value: member.id,
    picture: member.picture,
    disabled: fixedCanBeSeenByIds.includes(member.id),
  });

  const initialValues = useMemo(() => {
    const obj = {
      ...parseCustomFieldsToInitialValues(filecase?.customFields),
      title: filecase.title || '',
      externalId: filecase.externalId || '',
      description: filecase.description || '',
      category: filecase.category
        ? { label: filecase.category.name, value: filecase.category.name }
        : null,
      side: filecase.side
        ? { label: filecase.side.name, value: filecase.side.name }
        : null,
      state: filecase.state
        ? {
            label: filecase.state.name,
            value: filecase.state.name,
            color: filecase.state.color,
          }
        : null,
      tags: filecase.tags
        ? filecase.tags.map((tag) => ({ label: tag.name, value: tag.name }))
        : [],
      assigned: [],
      id: filecase.id,
      canBeSeenBy:
        filecase?.canBeSeenBy?.length === 0 || !filecase?.canBeSeenBy
          ? members?.map(mapMemberToOption)
          : filecase?.canBeSeenBy.map(mapMemberToOption),
    };

    if (filecase.assigned) {
      obj.assigned = filecase.assigned.map((assigned) => ({
        label: assigned.name,
        value: assigned.id,
        picture: assigned.picture,
      }));
    } else if (members?.length === 1) {
      obj.assigned = [
        {
          label: members[0].name,
          value: members[0].id,
        },
      ];
    }

    return obj;
  }, [
    mapMemberToOption,
    defaultCanBeSeenBy,
    filecase.assigned,
    filecase.canBeSeenBy,
    filecase.category,
    filecase?.customFields,
    filecase.description,
    filecase.externalId,
    filecase.id,
    filecase.side,
    filecase.state,
    filecase.tags,
    filecase.title,
    members,
  ]);

  const { openSnackbar } = useSnackbar();

  const handleSubmit = useCallback(
    async (values, formikBag) => {
      // Either edit or create filecase
      const isEdit = !!(filecase && filecase.id);
      const mutation = isEdit ? updateFilecase : createFilecase;

      const query = {
        variables: {
          entities,
          studio: studioId,
          title: values.title,
          externalId: values.externalId,
          description: values.description,
          // TODO: Create helper to ease this process
          category: values?.category?.value ?? values?.category?.label ?? null,
          side: values?.side?.value ?? values?.side?.label ?? null,
          state: values?.state?.value ?? values?.state?.label ?? null,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label),
          assigned: values.assigned.map(
            (member) => member.value ?? member.label,
          ),
          canBeSeenBy:
            members.length === values.canBeSeenBy?.length ||
            values.canBeSeenBy?.length === 0
              ? []
              : values.canBeSeenBy.map((member) => member.value),
        },
      };

      if (isEdit) {
        query.variables.filecase = filecase.id;
      }

      try {
        await mutation(query);

        onSubmit();
        formikBag.resetForm();
        openSnackbar(
          isEdit
            ? 'Expediente editado con exito.'
            : 'Expediente creado con exito.',
          { severity: 'success' },
        );
      } catch (error) {
        openSnackbar(error.message ?? 'Hubo un error, intente mas tarde.', {
          severity: 'error',
        });
      } finally {
        formikBag.setSubmitting(false);
      }
    },
    [
      openSnackbar,
      createFilecase,
      entities,
      filecase,
      onSubmit,
      studioId,
      updateFilecase,
    ],
  );

  return { handleSubmit, initialValues };
};
