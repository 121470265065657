import React from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import {
  CheckCircleRounded,
  MailOutlineRounded,
  DoNotDisturbOnRounded,
} from '@mui/icons-material';

import GoogleMeetIMG from 'src/assets/images/googlemeet.png';

const responseStatusPerIcon = {
  accepted: <CheckCircleRounded color="success" />,
  declined: <DoNotDisturbOnRounded color="error" />,
};

const GoogleCalendarSection = ({ event }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 0.5,
      pl: 0.5,
    }}
  >
    {event.googleCalendarData.hangoutLink && (
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box component="img" src={GoogleMeetIMG} sx={{ height: 24 }} />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              color="secondary"
              href={event.googleCalendarData.hangoutLink}
              rel="noreferrer"
              size="small"
              target="_blank"
              variant="contained"
            >
              Unirse con Google Meet
            </Button>

            {event.googleCalendarData.reminders.useDefault && (
              <Tooltip title="Google te avisara 30 minutos antes de que comience tu evento">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box as="span" fontSize={18}>
                    🔔
                  </Box>
                  30 minutos antes
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    )}

    {!!event.googleCalendarData.attendees?.length && (
      <Box>
        {event.googleCalendarData.attendees?.map((attendant) => (
          <Box
            key={attendant.email}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <MailOutlineRounded sx={{ width: 16 }} />

            {attendant.email}

            {responseStatusPerIcon[attendant.responseStatus]}
          </Box>
        ))}
      </Box>

    )}

    {event.googleCalendarData.organizer?.displayName && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box as="span" fontSize={18}>
          🗓
        </Box>

        {event.googleCalendarData.organizer?.displayName}
      </Box>
    )}
  </Box>
);

export default GoogleCalendarSection;
