import React, { useState } from 'react';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Divider,
  Typography,
  Stack,
  Avatar,
  Button,
  IconButton,
  Fade,
  Switch,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  ArrowBack,
  Check,
  ChevronRight,
  Clear,
  ExpandLess,
  MailOutline,
  PersonAddAlt1,
  PriorityHigh,
} from '@mui/icons-material';
import { useOrganization } from '@clerk/clerk-react';
import { gql, useMutation } from '@apollo/client';

import { useStudioMembers } from '@legalsurf/hooks';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useUser } from 'src/utils/hooks/useUser';
import { permissionModules } from 'src/pages/Dashboard/Settings/StudioMember/StudioMemberPermissions';
import { useMemberACL } from 'src/utils/contexts/StudioMemberContext';
import Form, {
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { LSTrash } from 'src/components/icons';
import DisabledBackdrop from 'src/components/v2/DisabledBackdrop';
import { Badge } from 'src/components/Badge';
import { useDialog } from 'src/dialogs/Dialogs';

const roleToColor = {
  owner: '#8E35E8',
  admin: '#24C9DB',
  member: '#4BB1FF',
};

const roleToi18n = {
  owner: 'Propietario',
  admin: 'Administrador',
  member: 'Miembro',
};

const statusToi18n = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  PENDING: 'Este usuario debe aceptar la invitación en su correo electrónico',
};

const statusToComponent = {
  ACTIVE: (
    <Stack bgcolor="success.200" borderRadius={1} p={0.75}>
      <Check
        fontSize="inherit"
        sx={{
          stroke: (theme) => theme.palette.success.main,
          strokeWidth: 2,
        }}
      />
    </Stack>
  ),
  INACTIVE: (
    <Stack bgcolor="error.200" borderRadius={1} p={0.75}>
      <Clear
        fontSize="inherit"
        sx={{
          stroke: (theme) => theme.palette.error.main,
          strokeWidth: 2,
        }}
      />
    </Stack>
  ),
  PENDING: (
    <Stack bgcolor="warning.light" borderRadius={1} p={0.75}>
      <PriorityHigh
        fontSize="inherit"
        sx={{
          color: '#D95A32',
          strokeWidth: 2,
        }}
      />
    </Stack>
  ),
};

const InviteUserSchemaValidation = Yup.object().shape({
  email: Yup.string()
    .email('Debe ingresar un email válido')
    .required('Debe ingresar un email válido'),
});

const defaultInvitedUserPermissions = [
  LS_PERMISSIONS_MAP.FILECASES.ACCESS,
  LS_PERMISSIONS_MAP.FILECASES.READ,
  LS_PERMISSIONS_MAP.FILECASES.CREATE,
  LS_PERMISSIONS_MAP.FILECASES.UPDATE,

  LS_PERMISSIONS_MAP.DIRECTORY.ACCESS,
  LS_PERMISSIONS_MAP.DIRECTORY.READ,
  LS_PERMISSIONS_MAP.DIRECTORY.CREATE,
  LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,

  LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS,
  LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
  LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE,

  LS_PERMISSIONS_MAP.CALENDAR.ACCESS,
  LS_PERMISSIONS_MAP.CALENDAR.CREATE,
  LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  LS_PERMISSIONS_MAP.CALENDAR.DELETE,

  LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
];

const InviteUserMutation = gql`
  mutation InviteUser($email: ID!, $studioId: ID!, $permissions: [ID!]) {
    sendMemberInvitation(
      email: $email
      studioId: $studioId
      permissions: $permissions
    )
  }
`;

const InviteUserView = ({ everyone, onBack }) => {
  const [selectedPermissions, setSelectedPermissions] = useState(
    defaultInvitedUserPermissions,
  );
  const { openSnackbar } = useSnackbar();
  const studioId = useStudioId();
  const [inviteUser] = useMutation(InviteUserMutation);
  const { invitations } = useOrganization({
    invitations: {
      infinite: true,
      status: 'pending',
    },
  });

  const onSubmit = async (values) => {
    try {
      await inviteUser({
        variables: {
          studioId,
          email: values.email,
          permissions: selectedPermissions,
        },
      });

      await invitations?.revalidate?.();

      onBack();
    } catch (error) {
      if (error?.message === 'Forbidden') {
        if (everyone?.length >= 10) {
          openSnackbar(
            'No puedes invitar más de 10 miembros a tu estudio, adquiere un plan de pago para poder invitar más',
            {
              severity: 'error',
            },
          );
          return;
        }
      }

      openSnackbar(
        'Hubo un error al invitar al usuario, intente de nuevo mas tarde',
        {
          severity: 'error',
        },
      );

      Sentry.captureException(error);
    }
  };

  return (
    <Stack gap={2} pb={2}>
      <Stack direction="row" alignItems="center" gap={1} mb={2}>
        <IconButton onClick={onBack}>
          <ArrowBack />
        </IconButton>

        <Typography variant="h6" fontSize={20} fontWeight="600">
          Invitar usuario
        </Typography>
      </Stack>

      <Stack direction="row" alignItems="center" gap={1}>
        <MailOutline color="primary" />

        <Typography fontWeight="600" fontSize={18}>
          Invita a tu equipo de trabajo
        </Typography>
      </Stack>

      <Box>
        <Typography fontWeight="500" fontSize={14}>
          La persona deberá registrarse en legalsurf y aceptar la invitación
          para formar parte de tu equipo y acceder a tu espacio de trabajo.
        </Typography>
      </Box>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={InviteUserSchemaValidation}
        onSubmit={onSubmit}
        validateOnMount
      >
        <Form style={{ width: '100%' }} gridProps={{ alignItems: 'center' }}>
          <TextField
            label="Email del nuevo usuario"
            name="email"
            gridProps={{ xs: 6 }}
            noFormControlLabel
          />

          <SubmitButton gridProps={{ sx: 4 }}>Agregar usuario</SubmitButton>
        </Form>
      </Formik>

      <Accordion
        elevation={0}
        disableGutters
        sx={{
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: 1,
          '&::before': { display: 'none' },
        }}
      >
        <AccordionSummary expandIcon={<ExpandLess />}>
          <Stack>
            <Typography fontWeight="bold" fontSize={18} color="primary">
              Configurar permisos
            </Typography>

            <Typography fontWeight="400" fontSize={15} color="primary">
              Seleccionalos permisos del usuario
            </Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails>
          <Stack gap={4}>
            {permissionModules.map((module) => (
              <Stack gap={0.5} key={module.name}>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  {
                    <module.Icon
                      color="primary"
                      sx={{ width: 28, height: 28 }}
                    />
                  }

                  <Typography variant="h4" fontWeight="600">
                    {module.name}
                  </Typography>
                </Stack>

                <Stack
                  pl={1}
                  gap={0.5}
                  justifyContent="center"
                  divider={<Divider />}
                >
                  {module.actions.map((moduleAction) => (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      key={moduleAction.slug}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="600"
                        fontSize={14}
                      >
                        {moduleAction.name}
                      </Typography>

                      <Switch
                        checked={selectedPermissions.includes(
                          moduleAction.slug,
                        )}
                        edge="end"
                        onChange={(event) =>
                          setSelectedPermissions(
                            event.target.checked
                              ? [...selectedPermissions, moduleAction.slug]
                              : selectedPermissions.filter(
                                  (item) => item !== moduleAction.slug,
                                ),
                          )
                        }
                      />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

const DeleteMemberMutation = gql`
  mutation DeleteMemberMutation($studioId: ID!, $memberId: ID!) {
    deleteMember(studioId: $studioId, memberId: $memberId) {
      id
    }
  }
`;

const PermissionsView = ({ member, onBack }) => {
  const studioId = useStudioId();
  const {
    handleTogglePermission,
    hasAdminPrivileges,
    loading,
    checkedPermissions,
    handleToggleAllPermissions,
  } = useMemberACL(member.id);
  const { user, loading: userLoading } = useUser();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deleteMember] = useMutation(DeleteMemberMutation);
  const canDeleteMember = ['owner'].includes(user?.role);

  const handleDeleteUser = async () => {
    onBack();

    deleteMember({
      variables: {
        studioId,
        memberId: member.id,
      },
      update: (cache, result) => {
        cache.evict(cache.identify(result.data.deleteMember));
      },
    });
  };

  return (
    <Stack pb={4}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <IconButton onClick={onBack}>
          <ArrowBack />
        </IconButton>

        {canDeleteMember && (
          <IconButton
            onClick={() =>
              dispatchWarningPromptDialog(
                `ManageMembersPopoverBodyDeleteUser`,
                {
                  content: (
                    <Alert severity="error">
                      ¿Estás seguro que deseas eliminar al miembro {member.name}{' '}
                      del estudio?
                      <br />
                      Todos los cambios que esta persona realizo en el estudio
                      van a permanecer, tendras que invitarlo de nuevo en caso
                      de volver a solicitarlo.
                    </Alert>
                  ),
                  onAccept: handleDeleteUser,
                },
              )
            }
          >
            <LSTrash color="primary" />
          </IconButton>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" gap={2}>
        <Avatar src={member.picture} sx={{ width: 90, height: 90 }} />

        <Stack direction="row" gap={1}>
          <Stack>
            <Stack direction="row" gap={1}>
              <Typography variant="h5" fontWeight="800">
                {member.name}
              </Typography>

              <Box>{statusToComponent[member.status]}</Box>
            </Stack>

            <Typography variant="body2" fontWeight="600" mb={1}>
              {member.email}
            </Typography>

            <Badge
              backgroundColor={roleToColor[member.role]}
              label={roleToi18n[member.role]}
              textProps={{ fontSize: 12 }}
              height={36}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack gap={2} mt={3}>
        <Stack sx={{ bgcolor: 'primary.200', p: 2, borderRadius: 1 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            width="100%"
          >
            <Stack>
              <Typography
                variant="body2"
                fontWeight="700"
                color="primary"
                fontSize={16}
              >
                Activar permisos de administrador
              </Typography>

              <Typography
                variant="body2"
                fontWeight="500"
                color="primary"
                fontSize={13}
              >
                Los usuarios Administradores pueden ver, modificar y eliminar
                toda la información.
              </Typography>
            </Stack>

            <Switch
              checked={hasAdminPrivileges}
              disabled={loading || user.role !== 'owner'}
              onChange={(event) => {
                const { checked } = event.target;
                handleToggleAllPermissions(checked);
              }}
            />
          </Stack>
        </Stack>

        <Box
          sx={{
            border: '1px solid',
            borderColor: 'primary.main',
            p: 2,
            borderRadius: 1,
          }}
        >
          {permissionModules.map((module) => (
            <Stack gap={0.5} key={module.name}>
              <Stack direction="row" alignItems="center" gap={0.5}>
                {<module.Icon color="primary" sx={{ width: 30, height: 30 }} />}

                <Typography variant="h4" fontWeight="600">
                  {module.name}
                </Typography>
              </Stack>

              <Stack
                pl={1}
                gap={0.5}
                justifyContent="center"
                divider={<Divider />}
              >
                {module.actions.map((moduleAction) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    key={moduleAction.slug}
                  >
                    <Typography variant="body2" fontWeight="600" fontSize={14}>
                      {moduleAction.name}
                    </Typography>
                    {/* <ListItemText primary={moduleAction.name} /> */}

                    <Switch
                      checked={
                        checkedPermissions.indexOf(moduleAction.slug) !== -1 ||
                        hasAdminPrivileges
                      }
                      disabled={
                        !member ||
                        loading ||
                        userLoading ||
                        user.id === member.id ||
                        user.role === 'member' ||
                        member.role === 'admin' ||
                        (user.role === 'admin' && member.role === 'owner')
                      }
                      edge="end"
                      onChange={() => handleTogglePermission(moduleAction.slug)}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

const MemberItem = ({ member, onClick }) => {
  const { user } = useUser();
  const canViewPermissions =
    user.id !== member.id &&
    member.role !== 'owner' &&
    user.role !== 'member' &&
    user.role !== member.role;

  const isInvited = member.id.startsWith('orginv_');

  const { invitations } = useOrganization({
    invitations: {
      infinite: true,
      status: 'pending',
    },
  });

  const handleRevokeUser = async () => {
    await member.revoke();
    return invitations?.revalidate?.();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        width={250}
        maxWidth={250}
        overflow="hidden"
      >
        <Avatar src={member.picture} />

        <Stack>
          <Typography variant="body2" fontWeight="600">
            {member.name}
          </Typography>

          <Typography variant="body2">{member.email}</Typography>
        </Stack>
      </Stack>

      <Badge
        backgroundColor={roleToColor[member.role]}
        label={roleToi18n[member.role]}
        textProps={{ fontSize: 12 }}
        height={36}
      />

      <Tooltip title={statusToi18n[member.status]} arrow>
        {statusToComponent[member.status]}
      </Tooltip>

      {canViewPermissions && isInvited && (
        <Tooltip title="Revocar invitación" arrow>
          <IconButton color="error" onClick={handleRevokeUser}>
            <LSTrash fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Stack direction="row" alignItems="center" gap={1} ml="auto">
        {canViewPermissions && (
          <Tooltip
            title={
              isInvited
                ? 'Podrás modificar los permisos del usuario una vez se haya unido a tu estudio'
                : ''
            }
            arrow
            placement="top"
          >
            <span>
              <Button
                variant="text"
                endIcon={<ChevronRight />}
                onClick={onClick}
                disabled={isInvited}
                sx={{ textTransform: 'none' }}
              >
                Ver permisos
              </Button>
            </span>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
};

export const ManageMembersPopoverBody = ({
  defaultIsInviteOpen = false,
  defaultMemberId = null,
  ...rest
}) => {
  const studioId = useStudioId();
  const { user } = useUser();

  const { invitations } = useOrganization({
    invitations: {
      infinite: true,
      status: 'pending',
    },
  });

  const { data: members = [] } = useStudioMembers({
    variables: {
      status: 'ACTIVE',
      studio: studioId,
    },
  });

  const everyone = [...(members || []), ...(invitations?.data || [])].map(
    (member) => ({
      ...member,
      ...(member.emailAddress
        ? {
            id: member.id,
            name: '',
            email: member.emailAddress,
            studioId: member.organizationId,
            status: member.status.toUpperCase(),
            permissions: member.publicMetadata.permissions,
            role: member.publicMetadata.role ?? 'member',
          }
        : {}),
    }),
  );

  const [selectedMemberId, setSelectedMemberId] =
    React.useState(defaultMemberId);
  const [isInviteOpen, setIsInviteOpen] = React.useState(defaultIsInviteOpen);

  const view = selectedMemberId ? 'member' : isInviteOpen ? 'invite' : 'list';

  return (
    <Stack p={2} {...rest}>
      <Fade in key={view}>
        <Box>
          {view === 'member' && (
            <PermissionsView
              member={everyone.find((member) => member.id === selectedMemberId)}
              onBack={() => setSelectedMemberId(null)}
            />
          )}

          {view === 'list' && (
            <Stack gap={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack>
                  <Typography variant="h6" fontWeight="600">
                    Administrar equipo
                  </Typography>

                  <Typography variant="body2" fontFamily="500">
                    Aquí podrás gestionar a los usuarios
                  </Typography>
                </Stack>

                <Tooltip
                  title={
                    ['admin', 'owner'].includes(user.role)
                      ? ''
                      : 'La invitación de usuarios solo esta permitida para administradores'
                  }
                  arrow
                  placement="top"
                >
                  <span>
                    <Button
                      variant="contained"
                      endIcon={<PersonAddAlt1 fontSize="small" />}
                      onClick={() => setIsInviteOpen(true)}
                      disabled={!['admin', 'owner'].includes(user.role)}
                      sx={{ textTransform: 'none' }}
                    >
                      Invitar usuario
                    </Button>
                  </span>
                </Tooltip>
              </Stack>

              <DisabledBackdrop in={invitations?.isFetching}>
                <Stack
                  gap={2}
                  pb={2}
                  divider={<Divider sx={{ borderColor: '#EAE9FF' }} />}
                >
                  {everyone.map((member) => (
                    <MemberItem
                      key={member.id}
                      member={member}
                      onClick={() => setSelectedMemberId(member.id)}
                    />
                  ))}
                </Stack>
              </DisabledBackdrop>
            </Stack>
          )}

          {view === 'invite' && (
            <InviteUserView
              everyone={everyone}
              onBack={() => setIsInviteOpen(false)}
            />
          )}
        </Box>
      </Fade>
    </Stack>
  );
};
