import React from 'react';
import { lighten, Stack } from '@mui/material';
import { Formik } from 'formik';
import { gql, useMutation } from '@apollo/client';

import Page from 'src/components/v2/Page';
import { FilecaseOnboardingForm, Schema } from './FilecaseOnboardingForm';

import { FilecaseMock } from './FilecaseMock';
import { CREATE_FILECASE } from 'src/graphql/mutations/filecases';
import { useOrganization } from '@clerk/clerk-react';
import { CREATE_STATE } from 'src/graphql/mutations/state';
import { useGoogleCalendar } from 'src/utils/hooks/useGoogleCalendar';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'src/utils/hooks/useUser';
import { usePostHog } from 'posthog-js/react';

const initialValues = {
  categories: [],
  state: null,
  name: '',
  syncGoogleCalendar: false,
  category: null,
};

const CreateCategoriesMutation = gql`
  mutation CreateCategories($studioId: ID!, $names: [ID!]!) {
    createManyCategory(studioId: $studioId, names: $names) {
      id
      name
    }
  }
`;

export const FilecaseCreation = () => {
  const navigate = useNavigate();
  const [createFilecase] = useMutation(CREATE_FILECASE);
  const { organization } = useOrganization();
  const [createState] = useMutation(CREATE_STATE);
  const { redirectToGoogleCalendarAuthorzation } = useGoogleCalendar();
  const [createCategories] = useMutation(CreateCategoriesMutation);
  const { user } = useUser();
  const posthog = usePostHog();

  const handleSubmit = async (values) => {
    if (values.state) {
      await createState({
        variables: {
          name: values.state.value,
          studioId: organization.id,
          color: values.state.color,
        },
      });
    }

    if (values.categories?.length) {
      await createCategories({
        variables: {
          studioId: organization.id,
          names: values.categories,
        },
      });
    }

    await createFilecase({
      variables: {
        studio: organization.id,
        title: values.name,
        category: values.category?.value,
        state: values.state?.value,
        assigned: [user.id],
        canBeSeenBy: [],
      },
    });

    posthog.group('studio', organization.id, {
      onboarding: {
        filecaseOnboardingCategory: values.category?.value,
        createdCategories: values.categories.length,
        continueWithGoogleCalendar: !!values.syncGoogleCalendar,
      },
    });

    posthog.capture('webappOnboarding', {
      step: 'filecaseCreation',
      continueWithGoogleCalendar: !!values.syncGoogleCalendar,
    });

    if (values.syncGoogleCalendar) {
      await redirectToGoogleCalendarAuthorzation({
        studioId: organization.id,
        redirectSuccess: `/dashboard/${organization.id}/home?onboarding=completed`,
      });
      return;
    }

    navigate(`/dashboard/${organization.id}/home?onboarding=completed`, {
      replace: true,
    });
  };

  return (
    <Page
      sx={{
        p: 0,
      }}
      title="Crea tu estudio | LegalSurf"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {(formik) => (
          <Stack
            direction="row"
            sx={{
              minHeight: 'calc(100vh - 64px)',
            }}
          >
            <Stack
              display={['none', 'none', 'none', 'flex']}
              flex={1}
              sx={{
                backgroundColor: (theme) =>
                  lighten(theme.palette.primary[900], 0.05),
                overflow: 'hidden',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FilecaseMock />
            </Stack>

            <Stack
              sx={{ bgcolor: 'white' }}
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <FilecaseOnboardingForm {...formik} />
            </Stack>
          </Stack>
        )}
      </Formik>
    </Page>
  );
};
