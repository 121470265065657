const MuiTextField = {
  styleOverrides: {
    root: {
      borderRadius: '8px',

      input: {
        color: '#5E6278',
        zIndex: 2,
      },

      fieldset: {
        backgroundColor: '#F5F8FA',
        borderColor: '#F5F8FA',
        zIndex: 0,
      },

      textarea: {
        zIndex: 2,
      },

      '& .MuiAutocomplete-endAdornment, & .MuiAutocomplete-startAdornment': {
        zIndex: 2,
      },

      '& .MuiInputAdornment-root': {
        zIndex: 2,
      },

      '& .MuiAutocomplete-tag': {
        zIndex: 2,
      },

      '& .MuiCircularProgress-root': {
        zIndex: 2,
      },
    },
  },
  defaultProps: {
    variant: 'outlined',
  },
};

export default MuiTextField;
