import React from 'react';
import { SvgIcon } from '@mui/material';

const LSSale = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M20 6C20.3824 6 20.6923 6.30996 20.6923 6.69231V8.30769H22.3077C22.69 8.30769 23 8.61765 23 9C23 9.38235 22.69 9.69231 22.3077 9.69231H20.6923V11.3077C20.6923 11.69 20.3824 12 20 12C19.6176 12 19.3077 11.69 19.3077 11.3077V9.69231H17.6923C17.31 9.69231 17 9.38235 17 9C17 8.61765 17.31 8.30769 17.6923 8.30769H19.3077V6.69231C19.3077 6.30996 19.6176 6 20 6Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V7.25H15C15.4142 7.25 15.75 7.58579 15.75 8C15.75 8.41421 15.4142 8.75 15 8.75H11C10.7867 8.75 10.45 8.82016 10.1882 9.00865C9.96468 9.1696 9.75 9.44444 9.75 10C9.75 10.5556 9.96468 10.8304 10.1882 10.9913C10.45 11.1798 10.7867 11.25 11 11.25H13C13.4534 11.25 14.1167 11.3798 14.6882 11.7913C15.298 12.2304 15.75 12.9556 15.75 14C15.75 15.0444 15.298 15.7696 14.6882 16.2087C14.1167 16.6202 13.4534 16.75 13 16.75H12.75V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V16.75H9C8.58579 16.75 8.25 16.4142 8.25 16C8.25 15.5858 8.58579 15.25 9 15.25H13C13.2133 15.25 13.55 15.1798 13.8118 14.9913C14.0353 14.8304 14.25 14.5556 14.25 14C14.25 13.4444 14.0353 13.1696 13.8118 13.0087C13.55 12.8202 13.2133 12.75 13 12.75H11C10.5466 12.75 9.88332 12.6202 9.31177 12.2087C8.70198 11.7696 8.25 11.0444 8.25 10C8.25 8.95556 8.70198 8.2304 9.31177 7.79135C9.88332 7.37984 10.5466 7.25 11 7.25H11.25V6C11.25 5.58579 11.5858 5.25 12 5.25Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M11.4706 3.74938C6.92359 4.04122 3.47411 7.96386 3.76594 12.5109C4.05778 17.0579 7.98043 20.5073 12.5274 20.2155C16.1228 19.9847 19.0337 17.4834 19.949 14.1962C20.0601 13.7972 20.4736 13.5638 20.8727 13.6749C21.2717 13.786 21.5051 14.1996 21.394 14.5986C20.313 18.4812 16.8751 21.4395 12.6235 21.7124C7.24978 22.0573 2.61392 17.9807 2.26902 12.6069C1.92413 7.23321 6.00079 2.59736 11.3745 2.25246C13.8661 2.09255 16.2006 2.88404 18.0204 4.31385C18.3461 4.56976 18.4027 5.04125 18.1468 5.36695C17.8908 5.69266 17.4194 5.74924 17.0936 5.49333C15.5529 4.28273 13.5797 3.61401 11.4706 3.74938Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSSale;
