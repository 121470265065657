import React from 'react';
import { SvgIcon } from '@mui/material';

const LSDangerSign = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <circle cx="10" cy="10" r="9.5" fill="transparent" stroke="currentColor" />

    <path
      d="M11 5.85714C11 5.38376 10.5523 5 10 5C9.44772 5 9 5.38376 9 5.85714V10.1429C9 10.6162 9.44772 11 10 11C10.5523 11 11 10.6162 11 10.1429V5.85714Z"
      fill="currentColor"
    />

    <path
      d="M11 13C11 12.4477 10.5523 12 10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LSDangerSign;
