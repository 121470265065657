import React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { EntitiesAutocomplete } from 'src/autocompletes';
import Form from 'src/components/ui-components/Form';
import { Formik } from 'formik';

const EntitiesPopoverBody = ({
  loading,
  onSubmit: handleSubmit,
  entities,
  ...rest
}) => (
  <Box {...rest}>
    <Box sx={{ alignItems: 'center', gap: 2, p: 2, display: 'flex' }}>
      <Typography variant="h6">Vincular directorios</Typography>
    </Box>

    <Divider />

    <Formik
      initialValues={{
        entities: entities || [],
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form sx={{ p: 2 }}>
          <EntitiesAutocomplete multiple label="Directorios" name="entities" />

          <Grid item>
            <LoadingButton
              color="primary"
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Vincular
            </LoadingButton>
          </Grid>
        </Form>
      )}
    </Formik>
  </Box>
);

export default EntitiesPopoverBody;
