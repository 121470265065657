export const theme = {
  link: 'LegalSurf__textLink',
  text: {
    bold: 'LegalSurf__textBold',
    code: 'LegalSurf__textCode',
    italic: 'LegalSurf__textItalic',
    strikethrough: 'LegalSurf__textStrikethrough',
    subscript: 'LegalSurf__textSubscript',
    superscript: 'LegalSurf__textSuperscript',
    underline: 'LegalSurf__textUnderline',
    underlineStrikethrough: 'LegalSurf__textUnderlineStrikethrough',
  },
};
