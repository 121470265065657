import { gql, useQuery } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const getSubscriptionQuery = gql`
  query getSubscriptionQuery($studioId: ID!) {
    studio(id: $studioId) {
      id
      name
      picture
      slug
      subscription {
        id
        studioId
        customerId
        name
        img
        price
        currency
        currentPeriodEnd
        currentPeriodStart
        endedAt
        users
        status
        interval
        storageGB
        filecasesLimit
        filecaseCategoriesLimit
        filecasePartsLimit
        filecaseStatesLimit
        tagsLimit
        calendarsLimit

        alertMsg
        alertTitle
        alertSeverity

        forcedBlock
      }
    }
  }
`;

export const useSubscription = () => {
  const studioId = useStudioId();

  const {
    loading,
    data: { studio } = {},
    ...rest
  } = useQuery(getSubscriptionQuery, {
    variables: {
      studioId,
    },
    skip: !studioId,
  });

  return {
    ...rest,
    loading,
    subscription: studio?.subscription,
    studio,
  };
};
