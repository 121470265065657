import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'query-string';

import { FullPageLoader } from 'src/components/FullPageLoader';
import { useGoogleCalendar } from 'src/utils/hooks/useGoogleCalendar';
import { useGoogleDrive } from 'src/utils/hooks/useGoogleDrive';

export const CallbackGoogle = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { code, studioId, scope, reconnect, redirectSuccess } = useMemo(
    () => ({
      code: searchParams.get('code'),
      ...qs.parse(searchParams.get('state')),
    }),
    [searchParams],
  );

  const {
    syncGoogleScope: syncGoogleCalendar,
    loading: loadingGoogleCalendar,
    reconnectGoogleCalendar,
  } = useGoogleCalendar();

  const { syncGoogleScope: syncGoogleDrive, loading: loadingGoogleDrive } =
    useGoogleDrive();

  const loading = loadingGoogleCalendar || loadingGoogleDrive;

  const [loadingSync, setLoadingSync] = useState(false);
  const [done, setDone] = useState(false);

  const handleGoogleSync = useCallback(async () => {
    setLoadingSync(true);

    await (scope === 'googleCalendar' ? syncGoogleCalendar : syncGoogleDrive)(
      code,
      studioId,
    ).finally(() => {
      setLoadingSync(false);
      setDone(true);
    });

    navigate(`/dashboard/${studioId}/settings/${scope}?success=true`, {
      replace: true,
      state: {
        success: true,
        redirectSuccess,
      },
    });
  }, [
    code,
    navigate,
    redirectSuccess,
    scope,
    studioId,
    syncGoogleCalendar,
    syncGoogleDrive,
  ]);

  const handleGoogleCalendarResync = useCallback(async () => {
    setLoadingSync(true);

    try {
      await reconnectGoogleCalendar(code, studioId);

      navigate(`/dashboard/${studioId}/calendar?success=true&reconnect=true`, {
        replace: true,
        state: {
          success: true,
          redirectSuccess,
        },
      });
    } catch (error) {
      navigate(`/dashboard/${studioId}/calendar?success=false&reconnect=true`, {
        replace: true,
        state: {
          success: false,
          error: error.message,
          redirectSuccess,
        },
      });
    } finally {
      setLoadingSync(false);
      setDone(true);
    }
  }, [code, navigate, reconnectGoogleCalendar, redirectSuccess, studioId]);

  useEffect(() => {
    if (loadingSync) {
      return;
    }

    if (done) {
      return;
    }

    if (code && reconnect) {
      handleGoogleCalendarResync();
    } else if (code) {
      handleGoogleSync();
    } else {
      navigate('/dashboard/studios');
    }
  }, [
    done,
    code,
    handleGoogleSync,
    loading,
    navigate,
    loadingSync,
    reconnect,
    handleGoogleCalendarResync,
  ]);

  return <FullPageLoader />;
};
