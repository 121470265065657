import React from 'react';

import Page from 'src/components/v2/Page';
import { ContractsTable } from 'src/tables/ContractsTable';

export const ContractsSetting = () => (
  <Page title="Plantillas | legalsurf">
    <ContractsTable />
  </Page>
);
