import React from 'react';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useUpdateFilecaseAssignees } from '@legalsurf/hooks';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const AssignedCell = ({ row: filecase }) => {
  const studioId = useStudioId();
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.UPDATE,
  ]);

  const [updateCalendarEventAssignees] = useUpdateFilecaseAssignees();

  const handleAssignedSubmit = async (newAssignees) => {
    await updateCalendarEventAssignees({
      variables: {
        studioId,
        id: filecase.id,
        assigned: newAssignees,
      },
    });
  };

  return (
    <AssigneesPopover
      selectedAssignees={filecase.assigned}
      touchable={hasPermissions}
      onSubmit={handleAssignedSubmit}
    />
  );
};
export default AssignedCell;
