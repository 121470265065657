import React, { lazy } from 'react';
import { Route, Navigate, Routes, Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';

// Pages
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import Welcome from './pages/Welcome';
import FilecaseDetail from './components/v2/FilecaseDetail';
import { VoucherCharge } from './pages/Dashboard/Sales/VoucherCharge';
import { VoucherSale } from './pages/Dashboard/Sales/VoucherSale';
import HoursTracking from './pages/Dashboard/HoursTracking';
import Files from './pages/Dashboard/Files';
import Task from './pages/Dashboard/Task';
import Expenses from './pages/Dashboard/Expenses';
import { Sales } from './pages/Dashboard/Sales';
import Settings from './pages/Dashboard/Settings';
import { NotificationTemplatesSetting } from './pages/Dashboard/Settings/NotificationTemplates';
import DirectorySingle from './pages/Dashboard/Directories/DirectorySingle';
import Directories from './pages/Dashboard/Directories';
import { CalendarPage } from './pages/Dashboard/Calendar';
import { FilecasePDF } from './components/v2/FilecaseDetail/FilecasePDF';
import { Filecases } from './pages/Dashboard/Filecases';
import { StudioCreation } from './pages/Onboarding/StudioCreation';
import { StudioConfiguration } from './pages/Dashboard/Settings/StudioConfiguration';
import ErrorPage from './pages/Error';
import Studios from './pages/Dashboard/Studios';
import { FilecaseSingle } from './pages/Dashboard/Filecases/FilecaseSingle';
import Home from './pages/Dashboard/Home';
import { CallbackGoogle } from './pages/CallbackGoogle';
import { CallbackOneDrive } from './pages/CallbackOneDrive';
import { Profile } from './pages/Dashboard/Settings/Profile';

// Utils / HOCs
import { NavbarBox } from './components/Navbar';
import { usePosthogTracker } from './utils/hooks/analytics';
import { WithoutToCSign, WithToCSigned } from './utils/HOCs/withToCSign';
import { PrivateRoute } from './components/PrivateRoute';
import GoogleCalendarSetting from './pages/Dashboard/Settings/GoogleCalendarSetting';
import GoogleDriveSetting from './pages/Dashboard/Settings/GoogleDriveSetting';
import ReportsFilecases from './pages/Dashboard/ReportsFilecases';
import TreasuryReportsView from './pages/Dashboard/TreasuryReportsView';
import { SubscriptionWatcher } from './components/SubscriptionWatcher';
import { CallbackStripe } from './pages/CallbackStripe';
import { SubscriptionManagement } from './pages/Dashboard/Settings/SubscriptionManagement';
import { DialogsProvider } from './dialogs/Dialogs';
import { Organizers } from './pages/Dashboard/Settings/Organizers';
import { Login } from './pages/Authentication/Login';
import { Register } from './pages/Authentication/Register';
import { CallbackAuth } from './pages/CallbackAuth';
import { ContractsSetting } from './pages/Dashboard/Settings/ContractsSetting';
import { ContractsSettingForm } from './pages/Dashboard/Settings/ContractsSettingForm';
import { WorkflowsSetting } from './pages/Dashboard/Settings/WorkflowsSetting';
import { WorkflowCreate } from './pages/Dashboard/Settings/WorkflowCreate';
import { useShareModalEffect } from './components/ShareWithFriendsModal';
import { FilecaseCreation } from './pages/Onboarding/FilecaseCreation';
import { useStudioId } from './utils/hooks/useStudioId';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const StudioImports = lazy(
  () => import('./pages/Dashboard/Settings/StudioImports'),
);

const App = () => {
  const studioId = useStudioId();

  usePosthogTracker();
  useShareModalEffect();

  return (
    <SentryRoutes>
      <Route element={<Login />} path="sign-in/*" />

      <Route element={<Register />} path="sign-up/*" />

      <Route
        element={
          <PrivateRoute>
            <NavbarBox>
              <WithToCSigned>
                <Outlet />
              </WithToCSigned>
            </NavbarBox>
          </PrivateRoute>
        }
        path="dashboard/*"
      >
        <Route element={<Studios />} path="studios" />

        <Route
          element={
            <>
              <SubscriptionWatcher />

              <DialogsProvider key={studioId} />

              <Outlet />
            </>
          }
          path=":studioId/*"
        >
          <Route element={<Home />} path="home" />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.FILECASES.ACCESS]}>
                <FilecaseSingle />
              </PrivateRoute>
            }
            path="filecase/:filecaseId"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.FILECASES.ACCESS]}>
                <Filecases />
              </PrivateRoute>
            }
            path="filecases"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[
                  LS_PERMISSIONS_MAP.FILECASES.ACCESS,
                  LS_PERMISSIONS_MAP.FILECASES.READ,
                ]}
              >
                <FilecaseDetail />
              </PrivateRoute>
            }
            path="filecases/:filecaseId"
          />
          <Route
            element={
              <PrivateRoute
                permissions={[
                  LS_PERMISSIONS_MAP.FILECASES.ACCESS,
                  LS_PERMISSIONS_MAP.FILECASES.READ,
                ]}
              >
                <FilecasePDF />
              </PrivateRoute>
            }
            path="filecase/:filecaseId/pdf"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}>
                <CalendarPage />
              </PrivateRoute>
            }
            path="calendar"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.DIRECTORY.ACCESS]}>
                <Directories />
              </PrivateRoute>
            }
            path="directories"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.DIRECTORY.READ]}>
                <DirectorySingle />
              </PrivateRoute>
            }
            path="directories/:entity"
          />
          <Route
            element={
              <PrivateRoute
                somePermissions
                permissions={[
                  LS_PERMISSIONS_MAP.CONFIG.ACCESS_STUDIO_DATA,
                  LS_PERMISSIONS_MAP.CONFIG.ACCESS_ACCOUNTS_AND_PERMISSIONS,
                  LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
                  LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS,
                ]}
              >
                <Settings />
              </PrivateRoute>
            }
            path="settings"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES]}
              >
                <Organizers />
              </PrivateRoute>
            }
            path="settings/category"
          />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}>
                <GoogleCalendarSetting />
              </PrivateRoute>
            }
            path="settings/googlecalendar"
          />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS]}>
                <GoogleDriveSetting />
              </PrivateRoute>
            }
            path="settings/googledrive"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[LS_PERMISSIONS_MAP.CONFIG.ACCESS_STUDIO_DATA]}
              >
                <StudioConfiguration />
              </PrivateRoute>
            }
            path="settings/studio"
          />

          <Route
            element={
              <PrivateRoute
                minRole="owner"
                permissions={[LS_PERMISSIONS_MAP.CONFIG.ACCESS_STUDIO_DATA]}
              >
                <SubscriptionManagement />
              </PrivateRoute>
            }
            path="settings/subscription"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[
                  LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES,
                ]}
              >
                <NotificationTemplatesSetting />
              </PrivateRoute>
            }
            path="settings/templates"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[
                  LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES,
                ]}
              >
                <ContractsSetting />
              </PrivateRoute>
            }
            path="settings/contracts"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[
                  LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES,
                ]}
              >
                <ContractsSettingForm />
              </PrivateRoute>
            }
            path="settings/contracts/create"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.CONFIG.ACCESS]}>
                <WorkflowsSetting />
              </PrivateRoute>
            }
            path="settings/workflows"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.CONFIG.ACCESS]}>
                <WorkflowCreate />
              </PrivateRoute>
            }
            path="settings/workflows/create"
          />

          <Route
            element={
              <React.Suspense fallback={<div>Cargando...</div>}>
                <StudioImports />
              </React.Suspense>
            }
            path="settings/imports"
          />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.SALES.ACCESS]}>
                <Sales />
              </PrivateRoute>
            }
            path="sales"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.EXPENSES.ACCESS]}>
                <Expenses />
              </PrivateRoute>
            }
            path="expenses"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.FILECASES.ACCESS]}>
                <ReportsFilecases />
              </PrivateRoute>
            }
            path="reports/filecases"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.REPORTS.ACCESS]}>
                <TreasuryReportsView />
              </PrivateRoute>
            }
            path="reports/treasury"
          />
          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}>
                <Task />
              </PrivateRoute>
            }
            path="task"
          />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS]}>
                <Files />
              </PrivateRoute>
            }
            path="documents"
          />

          <Route
            element={
              <PrivateRoute
                permissions={[LS_PERMISSIONS_MAP.TIME_TRACKING.ACCESS]}
              >
                <HoursTracking />
              </PrivateRoute>
            }
            path="tracking-hours"
          />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.SALES.ACCESS]}>
                <VoucherSale />
              </PrivateRoute>
            }
            path="sale/:saleId/voucher"
          />

          <Route
            element={
              <PrivateRoute permissions={[LS_PERMISSIONS_MAP.SALES.ACCESS]}>
                <VoucherCharge />
              </PrivateRoute>
            }
            path="charge/:chargeId/voucher"
          />
        </Route>

        <Route element={<Navigate to="studios" />} path="*" />
      </Route>

      <Route
        element={
          <PrivateRoute>
            <NavbarBox>
              <Outlet />
            </NavbarBox>
          </PrivateRoute>
        }
        path="/onboarding/*"
      >
        <Route element={<StudioCreation />} path="studio" />

        <Route element={<FilecaseCreation />} path="filecase" />

        <Route element={<Navigate to="studio" />} path="*" />
      </Route>

      <Route
        element={
          <PrivateRoute>
            <CallbackGoogle />
          </PrivateRoute>
        }
        path="/callback/google"
      />

      <Route element={<CallbackAuth />} path="/callback/auth" />

      {/* TODO: Maybe this is not required, it doesn't make sense anyways */}
      <Route
        element={<CallbackOneDrive />}
        path="/files/import/onedrive/redirect"
      />
      <Route
        element={
          <PrivateRoute>
            <CallbackStripe />
          </PrivateRoute>
        }
        path="/callback/stripe/success"
      />

      <Route
        element={<Navigate replace to="/dashboard/studios" />}
        path="/invite"
      />

      <Route
        element={
          <WithoutToCSign>
            <PrivateRoute>
              <Welcome />
            </PrivateRoute>
          </WithoutToCSign>
        }
        path="/welcome"
      />

      <Route
        element={
          <PrivateRoute>
            <NavbarBox>
              <Profile />
            </NavbarBox>
          </PrivateRoute>
        }
        path="/profile"
      />

      <Route element={<ErrorPage />} path="/error404" />

      <Route element={<Navigate replace to="/dashboard" />} path="/*" />
    </SentryRoutes>
  );
};

export default App;
