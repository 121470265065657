import { gql, useMutation } from '@apollo/client';

export const DeleteEventCommentMutation = gql`
  mutation DeleteEventCommentMutation(
    $commentId: ID!
    $calendarId: ID!
    $eventId: ID!
    $studioId: ID!
  ) {
    deleteEventComment(
      commentId: $commentId
      calendarId: $calendarId
      eventId: $eventId
      studioId: $studioId
    ) {
      id
      eventId
      calendarId
    }
  }
`;

export const UpdateEventCommentMutation = gql`
  mutation UpdateEventCommentMutation(
    $commentId: ID!
    $text: String!
    $calendarId: ID!
    $eventId: ID!
    $studioId: ID!
  ) {
    updateEventComment(
      commentId: $commentId
      text: $text
      calendarId: $calendarId
      eventId: $eventId
      studioId: $studioId
    ) {
      id
      eventId
      calendarId
      text
      updatedAt
    }
  }
`;

export const useDeleteEventComment = () => {
  const [deleteEventComment] = useMutation(DeleteEventCommentMutation, {
    update: (cache, { data: { deleteEventComment } }) => {
      cache.evict({ id: cache.identify(deleteEventComment) });
    },
  });

  return deleteEventComment;
};

export const useUpdateEventComment = () => {
  const [updateEventComment] = useMutation(UpdateEventCommentMutation);

  return updateEventComment;
};
