import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { Box } from '@mui/material';
import { useQuery } from '@apollo/client';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';

// Table
import Header from './Header';

// gql
import { getFilesTableDocumentsQuery } from './gql';
import { useDocumentsTableColumns } from './columns';
import { useDialog } from 'src/dialogs/Dialogs';

const DocumentsTable = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(defaultPageSize);
  const [selectedItems, setSelectedItems] = useState([]);
  const columns = useDocumentsTableColumns();
  const { openSnackbar } = useSnackbar();
  const { studioId } = useParams();
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');

  const {
    loading,
    refetch,
    data: { paginatedDocuments } = {},
  } = useQuery(getFilesTableDocumentsQuery, {
    variables: {
      studioId,
      limit: defaultPageSize,
      offset: 0,
    },
  });

  const { handleEditDocumentName } = useDigitalOceanSpace();

  const handlePaginationModelChange = (pagination) => {
    setPage(pagination.page);
    setLimit(pagination.pageSize);

    refetch({
      limit: pagination.pageSize,
      offset: pagination.page * pagination.pageSize,
    });
  };

  return (
    <Box>
      <Table
        columns={columns}
        components={{
          toolbar: Header,
        }}
        componentsProps={{
          toolbar: {
            totalItems: paginatedDocuments?.count ?? 0,
            loading,
            refetch,
            selectedItems,
          },
          noResultsOverlay: {
            variant: 'documents',
          },
        }}
        loading={loading}
        paginationModel={{ page, pageSize: limit }}
        processRowUpdate={async (newDocument, oldDocument) => {
          if (newDocument.name === oldDocument.name) return oldDocument;

          handleEditDocumentName({
            key: newDocument.id,
            name: newDocument.name,
          });

          return newDocument;
        }}
        rowCount={paginatedDocuments?.count ?? 0}
        rowSelectionModel={selectedItems}
        rows={paginatedDocuments?.data ?? []}
        onPaginationModelChange={handlePaginationModelChange}
        onProcessRowUpdateError={() => {
          openSnackbar(
            'Ha ocurrido un error al intentar guardar sus cambios, intente mas tarde.',
            { severity: 'error' },
          );
        }}
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
        onRowClick={(params) => {
          if (params.row.kind === 'spaces') {
            dispatchDocumentPreviewDialog(`DocumentsTable-${params.row?.id}`, {
              document: params.row,
              downloable: true,
            });
          } else {
            window.open(params.row.url, '_blank');
          }
        }}
        onSortModelChange={([column]) => {
          refetch({
            ...(column
              ? {
                  orderBy: column.field,
                  sortOrder: column.sort,
                }
              : {
                  orderBy: null,
                  sortOrder: null,
                }),
          });
        }}
        {...props}
      />
    </Box>
  );
};

export default DocumentsTable;
