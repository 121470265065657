import { gql } from '@apollo/client';

export const getFilesTableDocumentsQuery = gql`
  query getPaginatedDocuments(
    $studioId: ID!
    $search: String
    $extensions: [String!]
    $filecaseId: ID
    $offset: Int
    $limit: Int
    $orderBy: DocumentsSortBy
    $sortOrder: SortOrder
  ) {
    paginatedDocuments(
      studioId: $studioId
      search: $search
      extensions: $extensions
      filecaseId: $filecaseId
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      sortOrder: $sortOrder
    ) {
      count
      data {
        id
        name
        extension
        mimetype
        url
        isFolder
        size
        kind
        filecases {
          id
          title
        }
      }
    }
  }
`;

export const getFilesTableFiltersQuery = gql`
  query getFilters($studioId: ID!) {
    filecases(studio: $studioId) {
      title
      id
    }
  }
`;
