import * as Yup from 'yup';

const DirectoryFormValidationSchema = Yup.object({
  firstname: Yup.string().when('isLegalEntity', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().required('Este campo es requerido.'),
  }),
  businessname: Yup.string().when('isLegalEntity', {
    is: true,
    then: Yup.string().required('Este campo es requerido.'),
    otherwise: Yup.string(),
  }),
  type: Yup.object().nullable().required('Este campo es requerido.'),
});

export default DirectoryFormValidationSchema;
