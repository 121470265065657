import React from 'react';
import { Box, Typography, Stack, darken } from '@mui/material';

export const multipleEntitySpecialWordColors = [
  '#e5a7a8',
  '#faf4c0',
  '#aa9077',
  '#b4e2a6',
  '#e0aee3',
  '#a5c2e1',
];

export const MultipleEntitySpecialWordPopoverBody = ({
  onClick: handleClick,
}) => (
  <Box sx={{ p: 2 }}>
    <Typography>
      Organiza tus entidades por color para poder asociar multiples
    </Typography>

    <Stack direction="row" gap={0.8} sx={{ flexWrap: 'wrap', padding: 2 }}>
      {multipleEntitySpecialWordColors.map((basicColor, index) => (
        <Box
          component="button"
          key={basicColor}
          size="small"
          style={{ backgroundColor: basicColor }}
          sx={{
            width: '20px !important',
            height: '20px !important',
            borderRadius: 0.5,
            border: '1px solid',
            borderColor: 'grey.400',
            cursor: 'pointer',

            '&:hover': {
              backgroundColor: darken(basicColor, 0.5),
            },
          }}
          onClick={() => handleClick(basicColor, index)}
        />
      ))}
    </Stack>
  </Box>
);
