import variants from './variants';

const MuiButton = {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: {
      boxShadow: 'none',
      ':hover': {
        boxShadow: 'none',
      },
    },
  },
  variants,
};

export default MuiButton;
export { variants };
