import { gql } from '@apollo/client';

export const GetHomeDataQuery = gql`
  query getHomeData(
    $studio: ID!
    $assigned: [ID!]!
    $start: DateTime!
    $end: DateTime!
    $limit: Int!
  ) {
    studio(id: $studio) {
      id
      picture
      name
    }

    filecases(limit: $limit, studio: $studio) {
      id
      title
      externalId
    }

    events(
      studio: $studio
      filters: { assigned: $assigned, start: $start, end: $end }
    ) {
      id
      calendarId
      type
      state
      title
      start
      end
      allDay
      calendar {
        id
        name
        color
        googleCalendarId
      }
      assigned {
        id
        name
        picture
      }
    }
  }
`;
