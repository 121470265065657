import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Dialog, DialogTitle, Divider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'src/components/v3/Snackbar';

import HoursTrackingForm from 'src/forms/HoursTrackingForm';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';
import { useBreakpoints } from 'src/utils/hooks/common';
import { getHoursTrackingTableDataQuery } from 'src/pages/Dashboard/HoursTracking';

export const createHoursTrackingMutation = gql`
  mutation createHoursTrackingMutation(
    $filecaseId: ID!
    $assignedTo: ID!
    $studioId: ID!
    $concept: String
    $hours: Int
    $minutes: Int
  ) {
    createHoursTracking(
      filecaseId: $filecaseId
      assignedTo: $assignedTo
      studioId: $studioId
      concept: $concept
      hours: $hours
      minutes: $minutes
    ) {
      id
      hours
      minutes
      concept
      createdAt
      assignedTo {
        id
        name
        picture
      }
      filecase {
        id
        title
      }
    }
  }
`;

const HoursTrackingDialog = ({
  open,
  onClose,
  hourTracking,
  disabledFields,
}) => {
  const params = useParams();
  const { isMobile } = useBreakpoints();
  const { openSnackbar } = useSnackbar();

  const [createHourTracking] = useMutation(createHoursTrackingMutation, {
    refetchQueries: [
      {
        query: GET_FILECASE_SINGLE_DATA,
        variables: {
          studio: params.studioId,
          filecase: hourTracking?.filecase?.value,
        },
      },
      getHoursTrackingTableDataQuery,
    ],
  });

  const handleSubmit = async (values, formikBag) => {
    try {
      await createHourTracking({
        variables: {
          hours: values.hours || 0,
          minutes: values.minutes || 0,
          concept: values.concept,
          studioId: params.studioId,
          assignedTo: values.assigned.value,
          filecaseId: values.filecase.value,
        },
      });

      openSnackbar('Horas registradas con exito.', {
        severity: 'success',
      });

      onClose();
      formikBag.resetForm();
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar('Hubo un error al intentar cargar las horas', {
        severity: 'error',
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: 650,
        },
      }}
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Cargar horas</DialogTitle>

      <Divider />

      <HoursTrackingForm
        buttonLabel="Cargar"
        disabledFields={disabledFields}
        initialValues={hourTracking}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default HoursTrackingDialog;
