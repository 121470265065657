import React from 'react';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { Box } from '@mui/material';

import TaskEventStatusChip from 'src/components/v3/TaskEventStatusChip';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { CheckListCell } from './CheckListCell';

const StateCell = ({ row: event, colDef }) => {
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  ]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, ...colDef.sx }}>
      <TaskEventStatusChip
        calendarId={event.calendarId}
        eventId={event.id}
        state={event.type === 'alert' ? null : event.state}
        sx={colDef.sx}
        touchable={hasPermissions}
      />

      {!!event?.todos?.length && <CheckListCell event={event} />}
    </Box>
  );
};

export default StateCell;
