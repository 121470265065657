import React, { useState } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Check } from '@mui/icons-material';

import { LSAdd, LSTrash } from 'src/components/icons';
import { CreateTimelineStepIconButton, TimelineStartComponent } from './utils';
import { WorkflowTimelineItemEvent } from './WorkflowTimelineContent/components/WorkflowTimelineItemEvent';

const BaseWorkflowTimelineIcon = ({ children, sx }) => (
  <Box
    sx={{
      borderRadius: 0.5,
      width: 36,
      height: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: 'white',
      ...sx,
    }}
  >
    {children}
  </Box>
);

export const WorkflowTimelineContent = ({ children }) => (
  <Box sx={{ maxWidth: 420 }}>{children}</Box>
);

const WorkflowTimelineItem = ({ onDelete, index, children }) => {
  const [hover, setHover] = useState(false);

  return (
    <TimelineItem
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <TimelineOppositeContent sx={{ flex: 0.4 }}>
        {hover && onDelete && (
          <Tooltip title="Eliminar paso">
            <IconButton
              color="primary"
              sx={{ bgcolor: 'white' }}
              onClick={onDelete}
            >
              <LSTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        )}
      </TimelineOppositeContent>

      <TimelineSeparator sx={{ minHeight: 180 }}>
        <BaseWorkflowTimelineIcon>{index + 1}</BaseWorkflowTimelineIcon>
        <TimelineConnector
          sx={{
            bgcolor: 'white',
          }}
        />
      </TimelineSeparator>

      <TimelineContent>
        <WorkflowTimelineContent>{children}</WorkflowTimelineContent>
      </TimelineContent>
    </TimelineItem>
  );
};

export const WorkflowTimeline = ({ steps, onClick, onDelete, onUpdate }) => (
  <Timeline>
    <TimelineItem
      sx={{
        '&::before': {
          flex: 0.4,
        },
      }}
    >
      <TimelineSeparator sx={{ minHeight: 180 }}>
        <BaseWorkflowTimelineIcon
          sx={{
            bgcolor: 'success.light',
          }}
        >
          <Check color="success" />
        </BaseWorkflowTimelineIcon>

        <TimelineConnector
          sx={{
            bgcolor: 'white',
          }}
        />
      </TimelineSeparator>

      <TimelineContent>
        <WorkflowTimelineContent>
          <TimelineStartComponent />
        </WorkflowTimelineContent>
      </TimelineContent>
    </TimelineItem>

    {steps.map((step, index) => (
      <WorkflowTimelineItem
        index={index}
        key={step.id ?? index}
        onDelete={() => onDelete(step, index)}
      >
        <WorkflowTimelineItemEvent
          step={{ ...step, index }}
          onClick={() => onClick(step, index)}
          onUpdate={onUpdate}
        />
      </WorkflowTimelineItem>
    ))}

    <TimelineItem
      sx={{
        '&::before': {
          flex: 0.4,
        },
      }}
    >
      <TimelineSeparator>
        <BaseWorkflowTimelineIcon
          sx={{
            bgcolor: 'primary.main',
          }}
        >
          <LSAdd />
        </BaseWorkflowTimelineIcon>

        <TimelineConnector
          sx={{
            maxHeight: 0,
          }}
        />
      </TimelineSeparator>

      <TimelineContent>
        <CreateTimelineStepIconButton onClick={onClick} />
      </TimelineContent>
    </TimelineItem>
  </Timeline>
);
