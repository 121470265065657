import React, { useCallback } from 'react';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { ArrayParam, StringParam } from 'use-query-params';

import SectionHeader, {
  SectionHeaderHeading,
  SectionHeaderActiveFilters,
} from 'src/components/v3/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import { TextField } from 'src/components/ui-components/Form';
import { LSAdd as LSAddIcon, LSLen } from 'src/components/icons';
import {
  AssigneesAutocomplete,
  EntitiesAutocomplete,
  FilecasesAutocomplete,
} from 'src/autocompletes';
import { createHeaderFiltersStore } from 'src/components/v3/SectionHeader/utils';
import { useFilters } from 'src/components/v3/SectionHeader/useFilters';

export const calendarSearchFiltersId = 'CalendarSearchFilters-v2';

const paramConfigMap = {
  entity: StringParam,
  filecases: ArrayParam,
  assigned: ArrayParam,
  calendars: ArrayParam,
  text: StringParam,
  start: StringParam,
  end: StringParam,
};

export const agendaHeaderDefaultInitialValues = {
  entity: null,
  filecases: [],
  assigned: [],
  calendars: [],
  text: '',
  start: null,
  end: null,
};

const useEntitiesFiltersStore = createHeaderFiltersStore(
  calendarSearchFiltersId,
  agendaHeaderDefaultInitialValues,
);

export const useAgendaFilters = () => {
  return useFilters(
    useEntitiesFiltersStore,
    paramConfigMap,
    agendaHeaderDefaultInitialValues,
  );
};
const Header = ({ title, subtitle, loading }) => {
  const dispatchEventFormDialog = useDialog('eventForm');
  const { filters, setFilters, resetFilters, initialValues } =
    useAgendaFilters();

  const handleFiltersChange = useCallback(
    (values, prev) =>
      Promise.resolve(setFilters(values, values.text !== prev.text)),
    [setFilters],
  );

  const handleReset = useCallback(
    (formikBag) => {
      resetFilters();
      formikBag.setValues(agendaHeaderDefaultInitialValues);
    },
    [setFilters, resetFilters],
  );

  return (
    <SectionHeader
      filters={initialValues}
      loading={loading}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersChange}
    >
      {() => (
        <>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack direction="column">
              <SectionHeaderHeading variant="h3">{title}</SectionHeaderHeading>

              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <Box>
              <TextField
                debounce
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LSLen color="primary" />
                    </InputAdornment>
                  ),
                }}
                name="text"
                placeholder="Buscar..."
                size="small"
              />
            </Box>

            <SectionHeaderAdvanceFilters onReset={handleReset}>
              <EntitiesAutocomplete
                label="Directorio"
                name="entity"
                size="small"
              />

              <FilecasesAutocomplete
                multiple
                label="Expedientes"
                name="filecases"
                placeholder="Ej: 1234/2021"
                size="small"
              />

              <AssigneesAutocomplete
                multiple
                label="Asignados"
                name="assigned"
                placeholder="Ej: Nicolas Salazar"
                size="small"
              />
            </SectionHeaderAdvanceFilters>

            <Box
              sx={{ ml: 'auto', gap: 2, display: 'flex', alignItems: 'center' }}
            >
              <Button
                color="primary"
                startIcon={<LSAddIcon color="white" />}
                variant="contained"
                onClick={() =>
                  dispatchEventFormDialog('CalendarPageEventForm', {
                    type: 'task',
                  })
                }
              >
                Crear evento
              </Button>
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              filecase: 'Expediente',
              entity: 'Directorio',
              filecases: 'Expedientes',
              assigned: 'Asignados',
              onlyMe: 'Solo yo',
              start: 'Desde',
              end: 'Hasta',
              text: 'Texto',
            }}
            filters={filters}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;
