import React, { useRef } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import Form, { CommentField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CREATE_ENTRY } from 'src/graphql/mutations/filecases';

const ValidationSchema = Yup.object({
  content: Yup.string()
    .nullable(false)
    .notOneOf(['<p><br></p>'], 'El texto de la actuación es requerido')
    .min(1, 'El texto de la actuación debe tener al menos 1 caracter')
    .required('El texto de la actuación es requerido'),
  date: Yup.mixed()
    .nullable()
    .test('valid-date', 'Debes ingresar una fecha válida', (value) => {
      if (!value) {
        return true;
      }

      return dayjs(value).isValid();
    }),
});

const initialValues = {
  content: '',
  date: null,
};

export const CommentForm = ({ filecase }) => {
  const studioId = useStudioId();
  const richEditorRef = useRef(null);

  const [createEntry] = useMutation(CREATE_ENTRY, {
    update: (cache, result, context) => {
      cache.modify({
        id: cache.identify({
          __typename: 'Filecase',
          id: context.variables.filecase,
        }),
        fields: {
          latestEntry(latestEntry, { readField }) {
            if (!latestEntry) {
              return result.data.createEntry;
            }

            const currentLatestEntryDate = readField('date', latestEntry);
            const currentEntryIsLatest = dayjs(currentLatestEntryDate).isBefore(
              result.data.createEntry.date,
            );

            if (currentEntryIsLatest) {
              return result.data.createEntry;
            }

            return undefined;
          },
          entries(entries, { readField }) {
            // desc order
            return [...entries, result.data.createEntry].sort((a, b) => {
              const dateA = new Date(readField('date', a));
              const dateB = new Date(readField('date', b));

              return dateB - dateA;
            });
          },
        },
      });
    },
    onCompleted: () => {
      if (richEditorRef.current) {
        const editor = richEditorRef.current;

        editor.dispatchCommand(CLEAR_EDITOR_COMMAND);
      }
    },
  });

  const handleSubmitComment = async (values, { setSubmitting, resetForm }) => {
    await createEntry({
      variables: {
        content: values.content,
        date: values.date || dayjs().toDate(),
        studioId,
        filecase: filecase.id,
      },
    });

    resetForm();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={handleSubmitComment}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form id="commentForm">
          <CommentField
            dateProps={{
              value: values.date || dayjs(),
              onChange: (date) => setFieldValue('date', date),
            }}
            error={touched.date && errors.date}
            filesFormProps={{
              mutationOptions: {
                update: (cache, { data }) => {
                  cache.modify({
                    id: cache.identify(filecase),
                    fields: {
                      documents(docs) {
                        return [...data.uploadFiles, ...docs];
                      },
                    },
                  });
                },
              },
              file: {
                filecases: [
                  {
                    value: filecase.id,
                    label: filecase.title,
                  },
                ],
              },
            }}
            helperText={touched.date && errors.date}
            name="content"
            ref={richEditorRef}
            sx={{ bgcolor: 'background.input', p: 2, borderRadius: 2 }}
          />
        </Form>
      )}
    </Formik>
  );
};
