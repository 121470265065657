import React from 'react';
import { useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { GET_STUDIO_MEMBERS } from 'src/graphql/queries/studios';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useUser } from 'src/utils/hooks/useUser';

const StudioMembersAutocomplete = ({
  disabled,
  label = '¿Quiénes podrán verlo?',
  placeholder = 'Ej. Jhon Doe',
  excludeCurrentUser = false,
  name,
  fixedOptions = [],
  ...rest
}) => {
  const studioId = useStudioId();
  const { user } = useUser();
  const { loading, data: { members = [] } = {} } = useQuery(
    GET_STUDIO_MEMBERS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        status: ['ACTIVE'],
        studio: studioId,
      },
    },
  );

  const options = (members ?? []).reduce((accMembers, member) => {
    if (member.id === user.id && excludeCurrentUser) return accMembers;

    return [
      ...accMembers,
      {
        value: member.id,
        label: member.name,
        picture: member.picture,
        disabled: fixedOptions.includes(member.id),
      },
    ];
  }, []);

  return (
    <SelectField
      multiple
      withSelectAll
      disabled={disabled || loading}
      fixedOptions={fixedOptions}
      label={label}
      loading={loading}
      name={name}
      options={options}
      placeholder={placeholder}
      renderOption={(option) =>
        `${option.label}${option.value === user.id ? ' (Yo)' : ''}`
      }
      {...rest}
    />
  );
};

export default StudioMembersAutocomplete;
