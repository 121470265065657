import React from 'react';
import { Box, Typography, Avatar, Chip } from '@mui/material';

import { stringAvatar } from 'src/utils/formatters';

const TitleCell = ({ row }) => (
  <Box
    sx={{
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexWrap: 'no-wrap',
      gap: 0.5,
      alignItems: 'center',
    }}
  >
    <Avatar
      role="button"
      {...stringAvatar(row.displayName, {
        backgroundColor: 'primary.main',
        color: 'primary.contrastText',
        cursor: 'pointer',
        fontSize: '1rem',
        display: ['none', 'none', 'flex'],
        mr: 1,
      })}
    />

    <Typography
      role="button"
      sx={{
        fontWeight: '500',
      }}
    >
      {row.displayName}
    </Typography>

    {row.tags?.map((tag) => (
      <Chip
        key={tag.name}
        label={tag.name}
        size="small"
        sx={{
          color: 'primary.dark',
          backgroundColor: 'primary.100',
        }}
      />
    ))}
  </Box>
);

export default TitleCell;
