import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { Alert, AlertTitle, Box } from '@mui/material';

// import { useLocation } from 'react-router-dom';
// import track from 'src/utils/analytics';

const Page = forwardRef(
  ({ title, children, hideContent, sx, ...rest }, ref) => (
    // const location = useLocation();

    // TODO: Add when GA is available
    // const sendPageViewEvent = useCallback(() => {
    //   track.pageview({
    //     page_path: location.pathname,
    //   });
    // }, [location]);

    // useEffect(() => {
    //   sendPageViewEvent();
    // }, [sendPageViewEvent]);

    <Box ref={ref} {...rest} sx={{ maxWidth: '100vw', overflowX: 'hidden' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Box
        sx={{
          p: [1, 1, 2, 3],
          ...sx,
        }}
        {...rest}
      >
        {hideContent && (
          <Alert severity="error">
            <AlertTitle>Recurso no encontrado</AlertTitle>
            Lo que buscabas no existe o <strong>no puedes verlo.</strong>
          </Alert>
        )}

        {!hideContent && children}
      </Box>
    </Box>
  ),
);

export default Page;
