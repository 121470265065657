import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
import { getEventRowId } from 'src/utils/dates';

import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useColumns } from './columns';

const sortEventsByDate = (events) =>
  [...(events || [])].sort((a, b) => new Date(a.start) - new Date(b.start));

export const CalendarAgendaTable = ({
  disabled,
  loading,
  events,
  selectedDate,
  weekly,
  ...props
}) => {
  const dispatchEventDialog = useDialog('event');
  const columns = useColumns(weekly);
  const ongoingTableColumns = useColumns();

  const [todaysEvents, ongoingEvents] = useMemo(() => {
    const todaysEventsArr = events.filter((event) => {
      if (event.allDay) {
        return dayjs(event.start, { utc: true }).isSame(
          dayjs(selectedDate, { utc: true }),
          'day',
        );
      }

      return dayjs(event.start).isSame(selectedDate, weekly ? 'week' : 'day');
    });

    const ongoingEventsArr = events.filter((event) => {
      if (event.allDay) {
        return (
          dayjs(selectedDate, { utc: true }).isBetween(
            dayjs(event.start, { utc: true }),
            dayjs(event.end, { utc: true }),
          ) &&
          !dayjs(selectedDate, { utc: true }).isSame(
            dayjs(event.start, { utc: true }),
            weekly ? 'week' : 'day',
          )
        );
      }

      return (
        selectedDate.isBetween(dayjs(event.start), dayjs(event.end)) &&
        !selectedDate.isSame(dayjs(event.start), weekly ? 'week' : 'day')
      );
    });

    return [
      sortEventsByDate(todaysEventsArr),
      sortEventsByDate(ongoingEventsArr),
    ];
  }, [events, selectedDate, weekly]);

  return (
    <Box>
      <Box sx={{ mt: 3, flexDirection: 'column', display: 'flex', gap: 2 }}>
        {/* TODO: ESTA TABLA DEBERIA SER UN COMPONENTE APARTE DE LA AGENDA */}
        <Table
          checkboxSelection={false}
          columns={columns}
          getRowId={getEventRowId}
          loading={loading}
          // pageSize={todaysEvents?.length}
          paginationMode="client"
          rows={todaysEvents ?? []}
          sortingMode="client"
          sxCard={{
            boxShadow: 'none',
          }}
          onRowClick={(params) =>
            dispatchEventDialog(params.row.id, {
              id: params.row.id,
              calendarId: params.row.calendarId,
            })
          }
          {...props}
        />
      </Box>

      {!!ongoingEvents.length && (
        <Box sx={{ flexDirection: 'column', display: 'flex', gap: 2 }}>
          <Typography sx={{ mb: 2 }} variant="subtitle2">
            Eventos en progreso semanal
          </Typography>

          {/* TODO: Y ESTO DEBERIA SER OTRA INSTANCIA DEL MISMO COMPONENTE, SOLO QUE CON OTRA DATA */}
          <Table
            checkboxSelection={false}
            columns={ongoingTableColumns}
            loading={loading}
            // pageSize={ongoingEvents?.length}
            paginationMode="client"
            rows={ongoingEvents ?? []}
            sortingMode="client"
            sxCard={{
              boxShadow: 'none',
            }}
            onRowClick={(params) =>
              dispatchEventDialog(params.row.id, {
                id: params.row.id,
                calendarId: params.row.calendarId,
              })
            }
            {...props}
          />
        </Box>
      )}
    </Box>
  );
};
