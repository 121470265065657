import React from 'react';
import { AssigneePopover } from 'src/components/v3/AssigneePopover';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { gql, useMutation } from '@apollo/client';

export const UpdateSaleResponsibleMutation = gql`
  mutation UpdateSaleResponsibleMutation(
    $id: ID!
    $studioId: ID!
    $responsible: ID!
  ) {
    updateSale(saleId: $id, studio: $studioId, responsible: $responsible) {
      id
      studioId
      updatedAt
      responsible {
        id
        name
        picture
      }
    }
  }
`;

export const ResponsibleCell = ({ row, ...rest }) => {
  const studioId = useStudioId();
  const { hasPermissions } = usePermissions([LS_PERMISSIONS_MAP.SALES.UPDATE]);

  const [updateEntityAssignees] = useMutation(UpdateSaleResponsibleMutation);

  const handleAssignedSubmit = async (responsible) => {
    await updateEntityAssignees({
      variables: {
        id: row.id,
        studioId,
        responsible: responsible,
      },
    });
  };

  return (
    <AssigneePopover
      selectedAssignee={row.responsible}
      touchable={hasPermissions}
      onSubmit={handleAssignedSubmit}
      {...rest}
    />
  );
};
