import { Box, Grid, Typography } from '@mui/material';
import React from 'react';

export const EmptyComments = ({ sx = {}, ...props }) => (
  <Box
    {...props}
    sx={{
      borderColor: 'primary.main',
      backgroundColor: 'primary.200',

      borderRadius: 1,
      borderWidth: '1px',
      borderStyle: 'dashed',
      p: 2,
      pl: 3,
      ...sx,
    }}
  >
    <Grid container alignItems="flex-start" spacing={2}>
      <Grid item xs>
        <Typography
          color="primary.main"
          fontSize={16}
          fontWeight={500}
          variant="caption"
        >
          Aún no posees ningun comentario
        </Typography>

        <Typography
          color="grey.600"
          fontSize={16}
          fontWeight={500}
          variant="subtitle1"
        >
          Empieza la conversion de tu nuevo evento
        </Typography>
      </Grid>
    </Grid>
  </Box>
);
