import React from 'react';
import { useUser } from 'src/utils/hooks/useUser';
import {
  IconButton,
  Tooltip,
  AlertTitle,
  Alert,
  Button,
  Box,
  Avatar,
  darken,
} from '@mui/material';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useGoogleCalendar } from 'src/utils/hooks/useGoogleCalendar';
import { Error } from '@mui/icons-material';
import GoogleCalendarIMG from 'src/assets/images/googlecalendar.png';

export const ReconnectGoogleCalendarButton = ({ calendar }) => {
  const { user } = useUser();
  const studioId = useStudioId();
  const { redirectToGoogleCalendarAuthorzation } = useGoogleCalendar();

  const handleGoogleCalendaReconnectClick = (event) => {
    event.stopPropagation();

    if (user.id === calendar.createdBy.id) {
      redirectToGoogleCalendarAuthorzation({
        redirectSuccess: `/dashboard/${studioId}/calendar`,
        reconnect: true,
      });
    }
  };

  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            minWidth: 400,
            bgcolor: 'transparent',
          },
        },
      }}
      leaveDelay={500}
      placement="right-start"
      title={
        <Alert severity="error" variant="filled">
          <AlertTitle sx={{ fontWeight: 600 }}>
            Error al cargar eventos de google
          </AlertTitle>

          <Box>
            Hay un error con la sincronizacion de google calendar y este
            calendario.
            {user.id === calendar.createdBy.id ? (
              <Button
                color="primary"
                endIcon={
                  <Avatar
                    src={GoogleCalendarIMG}
                    sx={{ width: 18, height: 18 }}
                  />
                }
                sx={{
                  mt: 2,
                  backgroundColor: (theme) =>
                    darken(theme.palette.error.main, 0.2),
                }}
                variant="filled"
                onClick={handleGoogleCalendaReconnectClick}
              >
                Reconectar Google Calendar
              </Button>
            ) : (
              <>
                <br />
                El creador ({calendar.createdBy.name} -{' '}
                {calendar.createdBy.email}) debe realizar el proceso de
                reconexion
              </>
            )}
          </Box>
        </Alert>
      }
    >
      <IconButton
        color="error"
        size="small"
        onClick={handleGoogleCalendaReconnectClick}
      >
        <Error />
      </IconButton>
    </Tooltip>
  );
};
