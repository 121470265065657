import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { FullPageLoader } from './FullPageLoader';

const ranksPerRole = {
  owner: 1,
  admin: 2,
  member: 3,
};

const _PrivateRoute = ({
  somePermissions,
  permissions,
  minRole = 'member',
  children,
} = {}) => {
  const { loading, user, hasPermissions, hasSomePermissions, isSignedIn } =
    usePermissions(permissions);

  if (loading) {
    return <FullPageLoader />;
  }

  if (!isSignedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  if (ranksPerRole[user.role] > ranksPerRole[minRole]) {
    return <Navigate replace to="/error404" />;
  }

  if (somePermissions && hasSomePermissions) {
    return children;
  }

  if (!hasPermissions) {
    return <Navigate replace to="/error404" />;
  }

  return children;
};

export const PrivateRoute = _PrivateRoute;
