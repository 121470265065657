import { create } from 'zustand';

export const useSubscriptionStore = create((set) => ({
  showPricingTable: false,
  limitReached: false,
  limitReachedMsg: '',
  setPricingTable: (open) => set({ showPricingTable: open }),
  setLimitReached: (limitReached, msg) =>
    set({ limitReached, limitReachedMsg: msg }),
}));
