import { useMutation } from '@apollo/client';
import { Box, Card, CardContent, CircularProgress, Stack } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useSnackbar } from 'src/components/v3/Snackbar';
import * as Yup from 'yup';

import Form, {
  CommentField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import Page from 'src/components/v2/Page';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  GetContractTemplatesQuery,
  useContractTemplate,
} from 'src/tables/ContractsTable/queries';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CreateContractTemplateMutation,
  UpdateContractTemplateMutation,
} from './gql';
import { defaultTemplateContent } from './utils';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'El nombre es muy corto')
    .max(144, 'El nombre es muy largo')
    .required('El nombre es requerido'),
});

export const ContractsSettingForm = () => {
  // const params = useParams();
  const [searchParams] = useSearchParams();
  const studioId = useStudioId();
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const templateId = searchParams.get('templateId');
  const { loading, contract } = useContractTemplate(templateId);

  const [createContractTemplate] = useMutation(CreateContractTemplateMutation, {
    refetchQueries: [
      { query: GetContractTemplatesQuery, variables: { studioId } },
    ],
  });

  const [updateContractTemplate] = useMutation(UpdateContractTemplateMutation, {
    refetchQueries: [
      { query: GetContractTemplatesQuery, variables: { studioId } },
    ],
  });

  const handleSubmit = async (values, formikBag) => {
    const isEdit = !!templateId;

    const mutation = isEdit ? updateContractTemplate : createContractTemplate;

    const query = {
      variables: {
        id: contract?.id,
        studioId,
        name: values.name,
        content: values.content,
      },
    };

    try {
      await mutation(query);

      formikBag.resetForm();
      navigate(`/dashboard/${studioId}/settings/contracts`);

      openSnackbar(
        isEdit ? 'plantilla editada con exito.' : 'plantilla creada con exito.',
        {
          severity: 'success',
        },
      );
    } catch (error) {
      openSnackbar(error.message ?? 'Hubo un error, intente mas tarde.', {
        severity: 'error',
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  if (templateId && loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 200,
        }}
      >
        <CircularProgress color="primary" size={30} />
      </Box>
    );
  }

  const initialValues = {
    name: contract?.name || '',
    content: contract?.content || defaultTemplateContent,
  };

  return (
    <Page title="Plantillas | legalsurf">
      <Card>
        <CardContent>
          <Stack
            alignItems="center"
            justifyContent="center"
            maxWidth={['100%', 1100]}
            mx="auto"
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <TextField
                    label="Nombre de la plantilla"
                    name="name"
                    placeholder="Documento de representación, plantilla de compra/venta"
                  />

                  <CommentField
                    disableUpload
                    enableDynamicFields
                    editorContentSx={{
                      minHeight: 100,
                    }}
                    name="content"
                    sx={{
                      bgcolor: 'background.input',
                      p: 2,
                      borderRadius: 0.5,
                    }}
                    toolbarEndComponent={null}
                  />

                  <SubmitButton
                    disabled={isSubmitting}
                    gridProps={{ ml: 'auto' }}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    Guardar plantilla
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          </Stack>
        </CardContent>
      </Card>
    </Page>
  );
};
