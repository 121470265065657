import React, { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { gql, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { Linkify } from 'src/components/Linkify';
import TextField from '../TextField';
import SelectField from '../SelectField';
import CheckField from '../CheckField';
import DateField from '../DateField';

const UpsertCustomFieldValue = gql`
  mutation UpsertCustomFieldValue(
    $studioId: ID!
    $modelId: ID
    $modelType: CustomFieldValueType!
    $customFieldId: ID!
    $customFieldValue: CustomFieldValueInput!
  ) {
    upsertCustomFieldValue(
      studioId: $studioId
      modelId: $modelId
      modelType: $modelType
      customFieldId: $customFieldId
      customFieldValue: $customFieldValue
    ) {
      id
      name
      studioId
      value(modelId: $modelId, modelType: $modelType)
    }
  }
`;

export const isCustomFieldInitialValue = (customField) => {
  // TODO: Improve this
  return (
    customField.value === null ||
    customField.value === undefined
  );
};

export const formatCustomFieldValue = (customField) => {
  switch (customField.type) {
    case 'Select':
      return customField.value || '';
    case 'Checkbox':
      return customField.value ? 'Si' : 'No';
    case 'Text':
      return <Linkify text={customField.value || ''} />;
    case 'Number':
      return customField.value?.toFixed?.(2) || '';
    case 'Date':
      return customField.value ? dayjs(customField.value).format('LL') : '';
    default:
      if (Array.isArray(customField.value)) {
        return customField.value.join(', ');
      }
      return customField.value || '';
  }
};

const shouldSubmit = (type, value, initialValue) => {
  if (type === 'Select') {
    return value?.value !== initialValue?.value;
  }

  return value !== initialValue;
};

const useCustomFieldForm = (type, name) => {
  const studioId = useStudioId();
  const [{ value }, { initialValue }] = useField(name);
  const [upsertCustomFieldValue] = useMutation(UpsertCustomFieldValue);

  const handleSubmit = (modelType, modelId, customFieldId) => {
    if (!shouldSubmit(type, value, initialValue)) {
      return;
    }

    upsertCustomFieldValue({
      variables: {
        studioId,
        customFieldId,
        customFieldValue: {
          name,
          // We use `value?.value` because of SelectField
          value: value?.value ?? value,
        },
        modelType,
        modelId,
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify(data.upsertCustomFieldValue),
          fields: {
            value() {
              return data.upsertCustomFieldValue.value;
            },
          },
        });
      },
    });
  };

  return handleSubmit;
};

const CustomField = ({
  label,
  gridProps = { xs: 12 },
  name,
  disabled,
  hint,
  noFormControlLabel,
  options,
  type,
  customFieldId,
  modelType,
  modelId = null,
  ...rest
}) => {
  const escapedName = `['${name}']`;
  const [field, meta] = useField(escapedName);
  const { isSubmitting } = useFormikContext();
  const handleSubmit = useCustomFieldForm(type, escapedName);

  const propsBag = {
    // fullWidth: true,
    // autoComplete: 'off',
    disabled: isSubmitting || disabled,
    // error: Boolean(meta.touched && meta.error),
    helperText: (meta.touched && meta.error) || rest.description,
    label,
    variant: 'outlined',
    name: field.name,
    onBlur: field.onBlur,
    value: field.value,
    multiple: field.multiple,
    ...rest,
  };

  const handleUpsert = useCallback(
    () => handleSubmit(modelType, modelId, customFieldId),
    [customFieldId, handleSubmit, modelId, modelType],
  );

  useEffect(() => {
    if (isSubmitting) {
      handleUpsert();
    }
    // TODO: Check this hack
  }, [isSubmitting]);

  const render = () => {
    const opts =
      type === 'Select'
        ? options?.map((option) => ({
          value: option,
          label: option,
        }))
        : [];

    switch (type) {
      case 'Number':
        return <TextField noFormControlLabel type="number" {...propsBag} />;

      case 'Select':
        return <SelectField {...propsBag} options={opts} />;

      case 'Text':
        return <TextField {...propsBag} />;

      case 'Checkbox':
        return <CheckField {...propsBag} />;

      case 'Date':
        return <DateField {...propsBag} />;

      default:
        return null;
    }
  };

  return (
    <Grid item {...gridProps}>
      {render()}
    </Grid>
  );
};

export default CustomField;
