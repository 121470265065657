import React from 'react';

import { SpecialWordPopoverBody } from './SpecialWordPopoverBody';
import { PopoverBase } from '../PopoverBase';

export const SpecialWordPopover = ({
  filecases,
  open,
  anchorEl,
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
  ...props
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    open={open}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <SpecialWordPopoverBody sx={{ maxWidth: 400 }} {...props} />
  </PopoverBase>
);
