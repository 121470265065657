import React from 'react';

import { FilecasesPopoverBody } from './FilecasesPopoverBody';
import { PopoverBase } from '../PopoverBase';

const FilecasesPopover = ({
  onSubmit,
  filecases,
  open,
  anchorEl,
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
  ...props
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    open={open}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <FilecasesPopoverBody
      filecases={filecases}
      sx={{ maxWidth: 400 }}
      onSubmit={onSubmit}
      {...props}
    />
  </PopoverBase>
);

export default FilecasesPopover;
