import React from 'react';
import { SvgIcon } from '@mui/material';

// person or organization
const LSDirectory = ({ type = 'person', ...props }) =>
  type === 'person' ? (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.89474C10.3431 4.89474 9 6.16719 9 7.73684C9 9.30649 10.3431 10.5789 12 10.5789C13.6569 10.5789 15 9.30649 15 7.73684C15 6.16719 13.6569 4.89474 12 4.89474ZM7 7.73684C7 5.12076 9.23858 3 12 3C14.7614 3 17 5.12076 17 7.73684C17 10.3529 14.7614 12.4737 12 12.4737C9.23858 12.4737 7 10.3529 7 7.73684ZM7.45609 16.004C6.40184 16.4475 6 17.0076 6 17.6842C6 17.896 6.03976 18.016 6.09728 18.1029C6.15483 18.1899 6.29214 18.3372 6.66219 18.4958C7.45312 18.8348 9.01609 19.1053 12 19.1053C14.9839 19.1053 16.5469 18.8348 17.3378 18.4958C17.7079 18.3372 17.8452 18.1899 17.9027 18.1029C17.9602 18.016 18 17.896 18 17.6842C18 17.0076 17.5982 16.4475 16.5439 16.004C15.4614 15.5487 13.8722 15.3158 12 15.3158C10.1278 15.3158 8.53857 15.5487 7.45609 16.004ZM6.64442 14.2724C8.09544 13.6619 10.0062 13.4211 12 13.4211C13.9938 13.4211 15.9046 13.6619 17.3556 14.2724C18.8348 14.8946 20 15.9924 20 17.6842C20 18.1263 19.9148 18.6295 19.5973 19.1093C19.2798 19.589 18.7921 19.9521 18.1622 20.2221C16.9531 20.7403 15.0161 21 12 21C8.98391 21 7.04688 20.7403 5.83781 20.2221C5.20786 19.9521 4.72017 19.589 4.40272 19.1093C4.08524 18.6295 4 18.1263 4 17.6842C4 15.9924 5.16516 14.8946 6.64442 14.2724Z"
      />
    </SvgIcon>
  ) : (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72727 3.81818C6.2252 3.81818 5.81818 4.2252 5.81818 4.72727V19.2727C5.81818 19.7748 6.2252 20.1818 6.72727 20.1818H17.6364C18.1384 20.1818 18.5455 19.7748 18.5455 19.2727V4.72727C18.5455 4.2252 18.1384 3.81818 17.6364 3.81818H6.72727ZM4 4.72727C4 3.22104 5.22104 2 6.72727 2H17.6364C19.1426 2 20.3636 3.22104 20.3636 4.72727V19.2727C20.3636 20.779 19.1426 22 17.6364 22H6.72727C5.22104 22 4 20.779 4 19.2727V4.72727Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54541 17.456C8.54541 16.9539 8.95242 16.5469 9.4545 16.5469H14.909C15.4111 16.5469 15.8181 16.9539 15.8181 17.456V21.0923C15.8181 21.5944 15.4111 22.0014 14.909 22.0014C14.407 22.0014 14 21.5944 14 21.0923V18.3651H10.3636V21.0923C10.3636 21.5944 9.95658 22.0014 9.4545 22.0014C8.95242 22.0014 8.54541 21.5944 8.54541 21.0923V17.456Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63672 6.54581C7.63672 6.04373 8.04373 5.63672 8.54581 5.63672H8.5549C9.05698 5.63672 9.46399 6.04373 9.46399 6.54581C9.46399 7.04789 9.05698 7.4549 8.5549 7.4549H8.54581C8.04373 7.4549 7.63672 7.04789 7.63672 6.54581Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9092 6.54581C14.9092 6.04373 15.3162 5.63672 15.8183 5.63672H15.8274C16.3294 5.63672 16.7365 6.04373 16.7365 6.54581C16.7365 7.04789 16.3294 7.4549 15.8274 7.4549H15.8183C15.3162 7.4549 14.9092 7.04789 14.9092 6.54581Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2725 6.54581C11.2725 6.04373 11.6795 5.63672 12.1816 5.63672H12.1906C12.6927 5.63672 13.0997 6.04373 13.0997 6.54581C13.0997 7.04789 12.6927 7.4549 12.1906 7.4549H12.1816C11.6795 7.4549 11.2725 7.04789 11.2725 6.54581Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2725 10.1806C11.2725 9.6785 11.6795 9.27148 12.1816 9.27148H12.1906C12.6927 9.27148 13.0997 9.6785 13.0997 10.1806C13.0997 10.6827 12.6927 11.0897 12.1906 11.0897H12.1816C11.6795 11.0897 11.2725 10.6827 11.2725 10.1806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2725 13.8192C11.2725 13.3172 11.6795 12.9102 12.1816 12.9102H12.1906C12.6927 12.9102 13.0997 13.3172 13.0997 13.8192C13.0997 14.3213 12.6927 14.7283 12.1906 14.7283H12.1816C11.6795 14.7283 11.2725 14.3213 11.2725 13.8192Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9092 10.1806C14.9092 9.6785 15.3162 9.27148 15.8183 9.27148H15.8274C16.3294 9.27148 16.7365 9.6785 16.7365 10.1806C16.7365 10.6827 16.3294 11.0897 15.8274 11.0897H15.8183C15.3162 11.0897 14.9092 10.6827 14.9092 10.1806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9092 13.8192C14.9092 13.3172 15.3162 12.9102 15.8183 12.9102H15.8274C16.3294 12.9102 16.7365 13.3172 16.7365 13.8192C16.7365 14.3213 16.3294 14.7283 15.8274 14.7283H15.8183C15.3162 14.7283 14.9092 14.3213 14.9092 13.8192Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63672 10.1806C7.63672 9.6785 8.04373 9.27148 8.54581 9.27148H8.5549C9.05698 9.27148 9.46399 9.6785 9.46399 10.1806C9.46399 10.6827 9.05698 11.0897 8.5549 11.0897H8.54581C8.04373 11.0897 7.63672 10.6827 7.63672 10.1806Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63672 13.8192C7.63672 13.3172 8.04373 12.9102 8.54581 12.9102H8.5549C9.05698 12.9102 9.46399 13.3172 9.46399 13.8192C9.46399 14.3213 9.05698 14.7283 8.5549 14.7283H8.54581C8.04373 14.7283 7.63672 14.3213 7.63672 13.8192Z"
      />
    </SvgIcon>
  );

export default LSDirectory;
