import React from 'react';
import { Avatar, Button } from '@mui/material';

import DropboxIcon from 'src/pages/Dashboard/Settings/Dropbox_Tab_80.svg';
import { useScript } from 'src/utils/hooks/useScript';

const mapDropboxFiles = (arr) =>
  arr.map((val) => ({
    ...val,
    url: val.link,
    size: val.bytes,
    kind: 'dropbox',
    isFolder: val.isDir,
  }));

const chooserOptions = {
  // Optional. "preview" (default) is a preview link to the document for sharing,
  // "direct" is an expiring link to download the contents of the file. For more
  // information about link types, see Link types below.
  linkType: 'preview', // or "direct"

  // Optional. A value of false (default) limits selection to a single file, while
  // true enables multiple file selection.
  multiselect: true, // or true

  // Optional. This is a list of file extensions. If specified, the user will
  // only be able to select files with these extensions. You may also specify
  // file types, such as "video" or "images" in the list. For more information,
  // see File types below. By default, all extensions are allowed.
  // extensions: ['.pdf', '.doc', '.docx'],

  // Optional. A value of false (default) limits selection to files,
  // while true allows the user to select both folders and files.
  // You cannot specify `linkType: "direct"` when using `folderselect: true`.
  folderselect: true, // or true
};

export const DropboxButton = ({ disabled, onSelect }) => {
  useScript('https://www.dropbox.com/static/api/2/dropins.js', {
    'data-app-key': import.meta.env.VITE_DROPBOX_APP_KEY,
  });

  const handleClick = () => {
    window.Dropbox.appKey = import.meta.env.VITE_DROPBOX_APP_KEY;
    window.Dropbox.choose({
      ...chooserOptions,
      success: (files) => onSelect(mapDropboxFiles(files)),
    });
  };

  return (
    <Button
      disabled={disabled}
      startIcon={
        <Avatar src={DropboxIcon} sx={{ img: { objectFit: 'contain' } }} />
      }
      sx={{
        textTransform: 'none',
        mt: 2,
        p: 2,
      }}
      variant="text"
      onClick={handleClick}
    >
      Dropbox
    </Button>
  );
};
