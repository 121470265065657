import React, { useRef } from 'react';

import { useQuery } from '@apollo/client';
import PieChart from 'src/components/Charts/PieChart';
import TableBody from '@mui/material/TableBody';
import BarChart from 'src/components/Charts/BarChart';
import TreemapChart from 'src/components/Charts/TreemapChart';
import { GET_REPORTS_FILECASES } from 'src/graphql/queries/filecases';
import Page from 'src/components/v2/Page';

import {
  Grid,
  Typography,
  Card,
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  Paper,
  Avatar,
  Stack,
  Button,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';
import { FilecasesReportsHeaderFilters } from './FilecasesReportsHeaderFilters';

// TODO: REFACTOR THE ENTIRE SECTION ASAP
const ReportsFilecases = () => {
  const { studioId } = useParams();
  const { data: { filecases = [] } = {}, loading, refetch } = useQuery(GET_REPORTS_FILECASES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      studio: studioId,
    },
  });

  const DataFunnelChart = filecases.reduce((acc, val) => {
    if (!val.category) {
      return acc;
    }

    if (!acc[val.category.name]) {
      acc[val.category.name] = 1;
    } else {
      acc[val.category.name] += 1;
    }

    return acc;
  }, {});

  const treeMapChart = filecases.reduce((acc, val) => {
    if (!val.tags || !val.tags.length) {
      return acc;
    }

    val.tags.forEach((tag) => {
      if (!acc[tag.name]) {
        acc[tag.name] = 1;
      } else {
        acc[tag.name] += 1;
      }
    });

    return acc;
  }, {});

  const filterFilecases = filecases.filter(
    (filecase) => filecase.assigned,
  );

  // TODO: CREATE SEPARATE TABLE FOR USERS BY FILECASES AND USE IT HERE!
  // PLEASE!!!!!
  const filecasesPerAssigned = filterFilecases.reduce(
    (acc, val) => {
      val.assigned.forEach((assign) => {
        if (!acc.has(assign.id)) {
          acc.set(assign.id, {
            filecase: val,
            count: 1,
          });
        } else {
          acc.set(assign.id, {
            filecase: val,
            count: acc.get(assign.id).count + 1,
          });
        }
      });

      return acc;
    },
    new Map(),
  );

  const filecasesPerState = filecases.reduce((acc, val) => {
    if (!val.state) {
      return acc;
    }

    if (!acc.has(val.state.name)) {
      acc.set(val.state.name, {
        filecase: val.state,
        count: 1,
      });
    } else {
      acc.set(val.state.name, {
        filecase: val.state,
        count: acc.get(val.state.name).count + 1,
      });
    }

    return acc;
  }, new Map());

  const initialValues = {
    start: null,
    end: null,
    assigned: [],
    entities: [],
    tags: [],
    category: null,
    side: null,
    state: null
  };


  const onFiltersSubmit = (values) =>
    refetch({
      studioId,
      start: values.start ? dayjs(values.start).startOf('month').toISOString() : null,
      end: values.end ? dayjs(values.end).add(1, 'month').startOf('month').toISOString() : null,
      assigned: values.assigned?.map(({ value }) => value),
      entities: values.entities?.map(({ value }) => value),
      tags: values.tags?.map(({ value }) => value),
      category: values.category?.value,
      side: values.side?.value,
      state: values.state?.value,
    });

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current.parentElement,
    pageStyle: 'button { visibility: hidden }'
  });


  return (
    <Page>
      <Typography
        mb={1}
        sx={{ display: 'flex', justifyContent: 'center' }}
        variant="h2"
      >
        Informe expedientes
      </Typography>

      <Typography
        mb={2}
        sx={{ display: 'flex', justifyContent: 'center' }}
        variant="h6"
      >
        Comprende mejor la dinamica de tus causas procesales con indicadores
        claros de tus casos y expedientes.
      </Typography>

      <Stack gap={2}>
        <Card sx={{ p: 4 }}>
          <FilecasesReportsHeaderFilters
            initialValues={initialValues}
            onSubmit={onFiltersSubmit}
          />
        </Card>


        {!loading && filecases && (
          <Card sx={{ padding: 5 }}>
            <Stack alignItems="center" direction="row" gap={2} justifyContent="space-between" mb={2} pr={[0, 0, 2]}>
              <Typography>
                Expedientes encontrados: {filecases?.length}
              </Typography>

              <Button variant='contained' onClick={handlePrint}>
                Descargar PDF
              </Button>
            </Stack>

            <Grid container item md ref={componentRef} spacing={2} xs={12}>
              <Grid item md={6} xs={12}>
                <Card sx={{ backgroundColor: 'primary.white' }}>
                  <BarChart data={DataFunnelChart} />
                </Card>
              </Grid>

              <Grid center item md={6} xs={12}>
                <Card sx={{ backgroundColor: 'primary.white' }}>
                  <PieChart filecases={filecasesPerState} />
                </Card>
              </Grid>

              <Grid center item md={6} xs={12}>
                <Card sx={{ backgroundColor: 'primary.white' }}>
                  <Typography
                    sx={{ backgroundColor: 'common.white', padding: '2rem' }}
                  >
                    Expedientes por Usuario
                  </Typography>
                  <TableContainer component={Paper} sx={{ padding: 5 }}>
                    <Table aria-label="simple table" sx={{ minWidth: 300 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>Nombre</TableCell>
                          <TableCell align="right">Expedientes</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {[...filecasesPerAssigned].map(
                          ([assignedId, { filecase, count }]) => {
                            const assign = filecase.assigned.find(
                              ({ id }) => assignedId === id,
                            );

                            return (
                              <TableRow
                                key={assignedId}
                                sx={{
                                  '&:last-child td, &:last-child th': { border: 0 },
                                }}
                              >
                                <Avatar alt="picture" src={assign.picture} />
                                <TableCell component="th" scope="row">
                                  {assign.name}
                                </TableCell>
                                <TableCell align="right">{count}</TableCell>
                              </TableRow>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </Grid>

              <Grid item md={6} xs={12}>
                <Card
                  sx={{
                    backgroundColor: 'primary.white',
                    padding: 1,
                    borderRadius: 2,
                  }}
                >
                  <TreemapChart file={treeMapChart} />
                </Card>
              </Grid>
            </Grid>
          </Card>
        )}
      </Stack>

    </Page>
  );
};

export default ReportsFilecases;
