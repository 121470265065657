import React from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent } from '@mui/material';
import * as Yup from 'yup';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import MoneyField from 'src/components/ui-components/Form/MoneyField';
import { YupDateValidations } from 'src/utils/dates';

const validationSchema = Yup.lazy((values) => {
  const maxAmount =
    values.expenseTotal - (values.paid - (values?.payment?.amount ?? 0));

  return Yup.object({
    date: YupDateValidations,
    amount: Yup.number()
      .positive('Debe ser un monto positivo')
      .max(maxAmount, `La cantidad no puede exceder: ${maxAmount}`)
      .required('Debes agregar un monto a tu gasto'),
    paymentMethod: Yup.object()
      .nullable()
      .required('Debes agregar un metodo de pago'),
  });
});

const PaymentForm = ({
  initialValues,
  onSubmit,
  onCancel,
  buttonLabel,
  ...rest
}) => (
  <Formik
    {...rest}
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    {({ isSubmitting }) => (
      <>
        <DialogContent>
          <Form id="paymentForm">
            <DateField label="Fecha" name="date" />

            <MoneyField
              disabledCurrency
              amountName="amount"
              currencyName="currency"
              label="Monto"
              type="number"
            />

            <TextField multiline label="Concepto" minRows={3} name="concept" />

            <SelectField
              label="Medio de pago"
              name="paymentMethod"
              options={DEFAULT_PAYMENT_METHODS}
            />
          </Form>
        </DialogContent>

        <DialogActions>
          <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
            Cancelar
          </Button>

          <SubmitButton fullWidth form="paymentForm">
            {buttonLabel}
          </SubmitButton>
        </DialogActions>
      </>
    )}
  </Formik>
);

export default PaymentForm;
