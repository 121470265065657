import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import { ExpandLess, DragIndicator } from '@mui/icons-material';
import { Draggable } from '@hello-pangea/dnd';

const DetailSection = ({ icon, title, children, ...props }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Draggable draggableId={title} key={props.draggableId} {...props}>
      {(provided, snapshot) => (
        <Accordion
          disableGutters
          elevation={0}
          expanded={isExpanded}
          ref={provided.innerRef}
          onChange={() => setIsExpanded(!isExpanded)}
          {...provided.draggableProps}
        >
          <AccordionSummary
            expandIcon={<ExpandLess />}
            sx={{
              padding: 0,
              ...(snapshot.isDragging && {
                backgroundColor: 'primary.100',
              }),
            }}
          >
            <Stack alignItems="center" direction="row" spacing={1}>
              <Box
                {...provided.dragHandleProps}
                component="span"
                sx={{
                  display: 'flex',
                  svg: {
                    color: 'grey.200',
                  },
                }}
              >
                <DragIndicator />
              </Box>

              {icon}

              <Typography sx={{ fontWeight: '500' }} variant="h6">
                {title}
              </Typography>
            </Stack>
          </AccordionSummary>

          <AccordionDetails
            sx={{
              pl: 4,
            }}
          >
            <Box>{children}</Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default React.memo(DetailSection);
