import React from 'react';
import { Box, Avatar, Tooltip, Badge } from '@mui/material';

import {
  measureFileIconBackground,
  measureFileIcon,
  humanizeFileCategory,
  categorizeFileExtension,
} from 'src/utils/formatters';

import GoogleDriveIcon from 'src/pages/Dashboard/Settings/logo_drive_2020q4_color_2x_web_64dp.png';
import DropboxIcon from 'src/pages/Dashboard/Settings/Dropbox_logo.svg';
import OneDriveIcon from 'src/pages/Dashboard/Settings/onedrive.svg';

// FIXME: duplicated code
const badgesByKind = {
  dropbox: DropboxIcon,
  drive: GoogleDriveIcon,
  onedrive: OneDriveIcon,
  spaces: null,
};

const TypeCell = ({ row: document }) => {
  const Icon = measureFileIcon(document);
  const bgColor = measureFileIconBackground(document);
  const fileCategory = categorizeFileExtension(document);
  const categoryLabel = humanizeFileCategory(fileCategory);

  return (
    <Tooltip title={categoryLabel}>
      <Box sx={{ position: 'relative' }}>
        <Avatar
          sx={{
            backgroundColor: bgColor,
            color: 'white',
            borderRadius: 0.5,
          }}
          variant="square"
        >
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <Box
                as="img"
                src={badgesByKind[document.kind]}
                sx={{ width: 14, height: 14, bgcolor: 'transparent' }}
              />
            }
            invisible={!badgesByKind[document.kind]}
            overlap={document.isFolder ? 'circular' : 'rectangular'}
          >
            <Icon />
          </Badge>
        </Avatar>
      </Box>
    </Tooltip>
  );
};

export default TypeCell;
