import { useQuery, QueryHookOptions } from '@apollo/client';
import { CalendarEventsQuery } from '@legalsurf/queries';

import { CalendarEventsHookVariables } from './types';

export const useCalendarEvents = (
  variables: CalendarEventsHookVariables,
  options: QueryHookOptions,
) => {
  const query = useQuery<any>(CalendarEventsQuery, {
    fetchPolicy: 'cache-and-network',
    variables,
    ...options,
  });

  return {
    ...query,
    data: query.data?.events ?? [],
  };
};
