import React from 'react';
import { Box, Dialog } from '@mui/material';

import { EntityDetail } from 'src/components/v2/EntityDetail';

export const EntityDialog = ({ id, open, onClose }) => (
  <Dialog
    fullScreen
    open={!!open}
    sx={{ width: ['initial', 700], ml: 'auto' }}
    onClose={onClose}
  >
    <Box sx={{ overflowY: 'scroll' }}>
      <EntityDetail entityId={id} onClose={onClose} />
    </Box>
  </Dialog>
);
