import React from 'react';

import { Box, Typography } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

const ReportCard = ({ children, title }) => (
  <Box sx={{ position: 'relative' }}>
    <Box
      sx={{
        padding: 2,
        backgroundColor: 'white',
        borderRadius: 1,
        boxShadow: (theme) => theme.shadows[1],
      }}
    >
      {title && (
        <Typography sx={{ my: 1 }} textAlign="center" variant="h6">
          {title}
        </Typography>
      )}

      <Box
        sx={{
          '& .recharts-wrapper': {
            maxWidth: '100%',

            '& .recharts-surface': {
              maxWidth: '100%',
            },

            '& .recharts-legend-wrapper': {
              maxWidth: '100%',
            },
          },
        }}
      >
        <ResponsiveContainer height="100%" width="100%">
          {children}
        </ResponsiveContainer>
      </Box>
    </Box>
  </Box>
);

export default ReportCard;
