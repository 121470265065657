import { gql, useQuery } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const GetContractTemplatesQuery = gql`
  query getNotificationTemplatesQuery($studioId: ID!) {
    getContractTemplates(studioId: $studioId) {
      id
      name
      content
      studioId
    }
  }
`;

export const useContractTemplates = () => {
  const { data, loading, error, ...rest } = useQuery(
    GetContractTemplatesQuery,
    {
      variables: {
        studioId: useStudioId(),
      },
    },
  );

  return {
    contracts: data?.getContractTemplates || [],
    loading,
    error,
    ...rest,
  };
};

export const GetContractTemplateQuery = gql`
  query GetContractTemplateQuery($studioId: ID!, $id: ID!) {
    getContractTemplate(studioId: $studioId, id: $id) {
      id
      name
      content
      studioId
    }
  }
`;

export const useContractTemplate = (id) => {
  const { data, loading, error, ...rest } = useQuery(GetContractTemplateQuery, {
    variables: {
      studioId: useStudioId(),
      id,
    },
    skip: !id,
  });

  return {
    contract: data?.getContractTemplate,
    loading,
    error,
    ...rest,
  };
};
