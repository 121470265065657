import React from 'react';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import { getWeeklyStyles } from '../utils';

export const CalendarAsideDay = ({
  weekly,
  selectedDate,
  onSelectDate,
  daysWithEvents,
  day: date,
}) => {
  const sx =
    weekly && selectedDate.isSame(date, 'week') && getWeeklyStyles(date);

  const eventsWithinDate = daysWithEvents[dayjs(date, { utc: true }).format('YYYY-MM-DD')];

  if (date.isToday()) {
    return (
      <Tooltip key={date} placement="top" title="Hoy">
        <PickersDay
          day={date}
          outsideCurrentMonth={false}
          selected={date.isSame(selectedDate, 'day')}
          sx={{
            border: '1px dashed',
            borderColor: 'primary.main',
            backgroundColor: eventsWithinDate ? 'primary.200' : null,
            ...sx,
          }}
          onDaySelect={onSelectDate}
        />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      key={date}
      title={eventsWithinDate ? `Tiene ${eventsWithinDate} eventos` : ''}
    >
      <PickersDay
        day={date}
        key={date}
        outsideCurrentMonth={false}
        selected={date.isSame(selectedDate, 'day')}
        sx={{
          backgroundColor: eventsWithinDate ? 'primary.200' : undefined,
          ...sx,
        }}
        onDaySelect={onSelectDate}
      />
    </Tooltip>
  );
};
