import React, { useMemo } from 'react';
import { Stack } from '@mui/material';

import PermissionGuard from 'src/components/v2/PermissionGuard';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import Table from 'src/components/v3/Table';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { useSalesColumns } from 'src/tables/SalesTable/columns';
import { getColumns } from 'src/tables/ExpensesTable/columns';

const expensesColumns = getColumns();

export const SingleFilecaseSales = ({ filecase, type }) => {
  const dispatchSaleDialog = useDialog('sale');
  const dispatchExpenseDialog = useDialog('expense');
  const dispatchSalesFormDialog = useDialog('salesForm');
  const dispatchExpensesFormDialog = useDialog('expenseForm');
  const columns = useSalesColumns();

  const { hasPermissions: hasSalesAccessPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.SALES.ACCESS,
  ]);

  const rows = useMemo(
    () => (type === 'sales' ? filecase.sales : filecase.expenses),
    [filecase.expenses, filecase.sales, type],
  );

  return (
    <Stack gap={2}>
      {!!rows.length && (
        <Table
          hideFooter
          checkboxSelection={false}
          columns={type === 'sales' ? columns : expensesColumns}
          loading={false}
          pageSize={rows.length}
          paginationMode="client"
          rows={rows}
          sxCard={{
            boxShadow: 'none',
          }}
          onRowClick={(params) =>
            hasSalesAccessPermissions &&
            (type === 'sales' ? dispatchSaleDialog : dispatchExpenseDialog)(
              `FilecaseSalesSingle-${params.row.id}`,
              {
                id: params.row.id,
                expenseId: params.row.id,
              },
            )
          }
        />
      )}

      <PermissionGuard
        permissions={[
          type === 'sales'
            ? LS_PERMISSIONS_MAP.SALES.CREATE
            : LS_PERMISSIONS_MAP.EXPENSES.CREATE,
        ]}
      >
        <CreationInfoBanner
          buttonStr={`Crear ${type === 'sales' ? 'Honorario' : 'Gasto'}`}
          description={`Comienza ahora mismo a cargar tus ${
            type === 'sales' ? 'Honorarios' : 'Gastos'
          }.`}
          title={`Crear ${type === 'sales' ? 'Honorario' : 'Gasto'}`}
          onClick={() =>
            type === 'sales'
              ? dispatchSalesFormDialog('FilecaseSalesSingle', {
                  filecase,
                })
              : dispatchExpensesFormDialog('FilecaseSalesSingle', {
                  filecase,
                })
          }
        />
      </PermissionGuard>
    </Stack>
  );
};
