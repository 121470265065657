import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { Pressable } from 'src/components/ui-components/Pressable';

const HelpCard = ({ emoji, avatarColor, title, description, href }) => (
  <Pressable href={href} rel="noopener" sx={{ height: '100%' }} target="_blank">
    <Card sx={{ backgroundColor: '#5D34F2' }}>
      <CardContent>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Avatar
            sx={{
              backgroundColor: avatarColor,
            }}
            variant="rounded"
          >
            {emoji}
          </Avatar>

          <Stack direction="column">
            <Typography color="white" fontSize={14} variant="subtitle1">
              {title}
            </Typography>

            <Typography color="white" fontSize={12} variant="subtitle2">
              {description}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  </Pressable>
);

export default HelpCard;
