import React from 'react';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { gql, useMutation } from '@apollo/client';

export const UpdateEntityAssigneesMutation = gql`
  mutation UpdateEntityAssigneesMutation($id: ID!, $assigned: [ID!]) {
    updateEntity(id: $id, assigned: $assigned) {
      id
      studioId
      assigned {
        id
        name
        picture
      }
    }
  }
`;

const AssignedCell = ({ row: entity }) => {
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
  ]);

  const [updateEntityAssignees] = useMutation(UpdateEntityAssigneesMutation);

  const handleAssignedSubmit = async (newAssignees) => {
    await updateEntityAssignees({
      variables: {
        id: entity.id,
        assigned: newAssignees,
      },
    });
  };

  return (
    <AssigneesPopover
      selectedAssignees={entity.assigned}
      touchable={hasPermissions}
      onSubmit={handleAssignedSubmit}
    />
  );
};
export default AssignedCell;
