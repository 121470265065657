import { gql } from '@apollo/client';

export const SalesQuery = gql`
  query TransactionsTableQuery(
    $studio: ID!
    $filecases: [ID!]
    $entities: [ID!]
    $responsibles: [ID!]
    $currencies: [ID!]
    $tags: [ID!]
    $start: DateTime
    $end: DateTime
  ) {
    sales(
      studio: $studio
      filters: {
        filecases: $filecases
        start: $start
        end: $end
        entities: $entities
        responsibles: $responsibles
        currencies: $currencies
        tags: $tags
      }
    ) {
      id
      date
      concept
      amount
      hours
      charged

      items {
        amount
        description
      }

      currency {
        id
        name
        studioId
      }

      entity {
        id
        type
        displayName
        phone
      }
      responsible {
        id
        picture
        name
      }
      filecase {
        id
        title
        externalId
        description
        state {
          id
          studioId
          color
          name
        }
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const GET_SALE = gql`
  query getSale($id: ID!) {
    sale(id: $id) {
      id
      date
      concept
      amount
      hours
      charged

      items {
        description
        amount
      }

      currency {
        id
        name
        studioId
      }
      charges {
        id
        date
        method
        concept
        amount
      }
      entity {
        id
        type
        displayName
      }
      filecase {
        id
        title
        externalId
        description

        latestEntry {
          id
          content
          date
          createdAt
          updatedAt
          createdBy {
            id
            picture
            name
          }
        }
      }
      comments {
        id
        content
        updatedAt
        createdAt
        createdBy {
          id
          name
          picture
        }
      }
      responsible {
        id
        picture
        name
      }
      issuedBy {
        id
        picture
        name
      }
      tags {
        id
        name
        studioId
      }
      studio {
        id
        picture
        name
      }
    }
  }
`;

export const GET_CHARGE = gql`
  query charge($id: ID!) {
    charge(id: $id) {
      id
      date
      method
      concept
      amount

      currency {
        id
        name
        studioId
      }

      sale {
        id
        items {
          description
          amount
        }

        entity {
          id
          type
          displayName
        }

        filecase {
          id
          title
          externalId
          description
        }
      }
    }
  }
`;

export const DELETE_CHARGE = gql`
  mutation deleteCharge($studioId: ID!, $id: ID!) {
    deleteCharge(studioId: $studioId, id: $id) {
      id
      sale {
        id
        charged
      }
    }
  }
`;

export const DELETE_SALE = gql`
  mutation deleteSale($studioId: ID!, $id: ID!) {
    deleteSale(studioId: $studioId, id: $id) {
      id
    }
  }
`;
