import { useCallback, useMemo } from 'react';
import { useSearchFilterValues } from './utils';
import { debounce } from '@mui/material';

export const useFilters = (
  useFiltersStore,
  paramConfigMap,
  defaultInitialValues,
) => {
  const [params, setParams] = useSearchFilterValues(
    useFiltersStore,
    paramConfigMap,
  );

  const debouncedSetParams = useCallback(debounce(setParams, 300), [setParams]);

  const handleFiltersChange = useCallback(
    (values, debounce = false) => {
      const newParams = Object.entries(values).reduce((acc, [key, val]) => {
        if (Array.isArray(val)) {
          acc[key] = val.map((item) => item?.value ?? item);
        } else {
          acc[key] = val?.value ?? val;
        }
        return acc;
      }, {});

      if (debounce) {
        debouncedSetParams(newParams);
      } else {
        setParams(newParams);
      }
    },
    [debouncedSetParams, setParams],
  );

  const handleReset = useCallback(() => setParams({}), [setParams]);

  const initialValues = Object.keys(defaultInitialValues).reduce((acc, key) => {
    if (params[key]) {
      acc[key] = params[key];
    } else {
      acc[key] = defaultInitialValues[key];
    }
    return acc;
  }, {});

  return {
    filters: params,
    setFilters: handleFiltersChange,
    resetFilters: handleReset,
    initialValues,
  };
};
