import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';

export const LinearProgressWithLabel = ({
  value,
  sx,
  linearProgressSx,
  ...props
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
    <Box sx={{ width: '100%', mr: 1 }}>
      <LinearProgress
        sx={linearProgressSx}
        value={value}
        variant="determinate"
        {...props}
      />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography color="text.secondary" variant="body2">{`${Math.round(
        value,
      )}%`}</Typography>
    </Box>
  </Box>
);
