import * as Yup from 'yup';
import { imageFormatters } from 'src/utils/imageFormatters';

const StudioFormSchemaValidation = Yup.object({
  file: Yup.mixed()
    .test(
      'file-type',
      'El archivo debe ser formato .jpg .jpeg .png',
      (file) => {
        if (!file) return true;

        return imageFormatters.includes(file.type);
      },
    )
    .test('file-size', 'El archivo debe ser inferior a 12MB', (file) => {
      if (!file) return true;

      return file.size < 12000000;
    }),
  studioName: Yup.string().required('Este campo es requerido.'),
});

export default StudioFormSchemaValidation;
