import { gql } from '@apollo/client';

export const CalendarPageQuery = gql`
  query getCalendarPageData(
    $studio: ID!
    $text: String
    $calendars: [ID!]
    $taskStates: [TaskState!]
    $assigned: [ID!]
    $entity: ID
    $type: EventType
    $filecases: [ID!]
    $start: DateTime
    $end: DateTime
  ) {
    events(
      studio: $studio
      filters: {
        type: $type
        text: $text
        calendars: $calendars
        assigned: $assigned
        entity: $entity
        state: $taskStates
        filecases: $filecases
        start: $start
        end: $end
      }
    ) {
      id
      calendarId
      timezone
      title
      start
      end
      state
      allDay
      calendar {
        id
        name
        timezone
        googleCalendarId
        color
      }
    }

    members(studio: $studio) {
      id
      name
      email
    }

    entities(studio: $studio) {
      id
      displayName
      businessname
      firstname
      lastname
    }

    filecases(studio: $studio) {
      id
      title
    }
  }
`;
