import React from 'react';
import { Stack, Grid, Typography, FormHelperText } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { useField } from 'formik';
import es from 'react-phone-input-2/lang/es.json';

import 'react-phone-input-2/lib/style.css';

const PhoneField = ({
  placeholder = '+54 12 12345678',
  label,
  name,
  gridProps = { xs: 12 },
  helperText,
  disabled,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Grid
      item
      sx={{
        '& .react-tel-input': {
          borderRadius: 1,

          ...(disabled
            ? {
                border: '1px solid rgba(0, 0, 0, 0.35)',
                opacity: 0.8,
                color: 'rgba(0, 0, 0, 0.38)',
                fontWeight: 600,
                cursor: 'click',
              }
            : {
                '&:hover': {
                  '& input': {
                    borderColor: 'black',

                    ...(meta.touched &&
                      meta.error && {
                        borderColor: 'error.main',
                      }),
                  },

                  '& .flag-dropdown': {
                    borderRight: 'none',
                    borderLeftColor: 'black',
                    borderTopColor: 'black',
                    borderBottomColor: 'black',

                    ...(meta.touched &&
                      meta.error && {
                        borderLeftColor: 'error.main',
                        borderTopColor: 'error.main',
                        borderBottomColor: 'error.main',
                      }),
                  },
                },
              }),

          '& input': {
            backgroundColor: 'background.input',
            borderColor: 'transparent',
            borderRadius: 1,

            ...(meta.touched &&
              meta.error && {
                border: '1px solid',
                borderLeftColor: 'error.main',
                borderRightColor: 'error.main',
                borderTopColor: 'error.main',
                borderBottomColor: 'error.main',
              }),

            '&:focus': {
              borderWidth: 2,
              borderColor: 'primary.main',

              ...(meta.touched &&
                meta.error && {
                  borderColor: 'error.main',
                }),

              '& + .flag-dropdown': {
                borderWidth: 2,
                borderLeftColor: 'primary.main',
                borderTopColor: 'primary.main',
                borderBottomColor: 'primary.main',
                overflow: 'hidden',

                ...(meta.touched &&
                  meta.error && {
                    borderLeftColor: 'error.main',
                    borderTopColor: 'error.main',
                    borderBottomColor: 'error.main',
                  }),
              },
            },

            ...(meta.touched &&
              meta.error && {
                borderColor: 'error.main',
                border: '1px solid',
              }),
          },

          '& .flag-dropdown': {
            backgroundColor: 'background.input',
            borderColor: 'transparent',
            borderRight: 'none',
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderBottomLeftRadius: (theme) => theme.shape.borderRadius,

            ...(meta.touched &&
              meta.error && {
                borderLeftColor: 'error.main',
                borderTopColor: 'error.main',
                borderBottomColor: 'error.main',
              }),
          },
        },
      }}
      {...gridProps}
    >
      <Stack direction="column" spacing={1}>
        {label && <Typography variant="label">{label}</Typography>}

        <PhoneInput
          disabled={disabled}
          inputProps={{
            name: field.name,
            onBlur: field.onBlur,
          }}
          inputStyle={{
            height: 50,
            width: '100%',
          }}
          localization={es}
          placeholder={placeholder}
          regions={['america', 'eu-union']}
          value={field.value}
          onChange={(phone) => helpers.setValue(phone)}
          {...props}
        />

        {helperText && <FormHelperText>{helperText}</FormHelperText>}

        {meta.touched && meta.error && (
          <Typography color="error.main" sx={{ pl: 2 }}>
            {meta.error}
          </Typography>
        )}
      </Stack>
    </Grid>
  );
};

export default PhoneField;
