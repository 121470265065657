import React from 'react';
import { Alert, AlertTitle, Box, Dialog } from '@mui/material';

import { ErrorBoundary } from '@sentry/react';
import { CalendarEventDetailsDialogContent } from './CalendarEventDetailsDialogContent';

const SingleEventErrorBoundary = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <Alert color="error">
      <AlertTitle>Ha ocurrido un error fatal.</AlertTitle>
      Algo ha occurrido mientras cargabamos tu evento, estamos solucionandolo
      ahora mismo. Intente mas tarde.
    </Alert>
  </Box>
);

const CalendarEventDetailsDialog = ({
  id: eventId,
  calendarId,
  open,
  onClose,
}) => (
  <Dialog
    fullScreen
    open={open}
    sx={{
      maxWidth: 650,
      ml: 'auto',
      height: '100%',
    }}
    onClose={onClose}
  >
    <Box sx={{ overflowY: 'auto' }}>
      <ErrorBoundary fallback={SingleEventErrorBoundary}>
        <CalendarEventDetailsDialogContent
          calendarId={calendarId}
          eventId={eventId}
          onClose={onClose}
        />
      </ErrorBoundary>
    </Box>
  </Dialog>
);

export default CalendarEventDetailsDialog;
