import { gql, useQuery } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const getNotificationTemplatesQuery = gql`
  query getNotificationTemplatesQuery($studioId: ID!) {
    getNotificationTemplates(studioId: $studioId) {
      id
      name
      body
      studioId
    }
  }
`;

export const useNotificationTemplates = () => {
  const { data, loading, error, ...rest } = useQuery(
    getNotificationTemplatesQuery,
    {
      variables: {
        studioId: useStudioId(),
      },
    },
  );

  return {
    templates: data?.getNotificationTemplates || [],
    loading,
    error,
    ...rest,
  };
};
