import React from 'react';

import { PopoverBase } from '../../../PopoverBase';
import { MultipleEntitySpecialWordPopoverBody } from './MultipleEntitySpecialWordPopoverBody';

export const MultipleEntitySpecialWordPopover = ({
  open,
  anchorEl,
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
  ...props
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    open={open}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <MultipleEntitySpecialWordPopoverBody sx={{ maxWidth: 400 }} {...props} />
  </PopoverBase>
);
