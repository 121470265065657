import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import WarningShark from './warning-shark.svg';

export const DesyncGoogleCalendarDialog = ({
  open,
  loading,
  onCancel,
  onAccept,
}) => {
  const [innerLoading, setInnerLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    setInnerLoading(true);

    return onAccept().finally(() => setInnerLoading(false));
  }, [onAccept]);

  return (
    <Dialog open={open} onClose={!loading ? onCancel : undefined}>
      <DialogTitle textAlign="center">
        <Box as="img" src={WarningShark} sx={{ width: 260 }} />

        <Typography color="error" variant="h5">
          ¡Ten mucho cuidado!
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ fontWeight: 500, px: [2, 12], textAlign: 'center' }}>
        Si desincronizas tu cuenta de Google Calendar, se eliminarán los eventos
        y calendarios relacionados.
        <Box sx={{ mt: 2 }}>
          <Typography mb={1}>
            Los siguientes elementos desaparecerán:
          </Typography>

          <Typography variant="body2">
            ❌ Calendarios vinculados a Google Calendar
          </Typography>
          <Typography variant="body2">
            ❌ Eventos vinculados a Google Calendar
          </Typography>
          <Typography variant="body2">
            ❌ Tareas vinculados a Google Calendar
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <LoadingButton
          disabled={loading}
          loading={loading || innerLoading}
          variant="text"
          onClick={handleSubmit}
        >
          Desincronizar
        </LoadingButton>

        <Button
          disabled={loading || innerLoading}
          variant="contained"
          onClick={onCancel}
        >
          cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
