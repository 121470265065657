import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const GetCurrenciesQuery = gql`
  query GetCurrenciesQuery($studioId: ID!) {
    getCurrencies(studioId: $studioId) {
      id
      name
      studioId
      updatedAt
    }
  }
`;

export const useCurrencies = (options = {}) => {
  const studioId = useStudioId();
  const { data, loading } = useQuery(GetCurrenciesQuery, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: {
      studioId,
    },
  });

  return { currencies: data?.getCurrencies || [], loading };
};

export const useDefaultCurrency = () => {
  const { currencies, loading } = useCurrencies();

  return {
    currency: currencies[currencies.length - 1],
    loading,
  };
};

const CurrencyAutocomplete = ({
  disabled,
  label = 'Moneda',
  placeholder = 'Ej. USD',
  ...rest
}) => {
  const { currencies, loading } = useCurrencies();

  const options = useMemo(
    () =>
      currencies.map((val) => ({
        value: val.name,
        label: val.name,
      })),
    [currencies],
  );

  return (
    <SelectField
      disabled={disabled}
      label={label}
      loading={loading}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default CurrencyAutocomplete;
