import React, { useRef, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  Grow,
  IconButton,
  Tooltip,
  ClickAwayListener,
  lighten,
  Avatar,
} from '@mui/material';
import dayjs from 'dayjs';

import { useUser } from 'src/utils/hooks/useUser';
import { LSEdit, LSTrash } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import { Save } from '@mui/icons-material';
import { RichEditor } from '../RichEditor';
import { replaceEditorStateHTML } from '../RichEditor/utils';

const Comment = ({
  comment,
  onUpdate,
  formatComment,
  onRemove,
  filesFormProps,
  readOnly,
}) => {
  // Unify on single text or content property
  const initialValue = comment?.text || comment?.content || '';
  const { user } = useUser();
  const editorRef = useRef();
  const [hovered, setHovered] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [date, setDate] = useState(dayjs(comment.date));
  const [dateOpen, setDateOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const canDelete = !!onRemove;
  const canUpdate = !!onUpdate;
  const hasPermissionsToModify =
    user.id === comment.createdBy.id || ['owner', 'admin'].includes(user.role);

  const handleDeleteComment = () =>
    dispatchWarningPromptDialog(`Comment-${comment.id}`, {
      onAccept: () => onRemove(comment.id),
      title: 'Eliminar comentario',
    });

  const shouldSave =
    value !== initialValue ||
    (comment.date && !dayjs(comment.date).isSame(date));

  const handleSubmit = () =>
    onUpdate({
      commentId: comment.id,
      date,
      content: value,
    }).finally(() => {
      setEditing(false);
      editorRef.current?.setEditable(false);
    });

  const handleOutsideClick = () => {
    if (!editing || dateOpen) {
      return;
    }

    if (!shouldSave) {
      editorRef.current?.setEditable(false);
      setEditing(false);
      return;
    }

    // eslint-disable-next-line no-alert
    const yes = window?.confirm(`¿Deseas descartar los cambios?`);

    if (yes) {
      editorRef.current?.setEditable(false);
      setEditing(false);
      setValue(initialValue);
      setDate(dayjs(comment.date));
      replaceEditorStateHTML(editorRef.current, initialValue);
    } else {
      editorRef.current?.focus();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleOutsideClick}>
      <Stack
        direction="row"
        gap={1}
        sx={{
          p: 1,
          borderRadius: 0.5,
          backgroundColor: (theme) =>
            editing
              ? 'background.input'
              : lighten(theme.palette.primary[100], 0.2),
          '&:hover': editing
            ? null
            : {
                backgroundColor: 'primary.100',
              },
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Tooltip title={comment.createdBy.name}>
          <Avatar
            alt={comment.createdBy.name}
            src={comment.createdBy.picture}
            sx={{ height: 28, width: 28, ml: 1 }}
          />
        </Tooltip>

        <Stack flex={1}>
          <Stack
            alignItems="center"
            direction="row"
            gap={1}
            justifyContent="space-between"
          >
            <Stack alignItems="center" direction="row" gap={1}>
              <Tooltip
                placement="right"
                title={dayjs(comment.createdAt).format('LLLL')}
              >
                <Typography
                  color="textSecondary"
                  fontSize={12}
                  fontStyle="italic"
                  variant="subtitle2"
                >
                  {formatComment
                    ? formatComment(comment.createdAt)
                    : dayjs(comment.createdAt).fromNow()}
                  {Math.abs(
                    dayjs(comment.createdAt).diff(
                      dayjs(comment.updatedAt),
                      'minutes',
                    ),
                  ) > 3 && ' (editado)'}
                </Typography>
              </Tooltip>
            </Stack>

            {hasPermissionsToModify && (
              <Stack direction="row" gap={1}>
                {canDelete && (
                  <Grow in={hovered}>
                    <IconButton
                      color="error"
                      size="small"
                      sx={{
                        '&:hover': {
                          color: 'error.main',
                        },
                      }}
                      onClick={handleDeleteComment}
                    >
                      <LSTrash fontSize="small" />
                    </IconButton>
                  </Grow>
                )}

                {canUpdate && (
                  <Grow in={hovered} {...(hovered ? { timeout: 400 } : {})}>
                    <IconButton
                      color="primary"
                      size="small"
                      sx={{
                        '&:hover': {
                          color: 'info.dark',
                        },
                      }}
                      onClick={() => {
                        editorRef.current?.setEditable(
                          !editorRef.current?._editable,
                        );
                        editorRef.current?.focus();
                        setEditing(!editing);
                      }}
                    >
                      <LSEdit fontSize="small" />
                    </IconButton>
                  </Grow>
                )}

                {editing && shouldSave && (
                  <Grow in>
                    <IconButton
                      color="primary"
                      size="small"
                      sx={{
                        '&:hover': {
                          color: 'info.dark',
                        },
                      }}
                      onClick={handleSubmit}
                    >
                      <Save fontSize="small" />
                    </IconButton>
                  </Grow>
                )}
              </Stack>
            )}
          </Stack>

          <Box
            onClick={(event) => {
              event.stopPropagation();

              if (!hasPermissionsToModify || readOnly) {
                return;
              }

              if (!editing) {
                editorRef.current?.setEditable(true);
                editorRef.current?.focus();
                setEditing(true);
              }
            }}
          >
            <RichEditor
              dateProps={
                comment.date
                  ? {
                      value: date,
                      onChange: setDate,
                      open: dateOpen,
                      onOpen: () => setDateOpen(true),
                      onClose: () => setDateOpen(false),
                    }
                  : null
              }
              disabled={!editing}
              filesFormProps={filesFormProps}
              initialValue={initialValue}
              ref={editorRef}
              variant="outlined"
              onChange={setValue}
            />
          </Box>
        </Stack>
      </Stack>
    </ClickAwayListener>
  );
};

export default Comment;
