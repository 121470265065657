import { Stack, Typography } from '@mui/material';
import React from 'react';

import rectangle2 from 'src/assets/images/Rectangle2.png';
import boga from 'src/assets/images/boga.jpg';
import boga2 from 'src/assets/images/boga2.png';

import HelpCard from './HelpCard';
import ResourceCard from './ResourceCard';

const ExternalResources = () => (
  <Stack spacing={3} width={['100%', '100%', 700]}>
    <Typography color="white" fontWeight={500} variant="h4">
      Saca el máximo provecho a LegalSurf.
    </Typography>

    <Stack direction="column" spacing={2}>
      <Stack
        direction={['column', 'row']}
        gap={2}
        justifyContent="space-between"
        spacing={2}
      >
        <Stack direction="column" flex={1} spacing={2}>
          <HelpCard
            avatarColor="#9CB5FE"
            description="Aprende como funciona legal.surf"
            emoji="🚀"
            href="https://www.youtube.com/watch?v=C3Izd204H2Y&ab_channel=LegalSurfLLC"
            title="Introducción"
          />
          <HelpCard
            avatarColor="#9690FE"
            description="Obtén más información y soporte"
            emoji="👩🏻‍💻"
            href="https://soporte.legal.surf"
            title="Centro de ayuda"
          />
          <HelpCard
            avatarColor="#2E22B6"
            description="Solicita una reunión con uno de nuestros asesores"
            emoji="😁"
            href="https://calendly.com/legalsurf-ventas/asesoria-onboarding"
            title="Solicitar una reunión"
          />
        </Stack>

        <Stack flex={1}>
          <ResourceCard
            cover={rectangle2}
            description="Descarga nuestra App y accede a tu información de forma instantanea"
            href="https://linktr.ee/legalsurf"
            sx={{ width: '100%', height: '100%' }}
            title="Potencia la gestión de tu despacho"
          />
        </Stack>
      </Stack>

      <Stack direction={['column', 'row']} gap={2} spacing={2}>
        <ResourceCard
          cover={boga}
          description="Visita nuestro blog y
descubre los temás más
relevantes del mundo
legaltech."
          href="https://www.instagram.com/legalsurf"
          sx={{ flex: 1, height: 320 }}
          title="Comunidad LegalSurf"
        />

        <ResourceCard
          cover={boga2}
          description="El gran desafío de las firmas de abogados."
          href="https://legalsurf.medium.com/crecer-y-desarrollar-estructura-el-gran-desaf%C3%ADo-de-las-firmas-de-abogados-69908888e347"
          sx={{ flex: 1, height: 320 }}
          title="Crecer y desarrollar estructura"
        />
      </Stack>
    </Stack>
  </Stack>
);

export default ExternalResources;
