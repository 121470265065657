import React from 'react';

import { getEventRowId } from 'src/utils/dates';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';

// TODO: Pasar toda esa data a esta tabla, fuera de la agenda
import { useColumns } from '../../pages/Dashboard/Calendar/CalendarAgendaTable/columns';

const EventsTable = ({ weekly, ...props }) => {
  const columns = useColumns(weekly);
  const dispatchEventDialog = useDialog('event');

  return (
    <Table
      checkboxSelection={false}
      columns={columns}
      getRowId={getEventRowId}
      paginationMode="client"
      sortingMode="client"
      sxCard={{
        boxShadow: 'none',
      }}
      onRowClick={(params) =>
        dispatchEventDialog(params.row.id, {
          id: params.row.id,
          calendarId: params.row.calendarId,
        })
      }
      {...props}
    />
  );
};

export default EventsTable;
