import { gql } from '@apollo/client';

export const ExpensesQuery = gql`
  query ExpensesQuery(
    $studio: ID!
    $filecases: [ID!]
    $entities: [ID!]
    $responsibles: [ID!]
    $start: DateTime
    $end: DateTime
  ) {
    expenses(
      studio: $studio
      filters: {
        filecases: $filecases
        start: $start
        end: $end
        entities: $entities
        responsibles: $responsibles
      }
    ) {
      id
      date
      concept
      amount
      paid

      items {
        description
        amount
      }

      entity {
        id
        type
        displayName
      }

      responsible {
        id
        picture
        name
      }

      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const CreateExpenseMutation = gql`
  mutation createExpense(
    $studio: ID!
    $amount: Float!
    $concept: String!
    $responsible: ID
    $tags: [String!]
    $date: DateTime!
    $entity: ID
    $filecase: ID
    $currency: ID
    $canBeSeenByCreatorOnly: Boolean!
    $items: [ExpenseItemInput!]
    $payments: [PaymentInput!]
    $comments: [String!]
  ) {
    createExpense(
      studio: $studio
      tags: $tags
      currency: $currency
      amount: $amount
      responsible: $responsible
      date: $date
      concept: $concept
      entity: $entity
      filecase: $filecase
      canBeSeenByCreatorOnly: $canBeSeenByCreatorOnly
      items: $items
      payments: $payments
      comments: $comments
    ) {
      id
      date
      concept
      amount
      paid

      items {
        description
        amount
      }

      entity {
        id
        type
        displayName
      }

      responsible {
        id
        picture
        name
      }

      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const GetExpenseDetails = gql`
  query getExpense($id: ID!) {
    expense(id: $id) {
      id
      date
      concept
      amount
      paid

      items {
        description
        amount
      }

      comments {
        id
        content
        updatedAt
        createdAt
        createdBy {
          id
          name
          picture
        }
      }
      issuedBy {
        id
        name
        picture
      }

      currency {
        id
        name
        studioId
      }
      payments {
        id
        date
        method
        concept
        amount
      }
      entity {
        id
        type
        displayName
      }
      filecase {
        id
        title
      }
      responsible {
        id
        picture
        name
      }
      tags {
        id
        name
        studioId
      }
      studio {
        id
        picture
        name
      }
    }
  }
`;

export const GetPaymentQuery = gql`
  query charge($id: ID!, $studioId: ID!) {
    charge(id: $id) {
      id
      date
      concept
      amount
    }
    studio(id: $studioId) {
      name
      id
      picture
    }
  }
`;

export const DeletePaymentMutation = gql`
  mutation deletePayment($id: ID!) {
    deletePayment(id: $id) {
      id

      expense {
        id
        paid
      }
    }
  }
`;

export const DeleteExpenseMutation = gql`
  mutation deleteExpense($id: ID!) {
    deleteExpense(id: $id) {
      id
    }
  }
`;

export const CreatePaymentMutation = gql`
  mutation createPayment(
    $expense: ID!
    $amount: Float!
    $concept: String!
    $date: DateTime!
    $method: CharginMethod!
  ) {
    createPayment(
      expense: $expense
      amount: $amount
      concept: $concept
      date: $date
      method: $method
    ) {
      id

      expense {
        id
        paid
      }
    }
  }
`;

export const UpdatePaymentMutation = gql`
  mutation updatePayment(
    $paymentId: ID
    $amount: Float
    $concept: String!
    $date: DateTime!
    $method: CharginMethod!
  ) {
    updatePayment(
      paymentId: $paymentId
      concept: $concept
      date: $date
      method: $method
      amount: $amount
    ) {
      id

      expense {
        id
        paid
      }
    }
  }
`;

export const UpdateExpenseMutation = gql`
  mutation updateExpense(
    $studio: ID!
    $amount: Float
    $expenseId: ID
    $tags: [String!]
    $concept: String
    $responsible: ID
    $currency: ID
    $date: DateTime
    $entity: ID
    $filecase: ID
    $items: [ExpenseItemInput!]
  ) {
    updateExpense(
      expenseId: $expenseId
      amount: $amount
      tags: $tags
      studio: $studio
      responsible: $responsible
      currency: $currency
      date: $date
      concept: $concept
      entity: $entity
      filecase: $filecase

      items: $items
    ) {
      id
      date
      concept
      amount
      paid

      items {
        description
        amount
      }

      entity {
        id
        type
        displayName
      }

      responsible {
        id
        picture
        name
      }

      tags {
        id
        name
        studioId
      }
    }
  }
`;
