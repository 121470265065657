import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../hooks/useUser';

export const WithToCSigned = ({ children }) => {
  const location = useLocation();
  const { user, loading, error } = useUser();

  if (loading) {
    return null;
  }

  if (error) {
    return <Navigate replace to="/error404" />;
  }

  if (!user?.toc?.signed) {
    return (
      <Navigate
        replace
        state={{ referrer: location.pathname + location.search }}
        to={{
          pathname: '/welcome',
        }}
      />
    );
  }

  return children;
};

export const WithoutToCSign = ({ children }) => {
  const { user, loading, error } = useUser();

  if (loading) {
    return null;
  }

  if (error) {
    return (
      <Navigate
        to={{
          pathname: '/error404',
        }}
      />
    );
  }

  if (user?.toc?.signed) {
    return <Navigate replace to="/dashboard" />;
  }

  return children;
};
