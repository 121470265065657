import { useQuery, QueryHookOptions } from '@apollo/client';
import { ExpensesQuery } from '@legalsurf/queries';

import { ExpensesHookQueryVariables, ExpensesHookQueryData } from './types';

export const useExpenses = (
  variables: ExpensesHookQueryVariables,
  options: QueryHookOptions,
) => {
  const query = useQuery<ExpensesHookQueryData, ExpensesHookQueryVariables>(
    ExpensesQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables,
      ...options,
    },
  );

  return {
    ...query,
    data: query.data?.expenses ?? [],
  };
};

export * from './types';
