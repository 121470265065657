import React from 'react';

import Page from 'src/components/v2/Page';
import { WorkflowsTable } from 'src/tables/WorkflowsTable';

export const WorkflowsSetting = () => (
  <Page title="Flujos de trabajo | legalsurf">
    <WorkflowsTable />
  </Page>
);
