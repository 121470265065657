import React, { forwardRef } from 'react';
import { Grid } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import UserInput from 'src/components/v3/UserInput';

const RichEditorField = forwardRef(
  (
    { label, gridProps = { xs: 12 }, onChange, name, disabled, ...rest },
    ref,
  ) => {
    const [field, meta, helpers] = useField(name);
    const { isSubmitting, handleSubmit } = useFormikContext();

    return (
      <Grid item {...gridProps}>
        <UserInput
          disabled={isSubmitting || disabled}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
          initialValue={meta.initialValue}
          label={label}
          ref={ref}
          variant="outlined"
          onSubmit={handleSubmit}
          {...field}
          onChange={(value) => {
            helpers.setValue(value);

            if (onChange) {
              onChange(value);
            }
          }}
          {...rest}
        />
      </Grid>
    );
  },
);

export default RichEditorField;
