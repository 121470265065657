import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { GET_TAGS } from 'src/graphql/queries/tags';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { Chip } from '@mui/material';

export const useTags = (options = {}) => {
  const studioId = useStudioId();
  const { data, loading } = useQuery(GET_TAGS, {
    ...options,
    fetchPolicy: 'cache-first',
    variables: {
      studio: studioId,
    },
  });

  return { tags: data?.tags || [], loading };
};

const TagsAutocomplete = ({
  disabled,
  label = 'Etiquetas',
  placeholder = 'Ej. Zona sur',
  ...rest
}) => {
  const { tags, loading } = useTags();

  const options = useMemo(
    () =>
      tags?.map((val) => ({
        label: val.name,
        value: val.name,
      })) || [],
    [tags],
  );

  return (
    <SelectField
      disabled={disabled}
      label={label}
      loading={loading}
      options={options}
      placeholder={placeholder}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const tagProps = getTagProps({ index });

          return (
            <Chip
              {...tagProps}
              disabled={option.disabled}
              key={option.id ?? index}
              label={option.name ?? option.label ?? option.value}
              size="small"
              sx={{
                color: 'primary.dark',
                backgroundColor: 'primary.300',
              }}
              onDelete={!option.disabled ? tagProps.onDelete : undefined}
            />
          );
        })
      }
      {...rest}
    />
  );
};

export default TagsAutocomplete;
