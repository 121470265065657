import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';

const FilecaseDetailsSkeleton = () => (
  <Box sx={{ p: 4 }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pt: 4,
            mb: 4,
            '& > * + *': { marginLeft: 2 },
          }}
        >
          <Skeleton
            animation="wave"
            height={18}
            variant="circular"
            width={18}
          />
          <Skeleton
            animation="wave"
            height={18}
            variant="circular"
            width={18}
          />
          <Skeleton
            animation="wave"
            height={18}
            variant="circular"
            width={18}
          />
        </Box>
      </Grid>

      <Grid container item spacing={2} xs={12}>
        <Grid item xs={1}>
          <Skeleton
            animation="wave"
            height={32}
            variant="rectangular"
            width={32}
          />
        </Grid>

        <Grid item xs>
          <Box mb={4}>
            <Skeleton animation="wave" width={150} />
            <Skeleton animation="wave" variant="text" width={400} />
            <Skeleton animation="wave" variant="text" width={350} />
            <Skeleton animation="wave" variant="text" width={200} />
          </Box>

          <Box sx={{ display: 'flex', '& > * + *': { marginLeft: 2 } }}>
            <Skeleton animation="wave" height={35} variant="text" width={60} />
            <Skeleton animation="wave" height={35} variant="text" width={60} />
            <Skeleton animation="wave" height={35} variant="text" width={60} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mt={10}>
            <Skeleton animation="wave" height={35} variant="text" width={85} />
            <br />
            <Skeleton animation="wave" variant="text" />
            <br />
            <Skeleton animation="wave" variant="text" />
            <br />
            <Skeleton animation="wave" variant="text" />
            <br />
          </Box>

          <Skeleton animation="wave" height={200} />
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default FilecaseDetailsSkeleton;
