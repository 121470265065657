import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Typography, Grid, Box } from '@mui/material';
import { MobileDatePicker } from '@mui/x-date-pickers';

import DateField from './DateField';
import HoursField from './HoursField';

const MobileDatePickerInput = ({ inputProps, InputProps, ...props }) => (
  <DateField
    inputProps={{ ...inputProps, ...InputProps }}
    {...props}
    sx={{ minWidth: '130px' }}
  />
);

const DateBlockField = ({ gridProps = { xs: 12 }, noHours = false }) => {
  const [open, setOpen] = useState(false);

  const { values, setFieldValue, isSubmitting } = useFormikContext();

  return (
    <Grid item {...gridProps}>
      <Box
        sx={{
          transition: 'all 0.04s ease-in-out',
        }}
        onClick={() => (!open ? setOpen(true) : null)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <MobileDatePicker
            disabled={isSubmitting}
            inputFormat="dddd, DD MMM"
            renderInput={MobileDatePickerInput}
            value={values.start}
            onChange={(newValue) => {
              const newStart = values.allDay
                ? dayjs(newValue)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('seconds', 0)
                : newValue;

              if (dayjs(newStart).isSame(values.end, 'day')) {
                setFieldValue('end', dayjs(newStart).add(1, 'hour'));
              }

              if (
                dayjs(newStart).isAfter(dayjs(values.end)) ||
                dayjs(newStart).isSame(dayjs(values.end))
              ) {
                setFieldValue('start', newStart);
                setFieldValue('end', dayjs(newStart).add(1, 'hour'));
                return;
              }

              setFieldValue('start', newStart);
            }}
          />

          {!values.allDay && !noHours && (
            <HoursField
              disabled={isSubmitting}
              value={values.start}
              onChange={(newHour) => {
                const newHourDate = dayjs(newHour);
                const newStart = dayjs(values.start)
                  .set('hour', newHourDate.hour())
                  .set('minutes', newHourDate.minute());

                if (newStart.isSameOrAfter(values.end)) {
                  setFieldValue('end', dayjs(newStart).add(1, 'hour'));
                }

                setFieldValue('start', newStart);
              }}
            />
          )}

          <Typography>a</Typography>

          <MobileDatePicker
            disabled={isSubmitting}
            inputFormat="dddd, DD MMM"
            minDate={
              dayjs(values.start).hour() === 23
                ? dayjs(values.start).add(1, 'hour')
                : values.start
            }
            renderInput={MobileDatePickerInput}
            value={values.end}
            onChange={(newValue) => {
              const newEnd = values.allDay
                ? dayjs(newValue)
                    .set('hour', 0)
                    .set('minute', 0)
                    .set('seconds', 0)
                : newValue;

              // MobileDatePicker has this issue https://github.com/mui/material-ui/issues/30731
              // if (dayjs(newValue).isSame(initialValues.end)) {
              //   return;
              // }

              if (dayjs(newEnd).isSameOrBefore(dayjs(values.start))) {
                setFieldValue('end', dayjs(values.start).add(1, 'hour'));
                return;
              }

              setFieldValue('end', newEnd);
            }}
          />

          {!values.allDay && !noHours && (
            <HoursField
              disablePastFrom={
                dayjs(values.start).isSame(values.end, 'day')
                  ? dayjs(values.start)
                  : undefined
              }
              disabled={isSubmitting}
              value={values.end}
              onChange={(newHour) => {
                const newHourDate = dayjs(newHour);
                const newEnd = dayjs(values.end)
                  .set('hour', newHourDate.hour())
                  .set('minutes', newHourDate.minute());

                setFieldValue('end', newEnd);
              }}
            />
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default DateBlockField;
