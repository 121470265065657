import React from 'react';
import { Backdrop, Popover } from '@mui/material';

export const PopoverBase = ({
  children,
  transformOrigin = {},
  anchorOrigin = {},
  ...props
}) => (
  <Backdrop
    open={props.open}
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  >
    <Popover
      {...props}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...anchorOrigin,
      }}
      slotProps={{
        paper: {
          sx: {
            boxShadow: 'none',
            border: '1px solid #d2d2d2',
          },
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        ...transformOrigin,
      }}
    >
      {children}
    </Popover>
  </Backdrop>
);
