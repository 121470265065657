import React, { useState } from 'react';
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  ListItemText,
  Typography,
  ListItemIcon,
  IconButton,
  Tooltip,
  Skeleton,
} from '@mui/material';

import { useNotificationTemplates } from 'src/tables/TemplateMessageTable/queries';
import { LSAdd, LSSendTemplate } from 'src/components/icons';
import { useUser } from 'src/utils/hooks/useUser';
import { populateSpecialKeyWords } from 'src/utils/notificationTemplates';
import { useStudio } from 'src/utils/hooks/useStudio';
import { useDialog } from 'src/dialogs/Dialogs';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import WhatsAppChat from './WhatsAppChat';

export const NotificationTemplatesPopoverBody = ({
  onClick,
  entity,
  data = {},
  ...rest
}) => {
  const { loading, templates } = useNotificationTemplates();
  const { user } = useUser();
  const { studio } = useStudio();
  const dispatchNotificationTemplateFormDialog = useDialog(
    'notificationTemplateFormDialog',
  );
  const [hoveredNotificationTemplate, setHoveredNotificationTemplate] =
    useState(null);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <WhatsAppChat
          data={{ entity, ...data }}
          entity={entity}
          message={hoveredNotificationTemplate || undefined}
        />
      </Box>

      <Box {...rest}>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            p: 2,
            display: 'flex',
          }}
        >
          <Typography variant="h6">Plantillas</Typography>

          <PermissionGuard
            permissions={[
              LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES,
            ]}
          >
            <Tooltip title="Crear plantilla">
              <IconButton
                color="primary"
                onClick={() =>
                  dispatchNotificationTemplateFormDialog(
                    'NotificationTemplatePopoverBody',
                  )
                }
              >
                <LSAdd />
              </IconButton>
            </Tooltip>
          </PermissionGuard>
        </Box>

        <Divider />

        <MenuList onMouseLeave={() => setHoveredNotificationTemplate(null)}>
          {!loading &&
            templates.map((template) => (
              <MenuItem
                key={template.id}
                onClick={() =>
                  onClick(
                    populateSpecialKeyWords(template.body, {
                      studio,
                      entity,
                      user,
                      ...data,
                    }),
                  )
                }
                onMouseEnter={() => setHoveredNotificationTemplate(template)}
              >
                <ListItemIcon>
                  <LSSendTemplate color="primary" />
                </ListItemIcon>

                <ListItemText>{template.name}</ListItemText>
              </MenuItem>
            ))}

          {!loading && !templates.length && (
            <MenuItem
              disabled
              sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
            >
              <Typography>No encontramos plantillas :(</Typography>
            </MenuItem>
          )}

          {loading &&
            Array.from({ length: 5 }).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <MenuItem key={index}>
                <ListItemText>
                  <Skeleton variant="text" width={300} />
                </ListItemText>
              </MenuItem>
            ))}
        </MenuList>
      </Box>
    </Box>
  );
};
