import { useQuery, QueryHookOptions } from '@apollo/client';
import { StudioMembersQuery } from '@legalsurf/queries';

export type StudioMemberHookQueryVariables = {
  studio: string;
};

export type StudioMembersQueryData = {
  members: any[];
};

export const useStudioMembers = (
  options: QueryHookOptions<
    StudioMembersQueryData,
    StudioMemberHookQueryVariables
  >,
) => {
  const query = useQuery(StudioMembersQuery, options);

  return {
    ...query,
    data: query.data?.members ?? [],
  };
};
