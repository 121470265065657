import React from 'react';
import {
  Box,
  Divider,
  Typography,
  MenuList,
  MenuItem,
  Chip,
  Menu,
  Stack,
} from '@mui/material';

import { getSpecialKeyWordBackground } from 'src/utils/notificationTemplates';
import {
  LSCalendar,
  LSDirectory,
  LSFilecase,
  LSPin,
  LSTreasury,
} from 'src/components/icons';
import { AccountBalance, AccountCircleOutlined } from '@mui/icons-material';
import { MultipleEntitySpecialWordPopover } from './MultipleEntitySpecialWordPopover';
import { useGroupedSpecialKeywords } from '../utils';

export const iconsByModel = {
  date: <LSCalendar color="primary" />,
  entity: <LSDirectory color="primary" />,
  event: <LSPin color="primary" />,
  filecase: <LSFilecase color="primary" />,
  sale: <LSTreasury color="primary" />,
  studio: <AccountBalance color="primary" />,
  user: <AccountCircleOutlined color="primary" />,
};

export const SpecialWordPopoverBody = ({ onSubmit, onClick, ...rest }) => {
  const groupedKeywords = useGroupedSpecialKeywords();

  const [anchorGroupEl, setAnchorGroupEl] = React.useState(null);
  const groupMenu = Boolean(anchorGroupEl);

  const [menuItemEl, setMenuItemEl] = React.useState(null);
  const [menuItemKeyword, setMenuItemKeyword] = React.useState(null);
  const menuItemOpen = Boolean(menuItemEl);

  const [selectedEntityKeyword, setSelectedEntityKeyword] =
    React.useState(null);

  const handleGroupClick = (event, keyword) => {
    setAnchorGroupEl(event.currentTarget);
    setMenuItemKeyword(keyword);
  };

  const handleMenuItemClick = (event, keyword) => {
    if (keyword.model === 'entity') {
      setSelectedEntityKeyword(keyword);
      setMenuItemEl(event.currentTarget);
      return;
    }

    setSelectedEntityKeyword();
    onClick(keyword);
  };

  const handleClose = () => {
    setAnchorGroupEl();
    setMenuItemEl();
    setSelectedEntityKeyword();
    setMenuItemKeyword();
  };

  const handleEntitySpecialWordClick = (color, index) => {
    onClick({ ...selectedEntityKeyword, color, index });

    handleClose();
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box {...rest}>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            p: 2,
            display: 'flex',
          }}
        >
          <Typography variant="h6">Campos dinámicos</Typography>
        </Box>

        <Divider />

        <MenuList sx={{ height: 300 }}>
          {groupedKeywords
            .filter(
              (keyword) =>
                keyword.model !== 'event' &&
                keyword.model !== 'sale' &&
                keyword.key !== '[expediente_ultima_actuacion]',
            )
            .map((keyword) => (
              <MenuItem
                key={keyword.key + keyword.model}
                onClick={(event) => handleGroupClick(event, keyword)}
              >
                <Stack direction="horizontal" gap={1}>
                  {iconsByModel[keyword.model]}
                  <Chip
                    key={keyword.key}
                    label={keyword.label}
                    size="small"
                    sx={{
                      bgcolor: getSpecialKeyWordBackground(keyword),
                    }}
                  />
                </Stack>
              </MenuItem>
            ))}
        </MenuList>
      </Box>

      <Menu anchorEl={anchorGroupEl} open={groupMenu} onClose={handleClose}>
        {menuItemKeyword?.keywords?.map((keyword) => (
          <MenuItem
            key={keyword.key + keyword.model}
            onClick={(event) => handleMenuItemClick(event, keyword)}
          >
            <Chip
              key={keyword.key}
              label={keyword.key}
              size="small"
              sx={{
                bgcolor: getSpecialKeyWordBackground(keyword),
              }}
            />
          </MenuItem>
        ))}
      </Menu>

      <MultipleEntitySpecialWordPopover
        anchorEl={menuItemEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={menuItemOpen}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClick={handleEntitySpecialWordClick}
        onClose={handleClose}
      />
    </Box>
  );
};
