import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query tags($studio: ID!) {
    tags(studio: $studio) {
      id
      name
      studioId
      updatedAt
    }
  }
`;
