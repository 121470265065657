import React, { useMemo, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  Stack,
  Typography,
  Grid,
  Menu,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import { Download } from '@mui/icons-material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { $generateHtmlFromNodes } from '@lexical/html';

import Form, { RichEditorField } from 'src/components/ui-components/Form';
import { EntitiesAutocomplete, FilecasesAutocomplete } from 'src/autocompletes';
import { multipleEntitySpecialWordColors } from 'src/popovers/SpecialWordPopover/SpecialWordPopoverBody/MultipleEntitySpecialWordPopover/MultipleEntitySpecialWordPopoverBody';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';
import { specialKeyWords } from 'src/utils/notificationTemplates';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useDialog } from 'src/dialogs/Dialogs';
import { getCustomFieldDataInSpecialKeyword } from 'src/components/v3/RichEditor/plugins/SpecialKeywordPlugin/RichEditorSpecialKeyword';
import { useRichEditorSpecialKeywordContext } from './RichEditorSpecialKeywordProvider';

function countSubstringOccurrences(str) {
  const regex = /data-legalsurf-special-keyword-index="(\d+)"/g;
  let matches;
  const indices = [];

  // eslint-disable-next-line no-cond-assign
  while ((matches = regex.exec(str)) !== null) {
    indices.push(Number(matches[1]));
  }

  return [...new Set(indices)];
}

const useContractPreviewReplacementOptions = (filecaseId) => {
  const {
    data: { filecase },
  } = useQuery(GET_FILECASE_SINGLE_DATA, {
    fetchPolicy: 'cache-only',
    variables: {
      filecase: filecaseId,
    },
  });

  if (!filecase) {
    return [];
  }

  let options = [
    {
      type: 'filecase',
      value: filecase.id,
      data: filecase,
      label: filecase.title,
    },
  ];

  if (filecase.entities?.length) {
    options = options.concat(
      filecase.entities.map((entity) => ({
        type: 'entity',
        value: entity.id,
        label: entity.displayName,
        data: entity,
      })),
    );
  }

  if (filecase.sales?.length) {
    options = options.concat(
      filecase.sales.map((sale) => ({
        type: 'sale',
        value: sale.id,
        label: sale.concept,
        data: sale,
      })),
    );
  }

  return options;
};

export const GenerateContractMutation = gql`
  mutation GenerateContractMutation(
    $type: String!
    $studioId: ID!
    $content: String!
    $contractId: ID!
    $filecases: [ID!]
  ) {
    generateContract(
      studioId: $studioId
      content: $content
      contractId: $contractId
      filecases: $filecases
      type: $type
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

export const ContractPreviewDialogBody = ({
  open,
  loading,
  onClose,
  contract,
}) => {
  const contentRef = useRef(null);
  const { openSnackbar } = useSnackbar();
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');
  const [generateContract] = useMutation(GenerateContractMutation, {
    refetchQueries: [
      'paginatedDocuments',
      'getPaginatedDocuments',
      'documents',
      'DocumentsQuery',
      'DocumentsSearchQuery',
    ],
    update: (cache, { data }) => {
      if (contract.filecaseId) {
        cache.modify({
          id: cache.identify({
            __typename: 'Filecase',
            id: contract.filecaseId,
          }),
          fields: {
            documents(docs) {
              return [data.generateContract, ...docs];
            },
          },
        });
      }
    },
  });
  const [specialKeywordContext, setSpecialKeywordContext] =
    useRichEditorSpecialKeywordContext();

  const [submitButtonAnchorEl, setSubmitButtonAnchorEl] = React.useState(null);
  const submitButtonOpen = Boolean(submitButtonAnchorEl);

  const handleSubmitButtonClose = () => {
    setSubmitButtonAnchorEl(null);
  };

  // const handlePrint = useReactToPrint({
  //   content: () => contentRef.current,
  // });

  const handleSubmit = async (type) => {
    contentRef.current.update(async () => {
      const html = $generateHtmlFromNodes(contentRef.current, null);

      const placeholder = document.createElement('div');
      placeholder.innerHTML = html;

      placeholder
        .querySelectorAll('[data-legalsurf-special-keyword-key]')
        .forEach((node) => {
          const key = node.getAttribute('data-legalsurf-special-keyword-key');
          const model = node.getAttribute(
            'data-legalsurf-special-keyword-model',
          );
          const index = node.getAttribute(
            'data-legalsurf-special-keyword-index',
          );

          const specialKeywordContextKey =
            index && !Number.isNaN(Number(index))
              ? [model, index].join('_')
              : model;

          const contextValue = specialKeywordContext[specialKeywordContextKey];

          const customFieldData = getCustomFieldDataInSpecialKeyword(
            key,
            contextValue?.data,
          );

          if (customFieldData) {
            node.innerHTML = customFieldData.value;
            return;
          }

          const replacement = specialKeyWords.find((word) => word.key === key);

          if (!replacement) {
            node.innerHTML = key;
            return;
          }

          if (replacement.transform) {
            node.innerHTML = replacement.transform(
              contextValue.data?.[replacement.path],
              contextValue?.data,
            );
            return;
          }

          node.innerHTML = contextValue?.data?.[replacement.path] || key;
        });

      // if (type === 'pdf') {
      //   console.log(contentRef.current);
      //   // handlePrint();
      // }

      const res = await generateContract({
        variables: {
          studioId: contract.studioId,
          content: placeholder.outerHTML,
          contractId: contract.id,
          filecases: [contract.filecaseId],
          type,
        },
      });

      setSpecialKeywordContext({});

      dispatchDocumentPreviewDialog(`ContractPreviewDialog-${document?.id}`, {
        document: res.data?.generateContract,
        downloable: true,
      });

      openSnackbar(
        'El escrito se ha generado correctamente, estara disponible en el expediente o en la sección de documentos',
        {
          severity: 'success',
        },
      );
      onClose();
      handleSubmitButtonClose();
    });
  };

  const options = useContractPreviewReplacementOptions(contract.filecaseId);

  const handleSpecialKeywordValueChange = (index, entity) => {
    if (!entity) {
      if (specialKeywordContext[index]) {
        delete specialKeywordContext[index];
      }

      return;
    }

    const state = {
      ...specialKeywordContext,
      [index]: entity,
    };

    setSpecialKeywordContext(state);
  };

  const [content, setContent] = useState(contract.content);

  const entitiesIndexes = useMemo(
    () =>
      countSubstringOccurrences(
        content,
        'data-legalsurf-special-keyword-index',
      ),
    [content],
  );

  const initialValues = useMemo(
    () => ({
      content: contract.content,
      filecase: options.find((option) => option.type === 'filecase'),
      ...entitiesIndexes.reduce((acc, val) => {
        acc[val] = val;

        return acc;
      }, {}),
    }),
    [contract.content, entitiesIndexes, options],
  );

  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent>
        {/* <Box sx={{ width: 20, height: 20 }} dangerouslySetInnerHTML={} /> */}

        <Stack
          direction="row"
          gap={2}
          pt={2}
          sx={{ '& > form': { width: '100%' } }}
        >
          <Formik initialValues={initialValues}>
            <Form sx={{ width: '100%' }}>
              <RichEditorField
                disableUpload
                enableDynamicFields
                gridProps={{
                  xs: entitiesIndexes?.length === 0 ? 12 : 9,
                }}
                name="content"
                ref={contentRef}
                sx={{
                  bgcolor: 'background.input',
                  p: 2,
                  borderRadius: 0.5,
                  minWidth: '100%',
                }}
                toolbarEndComponent={null}
                toolbarStartComponent={null}
                onChange={setContent}
              />

              <Grid item xs={3}>
                <Stack direction="column" gap={1} height="100%">
                  {entitiesIndexes?.length > 0 && (
                    <Grid container gap={2} mt={2}>
                      <Typography variant="h5">Sustituciones</Typography>

                      <Typography variant="p">
                        Selecciona los atributos que deseas reemplazar en el
                        plantilla antes de descargarlo
                      </Typography>

                      <FilecasesAutocomplete
                        disabled
                        label="Expediente"
                        name="filecase"
                        options={options.filter(
                          (option) => option.type === 'filecase',
                        )}
                      />

                      {entitiesIndexes.map((key, index) => (
                        <EntitiesAutocomplete
                          key={key}
                          label={`Directorio ${index + 1}`}
                          name={`entity_${key}`}
                          options={options.filter(
                            (option) => option.type === 'entity',
                          )}
                          sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                            '&::before': {
                              content: '""',
                              width: 24,
                              height: 24,
                              bgcolor: multipleEntitySpecialWordColors[key],
                              borderRadius: 1,
                            },
                          }}
                          onChange={(event, option) =>
                            handleSpecialKeywordValueChange(
                              `entity_${key}`,
                              option,
                            )
                          }
                        />
                      ))}
                    </Grid>
                  )}

                  <Stack
                    direction="row"
                    gap={2}
                    justifyContent="flex-end"
                    mt="auto"
                  >
                    <Button disabled={loading} onClick={onClose}>
                      Cancelar
                    </Button>

                    <LoadingButton
                      color="primary"
                      disabled={loading}
                      endIcon={<Download />}
                      loading={loading}
                      sx={{ minWidth: 140 }}
                      variant="contained"
                      onClick={(event) =>
                        setSubmitButtonAnchorEl(event.currentTarget)
                      }
                    >
                      Generar
                    </LoadingButton>

                    <Menu
                      anchorEl={submitButtonAnchorEl}
                      open={submitButtonOpen}
                      onClose={handleSubmitButtonClose}
                    >
                      <MenuItem onClick={() => handleSubmit('docx')}>
                        Microsoft Word (.docx)
                      </MenuItem>

                      {/* <MenuItem onClick={() => handleSubmit('pdf')}>
                        PDF
                      </MenuItem> */}
                    </Menu>
                  </Stack>
                </Stack>
              </Grid>
            </Form>
          </Formik>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
