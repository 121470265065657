import React, { useMemo, useState } from 'react';

import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import Table from 'src/components/v3/Table';
import { useColumns } from 'src/pages/Dashboard/Calendar/CalendarAgendaTable/columns';
import { getEventRowId } from 'src/utils/dates';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { Box, Stack } from '@mui/material';
import { LSWorkflowIcon } from 'src/components/icons';
import { WorkflowsPopover } from 'src/popovers/WorkflowsPopover';

const sectionTypeByEventType = {
  alerts: 'alert',
  tasks: 'task',
};

export const EventsDetail = ({ entity, type }) => {
  const dispatchEventDialog = useDialog('event');
  const columns = useColumns();
  const dispatchEventFormDialog = useDialog('eventForm');
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.ACCESS,
  ]);

  const rows = useMemo(
    () =>
      entity.events
        .filter((event) => event.type === sectionTypeByEventType[type])
        .sort((a, b) => new Date(b.start) - new Date(a.start)),
    [entity.events, type],
  );

  const handlePopoverOpen = (clickEvent) => {
    setAnchorEl(clickEvent.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Table
        checkboxSelection={false}
        columns={columns}
        getRowId={getEventRowId}
        loading={false}
        pageSize={rows.length}
        paginationMode="client"
        rows={rows}
        sortingMode="client"
        sxCard={{
          boxShadow: 'none',
        }}
        onRowClick={(params) =>
          hasPermissions &&
          dispatchEventDialog(params.row.id, {
            id: params.row.id,
            calendarId: params.row.calendarId,
          })
        }
      />

      <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.CREATE]}>
        <CreationInfoBanner
          buttonStr={`Crear ${
            sectionTypeByEventType[type] === 'alert' ? 'Alerta' : 'Tarea'
          }`}
          description={`Crea una ${
            sectionTypeByEventType[type] === 'alert' ? 'Alerta' : 'Tarea'
          } de calendario vinculada a tu
          directorio.`}
          secondaryButton={
            sectionTypeByEventType[type] === 'task'
              ? {
                  buttonStr: (
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <LSWorkflowIcon sx={{ fontSize: 18 }} />
                      <Box>Flujo de trabajo</Box>
                    </Stack>
                  ),
                  onClick: handlePopoverOpen,
                }
              : undefined
          }
          title={`Crear ${
            sectionTypeByEventType[type] === 'alert' ? 'Alerta' : 'Tarea'
          }`}
          onClick={() =>
            dispatchEventFormDialog('SingleEntityEvents', {
              entities: [entity.id],
              type: type === 'alerts' ? 'alert' : 'task',
            })
          }
        />

        <WorkflowsPopover
          anchorEl={anchorEl}
          entities={[entity.id]}
          open={Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
        />
      </PermissionGuard>
    </Box>
  );
};
