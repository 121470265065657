import { gql, useQuery } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const CustomFieldsQuery = gql`
  query CustomFieldsQuery(
    $studioId: ID!
    $modelId: ID
    $onEntities: Boolean
    $onFilecases: Boolean
    $modelType: CustomFieldValueType
    $includeValue: Boolean = false
  ) {
    getCustomFields(
      studioId: $studioId
      onEntities: $onEntities
      onFilecases: $onFilecases
    ) {
      id
      name
      description
      studioId
      type
      options
      onEntities
      onFilecases

      value(modelId: $modelId, modelType: $modelType)
        @include(if: $includeValue)
    }
  }
`;

export const useCustomFields = (options) => {
  const studioId = useStudioId();
  const { data, ...rest } = useQuery(CustomFieldsQuery, {
    ...options,
    variables: {
      studioId,
      ...options?.variables,
      includeValue: !!(
        options?.variables?.modelId && options?.variables?.modelType
      ),
    },
  });

  return {
    customFields: data?.getCustomFields || [],
    ...rest,
  };
};
