import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Box,
  Avatar,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useGoogleCalendars } from 'src/autocompletes/GoogleCalendarsAutocomplete/useGoogleCalendars';
import GoogleCalendarIMG from 'src/assets/images/googlecalendar.png';
import { CREATE_CALENDAR } from 'src/graphql/mutations/calendars';
import { useMutation } from '@apollo/client';
import { GET_CALENDARS } from 'src/graphql/queries/calendars';
import CheckCircle from '@mui/icons-material/CheckCircle';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useUser } from 'src/utils/hooks/useUser';
import { useLocation, useNavigate } from 'react-router-dom';

import { CalendarPageQuery } from '../../Calendar/queries';
import { usePostHog } from 'posthog-js/react';

export const GoogleCalendarSuccessSyncDialog = ({ open }) => {
  const { loading: loadingCalendars, calendars } = useGoogleCalendars();
  const location = useLocation();
  const studioId = useStudioId();
  const { user } = useUser();
  const navigate = useNavigate();
  const [selectedCalendars, setSelectedCalendars] = useState([]);

  const [createCalendar, { loading }] = useMutation(CREATE_CALENDAR, {
    refetchQueries: [GET_CALENDARS, CalendarPageQuery],
  });

  const posthog = usePostHog();

  const handleCalendarChange = (calendarId) => {
    if (selectedCalendars.some((id) => id === calendarId)) {
      setSelectedCalendars(selectedCalendars.filter((id) => id !== calendarId));
    } else {
      setSelectedCalendars([...selectedCalendars, calendarId]);
    }
  };

  const handleSubmit = async () => {
    const mutations = selectedCalendars.map((calendarId) => {
      const calendar = calendars.find((cal) => cal.id === calendarId);

      return createCalendar({
        variables: {
          name: calendar.label?.trim(),
          color: calendar.backgroundColor,
          googleCalendarId: calendar.id,
          studio: studioId,
          canBeSeenBy: [],
        },
      });
    });

    await Promise.all(mutations);

    if (location.state?.redirectSuccess) {
      if (location.state?.redirectSuccess.includes('onboarding=completed')) {
        posthog.group('studio', studioId, {
          onboarding: {
            syncedWithGoogleCalendar: true,
            syncedCalendars: selectedCalendars.length,
          },
        });

        posthog.identify(user.id, {
          onboarding: {
            syncedWithGoogleCalendar: true,
            syncedCalendars: selectedCalendars.length,
          },
        });

        posthog.capture('webappOnboarding', {
          step: 'googleCalendarSuccess',
          syncedWithGoogleCalendar: true,
          syncedCalendars: selectedCalendars.length,
        });
      }

      window.location.assign(location.state?.redirectSuccess);
      window.history.replaceState(null, '');
      return;
    }

    navigate(`/dashboard/${studioId}/calendar`, {
      replace: true,
    });
  };

  return (
    <Dialog
      open={open}
      slotProps={
        location?.state?.redirectSuccess?.endsWith?.('onboarding=completed')
          ? {
              backdrop: {
                sx: {
                  backgroundColor: 'primary.900',
                },
              },
            }
          : null
      }
    >
      <DialogTitle textAlign="center" mt={2}>
        Tu cuenta de Google Calendar fue vinculada con éxito! 🙌
      </DialogTitle>

      <DialogContent sx={{ fontWeight: 500, px: [2, 12], textAlign: 'center' }}>
        Selecciona los calendarios de <b>Google</b> que quieres visualizar en
        LegalSurf
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 3 }}>
          {loadingCalendars && <CircularProgress sx={{ mx: 'auto' }} />}

          {calendars?.map((calendar) => {
            const active = selectedCalendars.some(
              (calendarId) => calendarId === calendar.id,
            );

            return (
              <Button
                fullWidth
                endIcon={
                  active && <CheckCircle color="success" sx={{ mr: 2 }} />
                }
                key={calendar.id}
                sx={{
                  opacity: active ? 1 : 0.5,
                  gap: 2,
                  backgroundColor: calendar.backgroundColor,
                  color: (theme) =>
                    theme.palette.computeContrast(calendar.backgroundColor),
                }}
                onClick={() => handleCalendarChange(calendar.id)}
              >
                <Avatar src={GoogleCalendarIMG} />

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="button">{calendar.label}</Typography>
                </Box>
              </Button>
            );
          })}
        </Box>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'center' }}>
        <LoadingButton
          disabled={loading || !selectedCalendars?.length}
          loading={loading}
          variant="contained"
          onClick={handleSubmit}
        >
          Finalizar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
