import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const NoRenderer = ({ document }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: 1,
    }}
  >
    {document?.fileType?.includes?.('audio/') ? (
      <audio controls muted={false} autoPlay={false} src={document.uri} />
    ) : (
      <Typography sx={{ mt: 2 }} textAlign="center" variant="subtitle2">
        No se pudo cargar el documento
      </Typography>
    )}
  </Box>
);

const FilePreviewerContent = ({ document = {} }) => {
  const {
    handleGenerateSignedUrl,
    temporarySignedDocument,
    isGeneratingSignedUrl,
  } = useDigitalOceanSpace();

  useEffect(() => {
    if (document && document?.kind === 'spaces') {
      handleGenerateSignedUrl(document.id);
    }
  }, [document, handleGenerateSignedUrl]);

  if (isGeneratingSignedUrl) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: 840,
          width: 920,
          maxWidth: '100%',
        }}
      >
        <CircularProgress size={32} />
        <Typography sx={{ mt: 2 }} textAlign="center" variant="subtitle2">
          Estamos generando tu previsualización...
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: 840,
        width: 920,
        maxWidth: '100%',
        '#proxy-renderer': {
          display: 'flex',
          height: '100%',
        },
      }}
    >
      <DocViewer
        config={{
          pdfVerticalScrollByDefault: true,
          header: {
            disableHeader: false,
          },
          noRenderer: {
            overrideComponent: NoRenderer,
          },
        }}
        documents={[
          {
            uri: temporarySignedDocument?.link,
            fileName: temporarySignedDocument?.name,
          },
        ]}
        language="es"
        pluginRenderers={DocViewerRenderers}
        prefetchMethod="GET"
        style={{
          overflowY: 'scroll',
          height: '100%',
        }}
      />
    </Box>
  );
};

export default FilePreviewerContent;
