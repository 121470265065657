import { gql } from '@apollo/client';

export const CREATE_CALENDAR = gql`
  mutation calendar(
    $name: String!
    $color: String
    $timezone: ID
    $canBeSeenBy: [ID!]
    $googleCalendarId: ID
    $studio: ID!
  ) {
    calendar(
      name: $name
      color: $color
      timezone: $timezone
      canBeSeenBy: $canBeSeenBy
      googleCalendarId: $googleCalendarId
      studio: $studio
    ) {
      id
      color
      googleCalendarId
      name
      canBeSeenBy {
        id
        name
        email
        picture
      }
    }
  }
`;

export const UPDATE_CALENDAR = gql`
  mutation updateCalendar(
    $id: ID!
    $name: String!
    $color: String
    $timezone: ID
    $canBeSeenBy: [ID!]
  ) {
    updateCalendar(
      id: $id
      name: $name
      color: $color
      timezone: $timezone
      canBeSeenBy: $canBeSeenBy
    ) {
      id
      color
      name
      canBeSeenBy {
        id
        name
        email
        picture
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation event(
    $studio: ID!
    $type: EventType!
    $timezone: ID
    $title: String!
    $description: String
    $allDay: Boolean
    $start: DateTime
    $end: DateTime
    $calendar: ID!
    $filecase: ID
    $entities: [ID!]
    $assigned: [ID!]
    $taskState: TaskState
    $workflowId: ID
    $todos: [EventTodoInput!]
  ) {
    event(
      workflowId: $workflowId
      studio: $studio
      timezone: $timezone
      type: $type
      title: $title
      description: $description
      allDay: $allDay
      start: $start
      end: $end
      calendar: $calendar
      filecase: $filecase
      entities: $entities
      assigned: $assigned
      taskState: $taskState
      todos: $todos
    ) {
      id
      calendarId
      title
      start
      type
      end
      allDay
      description
      state
      calendar {
        id
        name
        color
      }
      assigned {
        id
        name
        picture
      }
      filecase {
        id
        title
        externalId
      }
      entities {
        id
        displayName
        type
      }
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent(
    $id: ID!
    $type: EventType
    $title: String
    $description: String
    $allDay: Boolean
    $start: DateTime
    $end: DateTime
    $calendar: ID!
    $filecase: ID
    $entities: [ID!]
    $assigned: [ID!]
    $taskState: TaskState
    $canBeSeenBy: [ID!]
  ) {
    updateEvent(
      id: $id
      type: $type
      title: $title
      description: $description
      allDay: $allDay
      start: $start
      end: $end
      calendar: $calendar
      filecase: $filecase
      entities: $entities
      assigned: $assigned
      taskState: $taskState
      canBeSeenBy: $canBeSeenBy
    ) {
      id
      calendarId
      title
      start
      type
      end
      allDay
      description
      state
      googleCalendarData
      calendar {
        id
        timezone
        name
        color
      }
      assigned {
        id
        name
        picture
      }
      filecase {
        id
        title
        externalId
      }
      entities {
        id
        displayName
        type
      }
    }
  }
`;

export const DELETE_CALENDAR = gql`
  mutation deleteCalendar($id: ID!) {
    deleteCalendar(id: $id)
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: ID!, $calendarId: ID!) {
    deleteEvent(id: $id, calendarId: $calendarId)
  }
`;
