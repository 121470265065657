import React from 'react';
import { Grid, Paper } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { useDefaultCurrency } from 'src/autocompletes/CurrencyAutocomplete';
import ReportCard from '../ReportCard';
import TreasuryReportsLineChart from './TreasuryReportsLineChart';
import TreasuryReportsBarChart from './TreasuryReportsBarChart';
import { TreasuryReportsHeaderFilters } from './TreasuryReportsHeaderFilters';

export const treasuryReportsLineChartQuery = gql`
  query TreasuryReportQueries($studioId: ID!, $filters: ReportsFilters!) {
    salesVsExpenses(studio: $studioId, filters: $filters) {
      date
      expenses
      sales
    }

    salesReport(studio: $studioId, filters: $filters) {
      totalSaled
      totalCharged
      totalRemaining
      data {
        date
        sales
        charges
        remain
      }
    }

    expensesReport(studio: $studioId, filters: $filters) {
      totalExpended
      totalPaid
      totalRemaining
      data {
        date
        expenses
        payments
        remain
      }
    }
  }
`;

const formatReportsData = (data, granularity) =>
  data?.map?.((val) => ({
    ...val,
    formatedDate: dayjs(val.date).format(
      granularity === 'day' ? 'DD-MMMM' : 'MMMM-YYYY',
    ),
  }));

const TreasuryReportsViewCharts = () => {
  const { studioId } = useParams();
  const { currency: defaultCurrency, loading: loadingDefaultCurrency } =
    useDefaultCurrency();

  const initialValues = {
    start: dayjs().subtract(6, 'month').startOf('month'),
    end: dayjs().startOf('month'),
    filecases: [],
    responsibles: [],
    entities: [],
    tags: [],
    currency: defaultCurrency
      ? {
          label: defaultCurrency?.name,
          value: defaultCurrency?.name,
        }
      : null,
  };

  const {
    data: {
      salesVsExpenses = [],
      salesReport = { data: [] },
      expensesReport = { data: [] },
    } = {},
    loading,
    refetch,
    variables,
  } = useQuery(treasuryReportsLineChartQuery, {
    fetchPolicy: 'cache-and-network',
    skip: loadingDefaultCurrency,
    variables: {
      studioId,
      filters: {
        currency: defaultCurrency?.name || '',
        start: dayjs(initialValues.start).startOf('month').toISOString(),
        end: dayjs(initialValues.end)
          .add(1, 'month')
          .startOf('month')
          .toISOString(),
      },
    },
  });

  const onFiltersSubmit = (values) =>
    refetch({
      studioId,
      filters: {
        currency: values.currency.value,
        start: dayjs(values.start).startOf('month').toISOString(),
        end: dayjs(values.end).add(1, 'month').startOf('month').toISOString(),
        entities: values.entities?.map(({ value }) => value),
        responsibles: values.responsibles?.map(({ value }) => value),
        filecases: values.filecases?.map(({ value }) => value),
        tags: values.tags?.map(({ value }) => value),
        granularity:
          dayjs(values.start).diff(values.end, 'day') === 0 ? 'day' : 'month',
      },
    });

  return (
    <Grid
      container
      spacing={4}
      sx={{
        '& .MuiGrid-root': {
          zIndex: 0,
        },
      }}
    >
      <Grid item xs={12}>
        <Paper sx={{ p: 4 }}>
          <TreasuryReportsHeaderFilters
            initialValues={initialValues}
            onSubmit={onFiltersSubmit}
          />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <ReportCard title="Informe financiero - Honorarios vs Gastos">
          <TreasuryReportsLineChart
            data={formatReportsData(
              salesVsExpenses,
              variables.filters.granularity,
            )}
            loading={loading}
          />
        </ReportCard>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ReportCard title="Informe de honorarios">
          <TreasuryReportsBarChart
            loading={loading}
            reportData={{
              ...salesReport,
              data: formatReportsData(
                salesReport.data,
                variables.filters.granularity,
              ),
            }}
            variant="sales"
          />
        </ReportCard>
      </Grid>

      <Grid item lg={6} xs={12}>
        <ReportCard title="Informe de gastos">
          <TreasuryReportsBarChart
            loading={loading}
            reportData={{
              ...expensesReport,
              data: formatReportsData(
                expensesReport.data,
                variables.filters.granularity,
              ),
            }}
            variant="expenses"
          />
        </ReportCard>
      </Grid>
    </Grid>
  );
};

export default TreasuryReportsViewCharts;
