import React from 'react';

import { PhotoCamera } from '@mui/icons-material';
import {
  Typography,
  Avatar,
  Box,
  Stack,
  AvatarGroup,
  Tooltip,
} from '@mui/material';
import logonavbar from '../../../../assets/images/logo.svg';
import { useFormikContext } from 'formik';
import { useUser } from 'src/utils/hooks/useUser';
import { LSFilecase } from 'src/components/icons';
import { PreviewSectionContainer } from 'src/pages/Dashboard/Home/UserStudioCard';

const studioSizeToNumber = (size) => {
  if (size === '1') return 1;
  if (size === '2-3') return 3;
  if (size === '3-5') return 5;
  if (size === '5-10') return 11;
  if (size === '10-20') return 21;
  if (size === '20-50') return 51;
  if (size === '+50') return 51;
};

const humanizeStudioFilecases = (filecases) => {
  if (filecases === '-50') return 50;
  if (filecases === '50-to-250') return 250;
  if (filecases === '250-to-500') return 500;
  if (filecases === '500-to-1000') return 100;
  if (filecases === '1000-to-2000') return 200;
  if (filecases === '2000-to-5000') return 5000;
  if (filecases === '+5000') return '+5000';
};

const StudioCanva = ({ sx, studio }) => {
  const { values } = useFormikContext();
  const { user } = useUser();

  return (
    <Stack
      sx={{
        ...sx,
        borderRadius: 2,
        overflow: 'hidden',
        bgcolor: 'primary.900',
        width: '100%',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'primary.800',
          height: 64,
          display: 'flex',
          alignItems: 'center',
          pl: 3,
        }}
      >
        <img alt="LegalSurf" height="40" src={logonavbar} width="120" />
      </Box>

      <Stack gap={1} p={6}>
        <Stack
          alignItems="center"
          direction="row"
          gap={2}
          sx={{ width: '100%' }}
        >
          <Avatar
            src={studio.image ? URL.createObjectURL(studio.image) : ''}
            sx={{
              backgroundColor: studio.image ? '' : '#4654C8',
              height: 100,
              width: 100,
            }}
          >
            <PhotoCamera sx={{ fontSize: 40 }} />
          </Avatar>

          <Typography
            color="white"
            fontWeight={400}
            variant="h4"
            sx={{ wordBreak: 'break-word' }}
          >
            Te damos la bienvenida a{' '}
            <b>{studio.name || 'Nombre de su firma de abogados'}</b>
          </Typography>
        </Stack>

        {values.size && (
          <AvatarGroup
            max={studioSizeToNumber(values.size?.value)}
            total={studioSizeToNumber(values.size?.value)}
            sx={{
              pl: 1,
              maxWidth: 80,
              '& > div': {
                width: '38px !important',
                height: '38px !important',
                border: 'none !important',
                backgroundColor: '#ffffff20',
              },
            }}
          >
            <Tooltip title={user.name}>
              <Avatar
                alt={user.name}
                src={user.picture}
                sx={{
                  borderColor: 'transparent !important',
                  width: 50,
                  height: 50,
                }}
              >
                <Typography fontSize={12} variant="body1">
                  {user.name?.[0]}
                </Typography>
              </Avatar>
            </Tooltip>
          </AvatarGroup>
        )}
      </Stack>

      <Box
        sx={{
          height: 260,
          backgroundColor: 'common.white',
          padding: 4,
          position: 'relative',
        }}
      >
        <Box
          sx={{
            borderRadius: 2,
            backgroundColor: 'grey.100',
            position: 'absolute',
            left: 38,
            top: 38,
            bottom: -24,
            right: -24,
          }}
        >
          {values.filecases?.value && (
            <Box maxWidth={300} m={4}>
              <PreviewSectionContainer
                sx={{ borderWidth: 0 }}
                count={humanizeStudioFilecases(values.filecases?.value)}
                title="Expedientes"
                icon={<LSFilecase color="primary" />}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Stack>
  );
};
export default StudioCanva;
