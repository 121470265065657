import React from 'react';
import { Stack } from '@mui/material';

import Comment from 'src/components/v3/Comment';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  useDeleteEntityComment,
  useUpdateEntityComment,
} from 'src/components/v3/EntityComment/utils';
import { CommentForm } from './CommentForm';
import { EmptyComments } from './EmptyComments';

export const Comments = ({ entity }) => {
  const studioId = useStudioId();
  const deleteEntityComment = useDeleteEntityComment();
  const updateEntityComment = useUpdateEntityComment();

  const handleCommentDelete = (commentId) =>
    deleteEntityComment({
      variables: {
        entityId: entity.id,
        commentId,
        studioId,
      },
    });

  const handleCommentUpdate = ({ commentId, content }) =>
    updateEntityComment({
      variables: {
        commentId,
        entityId: entity.id,
        studioId,
        content,
      },
    });

  return (
    <Stack gap={2}>
      <Stack
        gap={2}
        sx={{
          pt: 1,
          '& > *:not(:last-child)': {
            borderBottom: '1px dashed',
            borderColor: 'divider',
          },
        }}
      >
        {entity.comments?.map((comment) => (
          <Comment
            comment={comment}
            filesFormProps={{
              file: {
                entities: [
                  {
                    label: entity.displayName,
                    value: entity.id,
                  },
                ],
              }
            }}
            key={comment.id}
            onRemove={handleCommentDelete}
            onUpdate={handleCommentUpdate}
          />
        ))}
      </Stack>

      {!entity.comments.length && <EmptyComments />}

      <CommentForm entity={entity} entityId={entity.id} />
    </Stack>
  );
};
