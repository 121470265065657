import React from 'react';
import { Box, Alert, CircularProgress, LinearProgress } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid';

const LoadingOverlay = ({ alertProps = {}, ...props }) => {
  const api = useGridApiContext();

  if (api.current.getRowsCount() > 0) {
    return (
      <Box sx={{ ...props.sx }} {...props}>
        <LinearProgress
          color="primary"
          sx={{
            mt: -7,
            position: 'absolute',
            backgroundColor: 'primary.200',
            zIndex: 1,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
          }}
        />
        <Box
          sx={{
            mt: -7,
            position: 'absolute',
            backgroundColor: 'primary.200',
            opacity: 0.4,
            zIndex: 1,
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
          }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2, ...props.sx }} {...props}>
      <Alert
        action={<CircularProgress color="inherit" size={15} />}
        severity="info"
        sx={{
          '& > .MuiAlert-action': { alignItems: 'center', mr: 2, p: 0 },
          ...alertProps.sx,
        }}
        {...alertProps}
      >
        Cargando...
      </Alert>
    </Box>
  );
};

export default LoadingOverlay;
