import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import {
  GetCurrenciesQuery,
  useCurrencies,
} from 'src/autocompletes/CurrencyAutocomplete';

const DeleteCurrencyMutation = gql`
  mutation DeleteCurrencyMutation($id: ID!, $studioId: ID!) {
    deleteCurrency(id: $id, studioId: $studioId) {
      id
    }
  }
`;

const CreateCurrencyMutation = gql`
  mutation CreateCurrencyMutation($name: ID!, $studioId: ID!) {
    createCurrency(name: $name, studioId: $studioId) {
      id
      name
      updatedAt
      studioId
    }
  }
`;

const UpdateCurrencyMutation = gql`
  mutation UpdateCurrencyMutation($id: ID!, $name: ID!, $studioId: ID!) {
    updateCurrency(id: $id, name: $name, studioId: $studioId) {
      id
      name
      updatedAt
      studioId
    }
  }
`;

const Actions = ({ row }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteCurrency] = useMutation(DeleteCurrencyMutation, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteCurrency));
      cache.gc();
    },
  });

  return [
    <Tooltip title="Para editar, doble click en los campos editables que deseas actualizar en la tabla">
      <GridActionsCellItem
        color="inherit"
        icon={<LSEdit color="primary" fontSize="small" />}
        label="Edit"
      />
    </Tooltip>,
    <Tooltip title="Eliminar moneda">
      <GridActionsCellItem
        color="inherit"
        icon={<LSTrash color="error" fontSize="small" />}
        label="Delete"
        onClick={() =>
          dispatchWarningPromptDialog(`CurrenciesTab-${row.id}`, {
            onAccept: () =>
              deleteCurrency({
                variables: {
                  id: row.id,
                  studioId,
                },
              }),
            title: 'Eliminar moneda',
          })
        }
      />
    </Tooltip>,
  ];
};

export const CurrenciesTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const { value } = useTabContext();
  const studioId = useStudioId();
  const { currencies, loading } = useCurrencies({ skip: value !== index });
  const [updateCurrency] = useMutation(UpdateCurrencyMutation);
  const [createTag] = useMutation(CreateCurrencyMutation, {
    refetchQueries: [GetCurrenciesQuery],
  });

  const handleUpdate = async (val, oldVal) => {
    if (val.isNew) {
      return (
        await createTag({
          variables: {
            studioId,
            name: val.name,
          },
        })
      ).data.createCurrency;
    }
    if (val.name === oldVal.name) {
      return oldVal;
    }

    const res = await updateCurrency({
      variables: {
        studioId,
        id: val.id,
        name: val.name,
      },
    });

    return res.data.updateCurrency;
  };

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: true,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 150,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      editable: false,
      getActions: Actions,
    },
  ];

  return (
    <TabPanel value={index}>
      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        editMode="row"
        loading={loading}
        pageSize={10}
        processRowUpdate={handleUpdate}
        rowCount={currencies?.length}
        rows={currencies}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe una moneda con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
      />
    </TabPanel>
  );
};
