import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

import wilsonIMG from '../../../../assets/images/wilson.jpg';

// TODO: ESTO DEBERIA SER ALGO GLOBAL O QUIZAS DEBERIA LLEVAR OTRO NOMBRE... NO LO SE
const AuthGuard = () => (
  <Card
    sx={{
      backgroundColor: 'primary.contrastText',
      width: '100%',
    }}
  >
    <CardContent
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        py: 6,
        gap: 3,
      }}
    >
      <Typography variant="h2">No tienes permisos</Typography>

      <Typography sx={{ fontWeight: 'normal' }} variant="h6">
        Habla con el administrador de la cuenta para que te de permisos
      </Typography>

      <Box sx={{ '& > img': { maxWidth: 280 } }}>
        <img alt="wilson" src={wilsonIMG} />
      </Box>
    </CardContent>
  </Card>
);

export default AuthGuard;
