import React, { useState } from 'react';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { useSnackbar } from 'src/components/v3/Snackbar';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useDeleteCalendarEvent } from '@legalsurf/hooks';

import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useDialog } from 'src/dialogs/Dialogs';
import { LSEdit, LSTrash, LSCopy } from 'src/components/icons';
import { WhatsApp } from '@mui/icons-material';
import { sendWhatsappMsg } from 'src/utils/notificationTemplates';
import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';

export const defaultEmptyEntity = {
  id: 1,
  displayName: '',
  state: {
    name: '',
  },
};

export const CalendarEventDetailsActions = ({
  event,
  filecase,
  eventId,
  calendarId,
  disabled,
  onClose,
}) => {
  const dispatchEventForm = useDialog('eventForm');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const { openSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (innerEvent) => {
    setAnchorEl(innerEvent?.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const [deleteEvent] = useDeleteCalendarEvent();

  const handleDeleteEvent = async () => {
    try {
      await deleteEvent({
        variables: {
          id: eventId,
          calendarId,
        },
      });

      onClose?.();

      openSnackbar('Evento eliminado con exito.', {
        severity: 'success',
      });
    } catch (error) {
      openSnackbar(error.message, { severity: 'error', autoDismiss: 200 });
    }
  };

  return (
    <>
      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        data={{ event, filecase }}
        entity={defaultEmptyEntity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg('', msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />

      <Stack direction="row" gap={1}>
        <Tooltip title="Plantillas de Whatsapp">
          <IconButton variant="text" onClick={handleOpenFilecasesPopover}>
            <WhatsApp
              sx={{
                color: '#24d366',
              }}
            />
          </IconButton>
        </Tooltip>

        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.UPDATE]}>
          <Tooltip title="Editar evento">
            <IconButton
              color="primary"
              disabled={disabled}
              sx={{
                '&:hover': {
                  color: 'info.main',
                },
              }}
              onClick={() =>
                dispatchEventForm(eventId, {
                  id: eventId,
                  calendarId,
                })
              }
            >
              <LSEdit fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard>

        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.DELETE]}>
          <Tooltip title="Borrar evento">
            <IconButton
              color="primary"
              disabled={disabled}
              sx={{
                '&:hover': {
                  color: 'error.main',
                },
              }}
              onClick={() =>
                dispatchWarningPromptDialog(
                  `CalendarEventDetailsDialog-${eventId}`,
                  {
                    onAccept: handleDeleteEvent,
                  },
                )
              }
            >
              <LSTrash fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard>

        <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.CREATE]}>
          <Tooltip title="Duplicar evento">
            <IconButton
              color="primary"
              disabled={disabled}
              onClick={() =>
                dispatchEventForm(eventId, {
                  id: eventId,
                  calendarId,
                  clone: true,
                })
              }
            >
              <LSCopy fontSize="small" />
            </IconButton>
          </Tooltip>
        </PermissionGuard>
      </Stack>
    </>
  );
};
