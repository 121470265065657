import React, { memo } from 'react';
import { Grid, FormControl, TextField as MuiTextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { CurrencyAutocomplete } from 'src/autocompletes';

const MoneyField = React.forwardRef(
  (
    {
      label,
      gridProps = { xs: 12 },
      amountName,
      currencyName,
      disabled,
      disabledCurrency,
      hint,
      ...rest
    },
    ref,
  ) => {
    const [field, meta] = useField(amountName);
    const { isSubmitting } = useFormikContext();

    return (
      <Grid item {...gridProps}>
        <FormControl
          sx={{ width: '100%', display: 'flex', gap: 1, flexDirection: 'row' }}
        >
          <CurrencyAutocomplete
            creatable
            freeSolo
            clearIcon={null}
            disabled={disabledCurrency}
            getFilterOptionsWithCreatableLabel={(inputValue) =>
              `+ "${inputValue}"`
            }
            gridProps={{ sx: { width: 200 } }}
            label="Moneda"
            name={currencyName}
          />

          <MuiTextField
            fullWidth
            autoComplete="off"
            disabled={isSubmitting || disabled}
            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
            inputRef={ref}
            label={label}
            variant="outlined"
            {...field}
            {...rest}
          />
        </FormControl>
      </Grid>
    );
  },
);

export default memo(MoneyField);
