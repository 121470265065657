import { gql } from '@apollo/client';

export const SIGN_TOC = gql`
  mutation signToc($sign: Boolean!, $phone: String!) {
    signToc(sign: $sign, phone: $phone, registeredOn: "@legalsurf/web") {
      id
      name
      phone
      studios {
        id
        name
      }
      toc {
        id
        signed
        createdAt
      }
    }
  }
`;
