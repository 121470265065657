import { gql } from '@apollo/client';

export const GET_STUDIO_STATUSES = gql`
  query getStudioStates($studioId: ID!) {
    states(studio: $studioId) {
      id
      name
      color
      studioId
      updatedAt
    }
  }
`;

export default null;
