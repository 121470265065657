import React from 'react';
import { Grid, FormControlLabel, Switch as MuiSwitch } from '@mui/material';
import { useField, useFormikContext } from 'formik';

const SwitchField = ({
  gridProps = { xs: 12 },
  disabled,
  label,
  labelPlacement = 'end',
  name,
  onChange,
  ...props
}) => {
  const { isSubmitting, isValidating } = useFormikContext();
  const [field, , helpers] = useField(name);

  return (
    <Grid item {...gridProps}>
      <FormControlLabel
        {...field}
        {...props}
        control={<MuiSwitch checked={field.value} />}
        disabled={isSubmitting || isValidating || disabled}
        label={label}
        labelPlacement={labelPlacement}
        value={field.value}
        onChange={(event) => {
          helpers.setValue(event.target.checked);

          if (onChange) {
            onChange(event.target.checked);
          }
        }}
      />
    </Grid>
  );
};

export default SwitchField;
