import React from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useStudio } from 'src/utils/hooks/useStudio';

export const StudioDataSettingOption = () => {
  const { studio, loading } = useStudio();
  const navigate = useNavigate();

  return (
    <Card sx={{ borderRadius: 1 }}>
      <CardContent
        sx={{
          flexDirection: 'column',
          display: 'flex',
          gap: 1,
          color: 'white',
          px: 4,
          pt: 4,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          bgcolor: '#131546',
        }}
      >
        <Stack alignItems="center" direction="row" gap={2}>
          <Avatar
            src={studio?.picture}
            style={{
              width: 54,
              height: 54,
            }}
          />

          <Typography color="white" variant="body1">
            {loading ? '...' : studio?.name}
          </Typography>
        </Stack>

        <Typography color="white" variant="body2">
          Administración tu cuenta de LegalSurf
        </Typography>

        <Stack columnGap={2} direction="row" mt={2}>
          <Button
            color="primary"
            sx={{ textTransform: 'none' }}
            variant="contained"
            onClick={() => navigate(`/dashboard/${studio?.id}/settings/studio`)}
          >
            Datos del Estudio
          </Button>

          <Button
            color="success"
            sx={{ textTransform: 'none' }}
            variant="contained"
            onClick={() =>
              navigate(`/dashboard/${studio?.id}/settings/subscription`)
            }
          >
            Suscripción
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
