import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
  BarChart as BarChartCustom,
  Bar,
  ResponsiveContainer,
  XAxis,
  Tooltip,
  LabelList,
} from 'recharts';

const BarChart = ({ data }) => {
  const ChartData = Object.entries(data).map(([key, value]) => ({
    label: key,
    value,
  }));
  const theme = useTheme();
  return (
    <>
      <Typography sx={{ backgroundColor: 'common.white', padding: '2rem' }}>
        Expedientes por categoria
      </Typography>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <ResponsiveContainer height={200} width="100%">
          <BarChartCustom
            barSize={15}
            data={ChartData}
            margin={{
              top: 5,
              right: 20,
              left: 35,
              bottom: 10,
            }}
          >
            <Tooltip />
            <XAxis dataKey="label" stroke={theme.palette.info.main} />
            <Bar
              bandSize={200}
              barSize={50}
              dataKey="value"
              fill={theme.palette.info.light}
              name="Expedientes"
            >
              <LabelList dataKey="value" position="center" style={{ fill: 'black' }} />
            </Bar>
          </BarChartCustom>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default BarChart;
