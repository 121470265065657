import React from 'react';
import { Formik } from 'formik';
import {
  DialogActions,
  Button,
  DialogContent,
  Box,
  Grid,
  Stack,
  Typography,
  Tooltip,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import * as Yup from 'yup';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SwitchField,
  SliderField,
  CommentField,
} from 'src/components/ui-components/Form';
import {
  AssigneesAutocomplete,
  EntitiesAutocomplete,
  TagsAutocomplete,
  FilecasesAutocomplete,
  CurrencyAutocomplete,
} from 'src/autocompletes';
import { YupDateValidations } from 'src/utils/dates';
import { ExpandLess, IndeterminateCheckBox } from '@mui/icons-material';
import { LSAdd } from 'src/components/icons';
import { formatMoney } from 'src/utils/formatters';

const defaultValues = {
  client: null,
  amount: 0,
  paid: 0,
  shouldPay: false,
  paidPercentage: 100,
  concept: '',
  filecase: null,
  responsible: null,
  canBeSeenByCreatorOnly: false,
  tags: [],
  items: [{ description: '', amount: 0 }],
  currency: 'usd',
};

const ExpenseForm = ({
  initialValues = defaultValues,
  onSubmit,
  isEdit,
  onCancel,
  buttonLabel,
  filecase,
  ...rest
}) => {
  const validationSchema = Yup.lazy((values) => {
    return Yup.object({
      currency: Yup.object()
        .required('Debes seleccionar o crear una moneda')
        .nullable(),
      date: YupDateValidations,
      items: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number()
              .positive('Debe ser un monto positivo')
              .required('Debes agregar un monto a tu gasto'),
          }),
        )
        .min(1, 'Debes agregar al menos un item a tu gasto'),
      paid: Yup.number().when('shouldPay', {
        is: true,
        then: () =>
          Yup.number().max(
            values.amount,
            `Debes cobrar un maximo de ${values?.currency?.value} ${formatMoney(values.amount)}`,
          ),
      }),
      amount: Yup.number().when('items', {
        is: (items) => {
          const total = items.reduce((acc, item) => acc + item.amount, 0);
          return total < values.paid;
        },
        then: Yup.number().min(
          values?.paid,
          `Este gasto ya fue pagado, el monto no puede ser menor a ${values?.currency?.value} ${formatMoney(values?.paid)}`,
        ),
        otherwise: Yup.number(),
      }),
    });
  });

  return (
    <Formik
      {...rest}
      initialValues={{ ...defaultValues, ...initialValues }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values, setFieldValue, errors, touched }) => (
        <>
          <DialogContent>
            <Form id="expensesForm">
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <DateField gridProps={{ xs: 6 }} label="Fecha" name="date" />

                  <CurrencyAutocomplete
                    creatable
                    gridProps={{ xs: 6 }}
                    name="currency"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack gap={1}>
                  <Stack direction="row">
                    <Typography flex={8} variant="label">
                      Detalle
                    </Typography>

                    <Typography flex={4} pl={4} variant="label">
                      Monto
                    </Typography>
                  </Stack>

                  {values.items.map((item, index) => (
                    <Grid container key={index} spacing={1} alignItems="center">
                      <TextField
                        gridProps={{ xs: 8 }}
                        flex={3}
                        name={`items[${index}].description`}
                        rows={1}
                      />

                      <TextField
                        flex={1}
                        gridProps={{ xs: 3 }}
                        name={`items[${index}].amount`}
                        type="number"
                        onChange={(event) => {
                          const newAmount = values.items.reduce(
                            (acc, val, i) =>
                              index === i
                                ? acc + Number(event.target.value)
                                : acc + val.amount,
                            0,
                          );

                          setFieldValue('amount', newAmount);

                          if (!isEdit) {
                            setFieldValue('paid', newAmount);
                            setFieldValue('paidPercentage', 100);
                          }
                        }}
                      />

                      {index !== 0 && (
                        <Grid item xs={1}>
                          <Tooltip title="Descartar linea">
                            <IconButton
                              onClick={() => {
                                const newItems = values.items.filter(
                                  (item, i) => i !== index,
                                );
                                const newAmount = newItems.reduce(
                                  (a, b) => a + b.amount,
                                  0,
                                );

                                setFieldValue('items', newItems);
                                setFieldValue('amount', newAmount);
                                if (!isEdit) {
                                  setFieldValue('paid', newAmount);
                                  setFieldValue('paidPercentage', 100);
                                }
                              }}
                            >
                              <IndeterminateCheckBox />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  ))}

                  <Box>
                    <Tooltip title="Agregar línea" placement="right">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setFieldValue('items', [
                            ...values.items,
                            { description: '', amount: 0 },
                          ])
                        }
                      >
                        <LSAdd color="white" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {!isEdit && (
                <SwitchField
                  gridProps={{ xs: 6 }}
                  label="Marcar como pagado"
                  name="shouldPay"
                />
              )}

              <Grid item xs={6} ml="auto">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Total:</Typography>

                  <Typography
                    fontWeight={600}
                    fontSize={24}
                    color="success.main"
                  >
                    {values?.currency?.value} {formatMoney(values?.amount)}
                  </Typography>
                </Stack>

                <Stack direction="row" alignItems="center" gap={1}>
                  {touched.amount && errors.amount && (
                    <Typography
                      fontWeight={500}
                      fontSize={14}
                      color="error.main"
                    >
                      {errors.amount}
                    </Typography>
                  )}
                </Stack>
              </Grid>

              {values.shouldPay && !isEdit && (
                <>
                  <SliderField
                    gridProps={{ xs: 4 }}
                    label={values.paidPercentage + '%'}
                    name="paidPercentage"
                    step={10}
                    onChange={(event) => {
                      setFieldValue(
                        'paid',
                        event.target.value * (values.amount / 100),
                      );
                    }}
                  />

                  <Grid item xs={2} />

                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography>Total pagado: </Typography>

                      <Grid container>
                        <TextField
                          type="number"
                          name="paid"
                          onChange={(event) => {
                            const percentage =
                              values.amount && event.target.value
                                ? Math.round(
                                    (event.target.value / values.amount) * 100,
                                  )
                                : 100;

                            setFieldValue('paidPercentage', percentage);
                          }}
                        />
                      </Grid>
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    borderStyle: 'dashed',
                    borderRadius: 1,
                    '&::before': { display: 'none' },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandLess color="primary" />}>
                    <Stack>
                      <Typography
                        fontWeight="bold"
                        fontSize={18}
                        color="primary"
                      >
                        + información adicional
                      </Typography>

                      <Typography
                        fontWeight="400"
                        fontSize={15}
                        color="primary"
                      >
                        Detalles extras del gasto
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {!isEdit && (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              bgcolor: 'background.input',
                              p: 2,
                              borderRadius: 2,
                            }}
                          >
                            <Grid container>
                              <CommentField
                                name="comment"
                                placeholder="Detalles adicionales (documentación, detalle de factura...)"
                              />
                            </Grid>
                          </Box>
                        </Grid>
                      )}

                      <EntitiesAutocomplete label="Proveedor" name="entity" />

                      <FilecasesAutocomplete
                        disabled={!!filecase?.id}
                        label="Expediente"
                        name="filecase"
                      />

                      <TagsAutocomplete
                        creatable
                        multiple
                        name="tags"
                        placeholder="Ej. Zona sur"
                      />

                      <AssigneesAutocomplete
                        label="Responsable"
                        name="responsible"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Form>
          </DialogContent>

          <DialogActions>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
                Cancelar
              </Button>

              <SubmitButton
                fullWidth
                form="expensesForm"
                gridProps={{ sx: { width: '100%' } }}
              >
                {buttonLabel}
              </SubmitButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default ExpenseForm;
