import React, { useRef } from 'react';
import { Box, Paper } from '@mui/material';
import { RichEditor } from 'src/components/v3/RichEditor';

export const ContractTemplatePreview = ({ contract, sx = {} }) => {
  const editorRef = useRef();

  if (!contract) {
    return null;
  }

  return (
    <Paper
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        backgroundColor: '#f9fbfd',
        backgroundSize: 'cover',
        maxHeight: [null, '60vh'],
      }}
    >
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: 3 }}>
        <RichEditor
          disabled
          initialValue={contract.content}
          key={contract.id}
          ref={editorRef}
          sx={{
            ...sx,
            bgcolor: 'white',
            boxShadow: 2,
            padding: 2,
            borderRadius: 1,
          }}
        />
      </Box>
    </Paper>
  );
};
