const MuiTableCell = {
  styleOverrides: {
    root: {
      borderBottom: 'none',
    },
    head: {
      // TODO: use _colors_ from the _palette_ folder
      color: '#3F4254',
      borderBottom: `1px solid #EFF2F5`,
    },
  },
};

export default MuiTableCell;
