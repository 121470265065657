import React from 'react';
import { Box, Card, CardContent } from '@mui/material';

const StateColumnHeader = ({ children, state }) => (
  <Card>
    <CardContent
      sx={{
        backgroundColor: state.bg,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: (theme) => `${theme.spacing(1)} !important`,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          color: state.color,
        }}
      >
        {children}
      </Box>
    </CardContent>
  </Card>
);

export default StateColumnHeader;
