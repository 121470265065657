import React, { useState, useMemo } from 'react';
import {
  IconButton,
  Box,
  Card,
  CardHeader,
  CardContent,
  Popover,
  ClickAwayListener,
  darken,
  Button,
  CardActions,
  useTheme,
  CircularProgress,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import StateForm from 'src/forms/StateForm';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CREATE_STATE, UPDATE_STATE } from 'src/graphql/mutations/state';

import {
  LSEdit as LSEditIcon,
  LSX as LSXIcon,
  LSAdd as LSAddIcon,
} from 'src/components/icons';
import { AutocompleteField } from 'src/components/ui-components/Form';
import { GET_STUDIO_STATUSES } from 'src/graphql/queries/state';
import StatusPopper from './StatusPopper';
import { Badge } from 'src/components/Badge';

export const useFilecaseStatuses = (options = {}) => {
  const studioId = useStudioId();

  const { data, loading } = useQuery(GET_STUDIO_STATUSES, {
    ...options,
    variables: {
      studioId,
    },
  });

  return { loading, statuses: data?.states || [] };
};

// TODO: Rethink a better API for this component
const StatusPopover = ({
  anchorEl,
  open,
  onClose,
  afterSubmit,
  onClickStatus,
  ...props
}) => {
  const [isFormView, setIsFormView] = useState(false);
  const [statusToEdit, setStatusToEdit] = useState();
  const studioId = useStudioId();
  const { loading, statuses } = useFilecaseStatuses();
  const [createState] = useMutation(CREATE_STATE);

  const { openSnackbar } = useSnackbar();
  const [updateState] = useMutation(UPDATE_STATE);

  const theme = useTheme();

  // TODO: Rename states to statuses on API

  const options = useMemo(() => {
    const arr = statuses?.map((val) => ({ ...val, label: val.name })) || [];

    if (arr.length) {
      arr.unshift({
        name: null,
        value: null,
        label: 'Ninguno (limpiar campo)',
        color: '#F8F5FF',
      });
    }

    return arr;
  }, [statuses]);

  const handleSubmit = async (values) => {
    try {
      // Either edit or create filecase
      const isEdit = !!(values && values.id);
      const mutation = isEdit ? updateState : createState;

      const query = {
        name: values.name,
        color: values.color || '#000000',
        studioId,
      };

      if (isEdit) {
        query.id = values.id;
      }

      await mutation({
        variables: query,
        update: (cache, result) => {
          const cachedStatuses = cache.readQuery({
            query: GET_STUDIO_STATUSES,
            variables: {
              studioId,
            },
            optimistic: true,
          });

          if (cachedStatuses) {
            cache.writeQuery({
              query: GET_STUDIO_STATUSES,
              variables: {
                studioId,
              },
              data: {
                states: isEdit
                  ? cachedStatuses.states.map((state) =>
                      state.id === result.data.updateState.id
                        ? result.data.updateState
                        : state,
                    )
                  : [...cachedStatuses.states, result.data.createState],
              },
            });
          }

          // if (isEdit && field.value.id === result.data.updateState.id) {
          //   helpers.setValue({
          //     ...result.data.updateState,
          //     label: result.data.updateState.name,
          //   });
          // }
        },
      });

      setIsFormView(false);

      openSnackbar(
        isEdit ? 'Estado actualizado con exito.' : 'Estado creado con exito.',
        {
          severity: 'success',
        },
      );
    } catch (error) {
      openSnackbar(
        'Hubo un error al crear el estado, Intente de nuevo mas tarde.',
        {
          severity: 'error',
        },
      );
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      {...props}
    >
      <ClickAwayListener
        onClickAway={() => {
          onClose();
          setIsFormView(false);
        }}
      >
        {!loading ? (
          <Box
            sx={{
              width: 300,
              '& .MuiDialogActions-root': {
                padding: 2,
              },
            }}
          >
            <Card sx={{ borderRadius: '8px' }}>
              <CardHeader
                action={
                  <IconButton onClick={() => onClose()}>
                    <LSXIcon />
                  </IconButton>
                }
                sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
                title={isFormView ? 'Nuevo estado' : 'Estados'}
              />

              <CardContent sx={{ px: 2, pt: 2, pb: 0 }}>
                {!isFormView && (
                  <Box>
                    <AutocompleteField
                      disableCloseOnSelect
                      open
                      PopperComponent={StatusPopper}
                      getOptionLabel={(option) => option.label}
                      noOptionsText={
                        <Box sx={{ color: 'error.light' }}>
                          No posees estados
                        </Box>
                      }
                      options={options}
                      placeholder="Buscar estados"
                      renderOption={(...[, option]) => (
                        <Box
                          component="li"
                          key={option.id || option.name}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Badge
                            minWidth="initial"
                            maxWidth="100%"
                            label={option.label}
                            backgroundColor={option.color}
                            sx={{
                              cursor: 'pointer',
                              flex: 1,
                              '&:hover': {
                                backgroundColor: darken(
                                  option.color ?? theme.palette.grey[100],
                                  0.2,
                                ),
                              },
                            }}
                            onClick={() => {
                              onClickStatus(option);
                              onClose();
                            }}
                          />

                          {option.name !== null && (
                            <PermissionGuard
                              permissions={[
                                LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES,
                              ]}
                            >
                              <IconButton
                                color="primary"
                                size="small"
                                sx={{
                                  visibility: option.default
                                    ? 'hidden'
                                    : 'visible',
                                }}
                                onClick={() => {
                                  setIsFormView(true);
                                  setStatusToEdit(option);
                                }}
                              >
                                <LSEditIcon />
                              </IconButton>
                            </PermissionGuard>
                          )}
                        </Box>
                      )}
                      onClose={(...[, reason]) => {
                        if (reason === 'escape') {
                          onClose();
                        }
                      }}
                    />
                  </Box>
                )}

                {isFormView && (
                  // Needed to be consistent with spacing.
                  <Box sx={{ m: -3 }}>
                    <StateForm
                      enableReinitialize
                      initialValues={statusToEdit}
                      onCancel={() => {
                        setIsFormView(false);
                        setStatusToEdit();
                      }}
                      onSubmit={handleSubmit}
                    />
                  </Box>
                )}
              </CardContent>

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.CONFIG.ACCESS_CATEGORIES]}
              >
                {!isFormView && (
                  <CardActions
                    sx={{
                      borderTop: '1px solid',
                      borderColor: 'divider',
                      py: 2,
                      px: 1,
                    }}
                  >
                    <Button
                      fullWidth
                      color="primary"
                      startIcon={<LSAddIcon />}
                      variant="contained"
                      onClick={() => setIsFormView(true)}
                    >
                      CREAR NUEVO ESTADO
                    </Button>
                  </CardActions>
                )}
              </PermissionGuard>
            </Card>
          </Box>
        ) : (
          <Box
            sx={{
              width: 180,
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="primary" size={22} />
          </Box>
        )}
      </ClickAwayListener>
    </Popover>
  );
};

export default StatusPopover;
