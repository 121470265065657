import React from 'react';
import { Box, Skeleton } from '@mui/material';

const CalendarEventDetailsDialogSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton animation="wave" height={25} width={250} />
    <Skeleton animation="wave" variant="text" />

    <Box sx={{ pb: 4 }} />

    <Skeleton animation="wave" height={25} width={300} />
    <Skeleton animation="wave" height={25} width={190} />
    <Skeleton animation="wave" height={25} width={190} />

    <Box sx={{ pb: 5 }} />

    <Skeleton animation="wave" variant="text" />

    <Box sx={{ pb: 5 }} />

    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
          marginLeft: 1,
        },
      }}
    >
      <Skeleton animation="wave" height={25} variant="circular" width={25} />
      <Skeleton animation="wave" height={25} width={50} />
      <Skeleton animation="wave" height={25} width={50} />
    </Box>

    <Box sx={{ pb: 2 }} />

    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
          marginLeft: 1,
        },
      }}
    >
      <Skeleton animation="wave" height={25} variant="circular" width={25} />
      <Skeleton animation="wave" height={25} width={50} />
      <Skeleton animation="wave" height={25} width={50} />
    </Box>

    <Box sx={{ pb: 2 }} />

    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > * + *': {
          marginLeft: 1,
        },
      }}
    >
      <Skeleton animation="wave" height={25} variant="circular" width={25} />
      <Skeleton animation="wave" height={25} width={50} />
      <Skeleton animation="wave" height={25} width={50} />
    </Box>

    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        mt: 5,
        '& > * + *': {
          marginLeft: 2,
        },
      }}
    >
      <Skeleton animation="wave" height={42} width={112} />
      <Skeleton animation="wave" height={42} width={112} />
      <Skeleton animation="wave" height={42} width={112} />
    </Box>
  </Box>
);

export default CalendarEventDetailsDialogSkeleton;
