import React from 'react';
import { useField, useFormikContext } from 'formik';

import {
  Alert,
  AlertTitle,
  Grid,
  Collapse,
  Typography,
  FormLabel,
} from '@mui/material';
import FilesDropzone from 'src/components/v2/FilesDropzone';

const FilesField = ({
  name,
  label,
  gridProps = { xs: 12 },
  multiple = true,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(name);
  const { isSubmitting } = useFormikContext();

  return (
    <Grid container item gap={2} {...gridProps}>
      <Grid item xs={12}>
        <FormLabel>
          {label && (
            <Typography sx={{ mb: 2 }} variant="subtitle2">
              {label}
            </Typography>
          )}
          <FilesDropzone
            {...props}
            disabled={isSubmitting}
            loading={isSubmitting}
            multiple={multiple}
            value={field.value}
            onChange={(value) => {
              if (!multiple) {
                setValue(value[0]);
                return;
              }

              setValue(
                [
                  ...field.value,
                  // Remove duplicated of already "selected" files on form
                  ...value.filter(
                    (file) =>
                      !field.value.some(
                        (savedFile) =>
                          savedFile.name === file.name &&
                          savedFile.size === file.size &&
                          savedFile.type === file.type,
                      ),
                  ),
                ],
                true,
              );
            }}
            onRemove={(value) => setValue(value, true)}
          />
        </FormLabel>
      </Grid>

      {!!meta.error && (
        <Grid item xs={12}>
          <Collapse in={!!meta.error}>
            <Alert color="error">
              <AlertTitle>{meta.error}</AlertTitle>
            </Alert>
          </Collapse>
        </Grid>
      )}
    </Grid>
  );
};

export default FilesField;
