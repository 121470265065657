import { gql } from '@apollo/client';

export const PaginatedFilecasesQuery = gql`
  query paginatedFilecases(
    $search: String
    $category: String
    $state: String
    $entities: [String!]
    $assigned: [ID!]
    $tags: [String!]
    $limit: Int
    $offset: Int
    $studio: ID!
    $onlyFavorites: Boolean
    $orderBy: PaginatedFilecasesSortBy
    $sortOrder: SortOrder
  ) {
    paginatedFilecases(
      search: $search
      category: $category
      state: $state
      entities: $entities
      assigned: $assigned
      tags: $tags
      limit: $limit
      offset: $offset
      studio: $studio
      onlyFavorites: $onlyFavorites
      orderBy: $orderBy
      sortOrder: $sortOrder
    ) {
      count
      data {
        id
        title
        externalId
        updatedAt
        description
        stateUpdatedAt
        latestEntry {
          id
          content
          date
          createdAt
          updatedAt
          createdBy {
            id
            picture
            name
          }
        }
        createdAt
        category {
          id
          name
        }
        state {
          id
          color
          name
        }
        side {
          id
          name
        }
        tags {
          name
          studioId
        }
        createdBy {
          id
          email
        }
        assigned {
          id
          picture
          name
        }
        entities {
          id
          type
          displayName
          phone
        }
        favorites {
          id
        }
      }
    }
  }
`;

export const UpdateFilecaseAssigneesMutation = gql`
  mutation UpdateFilecaseAssigneesMutation(
    $id: ID!
    $studioId: ID!
    $assigned: [ID!]
  ) {
    updateFilecase(filecase: $id, assigned: $assigned, studio: $studioId) {
      id
      studioId
      assigned {
        id
        name
        picture
      }
    }
  }
`;
