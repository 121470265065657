import { useMutation, MutationHookOptions } from '@apollo/client';
import { UpdateTodo } from '@legalsurf/queries';

import { UseUpdateEventTodoHookVariables, Todo } from '../types';

export const useUpdateTodo = (
  options: MutationHookOptions<Todo, UseUpdateEventTodoHookVariables>,
) =>
  useMutation(UpdateTodo, {
    ...options,
  });
