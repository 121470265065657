import React from 'react';
import { Box, Dialog } from '@mui/material';

import { SaleDetail } from 'src/components/v2/SaleDetail';

const SaleDialog = ({ id, open, onClose }) => (
  <Dialog
    fullScreen
    open={!!open}
    sx={{ width: ['initial', 700], ml: 'auto' }}
    onClose={onClose}
  >
    <Box sx={{ overflowY: 'scroll' }}>
      <SaleDetail saleId={id} onClose={onClose} />
    </Box>
  </Dialog>
);

export default SaleDialog;
