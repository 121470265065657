import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Grid,
  Popper,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { LoadingButton } from '@mui/lab';
import { FilterListOutlined } from '@mui/icons-material';

const SectionHeaderAdvanceFilters = ({ disabled, children, onReset }) => {
  const formikBag = useFormikContext();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'SectionHeaderAdvanceFilters-popper' : undefined;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="text"
        onClick={handleClick}
        aria-describedby={id}
        endIcon={<FilterListOutlined />}
      >
        Filtrar
      </Button>

      <Popper anchorEl={anchorEl} id={id} open={open}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Card>
            <CardContent>
              <Grid container spacing={2} sx={{ padding: 1, maxWidth: 280 }}>
                {children}
              </Grid>

              <Box
                sx={{
                  gap: 1,
                  p: 2,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  disabled={formikBag.isSubmitting || disabled}
                  variant="outlined"
                  onClick={() => onReset(formikBag)}
                >
                  Restaurar
                </Button>

                <LoadingButton
                  loading={formikBag.isSubmitting}
                  variant="contained"
                  onClick={() => {
                    formikBag.handleSubmit();
                    setAnchorEl(null);
                  }}
                >
                  Aplicar
                </LoadingButton>
              </Box>
            </CardContent>
          </Card>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default SectionHeaderAdvanceFilters;
