import { gql, useMutation } from '@apollo/client';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { useSubscription } from 'src/components/SubscriptionWatcher/useSubscription';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { subscriptionStatusesMap } from './utils';

function formatSubscriptionCurrency(subscriptionData) {
  const { currency, price, interval } = subscriptionData;

  const formattedAmount = new Intl.NumberFormat('es', {
    style: 'currency',
    currency,
  }).format(price / 100);

  const frequency = interval === 'month' ? 'mes' : 'año';

  return `${formattedAmount} / ${frequency}`;
}

const CreateCustomerPortalMutation = gql`
  mutation createCustomerPortal($studioId: ID!) {
    createCustomerPortal(studioId: $studioId)
  }
`;

export const SubscriptionDetails = () => {
  const studioId = useStudioId();
  const { studio } = useSubscription();
  const isSubscriptionValid = studio?.subscription?.status === 'active';

  const [createCustomerPortal, { loading }] = useMutation(
    CreateCustomerPortalMutation,
    {
      variables: {
        studioId,
      },
    },
  );

  const handleSubmit = async () => {
    const res = await createCustomerPortal();

    window.location.assign(res.data.createCustomerPortal);
  };

  if (!studio?.subscription) {
    return null;
    // TODO: Define whether we'll use stripe's pricing table or what
    // return (
    //   <Box>
    //     <stripe-pricing-table
    //       allow-top-navigation
    //       client-reference-id={[user.id, studio.id].join('_')}
    //       customer-email={user.email}
    //       key={studio.id}
    //       pricing-table-id="prctbl_1NGRukL3rAO9CGUvKcN52gh3"
    //       publishable-key="pk_live_51K7LDhL3rAO9CGUvO0XM46rCrigJOSW2qbfCeKD0jo9ZIXtGkJsDQPOEgB2aL0x2gn1yPFKAG2ThtwY8HKfn6LU300zLhzQvzP"
    //     />
    //   </Box>
    // );
  }

  return (
    <Box>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
          <Box
            alt="suscripcion actual"
            as="img"
            src={studio.subscription.img}
            style={{ height: 120, width: 120 }}
          />

          <Box>
            <Typography color="primary" fontSize={20} fontWeight={500}>
              {studio.subscription.name}
            </Typography>

            <Typography fontSize={17} fontWeight={600}>
              {formatSubscriptionCurrency(studio.subscription)}
            </Typography>
          </Box>
        </Box>

        <Typography fontSize={18} fontWeight={600}>
          Detalles de la suscripción contratada:
        </Typography>

        <Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography>ID suscripción:</Typography>

            <Typography color="primary">{studio.subscription.id}</Typography>
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography>Estado de suscripción:</Typography>

            <Typography
              color={
                subscriptionStatusesMap[studio?.subscription?.status].color
              }
            >
              {subscriptionStatusesMap[studio?.subscription?.status].label}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Typography>Fecha de vencimiento:</Typography>

            <Typography color={isSubscriptionValid ? 'primary' : 'error'}>
              {dayjs(studio.subscription.currentPeriodEnd).format('L')}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Button sx={{ mt: 2 }} variant="contained" onClick={handleSubmit}>
        Gestionar suscripción
      </Button>
    </Box>
  );
};
