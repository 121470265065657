import { typography, palette } from '../../foundations';

const MuiCssBaseline = {
  styleOverrides: {
    // Lexical Rich editor
    '& .LegalSurf__textBold': {
      fontWeight: 'bold',
    },

    '& .LegalSurf__textCode': {
      backgroundColor: 'rgb(240, 242, 245)',
      padding: '1px 0.25rem',
      fontFamily: 'Menlo, Consolas, Monaco, monospace',
      fontSize: '94%',
    },

    '& .LegalSurf__textItalic': {
      fontStyle: 'italic',
    },

    '& .LegalSurf__textStrikethrough': {
      textDecoration: 'line-through',
    },

    '& .LegalSurf__textSubscript': {
      fontSize: '0.8em',
      verticalAlign: 'sub !important',
    },

    '& .LegalSurf__textSuperscript': {
      fontSize: '0.8em',
      verticalAlign: 'super',
    },

    '& .LegalSurf__textUnderline': {
      textDecoration: 'underline',
    },

    '& .LegalSurf__textUnderlineStrikethrough': {
      textDecoration: 'underline line-through',
    },

    '& .LegalSurf__textLink': {
      color: palette.secondary.main,
      cursor: 'pointer',
    },

    '& .PlaygroundEditorTheme__hashtag': {
      backgroundColor: 'rgba(88, 144, 255, 0.15)',
      borderBottom: '1px solid rgba(88, 144, 255, 0.3)',
    },

    // Styles overrides for table HEAD cell
    '& th .fc-scrollgrid-sync-inner': {
      padding: '9px 8px',

      '& a': {
        textDecoration: 'none',
        fontWeight: typography.body2,
        ...typography.allVariants,
      },
    },

    // Styles overrides for table day cell number
    '& td .fc-daygrid-day-top': {
      '& a': {
        textDecoration: 'none',
        fontWeight: typography.body2,
        ...typography.allVariants,
      },
    },

    // Styles overrides for table event
    '& td a.fc-daygrid-dot-event': {
      backgroundColor: palette.grey[100],
      color: '#7e8299',
      padding: '5px',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: palette.grey[100],
      },
    },

    '& td a.fc-h-event': {
      border: 'none',
      padding: '4px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
};

export default MuiCssBaseline;
