import React from 'react';
import {
  Typography,
  Button,
  Stack,
  Card,
  CardContent,
  Box,
  Avatar,
  ButtonGroup,
} from '@mui/material';

import { LSDangerSign } from 'src/components/icons';

const StudioInviteCard = ({ name, picture, onCancel, onAccept, disabled }) => (
  <Box sx={{ width: '100%' }}>
    <Card>
      <Box
        sx={{
          p: 2,
          height: '100%',
          width: '100%',
        }}
      >
        <CardContent>
          <Stack alignItems="center" direction="row" gap={2}>
            <Avatar src={picture} sx={{ width: 74, height: 74 }} />

            <Stack gap={2}>
              <Stack>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <LSDangerSign color="warning" />

                  <Typography variant="h6">Invitación pendiente</Typography>
                </Stack>

                <Typography variant="subtitle2">
                  Tienes una invitación pendiente para trabajar en el estudio{' '}
                  <b>{name}</b>
                </Typography>
              </Stack>

              <Stack direction="row">
                <ButtonGroup size="small" sx={{ gap: 0.5 }}>
                  <Button
                    color="error"
                    disabled={disabled}
                    variant="contained"
                    onClick={onCancel}
                  >
                    Rechazar
                  </Button>

                  <Button
                    color="success"
                    disabled={disabled}
                    variant="contained"
                    onClick={onAccept}
                  >
                    Aceptar invitación
                  </Button>
                </ButtonGroup>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Box>
    </Card>
  </Box>
);

export default StudioInviteCard;
