import { useEffect, useState } from 'react';

export const useScript = (src, extraAttrs = {}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    if (Object.keys(extraAttrs).length) {
      Object.entries(extraAttrs).forEach(([key, val]) => {
        script.setAttribute(key, val);
      });
    }

    const handleLoad = () => {
      setIsLoaded(true);
    };

    const handleError = () => {
      setIsError(true);
    };

    script.addEventListener('load', handleLoad);
    script.addEventListener('error', handleError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', handleLoad);
      script.removeEventListener('error', handleError);
      document.body.removeChild(script);
    };
  }, [extraAttrs, src]);

  return { isLoaded, isError };
};
