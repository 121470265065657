import React from 'react';
import { SvgIcon } from '@mui/material';

const LSBooks = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <rect height="24" width="24" x="0" y="0" />
      <path
        d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z"
        fill="currentColor"
      />
      <rect
        fill="currentColor"
        height="18"
        opacity="0.3"
        rx="1"
        transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)"
        width="3"
        x="16.3255682"
        y="2.94551858"
      />
    </g>
  </SvgIcon>
);

export default LSBooks;
