import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const GET_FILECASES = gql`
  query FilecasesAutocomplete($studio: ID!) {
    filecases(studio: $studio) {
      id
      externalId
      title
    }
  }
`;

const FilecasesAutocomplete = ({
  disabled,
  label = 'Expediente',
  placeholder = 'Ej. Expediente 19923/7762:1',
  ...rest
}) => {
  const studioId = useStudioId();
  const { loading, data } = useQuery(GET_FILECASES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      studio: studioId,
    },
  });

  const filecases = useMemo(
    () =>
      data?.filecases?.map((f) => ({
        value: f.id,
        label: f.title,
        search: f.externalId,
      })),
    [data],
  );

  return (
    <SelectField
      disabled={disabled || loading}
      label={label}
      loading={loading}
      options={filecases || []}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default FilecasesAutocomplete;
