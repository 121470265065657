import React from 'react';
import { Box, Stack } from '@mui/material';
import { Draggable, Droppable } from '@hello-pangea/dnd';

export const getColumnEventId = (event) =>
  event?.id ? [event?.id, event?.calendarId].join('_') : undefined;

const DraggableColumn = ({
  header,
  items,
  renderItem,
  id,
  sx,
  isCombineEnabled,
  type,
  ...props
}) => (
  <Stack
    direction="column"
    gap={1}
    sx={{
      flex: 1,
      ...sx,
    }}
    {...props}
  >
    {header}

    <Droppable
      droppableId={id}
      isCombineEnabled={isCombineEnabled}
      key={id}
      type={type}
    >
      {(dropProvided, dropSnapshot) => (
        <Stack
          key="TaskBoard"
          {...dropProvided.droppableProps}
          direction="column"
          gap={1}
          ref={dropProvided.innerRef}
          sx={{
            flex: 1,
            borderRadius: 1,
            backgroundColor: dropSnapshot.isDraggingOver
              ? 'primary.300'
              : 'transparent',
          }}
        >
          {items.map((item, idx) => (
            <Draggable
              draggableId={getColumnEventId(item)}
              index={idx}
              key={item.id}
            >
              {(inner, snapshot) => (
                <Box
                  {...inner.draggableProps}
                  {...inner.dragHandleProps}
                  component="span"
                  ref={inner.innerRef}
                >
                  {renderItem({ item, provided: inner, snapshot })}
                </Box>
              )}
            </Draggable>
          ))}
          {dropProvided.placeholder}
        </Stack>
      )}
    </Droppable>
  </Stack>
);

export default DraggableColumn;
