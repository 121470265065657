import { Tooltip } from '@mui/material';
import React from 'react';

import Avatar from 'src/components/v2/Avatar';
import { useUser } from 'src/utils/hooks/useUser';

const LoggedUserAvatar = ({ sx, ...props }) => {
  const { loading, user } = useUser();

  return (
    <Tooltip title={user?.name}>
      <Avatar
        loading={loading}
        src={user.picture}
        sx={{
          width: 32,
          height: 32,
          ...sx,
        }}
        {...props}
      />
    </Tooltip>
  );
};

export default LoggedUserAvatar;
