import { gql } from '@apollo/client';

export const getTableTasksQuery = gql`
  query getTableTasksQuery(
    $studioId: ID!
    $text: String
    $state: [TaskState!]!
    $calendars: [ID!]
    $assigned: [ID!]
    $filecases: [ID!]
    $start: DateTime
    $end: DateTime
  ) {
    events(
      studio: $studioId
      filters: {
        text: $text
        type: task
        start: $start
        end: $end
        state: $state
        assigned: $assigned
        filecases: $filecases
        calendars: $calendars
      }
    ) {
      id
      calendarId
      type
      title
      start
      end
      allDay
      state
      updatedAt
      calendar {
        id
        name
        color
      }
      filecase {
        id
        title
      }
      entities {
        id
        displayName
      }
      todos {
        calendarId
        eventId
        id
        done
        title
      }
      assigned {
        id
        name
        picture
      }
    }
  }
`;
