import React from 'react';
import { Avatar, Button } from '@mui/material';
import OneDriveIcon from 'src/pages/Dashboard/Settings/onedrive.svg';
import { useScript } from 'src/utils/hooks/useScript';

const CONFIG = {
  clientId: import.meta.env.VITE_AZURE_APP_CLIENT_ID,
  redirectUri: import.meta.env.VITE_AZURE_APP_REDIRECT_URI,
  scopes: ['Files.Read'],
};

export const OneDriveButton = ({ disabled, onSelect }) => {
  useScript('https://alcdn.msauth.net/browser/2.19.0/js/msal-browser.min.js');

  const getToken = async (isSharePoint = false) => {
    const msalConfig = {
      auth: {
        clientId: CONFIG.clientId,
        redirectUri: CONFIG.redirectUri,
        authority: isSharePoint
          ? 'https://login.microsoftonline.com/organizations'
          : 'https://login.microsoftonline.com/consumers',
      },
    };

    const msalInstance = new window.msal.PublicClientApplication(msalConfig);

    try {
      const authResult = await msalInstance.acquireTokenPopup({
        scopes: CONFIG.scopes,
      });
      return authResult.accessToken;
    } catch (error) {
      if (error instanceof window.msal.InteractionRequiredAuthError) {
        const loginResult = await msalInstance.loginPopup({
          scopes: CONFIG.scopes,
        });
        msalInstance.setActiveAccount(loginResult.account);
        const authResult = await msalInstance.acquireTokenSilent({
          scopes: CONFIG.scopes,
        });
        return authResult.accessToken;
      }
    }
  };

  const addOneDriveTypeToDocs = (arr) =>
    arr.map((val) => ({
      ...val,
      kind: 'onedrive',
      isFolder: !!val.folder,
      url: val.webUrl,
    }));

  const openOneDrivePicker = async (isSharePoint = false) => {
    const token = await getToken(isSharePoint);

    if (!token) {
      console.error('Failed to get authentication token');
      return;
    }

    const odOptions = {
      clientId: CONFIG.clientId,
      action: 'share',
      multiSelect: true,
      accountSwitchEnabled: true,
      advanced: {
        redirectUri: CONFIG.redirectUri,
        accessToken: token,
        queryParameters: isSharePoint ? 'sharepoint=1' : undefined,
      },
      success(response) {
        onSelect(addOneDriveTypeToDocs(response?.value));
      },
      cancel() {
        console.log('Picker cancelled');
      },
      error(e) {
        console.error('Picker error:', e);
      },
    };

    window.OneDrive.open(odOptions);
  };

  return (
    <>
      <Button
        disabled={disabled}
        startIcon={
          <Avatar src={OneDriveIcon} sx={{ img: { objectFit: 'contain' } }} />
        }
        sx={{
          textTransform: 'none',
          mt: 2,
          p: 2,
        }}
        variant="text"
        onClick={() => openOneDrivePicker(false)}
      >
        OneDrive
      </Button>

      {/* <Button
        disabled={disabled}
        sx={{
          textTransform: 'none',
          mt: 2,
          p: 2,
        }}
        variant="text"
        onClick={() => openOneDrivePicker(true)}
      >
        SharePoint
      </Button> */}
    </>
  );
};
