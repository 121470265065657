import React from 'react';
import { Grid, Slider, Stack } from '@mui/material';
import { useField } from 'formik';

const SliderField = ({
  gridProps = { xs: 12 },
  label,
  name,
  onChange,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <Grid item {...gridProps}>
      <Stack direction="row" alignItems="center" gap={2}>
        {label}

        <Slider
          {...field}
          {...props}
          onChange={(event) => {
            field.onChange(event);

            if (onChange) {
              onChange(event);
            }
          }}
        />
      </Stack>
    </Grid>
  );
};

export default SliderField;
