import { gql } from '@apollo/client';

export const DocumentsQuery = gql`
  query DocumentsQuery(
    $studioId: ID!
    $search: String
    $extensions: [String!]
    $filecaseId: ID
    $entityId: ID
    $orderBy: DocumentsSortBy
    $sortOrder: SortOrder
  ) {
    documents(
      studioId: $studioId
      search: $search
      extensions: $extensions
      filecaseId: $filecaseId
      entityId: $entityId
      orderBy: $orderBy
      sortOrder: $sortOrder
    ) {
      id
      name
      extension
      mimetype
      url
      isFolder
      size
      kind
      filecases {
        id
        title
      }
    }
  }
`;
