import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';

import { useBreakpoints } from 'src/utils/hooks/common';
import DocumentsForm from 'src/forms/DocumentsForm';

const FilesFormDialog = ({
  open,
  onClose,
  file,
  disableFields,
  onSubmit,
  searchable,
  ...props
}) => {
  const { isMobile } = useBreakpoints();

  return (
    <Dialog fullWidth fullScreen={isMobile} open={open} onClose={onClose}>
      {!searchable && <DialogTitle>Subir documentos</DialogTitle>}

      <DocumentsForm
        disableFields={disableFields}
        initialValues={file}
        searchable={searchable}
        onCancel={onClose}
        onSubmit={onSubmit}
        {...props}
      />
    </Dialog>
  );
};

export default FilesFormDialog;
