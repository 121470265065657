import React from 'react';
import { Chip, Stack } from '@mui/material';
import { useFormikContext } from 'formik';

export const SectionHeaderActiveFilters = ({
  filters,
  filterLabels,
  onDelete,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const activeFilters = Object.entries(filters).filter(
    ([key, val]) =>
      filterLabels[key] &&
      values[key] &&
      (Array.isArray(val) ? val.length : Boolean(val)),
  );

  const getHumanizedSlug = (key) => {
    const option = values[key];
    const label = filterLabels[key];
    const separator = ': ';
    const val = Array.isArray(option)
      ? option?.length
      : typeof (option?.value || option) === 'boolean'
        ? option
          ? 'Sí'
          : 'No'
        : (option?.label ?? option).toString();

    return [label, separator, val].join('');
  };

  if (!activeFilters.length) {
    return null;
  }

  return (
    <Stack alignItems="center" direction="row" gap={1}>
      {activeFilters.map(([key, value]) => (
        <Chip
          key={key}
          color="primary"
          label={getHumanizedSlug(key)}
          size="small"
          sx={{
            '& .MuiChip-deleteIcon': {
              color: 'primary.main',
              '&:hover': {
                color: 'error.main',
              },
            },
          }}
          variant="light"
          onDelete={() => {
            setFieldValue(key, Array.isArray(value) ? [] : null);
            onDelete?.(key);
          }}
        />
      ))}

      {/* <ButtonGroup sx={{ backgroundColor: 'primary.100' }}>
        <Button size="small" onClick={handleReset}>
          Aplicar filtros por defecto
        </Button>

        <Button size="small" onClick={handleReset}>
          <CheckIcon fontSize="small" />
        </Button>
      </ButtonGroup> */}
    </Stack>
  );
};
