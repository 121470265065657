import { gql, useMutation } from '@apollo/client';

export const DeleteEntityCommentMutation = gql`
  mutation DeleteEntityCommentMutation(
    $commentId: ID!
    $entityId: ID!
    $studioId: ID!
  ) {
    deleteEntityComment(
      commentId: $commentId
      entityId: $entityId
      studioId: $studioId
    ) {
      id
      entityId
      content
      updatedAt
      createdBy {
        id
        name
        picture
      }
    }
  }
`;

export const UpdateEntityCommentMutation = gql`
  mutation UpdateEntityCommentMutation(
    $commentId: ID!
    $content: String!
    $entityId: ID!
    $studioId: ID!
  ) {
    updateEntityComment(
      commentId: $commentId
      content: $content
      entityId: $entityId
      studioId: $studioId
    ) {
      id
      entityId
      content
      updatedAt
      createdBy {
        id
        name
        picture
      }
    }
  }
`;

export const useDeleteEntityComment = () => {
  const [deleteEntityComment] = useMutation(DeleteEntityCommentMutation, {
    update: (cache, { data }) => {
      cache.evict({ id: cache.identify(data.deleteEntityComment) });
    },
  });

  return deleteEntityComment;
};

export const useUpdateEntityComment = () => {
  const [updateEntityComment] = useMutation(UpdateEntityCommentMutation);

  return updateEntityComment;
};
