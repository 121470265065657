import React, { useState } from 'react';
import {
  Fade,
  IconButton,
  Box,
  Avatar,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import GoogleCalendarIMG from 'src/assets/images/googlecalendar.png';
import { ReconnectGoogleCalendarButton } from './ReconnectGoogleCalendarButton';

const calendarButtonStyles = {
  fontSize: 14,
  fontWeight: 600,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const CalendarCard = ({
  active,
  onClick,
  calendar,
  onClickAction,
  sx,
  ...props
}) => {
  const [actionsEl, setActionsEl] = useState(null);
  const [hovered, setHovered] = useState(true);

  const handleClickAction = (action) => (event) => {
    event.stopPropagation();
    onClickAction(action);
  };

  const handleToggleActions = (event) => {
    event.stopPropagation();
    if (!actionsEl) setActionsEl(event.currentTarget);
    else setActionsEl(null);
  };

  return (
    <Box
      sx={{
        ...calendarButtonStyles,
        display: 'flex',
        gap: 1,
        cursor: 'pointer',
        pl: 2,
        py: 0.5,
        borderRadius: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
        opacity: 0.4,
        ...(calendar.googleCalendarNeedsResync
          ? {
              backgroundColor: 'white',
              borderColor: 'error.main',
              color: 'error.light',
              borderWidth: 2,
              borderStyle: 'solid',
            }
          : { backgroundColor: calendar.color, borderColor: calendar.color }),

        '&:hover': {
          opacity: 1,
        },

        ...(active && {
          opacity: 1,
        }),

        ...sx,
      }}
      onClick={() =>
        !calendar.googleCalendarNeedsResync ? onClick(calendar.id) : undefined
      }
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...props}
    >
      {!!calendar.googleCalendarId && (
        <Avatar src={GoogleCalendarIMG} sx={{ width: 18, height: 18 }} />
      )}

      <Typography
        noWrap
        fontSize={14}
        fontWeight={600}
        sx={{
          color: calendar.googleCalendarNeedsResync
            ? 'error.main'
            : (theme) => theme.palette.computeContrast(calendar.color),
          flex: 1,
        }}
      >
        {calendar.name}
      </Typography>

      {calendar.googleCalendarNeedsResync && (
        <ReconnectGoogleCalendarButton calendar={calendar} />
      )}

      <Fade in={hovered}>
        <IconButton
          size="small"
          sx={{
            color: !calendar.googleCalendarNeedsResync ? 'white' : 'black',
          }}
          onClick={handleToggleActions}
        >
          <MoreVert />
        </IconButton>
      </Fade>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'calendar-actions-button',
        }}
        anchorEl={actionsEl}
        id="calendar-actions"
        open={!!actionsEl}
        onClose={handleToggleActions}
      >
        <MenuItem onClick={handleClickAction('edit')}>Editar</MenuItem>

        <MenuItem onClick={handleClickAction('delete')}>Eliminar</MenuItem>
      </Menu>
    </Box>
  );
};

export default CalendarCard;
