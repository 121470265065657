import React from 'react';
import { Avatar, Box, Stack, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useUser } from 'src/utils/hooks/useUser';
import { Badge } from 'src/components/Badge';

export const FilecaseMock = () => {
  const { values } = useFormikContext();
  const { user } = useUser();

  return (
    <Stack
      display={['none', 'none', 'none', 'flex']}
      flex={1}
      sx={{ borderRadius: 1, width: '80%', mt: 'auto' }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          bottom: 0,
          mt: 'auto',
          ml: 10,
        }}
      >
        <Box
          sx={{
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            backgroundColor: 'white',
            height: 30,
            width: '46%',
          }}
        />

        <Box
          sx={{
            height: '100%',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            pt: 3,
          }}
        >
          <Stack p={4} pt={2} pl={5} gap={2}>
            <Stack alignItems="flex-end" direction="row">
              <Typography
                flex={4}
                fontWeight={600}
                variant="h5"
                sx={{ opacity: 0.7 }}
              >
                Nro. 00001
              </Typography>
            </Stack>

            <Stack direction="column" gap={2}>
              <Typography sx={{ fontWeight: 600, fontSize: 26 }} variant="h3">
                {values.name || 'Título de expediente'}
              </Typography>

              <Stack direction="column" gap={1}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    maxWidth: '60%',
                    alignItems: 'center',
                  }}
                >
                  {values.state ? (
                    <Badge
                      label={values.state.label}
                      backgroundColor={values.state.color}
                    />
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        backgroundColor: '#F6F8FA',
                        height: 33,
                        width: 100,
                      }}
                    />
                  )}

                  {values.category ? (
                    <Badge label={values.category.label} />
                  ) : (
                    <Box
                      sx={{
                        borderRadius: 0.5,
                        backgroundColor: '#F6F8FA',
                        height: 33,
                        width: 100,
                      }}
                    />
                  )}
                </Box>
              </Stack>

              <Tooltip title={user.name}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    sx={{ fontSize: 10, whiteSpace: 'nowrap', opacity: 0.8 }}
                    variant="subtitle2"
                  >
                    Creado por
                  </Typography>

                  <Avatar
                    src={user.picture}
                    sx={{ width: 25, height: 25, ml: 1 }}
                  />
                </Box>
              </Tooltip>
            </Stack>
          </Stack>

          <Stack>
            <Box
              sx={{
                borderRadius: 1,
                backgroundColor: '#F6F8FA',
                height: 60,
                width: '100%',
              }}
            />

            <Box
              sx={{
                borderRadius: 1,
                backgroundColor: '#E9F0F6',
                height: 60,
                width: '100%',
              }}
            />

            <Box
              sx={{
                borderRadius: 1,
                backgroundColor: '#DCE4EC',
                height: 60,
                width: '100%',
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};
