import React from 'react';

import Page from 'src/components/v2/Page';
import SalesTable from 'src/tables/SalesTable';

export const Sales = () => (
  <Page title="Honorarios | LegalSurf">
    <SalesTable />
  </Page>
);
