import React from 'react';

import { SelectField } from 'src/components/ui-components/Form';
import { ReconnectGoogleCalendarButton } from 'src/components/v3/CalendarCard/ReconnectGoogleCalendarButton';
import { Box, InputAdornment, Stack } from '@mui/material';
import googelCalendarIcon from 'src/assets/images/googlecalendar.png';
import { useGoogleCalendars } from './useGoogleCalendars';

const GoogleCalendarsAutocomplete = ({
  disabled,
  label = 'Calendarios',
  placeholder = 'Ej. Calendario #2',
  calendar,
  ...rest
}) => {
  const { loading, calendars } = useGoogleCalendars();

  const needsResyncProps = calendar?.googleCalendarNeedsResync
    ? {
        error: true,
        helperText: (
          <Stack alignItems="center" direction="row" gap={1}>
            Error al cargar los calendarios de Google
            <ReconnectGoogleCalendarButton calendar={calendar} />
          </Stack>
        ),
      }
    : undefined;

  return (
    <SelectField
      disabled={disabled}
      inputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Box
              component="img"
              src={googelCalendarIcon}
              sx={{ width: 24, height: 24 }}
            />
          </InputAdornment>
        ),
      }}
      label={label}
      loading={loading}
      options={calendars}
      placeholder={placeholder}
      {...needsResyncProps}
      {...rest}
    />
  );
};

export default GoogleCalendarsAutocomplete;
