import React from 'react';
import { SvgIcon } from '@mui/material';

const LSMail = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49561 6.08948L11.445 11.2677C11.7515 11.5884 12.2484 11.5884 12.5549 11.2677L13.3262 12.0747L12.5549 11.2677L17.5044 6.08948C16.375 5.55797 14.6522 5.2825 12 5.2825C9.3478 5.2825 7.62498 5.55797 6.49561 6.08948ZM19.1392 7.60707L14.0976 12.8817C12.9391 14.0937 11.0609 14.0937 9.90239 12.8817L4.86084 7.60708C4.41564 8.45201 4.18164 9.67205 4.18164 11.5C4.18164 14.2138 4.69552 15.5919 5.66649 16.3939C6.72248 17.2661 8.6021 17.7175 12 17.7175C15.3979 17.7175 17.2775 17.2661 18.3335 16.3939C19.3045 15.5919 19.8184 14.2138 19.8184 11.5C19.8184 9.67205 19.5844 8.452 19.1392 7.60707ZM4.04253 5.05508C5.73288 3.45756 8.40724 3 12 3C15.5928 3 18.2671 3.45756 19.9575 5.05508C21.5249 6.53646 22 8.74766 22 11.5C22 14.4135 21.4657 16.7148 19.6853 18.1854C17.99 19.5857 15.415 20 12 20C8.58499 20 6.01003 19.5857 4.31465 18.1854C2.53426 16.7148 2 14.4135 2 11.5C2 8.74766 2.47507 6.53646 4.04253 5.05508Z"
    />
  </SvgIcon>
);

export default LSMail;
