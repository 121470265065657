import { WhatsApp } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import {
  exampleEntityForWhatsApp,
  isValidWhatsappNumber,
  sendWhatsappMsg,
} from 'src/utils/notificationTemplates';
import { NotificationTemplatesPopover } from '.';

export const WhatsAppButton = ({
  phone,
  entity = exampleEntityForWhatsApp,
  data,
  fontSize = 24,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (event) => {
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const isWhatsappValid = isValidWhatsappNumber(phone);
  const tooltipTitle = isWhatsappValid
    ? phone
    : 'Se abrira la plantilla en su WhatsApp para que pueda seleccionar los contactos a los que quiera enviar el mensaje';

  return (
    <>
      <Box
        ref={anchorEl}
        sx={{ display: 'flex', alignItems: 'center', gap: 1, ...props?.sx }}
        {...props}
      >
        <Tooltip placement="left" title={tooltipTitle}>
          <span>
            <IconButton
              color="inherit"
              size="small"
              variant="text"
              onClick={handleOpenFilecasesPopover}
            >
              <WhatsApp
                sx={{
                  color: '#24d366',
                  fontSize: fontSize,
                  opacity: isWhatsappValid ? 1 : 0.8,
                }}
              />
            </IconButton>
          </span>
        </Tooltip>
      </Box>

      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        entity={entity}
        data={data}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg(phone, msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />
    </>
  );
};
