import React from 'react';
import { Box, Dialog } from '@mui/material';

import FilecaseDetail from '../../components/v2/FilecaseDetail';

const FilecaseDetailsDialog = ({ id, open, onClose }) => (
  <Dialog
    fullScreen
    open={open}
    sx={{ width: 700, ml: 'auto' }}
    onClose={onClose}
  >
    <Box sx={{ overflowY: 'scroll' }}>
      <FilecaseDetail filecaseId={id} onHide={onClose} />
    </Box>
  </Dialog>
);

export default FilecaseDetailsDialog;
