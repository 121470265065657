import React from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { Typography, Box } from '@mui/material';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ backgroundColor: 'white', padding: 1, borderRadius: 2 }}>
        <Typography variant="body1">
          {payload[0].payload.name}: {payload[0].value}
        </Typography>
      </Box>
    );
  }

  return null;
};
const TreemapChart = ({ file }) => {
  const filecases = Object.entries(file).map(([key, value]) => ({
    name: key,
    value,
  }));

  return (
    <>
      <Typography sx={{ backgroundColor: 'common.white', padding: '2rem' }}>
        Expedientes por Etiquetas
      </Typography>
      <ResponsiveContainer height={400} width="100%">
        <Treemap data={filecases} dataKey="value">
          <Tooltip content={<CustomTooltip />} />
        </Treemap>
      </ResponsiveContainer>
    </>
  );
};

export default TreemapChart;
