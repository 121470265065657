import { useCustomFields } from 'src/dialogs/CustomFieldFormDialog/utils';
import { specialKeyWords } from 'src/utils/notificationTemplates';

const mapCustomField2SpecialKeyword = (customField) => {
  const arr = [];

  const obj = {
    key: `[${customField.name.replace(/ /g, '_')}]`,
    path: customField.name,
    customField: true,
  };

  if (customField.onEntities) {
    arr.push({
      ...obj,
      model: 'entity',
    });
  }

  if (customField.onFilecases) {
    arr.push({
      ...obj,
      model: 'filecase',
    });
  }

  return arr;
};

const modelOrder = {
  entity: 1,
  filecase: 2,
  event: 3,
  sale: 4,
};

const sortKeywordsByModel = (a, b) => {
  if (modelOrder[a.model] < modelOrder[b.model]) {
    return -1;
  }

  if (modelOrder[a.model] > modelOrder[b.model]) {
    return 1;
  }

  return 0;
};

export const useSpecialKeywords = () => {
  const { customFields } = useCustomFields();

  const arr = [
    ...specialKeyWords,
    ...customFields.map(mapCustomField2SpecialKeyword).flat(),
  ];

  arr.sort(sortKeywordsByModel);

  return arr;
};

export const groupLabels = {
  date: 'Fecha de hoy...',
  entity: 'Directorio...',
  event: 'Evento...',
  filecase: 'Expediente...',
  sale: 'Honorario...',
  studio: 'Estudio...',
  user: 'Usuario actual...',
};

export const useGroupedSpecialKeywords = () => {
  const keywords = useSpecialKeywords();

  const groupedKeywords = keywords.reduce((acc, keyword) => {
    if (!acc[keyword.model]) {
      acc[keyword.model] = [];
    }

    acc[keyword.model].push(keyword);

    return acc;
  }, {});

  return Object.entries(groupedKeywords).map(([model, _keywords]) => ({
    model,
    keywords: _keywords,
    label: groupLabels[model],
  }));
};
