import React from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { getTableTasksQuery } from './gql';
import Header, {
  taskTableHeaderDefaultInitialValues,
  useTaskFilters,
} from './Header';
import { Board } from './Board';
import { Table } from './Table';
import { useUser } from 'src/utils/hooks/useUser';

export const TaskBoard = () => {
  const studioId = useStudioId();
  const { user } = useUser();
  const { filters } = useTaskFilters();

  const queryVariables = {
    studioId,
    text: filters.text,
    start: filters.start ? dayjs(filters.start).startOf('day').toDate() : null,
    end: filters.end ? dayjs(filters.end).endOf('day').toDate() : null,
    calendars: filters.calendars?.map((val) => val?.value ?? val),
    filecases: filters.filecases?.map((val) => val?.value ?? val),
    assigned:
      filters.assigned?.map((val) => val?.value ?? val) ||
      (filters.onlyMe ? [user.id] : null),
    state:
      filters?.viewType === 'board'
        ? taskTableHeaderDefaultInitialValues.state
        : filters.state || ['pending'],
  };

  const {
    data: { events } = { events: [] },
    loading,
    refetch,
  } = useQuery(getTableTasksQuery, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !studioId,
  });

  return (
    <Stack direction="column" gap={1}>
      <Header
        loading={loading}
        subtitle={`Totales ${events.length}`}
        title="Tareas"
      />

      {filters?.viewType === 'board' ? (
        <Board events={events} loading={loading} />
      ) : (
        <Table events={events} loading={loading} refetch={refetch} />
      )}
    </Stack>
  );
};
