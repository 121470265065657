import React from 'react';
import { Box, Typography } from '@mui/material';

export const Linkify = ({ text }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const tokens = text.split(urlRegex);

  return (
    <Box component="span" sx={{ whiteSpace: 'break-spaces' }}>
      {tokens.map((token, index) => {
        if (urlRegex.test(token)) {
          return (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{
                display: 'inline',
                textOverflow: 'ellipsis',
                whiteSpace: 'noWrap',
                maxWidth: '32rem',
                overflow: 'hidden',
              }}
            >
              <Typography
                color="textSecondary"
                component="a"
                href={token}
                rel="noreferrer"
                sx={{ underline: 'none', color: 'primary' }}
                target="_blank"
              >
                {token}
              </Typography>
            </Box>
          );
        }

        return token;
      })}
    </Box>
  );
};
