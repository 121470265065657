import React from 'react';

import { Box, Typography, Grow } from '@mui/material';

import TreasuryReportsViewCharts from './TreasuryReportsViewCharts';

const TreasuryReportsView = () => (
  <Box sx={{ padding: 5 }}>
    <Grow in>
      <Typography
        mb={5}
        sx={{ display: 'flex', justifyContent: 'center' }}
        variant="h2"
      >
        Informe tesoreria
      </Typography>
    </Grow>

    <Grow in>
      <Typography
        mb={5}
        sx={{ display: 'flex', justifyContent: 'center' }}
        variant="h6"
      >
        Entiende como fluye la plata en tu estudio
      </Typography>
    </Grow>

    <TreasuryReportsViewCharts />
  </Box>
);

export default TreasuryReportsView;
