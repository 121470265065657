import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const DocumentsSearchQuery = gql`
  query DocumentsSearchQuery($studioId: ID!) {
    documents(studioId: $studioId) {
      id
      name
      kind
    }
  }
`;

export const SearchableDocuments = ({
  disabled,
  label = 'Buscar documento',
  placeholder = 'Ej. Documento identidad',
  ...rest
}) => {
  const studioId = useStudioId();
  const { loading, data } = useQuery(DocumentsSearchQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      studioId,
    },
  });

  const documents = useMemo(
    () =>
      data?.documents?.map((doc) => ({
        value: doc.id,
        label: doc.name,
        document: doc
      })) || [],
    [data],
  );

  return (
    <SelectField
      disabled={disabled || loading}
      label={label}
      loading={loading}
      options={documents}
      placeholder={placeholder}
      {...rest}
    />
  );
};
