import React from 'react';

import EntitiesPopoverBody from './EntitiesPopoverBody';
import { PopoverBase } from '../PopoverBase';

const EntitiesPopover = ({
  loading,
  anchorEl,
  onSubmit,
  open,
  entities,
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
  ...props
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    open={open}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <EntitiesPopoverBody
      entities={entities}
      loading={loading}
      onSubmit={onSubmit}
      {...props}
    />
  </PopoverBase>
);

export default EntitiesPopover;
