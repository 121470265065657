import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import LSClock from 'src/components/icons/LSClock';
import { CommentTooltip } from '../Comment/CommentTooltip';
import { entityTypesObj } from '@legalsurf/common';

const ensureArray = (value) => {
  if (!value) return [];
  if (Array.isArray(value)) return value;
  return [value];
};

const formatValue = (value) => {
  if (value === null || value === undefined) {
    return '(vacío)';
  }

  if (typeof value === 'object') {
    // If it's an array, join the values
    if (Array.isArray(value)) {
      return value.map(formatValue).join(', ');
    }
    // If it has a name or displayName property, use that
    if (value.displayName) return value.displayName;
    if (value.name) return value.name;
    // Otherwise try to get a meaningful string representation
    try {
      const str = JSON.stringify(value);
      return str === '{}' ? '(vacío)' : str;
    } catch {
      return '(valor complejo)';
    }
  }
  if (value === '') return '(vacío)';
  return String(value);
};

const humanizeFieldName = (fieldName) => {
  const fieldMappings = {
    displayname: 'nombre',
    description: 'descripción',
    email: 'email',
    phone: 'teléfono',
    address: 'dirección',
    status: 'estado',
    title: 'título',
    // Add more field mappings as needed
  };

  return fieldMappings[fieldName.toLowerCase()] || fieldName;
};

export const getDetailedChanges = (log) => {
  if (!log) return [];
  const { operation, after, before } = log;
  const changes = [];

  if (operation === 'create') {
    const entityType = after?.type?.toLowerCase();
    const typeName = entityTypesObj[entityType] || 'directorio';

    const displayName = after?.displayName || after?.name;
    changes.push(
      `Creó ${displayName ? `${typeName} "${displayName}"` : `una nueva ${typeName}`}`,
    );
    return changes;
  }

  if (operation === 'update') {
    let changesDetected = false;

    const allFields = new Set([
      ...Object.keys(before || {}),
      ...Object.keys(after || {}),
    ]);

    const ignoredFields = new Set([
      'updatedAt',
      'createdAt',
      'firstname',
      'lastname',
      'businessname',
      'id',
      'type',
      'user',
    ]);

    const relationshipFields = {
      filecases: {
        singular: 'expediente',
        plural: 'expedientes',
        titleField: 'title',
      },
      sales: {
        singular: 'venta',
        plural: 'ventas',
        titleField: 'description',
      },
      expenses: {
        singular: 'gasto',
        plural: 'gastos',
        titleField: 'description',
      },
      documents: {
        singular: 'documento',
        plural: 'documentos',
        titleField: 'name',
      },
      tags: {
        singular: 'etiqueta',
        plural: 'etiquetas',
        titleField: 'name',
      },
      state: {
        singular: 'estado',
        plural: 'estados',
        titleField: 'name',
      },
      assigned: {
        singular: 'asignado',
        plural: 'asignados',
        titleField: 'name',
      },
    };

    // Handle regular field changes
    allFields.forEach((field) => {
      if (!ignoredFields.has(field) && !relationshipFields[field]) {
        const beforeVal = before?.[field];
        const afterVal = after?.[field];

        const fieldLabel = humanizeFieldName(field);
        changes.push(
          `Actualizó ${fieldLabel} de "${formatValue(beforeVal)}" a "${formatValue(afterVal)}"`,
        );
      }
    });

    // Handle relationship changes
    Object.entries(relationshipFields).forEach(([field, config]) => {
      if (before?.[field] || after?.[field]) {
        // Safely convert to arrays
        const beforeItems = ensureArray(before?.[field]);
        const afterItems = ensureArray(after?.[field]);

        // Safe mapping with ID extraction
        const beforeIds = new Set(
          beforeItems.map((item) => item?.id).filter(Boolean),
        );
        const afterIds = new Set(
          afterItems.map((item) => item?.id).filter(Boolean),
        );

        const added = afterItems.filter(
          (item) => item?.id && !beforeIds.has(item.id),
        );
        const removed = beforeItems.filter(
          (item) => item?.id && !afterIds.has(item.id),
        );

        if (added.length > 0 || removed.length > 0) {
          changesDetected = true;

          // Handle removals
          if (removed.length > 0) {
            if (
              removed.length === beforeItems.length &&
              afterItems.length === 0
            ) {
              changes.push(
                `Desvinculó todos los ${config.plural} (${removed.length})`,
              );
            } else if (removed.length <= 3) {
              removed.forEach((item) => {
                const itemTitle = formatValue(item[config.titleField]);
                changes.push(`Desvinculó ${config.singular}: "${itemTitle}"`);
              });
            } else {
              changes.push(`Desvinculó ${removed.length} ${config.plural}`);
            }
          }

          // Handle additions
          if (added.length > 0) {
            if (added.length <= 3) {
              added.forEach((item) => {
                const itemTitle = formatValue(item[config.titleField]);
                changes.push(`Vinculó ${config.singular} "${itemTitle}"`);
              });
            } else {
              changes.push(`Vinculó ${added.length} ${config.plural}`);
            }
          }
        }
      }
    });

    // If no specific changes were detected, provide a more detailed message
    if (!changesDetected && changes.length === 0) {
      const changedFields = [...allFields]
        .filter(
          (field) =>
            !ignoredFields.has(field) && before?.[field] !== after?.[field],
        )
        .map(humanizeFieldName);

      if (changedFields.length > 0) {
        changes.push(`Actualizó campos: ${changedFields.join(', ')}`);
      }
    }
  }

  if (operation === 'delete') {
    const entityType = before?.type?.toLowerCase();
    const typeName = entityTypesObj[entityType] || 'directorio';
    const displayName = before?.displayName || before?.name;

    changes.push(
      `Eliminó ${displayName ? `${typeName} "${displayName}"` : `la ${typeName}`}`,
    );
  }

  return changes;
};

export const getSimpleSummary = (log) => {
  if (!log) return '';

  const changes = getDetailedChanges(log);
  const fromNowstr = dayjs(log.createdAt).fromNow();

  // Special case for bulk operations
  const firstChange = changes[0] || '';
  if (
    firstChange.includes('todos los expedientes') ||
    firstChange.includes('Desvinculó todos los')
  ) {
    return `${fromNowstr} se ${firstChange.toLowerCase()}`;
  }

  // Special case for relationship changes
  if (
    changes.length > 1 &&
    changes.every(
      (change) =>
        change.startsWith('Vinculó') || change.startsWith('Desvinculó'),
    )
  ) {
    const action = changes[0].startsWith('Vinculó') ? 'vinculó' : 'desvinculó';
    const type = changes[0].split(' ')[1]; // gets "expediente" or "documento", etc.
    return `${fromNowstr} se ${action} múltiples ${type}s`;
  }

  // Default cases
  if (changes.length === 0) {
    return `${fromNowstr} realizó cambios no especificados`;
  }

  if (changes.length === 1) {
    return `${fromNowstr} se ${changes[0].toLowerCase()}`;
  }

  return `${fromNowstr} se realizó ${changes.length} cambios`;
};

export const LogCell = ({ log }) => {
  if (!log) return null;

  const changes = getDetailedChanges(log);
  const summary = getSimpleSummary(log);

  const tooltipContent = (
    <Stack p={1} gap={1}>
      <Stack direction="row" gap={1} alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <Avatar
            alt={log.user.name}
            src={log.user.picture}
            sx={{ height: 28, width: 28 }}
          />

          <Typography variant="subtitle2" color="textSecondary">
            {log.user.name}
          </Typography>
        </Stack>

        <Typography variant="subtitle2" color="textSecondary">
          -
        </Typography>

        <Typography variant="subtitle2" color="textSecondary">
          {dayjs(log.createdAt).fromNow()}
        </Typography>
      </Stack>

      {changes.join('\n').trim()}
    </Stack>
  );

  return (
    <CommentTooltip
      placement="left"
      title={tooltipContent}
      slotProps={{
        tooltip: {
          sx: {
            whiteSpace: 'break-spaces',
            fontSize: 14,
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={0.5}>
        <LSClock color="primary" sx={{ fontSize: '1.25rem' }} />

        <Box whiteSpace="break-spaces" lineHeight="1rem">
          {summary}
        </Box>
      </Stack>
    </CommentTooltip>
  );
};
