import { gql } from '@apollo/client';

export const CreateNotificationTemplateMutation = gql`
  mutation CreateNotificationTemplateMutation(
    $studioId: ID!
    $name: String!
    $body: String!
  ) {
    createNotificationTemplate(studioId: $studioId, name: $name, body: $body) {
      id
      name
      body
      studioId
    }
  }
`;

export const UpdateNotificationTemplateMutation = gql`
  mutation UpdateNotificationTemplateMutation(
    $studioId: ID!
    $id: ID!
    $name: String!
    $body: String!
  ) {
    updateNotificationTemplate(
      studioId: $studioId
      id: $id
      name: $name
      body: $body
    ) {
      id
      name
      body
      studioId
    }
  }
`;

export const DeleteNotificationTemplateMutation = gql`
  mutation DeleteNotificationTemplateMutation($id: ID!, $studioId: ID!) {
    deleteNotificationTemplate(id: $id, studioId: $studioId) {
      id
      name
      body
      studioId
    }
  }
`;
