import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import Form from 'src/components/ui-components/Form';
import { StudioMembersAutocomplete } from 'src/autocompletes';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { Visibility } from '@mui/icons-material';

const GiveFilecaseSeePermissionsMutation = gql`
  mutation GiveFilecaseSeePermissionsMutation(
    $studioId: ID!
    $canBeSeenBy: [ID!]
    $filecases: [ID!]
  ) {
    updateFilecasesCanBeSeenBys(
      studioId: $studioId
      canBeSeenBy: $canBeSeenBy
      filecases: $filecases
    ) {
      id
    }
  }
`;

const GiveFilecasesSeePermissionsModalSchemaValidation = Yup.object({
  filecases: Yup.array().min(1, 'Debes seleccionar al menos un expediente'),
  canBeSeenBy: Yup.array().min(1, 'Debes seleccionar al menos un usuario'),
});

const AllFilecases = gql`
  query AllFilecases($studioId: ID!) {
    filecases(studio: $studioId) {
      id
    }
  }
`;

export const GiveFilecasesSeePermissionsModal = ({
  filecases,
  open,
  onClose: handleClose,
  onSuccess,
}) => {
  const { openSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const studioId = useStudioId();

  const [getAllFilecases] = useLazyQuery(AllFilecases, {
    fetchPolicy: 'network-only',
    variables: {
      studioId,
    },
  });
  const [giveFilecaseSeePermissions] = useMutation(
    GiveFilecaseSeePermissionsMutation,
  );

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    let allFilecases;
    const areAllFilecasesSelected =
      filecases?.length === 1 && filecases[0]?.value === 'all';

    if (areAllFilecasesSelected) {
      allFilecases = (await getAllFilecases())?.data?.filecases;
    }

    await giveFilecaseSeePermissions({
      variables: {
        studioId,
        filecases: areAllFilecasesSelected
          ? allFilecases.map((filecase) => filecase.id)
          : filecases,
        canBeSeenBy: values.canBeSeenBy?.map((member) => member.value),
      },
    })
      .then(() => {
        openSnackbar('Permisos agregados correctamente', {
          severity: 'success',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });

    onSuccess();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle sx={{ display: 'flex', gap: 1 }}>
        <Visibility color="primary" /> Dar permisos ({filecases?.length}{' '}
        expedientes)
      </DialogTitle>

      <DialogContent sx={{ pt: 5 }}>
        <DialogContentText>
          Selecciona a los nuevos miembros de tu equipo a los que quieras darle
          acceso a estos expedientes:
        </DialogContentText>

        <Formik
          initialValues={{ canBeSeenBy: [], filecases }}
          validationSchema={GiveFilecasesSeePermissionsModalSchemaValidation}
          onSubmit={handleSubmit}
        >
          <Form id="giveFilecasesSeePermissionsForm" sx={{ pt: 2 }}>
            <StudioMembersAutocomplete label="Usuarios" name="canBeSeenBy" />
          </Form>
        </Formik>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>

        <Button
          disabled={isSubmitting}
          form="giveFilecasesSeePermissionsForm"
          loading={isSubmitting}
          type="submit"
          variant="contained"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
