import React from 'react';
import { Box, Typography } from '@mui/material';

import { getEntityType } from 'src/utils/formatters';

const TypeCell = ({ row }) => (
  <Box
    sx={{
      maxWidth: '40rem',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      gap: 1,
    }}
  >
    <Typography
      role="button"
      sx={{
        fontWeight: '500',
      }}
    >
      {getEntityType(row.type)}
    </Typography>
  </Box>
);

export default TypeCell;
