import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDialog } from 'src/dialogs/Dialogs';
import { getColumnEventId } from 'src/components/v3/DraggableColumn';
import { TaskCard } from 'src/components/v3/TaskCard';

import { areEqual, FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import { DEFAULT_TASK_STATES } from '@legalsurf/common';
import { useUpdateCalendarEventStatus } from '@legalsurf/hooks';

import StateColumnHeader from './StateColumnHeader';

const boardTaskStates = DEFAULT_TASK_STATES.filter(
  (state) => state.value !== 'finished',
);

const sortEventsByDate = (tasks) =>
  [...(tasks || [])].sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

const splitEventsByState = (events) => ({
  pending: sortEventsByDate(
    events.filter((event) => event.state === 'pending'),
  ),
  wip: sortEventsByDate(events.filter((event) => event.state === 'wip')),
  done: sortEventsByDate(events.filter((event) => event.state === 'done')),
  reviewing: sortEventsByDate(
    events.filter((event) => event.state === 'reviewing'),
  ),
});

function getStyle({ draggableStyle, virtualStyle, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...virtualStyle,
    ...draggableStyle,
  };

  // Being lazy: this is defined in our css file
  const grid = 8;

  // when dragging we want to use the draggable style for placement, otherwise use the virtual style
  const result = {
    ...combined,
    height: isDragging ? combined.height : combined.height - grid,
    left: isDragging ? combined.left : combined.left + grid,
    width: isDragging
      ? draggableStyle.width
      : `calc(${combined.width} - ${grid * 2}px)`,
    marginBottom: grid,
  };

  return result;
}

const Row = React.memo(function Row(props) {
  const { data: items, index, style } = props;
  const dispatchEventDialog = useDialog('event');
  const item = items[index];

  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={getColumnEventId(item)} index={index} key={item.id}>
      {(provided, snapshot) => (
        <TaskCard
          {...item}
          provided={provided}
          ref={provided.innerRef}
          onClick={() =>
            dispatchEventDialog(getColumnEventId(item), {
              id: item.id,
              calendarId: item.calendarId,
            })
          }
          isDragging={snapshot.isDragging}
          sx={getStyle({
            draggableStyle: provided.draggableProps.style,
            virtualStyle: style,
            isDragging: snapshot.isDragging,
          })}
        />
      )}
    </Draggable>
  );
}, areEqual);

const ItemList = React.memo(function ItemList({ state, events, width, index }) {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index
  const listRef = useRef();

  useLayoutEffect(() => {
    const list = listRef.current;
    if (list) {
      list.scrollTo(0);
    }
  }, [index]);

  return (
    <Droppable
      droppableId={state.value}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <TaskCard
          provided={provided}
          isDragging={snapshot.isDragging}
          {...events[rubric.source.index]}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? events.length + 1
          : events.length;

        return (
          <Stack
            pt={1}
            borderRadius={1}
            bgcolor={snapshot.isDraggingOver ? 'background.main' : ''}
          >
            <FixedSizeList
              height={180 * 4}
              itemCount={itemCount}
              itemSize={180}
              width={width - 16}
              outerRef={provided.innerRef}
              itemData={events}
              ref={listRef}
            >
              {Row}
            </FixedSizeList>
          </Stack>
        );
      }}
    </Droppable>
  );
});

const Column = React.memo(function Column({ state, events, width, index }) {
  return (
    <Stack className="column" gap={1}>
      <StateColumnHeader state={state}>{state.label}</StateColumnHeader>

      <ItemList width={width} state={state} events={events} index={index} />
    </Stack>
  );
});

export const Board = ({ events }) => {
  const studioId = useStudioId();
  const [updateCalendarEventStatus] = useUpdateCalendarEventStatus();

  // TODO: MOVE TO THE SERVER?
  const splittedEvents = useMemo(() => splitEventsByState(events), [events]);

  const handleDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // Re ordering inside the same origin column
    if (result.type === 'COLUMN') {
      console.log('Update the order from the event ');

      return;
    }

    const [eventId, calendarId] = result.draggableId.split('_');

    // Re ordering from other origin column
    const currentEvent = events.find(
      (event) => event.id === eventId && event.calendarId === calendarId,
    );

    updateCalendarEventStatus({
      variables: {
        calendarId: currentEvent.calendarId,
        id: currentEvent.id,
        state: result.destination.droppableId,
      },
      optimisticResponse: {
        updateEventStatus: {
          __typename: 'Event',
          id: currentEvent.id,
          studioId,
          calendarId: currentEvent.calendarId,
          state: result.destination.droppableId,
          type: 'task',
          updatedAt: new Date().toISOString(),
        },
      },
    });
  };

  return (
    <Card>
      <CardContent>
        <DragDropContext onDragEnd={handleDragEnd}>
          <AutoSizer disableHeight>
            {({ width }) => (
              <Stack direction="row" gap={2} flexGrow={1}>
                {boardTaskStates.map((state, index) => (
                  <Column
                    width={width / boardTaskStates.length}
                    key={state?.value}
                    index={index?.value}
                    state={state}
                    events={splittedEvents[state.value]}
                  />
                ))}
              </Stack>
            )}
          </AutoSizer>
        </DragDropContext>
      </CardContent>
    </Card>
  );
};
