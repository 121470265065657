import React from 'react';
import { Card, CardContent } from '@mui/material';

import { StudioConfigurationForm } from 'src/forms/StudioConfigurationForm';
import Page from 'src/components/v2/Page';

export const StudioConfiguration = () => (
  <Page title="Configuración del Estudio | LegalSurf">
    <Card sx={{ width: 800, maxWidth: '100%', margin: '0 auto' }}>
      <CardContent>
        <StudioConfigurationForm />
      </CardContent>
    </Card>
  </Page>
);
