import { useMutation, MutationHookOptions } from '@apollo/client';
import { CreateTodo } from '@legalsurf/queries';

import { UseCreateEventTodoHookVariables, UseCreateTodoData } from '../types';

export const useCreateTodo = (
  options: MutationHookOptions<
    UseCreateTodoData,
    UseCreateEventTodoHookVariables
  >,
) =>
  useMutation(CreateTodo, {
    update: (cache, result, { variables }) => {
      cache.modify({
        id: cache.identify({
          id: variables.eventId,
          calendarId: variables.calendarId,
          __typename: 'Event',
        }),
        fields: {
          todos(todos = []) {
            return [
              ...todos,
              { __ref: cache.identify(result.data.createEventTodo) },
            ];
          },
        },
      });
    },
    ...options,
  });
