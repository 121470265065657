import React, { useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { useSnackbar } from 'src/components/v3/Snackbar';

import { useBreakpoints } from 'src/utils/hooks/common';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { getNotificationTemplatesQuery } from 'src/tables/TemplateMessageTable/queries';
import { LSX } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import { NotificationTemplateForm } from './NotificationTemplateForm';
import {
  CreateNotificationTemplateMutation,
  DeleteNotificationTemplateMutation,
  UpdateNotificationTemplateMutation,
} from './gql';

const NotificationTemplateFormDialog = ({
  open,
  onClose,
  defaultAssigned,
  ...props
}) => {
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();
  const { isMobile } = useBreakpoints();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deleteNotificationTemplate] = useMutation(
    DeleteNotificationTemplateMutation,
  );

  const [createNotificationTemplate] = useMutation(
    CreateNotificationTemplateMutation,
    {
      refetchQueries: [getNotificationTemplatesQuery],
    },
  );

  const handleDeleteNotificationTemplate = async () => {
    try {
      await deleteNotificationTemplate({
        variables: {
          id: props.id,
          studioId,
        },
        update: (cache, { data }) => {
          cache.evict({
            id: cache.identify(data.deleteNotificationTemplate),
          });

          cache.gc();
        },
      });
      onClose();

      openSnackbar('Se ha eliminado correctamente.', {
        severity: 'success',
        placementType: 'bottom-right',
      });
    } catch (error) {
      openSnackbar('Hubo un error, intente mas tarde', {
        severity: 'error',
      });
    }
  };

  const [updateNotificationTemplate] = useMutation(
    UpdateNotificationTemplateMutation,
    {
      refetchQueries: [getNotificationTemplatesQuery],
    },
  );

  const handleSubmit = async (values, formikBag) => {
    const isEdit = !!props.id;
    const mutation = isEdit
      ? updateNotificationTemplate
      : createNotificationTemplate;

    const query = {
      variables: {
        studioId,
        name: values.name,
        body: values.body,
      },
    };

    if (isEdit) {
      query.variables.id = props.id;
    }

    try {
      await mutation(query);

      onClose();
      formikBag.resetForm();
      openSnackbar(
        isEdit ? 'Plantilla editada con exito.' : 'Plantilla creada con exito.',
        {
          severity: 'success',
        },
      );
    } catch (error) {
      openSnackbar(error.message ?? 'Hubo un error, intente mas tarde.', {
        severity: 'error',
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  // TODO: Refactor, too much
  const initialValues = useMemo(
    () => ({
      id: props.id,
      name: props.name || '',
      body: props.body || 'Hola [persona_nombre] 😁💵📂🔥',
    }),
    [props.body, props.id, props.name],
  );

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: 650,
        },
      }}
      fullScreen={isMobile}
      open={open}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Formulario plantilla
        <IconButton onClick={onClose}>
          <LSX />
        </IconButton>
      </DialogTitle>

      <NotificationTemplateForm
        enableReinitialize
        hideAssigned={!!defaultAssigned}
        initialValues={initialValues}
        onCancel={onClose}
        onDeleteClick={() =>
          dispatchWarningPromptDialog(props.id, {
            onAccept: handleDeleteNotificationTemplate,
          })
        }
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default NotificationTemplateFormDialog;
