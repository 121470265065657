import React from 'react';
import { Grid, FormControl } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';

import { DatePicker } from './DatePicker';

const DateField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  noFormControlLabel,
  onChange,
  size,
  ...rest
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, form] = useField(name);

  return (
    <Grid item {...gridProps}>
      <FormControl sx={{ width: '100%' }}>
        <DatePicker
          {...rest}
          {...field}
          disabled={form.isSubmitting || form.isValidating || disabled}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error}
          name={name}
          size={size}
          value={field.value ? dayjs(field.value) : null}
          onBlur={() => form.setTouched(true)}
          onChange={(date) => {
            setFieldValue(field.name, date ? dayjs(date).toDate() : null);

            if (onChange) {
              onChange(date);
            }
          }}
        />
      </FormControl>
    </Grid>
  );
};

export default DateField;
