import { useMutation, MutationHookOptions } from '@apollo/client';
import { UpdateFilecaseAssigneesMutation } from '@legalsurf/queries';

export const useUpdateFilecaseAssignees = (
  options: MutationHookOptions<any>,
) => {
  return useMutation(UpdateFilecaseAssigneesMutation, {
    ...options,
  });
};
