import React, { useMemo } from 'react';
import { Box } from '@mui/material';

import {
  getSpecialKeyWordBackground,
  specialKeyWords,
} from 'src/utils/notificationTemplates';
import { multipleEntitySpecialWordColors } from 'src/popovers/SpecialWordPopover/SpecialWordPopoverBody/MultipleEntitySpecialWordPopover/MultipleEntitySpecialWordPopoverBody';
import { useRichEditorSpecialKeywordContext } from 'src/dialogs/ContractPreviewDialog/RichEditorSpecialKeywordProvider';

export const getCustomFieldDataInSpecialKeyword = (key, data) => {
  if (!data?.customFields?.length) {
    return null;
  }

  let normalizedName = key.replace(/_/g, ' ');
  normalizedName = normalizedName.slice(1, normalizedName.length - 1);

  return data?.customFields?.find((field) => field.name === normalizedName);
};

export const RichEditorSpecialKeyword = ({ payload }) => {
  const bgcolor = useMemo(() => {
    if (payload.index && !Number.isNaN(Number(payload.index))) {
      return multipleEntitySpecialWordColors[payload.index];
    }

    return getSpecialKeyWordBackground(payload);
  }, [payload]);

  const [specialKeywordContext = {}] = useRichEditorSpecialKeywordContext();

  const label = useMemo(() => {
    const key =
      payload.index && !Number.isNaN(Number(payload.index))
        ? [payload?.model, payload?.index].join('_')
        : payload?.model;

    if (specialKeywordContext[key]) {
      const customFieldData = getCustomFieldDataInSpecialKeyword(
        payload.key,
        specialKeywordContext?.[key]?.data,
      );

      if (customFieldData) {
        return customFieldData.value;
      }

      const replacement = specialKeyWords.find(
        (word) => word.key === payload.key && word.model === payload.model,
      );

      if (!replacement) {
        return payload.key;
      }

      if (replacement.transform) {
        return replacement.transform(
          specialKeywordContext?.[key]?.data?.[replacement.path],
          specialKeywordContext[key].data,
        );
      }

      return specialKeywordContext?.[key]?.data?.[replacement.path];
    }

    return payload.key;
  }, [payload, specialKeywordContext]);

  return (
    <Box
      component="span"
      spellCheck={false}
      sx={{
        bgcolor,
      }}
    >
      {label}
    </Box>
  );
};
