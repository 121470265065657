import React, { useState } from 'react';

import { Typography, Box, Grow, Tooltip, IconButton } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import FilecasesPopover from 'src/popovers/FilecasesPopover';
import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { getFilesTableDocumentsQuery } from '../gql';

const FilecaseCell = ({ row: { filecases, id } }) => {
  const [hoveredCell, setHoveredCell] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { handleUpdateDocument } = useDigitalOceanSpace({
    refetchQueries: [getFilesTableDocumentsQuery],
  });

  const handleOpenFilecasesPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const handleFilecasesPopoverSubmit = (values) =>
    handleUpdateDocument({
      documentId: id,
      filecases: values.filecases?.map(({ value }) => value) || [],
    }).then(handleCloseFilecasesPopover);

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}
      onMouseLeave={() => setHoveredCell(null)}
      onMouseOver={() => setHoveredCell(id)}
    >
      {filecases && (
        <Typography
          sx={{
            maxWidth: 350,
          }}
        >
          {filecases?.map(({ title }) => title).join(', ')}
        </Typography>
      )}

      {!filecases?.length && <Typography>N/A</Typography>}

      <PermissionGuard permissions={[LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE]}>
        <Grow unmountOnExit in={hoveredCell === id}>
          <Tooltip title="Asignar expediente">
            <IconButton size="small" onClick={handleOpenFilecasesPopover}>
              <ArrowDropDown />
            </IconButton>
          </Tooltip>
        </Grow>
      </PermissionGuard>

      <FilecasesPopover
        multiple
        anchorEl={anchorEl}
        filecases={filecases?.map((val) => val.id)}
        open={Boolean(anchorEl)}
        onClose={handleCloseFilecasesPopover}
        onSubmit={handleFilecasesPopoverSubmit}
      />
    </Box>
  );
};

export default FilecaseCell;
