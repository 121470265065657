import React from 'react';

import Page from 'src/components/v2/Page';

import DocumentsTable from 'src/tables/DocumentsTable';

const Files = () => (
  <Page title="Documentos | LegalSurf">
    <DocumentsTable />
  </Page>
);

export default Files;
