import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  PieChart as PieChartCustom,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const renderCustomLabel = ({ cx, cy, midAngle, outerRadius, percent, name }) => {
  const RADIAN = Math.PI / 180;
  const radius = outerRadius + 10; // Adjust this value to add more gap
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      dominantBaseline="central"
      fill="black"
      fontSize={10}
      textAnchor={x > cx ? 'start' : 'end'}
      x={x}
      y={y}
    >
      {`${name}: ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const PieChart = ({ filecases }) => {
  const filecasesChartData = [...filecases].map(([key, value]) => ({
    label: key,
    value: value.count, // Adjusted to directly access the count value
    color: value.filecase.color, // Adjusted to directly access the color
  }));

  return (
    <>
      <Typography sx={{ backgroundColor: 'common.white', padding: 4 }}>
        Expedientes por estado
      </Typography>
      <Box padding={2} sx={{ display: 'flex' }}>
        <ResponsiveContainer height={200} width="100%">
          <PieChartCustom>
            <Tooltip />
            <Legend
              align="left"
              layout="vertical"
              wrapperStyle={{ maxHeight: '100%', overflowY: 'scroll' }}
            />
            <Pie
              data={filecasesChartData}
              dataKey="value"
              innerRadius={80}
              label={renderCustomLabel}
              labelLine={false}
              nameKey="label"
              outerRadius={100}
            >
              {filecasesChartData.map((entry) => (
                <Cell fill={entry.color} key={entry.label} />
              ))}
            </Pie>
          </PieChartCustom>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default PieChart;
