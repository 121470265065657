import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Pressable } from 'src/components/ui-components/Pressable';

const ResourceCard = ({ title, description, sx, cover, href, ...props }) => (
  <Pressable href={href} rel="noopener" sx={{ height: '100%' }} target="_blank">
    <Card
      sx={{
        backgroundImage: `url(${cover})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: ['12rem !important', 'inherit !important'],
        ...sx,
      }}
      {...props}
    >
      <CardContent>
        <Typography color="white" variant="h6">
          {title}
        </Typography>

        <Typography color="white">{description}</Typography>
      </CardContent>
    </Card>
  </Pressable>
);

export default ResourceCard;
