import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Navigate, useSearchParams } from 'react-router-dom';

import { FullPageLoader } from 'src/components/FullPageLoader';

const GetSessionQuery = gql`
  query GetSessionQuery($sessionId: ID!) {
    getSession(sessionId: $sessionId)
  }
`;

const getRedirectURL = (session) => {
  if (!session?.client_reference_id) {
    return `/dashboard/studios`;
  }

  const [userId, studioId] = session.client_reference_id.split('_');

  return `/dashboard/${studioId}/settings/subscription`;
};

export const CallbackStripe = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');

  const { data, loading } = useQuery(GetSessionQuery, {
    variables: {
      sessionId,
    },
  });

  if (loading) {
    return <FullPageLoader />;
  }

  return <Navigate to={getRedirectURL(data?.getSession)} replace />;
};
