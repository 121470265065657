import { gql } from '@apollo/client';

export const ExpensesQuery = gql`
  query ExpensesQuery(
    $studio: ID!
    $filecases: [ID!]
    $entities: [ID!]
    $responsibles: [ID!]
    $currencies: [ID!]
    $tags: [ID!]
  ) {
    expenses(
      studio: $studio
      filters: {
        filecases: $filecases
        entities: $entities
        responsibles: $responsibles
        currencies: $currencies
        tags: $tags
      }
    ) {
      id
      date
      concept
      amount
      paid

      currency {
        id
        name
        studioId
      }

      entity {
        id
        type
        displayName
      }

      responsible {
        id
        picture
        name
      }

      tags {
        id
        name
        studioId
      }
    }
  }
`;

export const SalesQueryFilters = gql`
  query getSalesFilters($studio: ID!) {
    filecases(studio: $studio) {
      id
      title
    }

    tags(studio: $studio) {
      name
      studioId
    }

    studio(id: $studio) {
      id
      members {
        id
        name
      }
    }

    entities(studio: $studio) {
      id
      type
      displayName
      firstname
      lastname
      businessname
    }
  }
`;

export const SalesQuery = gql`
  query SalesQuery(
    $studio: ID!
    $filecases: [ID!]
    $entities: [ID!]
    $responsibles: [ID!]
    $tags: [ID!]
    $start: DateTime
    $end: DateTime
  ) {
    sales(
      studio: $studio
      filters: {
        filecases: $filecases
        start: $start
        end: $end
        entities: $entities
        responsibles: $responsibles
        tags: $tags
      }
    ) {
      id
      date
      concept
      amount
      hours
      charged

      currency {
        id
        name
        studioId
      }

      entity {
        id
        type
        displayName
        phone
      }
      responsible {
        id
        picture
        name
      }
      filecase {
        id
        title
        externalId
        description
        state {
          id
          studioId
          color
          name
        }
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;
