import React from 'react';
import { FixedSizeList } from 'react-window';
import {
  Grid,
  Chip,
  Autocomplete as MuiAutocomplete,
  TextField,
  CircularProgress,
  createFilterOptions,
  Paper,
  Avatar,
  ListSubheader,
  Box,
} from '@mui/material';
import { LSX as LSXIcon } from 'src/components/icons';

export const defaultAutocompleteRenderTags = (tagValue, getTagProps) =>
  tagValue.map((option, index) => {
    const tagProps = getTagProps({ index });

    return (
      <Chip
        {...tagProps}
        avatar={
          option?.picture ? (
            <Avatar alt={option.label} src={option.picture} />
          ) : null
        }
        disabled={option?.disabled || tagProps?.disabled}
        key={option?.id ?? index}
        label={option.name ?? option.label ?? option.value}
        size="small"
        variant="outlined"
        onDelete={!option.disabled ? tagProps.onDelete : undefined}
      />
    );
  });

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  if (dataSet.group) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  return (
    <Box component="li" style={inlineStyle}>
      {dataSet}
    </Box>
  );
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = [];

    children.forEach((item) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSize = 48;

    const getChildSize = (child) => {
      if (child.hasOwnProperty('group')) {
        return 48;
      }
      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    // const gridRef = useResetCache(itemCount);
    const gridRef = React.useRef(null);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <FixedSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={itemSize}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </FixedSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const creatableFilter = createFilterOptions({
  ignoreCase: true,
  ignoreAccents: true,
  trim: true,
  stringify: (option) => {
    if (option?.search) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      return option?.label + option?.search;
    }

    return option.label;
  },
});

const AutocompleteField = ({
  options,
  loading,
  label,
  placeholder,
  gridProps = { xs: 12 },
  helperText,
  error,
  creatable = false,
  freeSolo = false,
  disableCloseOnSelect = false,
  filterOptions,
  multiple,
  renderTags,
  getFilterOptionsWithCreatableLabel,
  value,
  inputProps = {},
  onChange,
  withSelectAll,
  PaperComponent = Paper,
  ...rest
}) => {
  const extendedOptions = withSelectAll
    ? [
        {
          value: 'all',
          label: 'Seleccionar todos',
          isSelectAllOption: true,
        },
        ...options,
      ]
    : options;

  const handleChange = (event, selectedOption) => {
    // Check if "Seleccionar todos" was clicked
    const isSelectAllClicked = withSelectAll
      ? selectedOption.some((option) => option.isSelectAllOption)
      : false;

    // If "Seleccionar todos" is clicked, select/deselect all options
    if (isSelectAllClicked) {
      if (value.length === options.length) {
        // If everything is selected, deselect all
        onChange(event, []);
      } else {
        // Otherwise, select all options except the "Seleccionar todos" one
        onChange(event, options);
      }
    } else {
      // Otherwise, handle selection as usual
      onChange(event, selectedOption);
    }
  };

  const filterOptionsWithCreatable = (unfilteredOptions, params) => {
    const filtered = creatableFilter(unfilteredOptions, params);

    if (params.inputValue !== '') {
      filtered.push({
        value: params.inputValue,
        label: getFilterOptionsWithCreatableLabel
          ? getFilterOptionsWithCreatableLabel(params.inputValue)
          : `+ Agregar "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  return (
    <Grid item {...gridProps} sx={{ minWidth: 160 }}>
      <MuiAutocomplete
        clearIcon={<LSXIcon />}
        disableCloseOnSelect={multiple || disableCloseOnSelect}
        filterOptions={
          creatable
            ? filterOptionsWithCreatable
            : (filterOptions ?? creatableFilter)
        }
        freeSolo={creatable || freeSolo}
        isOptionEqualToValue={(option, val) =>
          option?.value === (val?.value ?? val)
        }
        loading={loading}
        multiple={multiple}
        noOptionsText="No encontramos opciones."
        options={extendedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            InputProps={{
              ...params.InputProps,
              ...inputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={12} />
                  ) : null}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            error={error}
            helperText={helperText}
            label={label}
            placeholder={value ? '' : placeholder}
          />
        )}
        renderTags={renderTags ?? defaultAutocompleteRenderTags}
        sx={{ width: '100%' }}
        value={value}
        onChange={handleChange}
        PaperComponent={PaperComponent}
        ListboxComponent={ListboxComponent}
        {...rest}
      />
    </Grid>
  );
};

export default AutocompleteField;
