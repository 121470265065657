import React, { useMemo } from 'react';

import StartCell from './StartCell';
import StateCell from './StateCell';
import AssignedCell from './AssignedCell';
import EndingCell from './EndingCell';
import TitleCell from './TitleCell';

const columns = (weekly = false) => [
  {
    weekly,
    hideable: false,
    filterable: false,
    valueGetter: (value) => new Date(value),
    disableColumnMenu: true,
    headerName: 'Comienza',
    type: 'date',
    field: 'start',
    minWidth: 140,
    renderCell: StartCell,
  },
  {
    editable: false,
    hideable: false,
    filterable: false,
    disableColumnMenu: true,
    minWidth: 200,
    flex: 1,
    headerName: 'Titulo',
    field: 'title',
    renderCell: TitleCell,
  },
  {
    editable: false,
    hideable: false,
    disableColumnMenu: true,
    filterable: false,
    headerName: 'Estado',
    field: 'state',
    width: 175,
    renderCell: StateCell,
  },
  {
    editable: false,
    hideable: false,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    width: 120,
    headerName: 'Asignados',
    field: 'assigned',
    renderCell: AssignedCell,
  },
  {
    hideable: false,
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Finalización',
    filterable: false,
    field: 'end',
    width: 160,
    renderCell: EndingCell,
  },
];

export const useColumns = (weekly = false) =>
  useMemo(() => columns(weekly), [weekly]);

export const useCompactColumns = (weekly = false) =>
  useMemo(() => columns(weekly).slice(1), [weekly]);
