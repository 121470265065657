import React from 'react';
import Page from 'src/components/v2/Page';

export const AuthenticationContainer = ({ children, ...props }) => (
  <Page
    {...props}
    sx={{
      bgcolor: 'primary.900',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {children}
  </Page>
);
