import { Fade } from '@mui/material';

const MuiMenu = {
  styleOverrides: {
    paper: {
      borderRadius: '8px !important',
    },
  },
  defaultProps: {
    TransitionComponent: Fade,
  },
};

export default MuiMenu;
