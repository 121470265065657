import React, { useId } from 'react';
import { useField } from 'formik';

import {
  Grid,
  FormControl,
  TextField as MuiTextField,
  Button,
  Avatar,
  Typography,
  FormLabel,
  Stack,
} from '@mui/material';

const ImageField = ({
  label,
  gridProps = { xs: 12 },
  name,
  imagenUser,
  fallbackImage,
  ...rest
}) => {
  const [, meta, helpers] = useField(name);
  const [selectedImage, setSelectedImage] = React.useState(imagenUser);
  const id = useId();

  const handleChangeFile = (e) => {
    if (e.target.files[0]) {
      helpers.setValue(e.target.files[0]);
      const imageURL = URL.createObjectURL(e.target.files[0]);
      setSelectedImage(imageURL);
    }
  };

  return (
    <Grid item {...gridProps}>
      <FormControl>
        <Button
          component="label"
          sx={{ display: 'flex', flexDirection: 'column' }}
        >
          <Stack direction="row" gap={2} px={4} py={2} alignItems="center">
            <Avatar
              alt="selected image"
              src={selectedImage}
              sx={{
                width: 90,
                height: 90,
                borderRadius: 80,
                margin: 'auto',
                position: 'relative',
                '&:hover': {
                  cursor: 'pointer',
                  opacity: 0.7,
                },
              }}
            >
              {fallbackImage}
            </Avatar>

            <MuiTextField
              id={id}
              sx={{
                display: 'none',
              }}
              type="file"
              onChange={handleChangeFile}
              {...rest}
              hidden
            />

            <Stack>
              {label && <FormLabel htmlFor={id}>{label}</FormLabel>}

              <FormLabel
                htmlFor={id}
                sx={{ color: 'primary.main', fontWeight: '500' }}
              >
                Cargar imagen
              </FormLabel>
            </Stack>
          </Stack>
        </Button>
      </FormControl>

      <Typography sx={{ color: 'error.main', marginTop: 1 }}>
        {meta.error}
      </Typography>
    </Grid>
  );
};

export default ImageField;
