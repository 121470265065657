import React, {
  useMemo,
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import {
  GET_CURRENT_MEMBER_WITH_PERMISSIONS,
  GET_STUDIO_MEMBER_DATA,
} from 'src/graphql/queries/studios';
import {
  ASSIGN_STUDIO_PERMISSION_TO_MEMBER,
  REMOVE_STUDIO_PERMISSION_TO_MEMBER,
  ASSIGN_STUDIO_ROLE_TO_MEMBER,
} from 'src/graphql/mutations/studios';

import { usePermissions } from '../hooks/usePermissions';
import { useStudioId } from '../hooks/useStudioId';

// Recoil js for the win
export const StudioMemberContext = createContext({
  role: 'member',
  loading: false,
  member: null,
  checkedPermissions: [],

  handleToggleAllPermissions: () => {},
  handleTogglePermission: () => {},
});

export const useMemberACL = (studioMemberId) => {
  const [checked, setChecked] = useState([]);
  const [hasAdminPrivileges, setHasAdminPrivileges] = useState(false);

  const studioId = useStudioId();
  const { role } = usePermissions();

  const { loading, data: { member } = { member: null } } = useQuery(
    GET_STUDIO_MEMBER_DATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        studio: studioId,
        user: studioMemberId,
      },
    },
  );

  const [assignPermissionToMember] = useMutation(
    ASSIGN_STUDIO_PERMISSION_TO_MEMBER,
    {
      update: (cache, { data }, options) => {
        const cachedMember = cache.readQuery({
          query: GET_CURRENT_MEMBER_WITH_PERMISSIONS,
          variables: {
            studio: options.variables.studioId,
            user: options.variables.user,
          },
        });

        cache.writeQuery({
          query: GET_CURRENT_MEMBER_WITH_PERMISSIONS,
          variables: {
            studio: options.variables.studioId,
            user: options.variables.user,
          },
          data: {
            member: {
              ...(cachedMember?.member ?? {}),
              ...(data?.assignStudioPermissionToMember ?? {}),
            },
          },
        });
      },
    },
  );

  const [removePermissionToMember] = useMutation(
    REMOVE_STUDIO_PERMISSION_TO_MEMBER,
    {
      update: (cache, { data }, options) => {
        const cachedMember = cache.readQuery({
          query: GET_CURRENT_MEMBER_WITH_PERMISSIONS,
          variables: {
            studio: options.variables.studioId,
            user: options.variables.user,
          },
        });

        cache.writeQuery({
          query: GET_CURRENT_MEMBER_WITH_PERMISSIONS,
          variables: {
            studio: options.variables.studioId,
            user: options.variables.user,
          },
          data: {
            member: {
              ...(cachedMember?.member ?? {}),
              ...(data?.removeStudioPermissionToMember ?? {}),
            },
          },
        });
      },
    },
  );

  const [assignRoleToMember] = useMutation(ASSIGN_STUDIO_ROLE_TO_MEMBER, {
    refetchQueries: [GET_STUDIO_MEMBER_DATA],
  });

  const handleTogglePermission = useCallback(
    (value) => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);

      const body = {
        variables: {
          studioId,
          permission: value,
          memberId: studioMemberId,
        },
      };

      if (currentIndex === -1) {
        assignPermissionToMember(body);
      } else {
        removePermissionToMember(body);
      }
    },
    [
      assignPermissionToMember,
      removePermissionToMember,
      checked,
      studioId,
      studioMemberId,
    ],
  );

  const handleToggleAllPermissions = useCallback(
    (value) => {
      const body = {
        variables: {
          studioId,
          role: value ? 'admin' : 'member',
          memberId: studioMemberId,
        },
      };

      assignRoleToMember(body);
      setHasAdminPrivileges(value);
    },
    [assignRoleToMember, studioId, studioMemberId],
  );

  useEffect(() => {
    if (member?.permissions) {
      setChecked(member.permissions);
    }
  }, [member]);

  useEffect(() => {
    if (member) {
      setHasAdminPrivileges(member.role === 'admin');
    }
  }, [member]);

  return useMemo(
    () => ({
      loading,
      role,
      checkedPermissions: checked,
      member,
      handleTogglePermission,
      handleToggleAllPermissions,
      hasAdminPrivileges,
    }),
    [
      checked,
      handleToggleAllPermissions,
      handleTogglePermission,
      hasAdminPrivileges,
      loading,
      member,
      role,
    ],
  );
};

export const StudioMemberContextProvider = ({ children }) => {
  const { studioMemberId } = useParams();
  const context = useMemberACL(studioMemberId);

  return (
    <StudioMemberContext.Provider value={context}>
      {children}
    </StudioMemberContext.Provider>
  );
};

export const useStudioMemberContext = () => useContext(StudioMemberContext);
