import { useCallback, useMemo, useState, useEffect } from 'react';

function reorder(list, startIndex, endIndex) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function useDraggableSections(initialSections, storageKey) {
  const [sections, setSections] = useState(() => {
    const storedSections = localStorage.getItem(storageKey);
    const parsedStoredSections = storedSections
      ? JSON.parse(storedSections)
      : initialSections;

    if (parsedStoredSections.length !== initialSections.length) {
      return initialSections;
    }

    return parsedStoredSections;
  });

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(sections));
  }, [sections, storageKey]);

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newSections = reorder(
        sections,
        result.source.index,
        result.destination.index,
      );

      setSections(newSections);
    },
    [sections],
  );

  return [sections, handleDragEnd];
}
