import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Sentry from '@sentry/browser';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Avatar,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';

import Form, {
  TextField,
  SubmitButton,
} from 'src/components/ui-components/Form';
import {
  GoogleCalendarsAutocomplete,
  StudioMembersAutocomplete,
} from 'src/autocompletes';
import { useUser } from 'src/utils/hooks/useUser';
import GoogleCalendarIMG from 'src/assets/images/googlecalendar.png';

import { generateCoolColor } from 'src/utils';
import { useGoogleCalendar } from 'src/utils/hooks/useGoogleCalendar';
import { useStudioMembers } from '@legalsurf/hooks';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  CREATE_CALENDAR,
  UPDATE_CALENDAR,
} from '../../../graphql/mutations/calendars';
import { GET_CALENDARS } from '../../../graphql/queries/calendars';
import { CalendarPageQuery } from './queries';
import { CalendarAsideQuery } from './gql';

const Schema = yup.object({
  name: yup
    .string()
    // TODO: Replace with proper localized copy strings
    .min(4, 'El nombre del calendario debe ser de al menos 4 caracteres')
    .max(64, 'El nombre del calendario debe ser de maximo 32 caracteres')
    .required('El nombre del calendario es requerido'),
});

const CalendarForm = ({ show, onHide: onClose, calendar }) => {
  const [defaultColor] = useState(generateCoolColor());
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();
  const { user } = useUser();
  const {
    hasGoogleCalendar,
    redirectToGoogleCalendarAuthorzation,
    loading: loadingGoogleCalendar,
  } = useGoogleCalendar();

  const [createCalendar] = useMutation(CREATE_CALENDAR);

  const [updateCalendar] = useMutation(UPDATE_CALENDAR, {
    refetchQueries: [
      { query: GET_CALENDARS, variables: { studio: studioId } },
      CalendarPageQuery,
      CalendarAsideQuery,
    ],
  });
  const { data: members } = useStudioMembers({
    variables: { studio: studioId, status: ['ACTIVE'] },
  });

  const defaultValues = {
    name: '',
    color: defaultColor,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    googleCalendar: null,
    canBeSeenBy: members?.map((member) => ({
      value: member.id,
      label: member.name,
    })),
  };

  const handleSubmit = async (values, formikBag) => {
    try {
      if (calendar) {
        await updateCalendar({
          variables: {
            id: calendar.id,
            name: values.name,
            color: values.color,
            timezone: values.timezone,
            canBeSeenBy:
              members.length === values.canBeSeenBy?.length ||
              values.canBeSeenBy?.length === 0
                ? []
                : values.canBeSeenBy.map((member) => member.value),
          },
        });
      } else {
        await createCalendar({
          refetchQueries: () => {
            const queries = [
              { query: GET_CALENDARS, variables: { studio: studioId } },
              CalendarPageQuery,
              CalendarAsideQuery,
            ];

            if (values.googleCalendar?.value) {
              queries.push('getCalendarPageData');
            }

            return queries;
          },
          variables: {
            name: values.name,
            color: values.color,
            timezone: values.timezone,
            googleCalendarId: values.googleCalendar?.value,
            studio: studioId,
            canBeSeenBy:
              members.length === values.canBeSeenBy?.length ||
              values.canBeSeenBy?.length === 0
                ? []
                : values.canBeSeenBy.map((member) => member.value),
          },
        });
      }

      onClose();
      formikBag.resetForm({});
      openSnackbar('Calendario creado con exito.', { appearance: 'success' });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar('Hubo error, intente mas tarde.', { appearance: 'error' });
    }
  };

  const canCreateGoogleCalendar = ['owner', 'admin'].includes(user?.role);

  const initialValues = calendar
    ? {
        ...calendar,
        googleCalendar: calendar?.googleCalendarId
          ? {
              value: calendar?.googleCalendarId,
              label: calendar.name,
            }
          : null,
        // wtf
        canBeSeenBy:
          calendar.canBeSeenBy?.length === 0
            ? (members?.map((member) => ({
                label: member.name,
                value: member.id,
              })) ?? [])
            : (calendar.canBeSeenBy?.map((member) => ({
                label: member.name,
                value: member.id,
              })) ??
              members?.map((member) => ({
                value: member.id,
                label: member.name,
              })) ??
              []),
      }
    : defaultValues;

  return (
    <Dialog fullWidth maxWidth="sm" open={show} onClose={onClose}>
      <DialogTitle>
        {calendar ? 'Editar calendario' : 'Crear calendario'}
      </DialogTitle>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <>
            <DialogContent dividers>
              <Form id="calendar-form">
                <TextField
                  label="Nombre"
                  name="name"
                  placeholder="Ej. Calendario #2..."
                />

                {calendar?.googleCalendarId && (
                  <GoogleCalendarsAutocomplete
                    calendar={calendar}
                    disabled={!!calendar?.id}
                    label="Calendarios de google"
                    name="googleCalendar"
                    placeholder=""
                    onChange={(event, googleCalendar) => {
                      setFieldValue('googleCalendar', googleCalendar);
                      setFieldValue('color', googleCalendar.backgroundColor);
                    }}
                  />
                )}

                {!calendar?.id &&
                  canCreateGoogleCalendar &&
                  !loadingGoogleCalendar && (
                    <>
                      {hasGoogleCalendar && (
                        <GoogleCalendarsAutocomplete
                          calendar={calendar}
                          disabled={!!calendar?.id}
                          label="Calendario de google"
                          name="googleCalendar"
                          placeholder=""
                          onChange={(event, googleCalendar) => {
                            setFieldValue('name', googleCalendar.label, true);

                            if (googleCalendar?.timezone) {
                              setFieldValue(
                                'timezone',
                                googleCalendar.timezone,
                              );
                            }

                            setFieldValue('googleCalendar', googleCalendar);
                            setFieldValue(
                              'color',
                              googleCalendar.backgroundColor,
                            );
                          }}
                        />
                      )}

                      {!hasGoogleCalendar && (
                        <Grid item xs={12}>
                          <LoadingButton
                            loading={false}
                            startIcon={<Avatar src={GoogleCalendarIMG} />}
                            variant="text"
                            onClick={redirectToGoogleCalendarAuthorzation}
                          >
                            Vincular Google Calendar
                          </LoadingButton>
                        </Grid>
                      )}
                    </>
                  )}

                <StudioMembersAutocomplete
                  fixedOptions={[user.id]}
                  getOptionDisabled={(option) => option.value === user.id}
                  name="canBeSeenBy"
                />

                <TextField
                  gridProps={{ xs: 9, md: 3 }}
                  name="color"
                  type="color"
                  value={values.color}
                />
              </Form>
            </DialogContent>

            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={2}>
                <Grid item>
                  <Button color="primary" variant="text" onClick={onClose}>
                    Cerrar
                  </Button>
                </Grid>

                <SubmitButton
                  form="calendar-form"
                  gridProps={{ justifyContent: 'flex-end' }}
                  sx={{ flex: 0, mr: 2 }}
                >
                  {calendar ? 'Editar' : 'Guardar'}
                </SubmitButton>
              </Grid>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default CalendarForm;
