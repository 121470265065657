import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React from 'react';
import {
  CategoriesAutocomplete,
  EntitiesAutocomplete,
  SidesAutocomplete,
  StatusAutocomplete,
  StudioMembersAutocomplete,
  TagsAutocomplete,
} from 'src/autocompletes';
import Form, {
  DateField,
  SubmitButton,
} from 'src/components/ui-components/Form';

const fieldGridProps = {
  lg: 3,
  md: 6,
  sm: 9,
  xs: 12,
};

export const FilecasesReportsHeaderFilters = ({
  initialValues,
  onSubmit: handleSubmit,
}) => (
  <Formik
    enableReinitialize
    initialValues={initialValues}
    onSubmit={handleSubmit}
  >
    {({ values }) => (
      <Form gridProps={{ sx: { display: 'flex' } }}>
        <Grid item xs={12}>
          <Grid container alignItems="center" gap={2}>
            <Grid item lg={4}>
              <Typography variant="h6">
                Filtra tu reporte por tus parametros preferidos!
              </Typography>
            </Grid>

            <DateField
              gridProps={{ xs: 12, lg: 3 }}
              label="Inicio"
              maxDate={dayjs().year(2040).month(0).startOf('month')}
              minDate={dayjs().year(2019).month(0).startOf('month')}
              name="start"
            />

            <Typography>a</Typography>

            <DateField
              gridProps={{ xs: 12, lg: 3 }}
              label="Final"
              maxDate={dayjs(values.start).add(10, 'years').endOf('month')}
              minDate={dayjs(values.start)}
              name="end"
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              flexWrap: ['wrap', 'wrap', 'nowrap'],
              display: 'flex',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <StudioMembersAutocomplete
              multiple
              gridProps={fieldGridProps}
              label="Asignados"
              name="assigned"
            />

            <StatusAutocomplete
              gridProps={fieldGridProps}
              label="Estado"
              name="state"
            />

            <EntitiesAutocomplete
              multiple
              gridProps={fieldGridProps}
              label="Directorios"
              name="entities"
            />

            <CategoriesAutocomplete
              gridProps={fieldGridProps}
              label="Categoria"
              name="category"
            />

            <SidesAutocomplete
              gridProps={fieldGridProps}
              label="Parte"
              name="side"
            />

            <TagsAutocomplete
              multiple
              gridProps={fieldGridProps}
              label="Etiquetas"
              name="tags"
            />

            <SubmitButton gridProps={{ lg: 3 }}>Aplicar filtros</SubmitButton>
          </Box>
        </Grid>
      </Form>
    )}
  </Formik>
);
