import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const ChartTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Card sx={{ borderRadius: 0.5 }}>
        <CardContent>
          {payload.map(({ name, value, stroke, color }) => (
            <Typography key={name} sx={{ color: stroke ?? color }}>
              {name}: {value}
            </Typography>
          ))}
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default ChartTooltip;
