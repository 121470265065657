import React from 'react';
import { Box, Skeleton } from '@mui/material';
import Page from 'src/components/v2/Page';

const StudioFormSkeleton = () => (
  <Page title="Configuracion del Estudio | Legal surf">
    <Box sx={{ p: 2, paddingTop: 8, display: 'flex', flexDirection: 'column' }}>
      <Skeleton
        animation="wave"
        height={120}
        sx={{ alignSelf: 'center' }}
        variant="circular"
        width={120}
      />
      <Skeleton
        animation="wave"
        height={520}
        sx={{ mt: 8 }}
        variant="rectangular"
      />
    </Box>
  </Page>
);

export default StudioFormSkeleton;
