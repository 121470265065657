import React from 'react';
import { SvgIcon } from '@mui/material';

const LSWhatsappIcon = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M11.25 3.5C6.96979 3.5 3.5 6.96979 3.5 11.25C3.5 15.5302 6.96979 19 11.25 19C12.4533 19 13.3178 18.7908 13.8659 18.5941C14.1407 18.4955 14.3379 18.3993 14.46 18.3326C14.521 18.2992 14.5633 18.2732 14.5872 18.2578C14.5991 18.2502 14.6064 18.2452 14.6092 18.2433L14.6076 18.2444L14.6059 18.2457L14.6048 18.2465M14.6092 18.2433C14.8048 18.1013 15.0575 18.062 15.2871 18.1385L19.0899 19.4061C19.2853 19.4712 19.4713 19.2853 19.4061 19.0898L18.2704 15.6825L18.1385 15.2873C18.0641 15.0641 18.0987 14.8189 18.232 14.6251C18.2346 14.6211 18.2397 14.613 18.248 14.5994C18.2647 14.572 18.2919 14.5254 18.3264 14.4596C18.3953 14.3281 18.4931 14.1204 18.5928 13.8379C18.7918 13.274 19 12.4083 19 11.25C19 6.96979 15.5302 3.5 11.25 3.5M18.232 14.6251L18.231 14.6266L18.2299 14.6282C18.2299 14.6282 18.2307 14.6271 18.232 14.6251ZM15.1394 19.6704C14.9552 19.7687 14.7003 19.8884 14.3726 20.0059C13.667 20.2592 12.6314 20.5 11.25 20.5C6.14137 20.5 2 16.3586 2 11.25C2 6.14137 6.14137 2 11.25 2C16.3586 2 20.5 6.14137 20.5 11.25C20.5 12.5917 20.2582 13.626 20.0073 14.3371C19.8873 14.6771 19.7655 14.9423 19.6678 15.1314L19.6933 15.208L20.8291 18.6155C21.2852 19.9836 19.9836 21.2851 18.6156 20.8291L15.2082 19.6933L15.1394 19.6704ZM6.5 11.25C6.5 10.8358 6.83579 10.5 7.25 10.5H7.26C7.67421 10.5 8.01 10.8358 8.01 11.25C8.01 11.6642 7.67421 12 7.26 12H7.25C6.83579 12 6.5 11.6642 6.5 11.25ZM10.5 11.25C10.5 10.8358 10.8358 10.5 11.25 10.5H11.26C11.6742 10.5 12.01 10.8358 12.01 11.25C12.01 11.6642 11.6742 12 11.26 12H11.25C10.8358 12 10.5 11.6642 10.5 11.25ZM14.5 11.25C14.5 10.8358 14.8358 10.5 15.25 10.5H15.26C15.6742 10.5 16.01 10.8358 16.01 11.25C16.01 11.6642 15.6742 12 15.26 12H15.25C14.8358 12 14.5 11.6642 14.5 11.25Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSWhatsappIcon;
