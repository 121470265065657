import React, { useCallback } from 'react';
import { Grid, Skeleton, Stack } from '@mui/material';

import { useCustomFields } from 'src/dialogs/CustomFieldFormDialog/utils';
import CustomField from '../CustomField';

export const parseCustomFieldsToInitialValues = (customFields) =>
  customFields?.reduce((acc, field) => {
    if (field.type === 'Select') {
      acc[field.name] = field.value
        ? {
            label: field.value,
            value: field.value,
          }
        : null;
    } else {
      acc[field.name] = field.value;
    }

    return acc;
  }, {});

const useRenderCustomFields = (modelType) => {
  const { loading, customFields } = useCustomFields({
    variables: {
      modelType,
      onFilecases: modelType === 'Filecase' || undefined,
      onEntities: modelType === 'Entity' || undefined,
    },
  });

  const render = useCallback(
    (props) =>
      customFields.map((field) => (
        <CustomField
          {...props}
          customFieldId={field.id}
          key={field.id}
          label={field.name}
          modelType={modelType}
          name={field.name}
          options={field.options}
          type={field.type}
        />
      )),
    [customFields, modelType],
  );

  return { loading, render };
};

const CustomFields = ({ modelType, ...props }) => {
  const { loading, render } = useRenderCustomFields(modelType);

  if (loading) {
    return (
      <Grid item sx={{ width: '100%' }}>
        <Stack gap={2}>
          <Skeleton
            animation="wave"
            height={42}
            sx={{ borderRadius: 1 }}
            variant="rounded"
          />

          <Skeleton
            animation="wave"
            height={42}
            sx={{ borderRadius: 1 }}
            variant="rounded"
          />
        </Stack>
      </Grid>
    );
  }

  return render(props);
};

export default CustomFields;
