import React from 'react';
import { autocompleteClasses, Box } from '@mui/material';

const StatusPopper = ({ anchorEl, disablePortal, ...props }) => (
  <Box
    {...props}
    sx={{
      [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        color: 'inherit',
        borderRadius: 0,
      },

      mt: 2,

      '& li + li': {
        mt: 1,
      },
    }}
  />
);

export default StatusPopper;
