import React from 'react';

import { Alert, AlertTitle, Box } from '@mui/material';

const CrashErrorMessage = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <Alert color="error">
      <AlertTitle>Ha ocurrido un error fatal.</AlertTitle>
      Estamos solucionandolo ahora mismo. Intente mas tarde.
    </Alert>
  </Box>
);

export default CrashErrorMessage;
