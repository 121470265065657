import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import {
  DELETE_CATEGORY,
  UPDATE_CATEGORY,
} from 'src/graphql/mutations/category';
import { useFilecaseCategories } from 'src/autocompletes/CategoriesAutocomplete';
import { FilecaseCategoriesPopover } from 'src/popovers/FilecaseCategoriesPopover';
import { OrganizersTabToolbar } from './OrganizerTabToolbar';

const Actions = ({ row }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteCategory));
      cache.gc();
    },
  });

  return [
    <Tooltip title="Para editar, doble click en los campos editables que deseas actualizar en la tabla">
      <GridActionsCellItem
        color="inherit"
        icon={<LSEdit color="primary" fontSize="small" />}
        label="Edit"
      />
    </Tooltip>,
    <Tooltip title="Eliminar categoría">
      <GridActionsCellItem
        color="inherit"
        icon={<LSTrash color="error" fontSize="small" />}
        label="Delete"
        onClick={() =>
          dispatchWarningPromptDialog(`FilecaseCategoriesTab-${row.id}`, {
            onAccept: () =>
              deleteCategory({
                variables: {
                  id: row.id,
                  studioId,
                },
              }),
            title: 'Eliminar categoría',
          })
        }
      />
    </Tooltip>,
  ];
};

export const FilecaseCategoriesTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const { value } = useTabContext();
  const studioId = useStudioId();
  const { categories, loading } = useFilecaseCategories({
    skip: value !== index,
  });
  const [updateCategory] = useMutation(UPDATE_CATEGORY);

  const handleUpdate = async (val, oldValue) => {
    if (val.name === oldValue.name) {
      return oldValue;
    }

    const res = await updateCategory({
      variables: {
        studioId,
        id: val.id,
        name: val.name,
      },
    });

    return res.data.updateCategory;
  };

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: true,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 150,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      editable: false,
      getActions: Actions,
    },
  ];

  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseEntitiesPopover = () => {
    setAnchorEl(null);
  };

  return (
    <TabPanel value={index}>
      <FilecaseCategoriesPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseEntitiesPopover}
        onSubmit={handleCloseEntitiesPopover}
      />

      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        components={{
          toolbar: OrganizersTabToolbar,
        }}
        componentsProps={{
          toolbar: {
            label: 'Crear categorías',
            onClick: (event) => setAnchorEl(event.currentTarget)
          },
        }}
        editMode="row"
        loading={loading}
        pageSize={10}
        processRowUpdate={handleUpdate}
        rowCount={categories.length}
        rows={categories}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe una categoría con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
      />
    </TabPanel>
  );
};
