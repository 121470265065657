import React from 'react';

import Page from 'src/components/v2/Page';
import { SubscriptionManagementForm } from './form';

export const SubscriptionManagement = () => (
  <Page title="Manejo de suscripción y planes | LegalSurf">
    <SubscriptionManagementForm />
  </Page>
);
