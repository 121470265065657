import { useMutation, MutationHookOptions } from '@apollo/client';
import { DeleteTodo } from '@legalsurf/queries';

import { UseDeleteEventTodoHookVariables, UseDeleteTodoData } from '../types';

export const useDeleteTodo = (
  options: MutationHookOptions<
    UseDeleteTodoData,
    UseDeleteEventTodoHookVariables
  >,
) =>
  useMutation(DeleteTodo, {
    update: (cache, result) =>
      cache.evict({ id: cache.identify(result.data.deleteEventTodo) }),
    ...options,
  });
