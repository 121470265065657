import { gql } from '@apollo/client';

export const CREATE_CATEGORY = gql`
  mutation createCategory($studioId: ID!, $name: String!) {
    createCategory(studioId: $studioId, name: $name) {
      id
      name
      studioId
    }
  }
`;
export const UPDATE_CATEGORY = gql`
  mutation updateCategory($studioId: ID!, $id: ID!, $name: String!) {
    updateCategory(studioId: $studioId, id: $id, name: $name) {
      id
      name
      studioId
    }
  }
`;
export const DELETE_CATEGORY = gql`
  mutation deleteCategory($studioId: ID!, $id: ID!) {
    deleteCategory(studioId: $studioId, id: $id) {
      id
      name
      studioId
    }
  }
`;
