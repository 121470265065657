import { tasksStatesObj } from '@legalsurf/common';
import { palette } from '../../foundations';

const MuiChip = {
  variants: [
    {
      props: { color: 'default', variant: 'filled' },
      style: {
        color: '#181C32',
        backgroundColor: '#EFF2F5',
      },
    },
    {
      props: { color: 'primary', variant: 'light' },
      style: {
        color: palette.primary.dark,
        backgroundColor: palette.primary[100],
      },
    },
    {
      props: { color: 'secondary', variant: 'light' },
      style: {
        color: palette.secondary.dark,
        backgroundColor: palette.secondary[100],
      },
    },
    {
      props: { variant: 'custom' },
      style: ({ ownerState, theme }) => ({
        color: theme.palette.computeContrast(ownerState.bg),
        backgroundColor: ownerState.bg,
      }),
    },
    {
      props: { variant: 'task' },
      style: ({ value }) => ({
        color: tasksStatesObj[value]?.color || '#444',
        backgroundColor: tasksStatesObj[value]?.bg || '#f2f2f2',
        fontWeight: 700,
        borderRadius: 8,
      }),
    },
    {
      props: { size: 'large' },
      style: {
        '& > span': {
          paddingTop: 4,
          paddingBottom: 4,
        },
        fontSize: 14,
      },
    },
    {
      props: { variant: 'light', color: 'success' },
      style: {
        fontSize: 14,
        backgroundColor: palette.success.light,
        color: palette.success.dark,
      },
    },
  ],
};

export default MuiChip;
