import React from 'react';
import { Box, Avatar, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { CommentTooltip } from 'src/components/v3/Comment/CommentTooltip';
import Comment from 'src/components/v3/Comment';
import { RichEditor } from 'src/components/v3/RichEditor';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { LSTrash } from 'src/components/icons';
import { humanizeHoursTrackingTime } from './utils';

const ConceptCell = ({ row }) => (
  <Box>
    <CommentTooltip
      placement="left"
      title={
        <Comment
          readOnly
          comment={{
            ...row,
            content: row.concept,
            updatedAt: row.createdAt,
          }}
          key={row.id}
        />
      }
    >
      <Box>
        {row.concept ? (
          <RichEditor
            disableUpload
            disabled
            initialValue={row.concept}
            variant="outlined"
          />
        ) : (
          'N/A'
        )}
      </Box>
    </CommentTooltip>
  </Box>
);

const AssignedToCell = ({ row }) => (
  <Box>
    <Tooltip key={row.assignedTo.id} title={row.assignedTo.name}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Avatar
          alt="Responsable"
          size="small"
          src={row.assignedTo.picture}
          sx={{ margin: 1, width: 30, height: 30 }}
        />
      </Box>
    </Tooltip>
  </Box>
);

const DateCell = ({ row }) => (
  <Box sx={{ flex: 1 }}>
    <Tooltip placement="left" title={dayjs(row.createdAt).format('LLL')}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        {dayjs(row.createdAt).format('L')}
      </Box>
    </Tooltip>
  </Box>
);

export const useColumns = ({ onDelete: handleDelete }) => [
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Horas',
    field: 'amount',
    renderCell: (cell) => humanizeHoursTrackingTime(cell.row.amount),
  },
  {
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    headerName: 'Concepto',
    field: 'concept',
    renderCell: (cell) => <ConceptCell {...cell} />,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Asignado',
    field: 'assignedTo',
    width: 120,
    renderCell: (cell) => <AssignedToCell {...cell} />,
  },
  {
    disableColumnMenu: true,
    sortable: false,
    headerName: 'Creación',
    field: 'date',
    width: 140,
    renderCell: (cell) => <DateCell {...cell} />,
  },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Acciones',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => [
      <GridActionsCellItem
        icon={<LSTrash color="error" />}
        label="Eliminar"
        onClick={() => handleDelete({ id })}
      />,
    ],
  },
];
