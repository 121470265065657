import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import qs from 'query-string';
import { useStudioId } from './useStudioId';

const linkGoogleTokenMutation = gql`
  mutation linkGoogleToken($scope: GoogleScope!, $token: ID!, $studioId: ID!) {
    linkGoogleToken(scope: $scope, token: $token, studioId: $studioId) {
      id
      googleAccessToken(scope: $scope, studioId: $studioId)
      googleToken(studioId: $studioId) {
        id
        googleDrive
      }
    }
  }
`;

const hasGoogleDriveQuery = gql`
  query hasGoogleDriveQuery($studioId: ID!) {
    getUserInfo {
      id
      googleToken(studioId: $studioId) {
        id
        googleDrive
      }
    }
  }
`;

const GetGoogleDriveAcessTokenQuery = gql`
  query GetGoogleDriveAcessTokenQuery($studioId: ID!) {
    getUserInfo {
      id
      googleAccessToken(scope: googleDrive, studioId: $studioId)
      googleToken(studioId: $studioId) {
        id
        googleDrive
      }
    }
  }
`;

const revokeGoogleRefreshTokenMutation = gql`
  mutation revokeGoogleRefreshToken($scope: GoogleScope!, $studioId: ID!) {
    revokeGoogleRefreshToken(scope: $scope, studioId: $studioId) {
      id
    }
  }
`;

const GenerateGoogleDriveAuthorizationURL = gql`
  query GenerateGoogleDriveAuthorizationURL(
    $redirectUri: String!
    $state: String
  ) {
    generateGoogleDriveAuthorizationURL(
      redirectUri: $redirectUri
      state: $state
    )
  }
`;

const scope = 'googleDrive';

export const useGoogleDrive = () => {
  const studioId = useStudioId();

  const { data, loading, refetch } = useQuery(hasGoogleDriveQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      scope,
      studioId,
    },
    skip: !studioId,
  });

  const [getGoogleDriveAccessTokenLazy] = useLazyQuery(
    GetGoogleDriveAcessTokenQuery,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        studioId,
      },
    },
  );

  const [linkGoogleToken] = useMutation(linkGoogleTokenMutation, {
    update: (cache, result, { variables }) => {
      cache.writeQuery({
        query: hasGoogleDriveQuery,
        variables,
        data: {
          getUserInfo: {
            ...result.data?.linkGoogleAccount,
          },
        },
      });
    },
  });

  const hasGoogleDrive = !!data?.getUserInfo?.googleToken?.googleDrive;

  const generateGoogleScopeAuthorizationURLState = (extraStateParams) => ({
    redirectUri: `${window.location.origin}/callback/google`,
    state: qs.stringify({
      studioId,
      scope,
      ...extraStateParams,
    }),
  });

  const [generateGoogleDriveAuthorizationURL] = useLazyQuery(
    GenerateGoogleDriveAuthorizationURL,
  );

  const [revokeGoogleRefreshToken] = useMutation(
    revokeGoogleRefreshTokenMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [hasGoogleDriveQuery],
    },
  );

  const syncGoogleScope = async (code, stateStudioId) =>
    linkGoogleToken({
      variables: {
        scope,
        token: code,
        studioId: stateStudioId,
      },
    });

  const redirectToGoogleDriveAuthorzation = (extraStateParams) =>
    generateGoogleDriveAuthorizationURL({
      variables: generateGoogleScopeAuthorizationURLState(extraStateParams),
    }).then((value) => {
      window.location.assign(value.data?.generateGoogleDriveAuthorizationURL);
    });

  const unsyncGoogleScope = async () =>
    revokeGoogleRefreshToken({
      variables: {
        scope,
        studioId,
      },
    });

  const getGoogleDriveAccessToken = () =>
    getGoogleDriveAccessTokenLazy().then(
      (params) => params.data?.getUserInfo?.googleAccessToken,
    );

  return {
    refetch,
    getGoogleDriveAccessToken,
    redirectToGoogleDriveAuthorzation,
    syncGoogleScope,
    unsyncGoogleScope,
    hasGoogleDrive,
    loading,
  };
};
