import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Alert,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const WarningDialog = ({
  open,
  title = '¡Cuidado!',
  content = (
    <Alert severity="error">
      Esta acción es permanente y no podra recuperarlo. ¿Desea continuar?
    </Alert>
  ),
  loading,
  onClose,
  onAccept,
}) => {
  const [innerLoading, setInnerLoading] = useState(false);

  const handleSubmit = () => {
    if (onAccept?.constructor?.name === 'AsyncFunction') {
      setInnerLoading(true);

      onAccept().finally(() => {
        setInnerLoading(false);
        onClose();
      });

      return;
    }

    onAccept();
    // Close the dialog
    onClose();
  };

  return (
    <Dialog open={open} onClose={!loading ? onClose : undefined}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>{content}</DialogContent>

      <DialogActions>
        <Button disabled={loading || innerLoading} onClick={onClose}>
          Cancelar
        </Button>

        <LoadingButton
          color="error"
          disabled={loading}
          loading={loading || innerLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Aceptar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
