import React, { forwardRef } from 'react';
import { Avatar as MuiAvatar, Skeleton } from '@mui/material';

const Avatar = forwardRef(({ loading, src, ...props }, ref) => {
  if (loading) {
    return (
      <Skeleton ref={ref} variant="circular">
        <MuiAvatar {...props} />
      </Skeleton>
    );
  }

  return <MuiAvatar ref={ref} src={src} {...props} />;
});

export default Avatar;
