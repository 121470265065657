import React, { useState } from 'react';
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  Typography,
  Stack,
  Skeleton,
} from '@mui/material';

import { useContractTemplates } from 'src/tables/ContractsTable/queries';
import { defaultTemplateContent } from 'src/pages/Dashboard/Settings/ContractsSettingForm/utils';
import { ContractTemplatePreview } from './ContractTemplatePreview';

const defaultContract = {
  id: '1',
  name: 'Ejemplo',
  content: defaultTemplateContent,
};

export const ContractTemplatePopoverBody = ({
  onClick,
  entity,
  data = {},
  ...rest
}) => {
  const { loading, contracts } = useContractTemplates();
  const [hoveredContract, setHoveredContract] = useState(null);

  return (
    <Stack direction={['column', 'row']} gap={1}>
      <Box {...rest}>
        <Box
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            p: 2,
            display: 'flex',
          }}
        >
          <Typography variant="h6">Plantillas</Typography>

          {/* <PermissionGuard
            permissions={[
              LS_PERMISSIONS_MAP.CONFIG.ACCESS_NOTIFICATION_TEMPLATES,
            ]}
          >
            <Tooltip title="Crear plantilla">
              <IconButton
                color="primary"
                onClick={() =>
                  navigate(`/dashboard/${studio.id}/settings/contracts`)
                }
              >
                <LSAdd />
              </IconButton>
            </Tooltip>
          </PermissionGuard> */}
        </Box>

        <Divider />

        <Stack direction="row">
          {loading ? (
            <Stack direction="column" gap={1} p={2}>
              {Array(4)
                .fill(null)
                .map((_, idx) => (
                  <Skeleton
                    height={20}
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    minWidth={100}
                    sx={{ borderRadius: 0.5, minWidth: 200 }}
                    variant="rectangular"
                    width="100%"
                  />
                ))}
            </Stack>
          ) : (
            <MenuList sx={{ height: 300, overflowY: 'scroll' }}>
              {contracts.map((contract) => (
                <MenuItem
                  key={contract.id}
                  onClick={() => onClick(contract)}
                  onMouseEnter={() => setHoveredContract(contract)}
                >
                  {contract.name}
                </MenuItem>
              ))}
            </MenuList>
          )}

          <Box sx={{ maxWidth: 400 }}>
            <ContractTemplatePreview
              disabled
              contract={hoveredContract || defaultContract}
              data={{ entity, ...data }}
              entity={entity}
              sx={{ fontSize: 10 }}
            />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
};
