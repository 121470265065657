import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';

import { useStudioId } from 'src/utils/hooks/useStudioId';
import { defaultInitialValues } from 'src/dialogs/WorkflowFormDialog';

export const CreateTimelineStepIconButton = (props) => (
  <Button
    color="primary"
    size="small"
    sx={{ minWidth: 160 }}
    variant="contained"
    {...props}
  >
    Crear paso nuevo
  </Button>
);

export const TimelineStartComponent = () => (
  <Stack>
    <Alert>
      <AlertTitle>Inicio: </AlertTitle>
      Una vez creado el flujo, las tareas se crearán automáticamente
    </Alert>
  </Stack>
);

export const CreateWorkflowMutation = gql`
  mutation CreateWorkflowMutation(
    $name: String!
    $description: String
    $studioId: ID!
    $tags: [ID!]
    $steps: [WorkflowStepInput!]!
  ) {
    createWorkflow(
      name: $name
      description: $description
      studioId: $studioId
      steps: $steps
      tags: $tags
    ) {
      id
      name
      description
      studioId
      tags {
        id
        studioId
        name
      }

      steps {
        id
        index
        description
        type
        data
      }
    }
  }
`;

export const UpdateWorkflowMutation = gql`
  mutation UpdateWorkflowMutation(
    $workflowId: ID!
    $name: String!
    $description: String
    $studioId: ID!
    $tags: [ID!]
    $steps: [WorkflowStepInput!]!
  ) {
    updateWorkflow(
      workflowId: $workflowId
      name: $name
      description: $description
      studioId: $studioId
      steps: $steps
      tags: $tags
    ) {
      id
      name
      studioId
      tags {
        id
        studioId
        name
      }
      steps {
        id
        index
        description
        type
        data
      }
    }
  }
`;

export const DeleteWorkflowMutation = gql`
  mutation DeleteWorkflowMutation($workflowId: ID!, $studioId: ID!) {
    deleteWorkflow(workflowId: $workflowId, studioId: $studioId) {
      id
      name
      studioId
    }
  }
`;

const GetWorkflowFormData = gql`
  query GetWorkflowFormData($workflowId: ID!, $studioId: ID!) {
    getWorkflow(studioId: $studioId, workflowId: $workflowId) {
      id
      name
      description
      tags {
        id
        name
        studioId
      }
      steps {
        id
        index
        description
        type
        data
      }
    }
  }
`;

export const useWorkflowFormInitialValues = (workflowId) => {
  const studioId = useStudioId();
  const { data } = useQuery(GetWorkflowFormData, {
    variables: {
      studioId,
      workflowId,
    },
    skip: !workflowId,
  });

  const workflow = data?.getWorkflow;

  return {
    name: workflow?.name ?? defaultInitialValues.name,
    description: workflow?.description ?? defaultInitialValues.description,
    tags:
      workflow?.tags?.map((tag) => ({ value: tag.name, label: tag.name })) ??
      defaultInitialValues.tags,
    steps: workflow?.steps ?? [],
  };
};
