import React from 'react';
import useDrivePicker from 'react-google-drive-picker';
import { LoadingButton } from '@mui/lab';
import { Avatar, Tooltip } from '@mui/material';

import GoogleDriveIcon from 'src/pages/Dashboard/Settings/logo_drive_2020q4_color_2x_web_64dp.png';
import { useGoogleDrive } from 'src/utils/hooks/useGoogleDrive';

const addDriveTypeToDocs = (arr) =>
  arr.map((val) => ({
    ...val,
    kind: 'drive',
    isFolder: val.mimeType === 'application/vnd.google-apps.folder',
  }));

export const GoogleDriveButton = ({ disabled, onSelect }) => {
  const { getGoogleDriveAccessToken, hasGoogleDrive, loading } =
    useGoogleDrive();
  const [openPicker] = useDrivePicker();

  const openGoogleDrivePicker = async () => {
    const token = (await getGoogleDriveAccessToken()) || undefined;

    const pickerApi = window.google.picker;

    const folderView = new pickerApi.DocsView(pickerApi.ViewId.FOLDERS);
    folderView.setIncludeFolders(true);
    folderView.setSelectFolderEnabled(true);

    const docsView = new pickerApi.DocsView(pickerApi.ViewId.DOCS);
    docsView.setIncludeFolders(false);
    docsView.setSelectFolderEnabled(false);

    const customViewsArray = [docsView, folderView];

    openPicker({
      clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      developerKey: import.meta.env.VITE_GOOGLE_DEVELOPER_KEY,
      token,

      disableDefaultView: true,
      setIncludeFolders: true,
      setSelectFolderEnabled: true,
      supportDrives: true,
      multiselect: true,

      customViews: customViewsArray,
      locale: 'es',

      callbackFunction: (state) => {
        if (state.action === 'picked') {
          onSelect(addDriveTypeToDocs(state.docs));
        }
      },
    });
  };

  return (
    <Tooltip
      disableFocusListener={loading || !(disabled || !hasGoogleDrive)}
      disableHoverListener={loading || !(disabled || !hasGoogleDrive)}
      disableTouchListener={loading || !(disabled || !hasGoogleDrive)}
      title='Dirigete a "configuración de estudio" 👉 "Google Drive" para trabajar con tus archivos rapidamente'
    >
      <span>
        <LoadingButton
          // disabled={disabled || !hasGoogleDrive}
          loading={loading}
          startIcon={<Avatar src={GoogleDriveIcon} />}
          sx={{
            textTransform: 'none',
            mt: 2,
            p: 2,
          }}
          variant="text"
          onClick={openGoogleDrivePicker}
        >
          Google Drive
        </LoadingButton>
      </span>
    </Tooltip>
  );
};
