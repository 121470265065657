import React, { useMemo, useState, createContext, useContext } from 'react';
import { useStudio } from 'src/utils/hooks/useStudio';
import { useUser } from 'src/utils/hooks/useUser';

export const RichEditorSpecialKeywordContext = createContext([]);

export const useRichEditorSpecialKeywordContext = () =>
  useContext(RichEditorSpecialKeywordContext);

export const RichEditorSpecialKeywordProvider = ({
  initialState,
  children,
}) => {
  const { user } = useUser();
  const { studio } = useStudio();
  const [state, setState] = useState({
    ...initialState,
    user: { data: user },
    studio: { data: studio },
    date: true,
  });
  const memoState = useMemo(() => [state, setState], [state]);

  return (
    <RichEditorSpecialKeywordContext.Provider value={memoState}>
      {children}
    </RichEditorSpecialKeywordContext.Provider>
  );
};
