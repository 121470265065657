import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import { COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { $insertNodeToNearestRoot } from '@lexical/utils';
import { RichEditorDocument } from './RichEditorDocument';

export const INSERT_DOCUMENTS_COMMAND = createCommand(
  'INSERT_DOCUMENTS_COMMAND',
);

export class DocumentNode extends DecoratorBlockNode {
  static getType() {
    return 'document';
  }

  static clone(node) {
    return new DocumentNode(node.document, node.__format, node.__key);
  }

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    const node = $uploadDocument(serializedNode.id);
    node.setFormat(serializedNode.format);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      id: this.getId(),
      type: 'document',
      version: 1,
    };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-legalsurf-document-id')) {
          return null;
        }

        return {
          // eslint-disable-next-line no-use-before-define
          conversion: convertDocumentElement,
          priority: 2,
        };
      },
    };
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-legalsurf-document-id', this.__id || this.document?.id);
    const text = document.createTextNode(this.__id || this.document?.id);
    element.append(text);
    return { element };
  }

  constructor(document, format, key) {
    super(format, key);
    this.document = document;
    this.__id = document.id;
  }

  getId() {
    return this.__id;
  }

  decorate(editor, config) {
    const embedBlockTheme = config.theme.embedBlock || {};
    const className = {
      base: embedBlockTheme.base || '',
      focus: embedBlockTheme.focus || '',
    };

    return (
      <RichEditorDocument
        className={className}
        document={this.document}
        format={this.__format}
        nodeKey={this.getKey()}
      />
    );
  }
}

const $uploadDocument = (document) => new DocumentNode(document);

function convertDocumentElement(domNode) {
  const document = {
    id: domNode.getAttribute('data-legalsurf-document-id'),
    name: domNode.getAttribute('data-legalsurf-document-name'),
    extension: domNode.getAttribute('data-legalsurf-document-extension'),
    size: domNode.getAttribute('data-legalsurf-document-size'),
    url: domNode.getAttribute('data-legalsurf-document-url'),
    kind: domNode.getAttribute('data-legalsurf-document-kind'),
    isFolder: domNode.getAttribute('data-legalsurf-document-isFolder') === 'true',
  }

  if (document?.id) {
    return { node: $uploadDocument(document) };
  }

  return null;
}

export const DocumentsPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([DocumentNode])) {
      throw new Error(
        'DocumentsPlugin: DocumentNode not registered on editor (initialConfig.nodes)',
      );
    }

    return editor.registerCommand(
      INSERT_DOCUMENTS_COMMAND,
      (payload) => {
        $insertNodeToNearestRoot($uploadDocument(payload));

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return null;
};
