import React from 'react';
import {
  Box,
  Divider,
  MenuItem,
  MenuList,
  ListItemText,
  Typography,
  ListItemIcon,
  IconButton,
  Tooltip,
  Skeleton,
} from '@mui/material';

import { LSAdd, LSSendTemplate } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { CREATE_EVENT } from 'src/graphql/mutations/calendars';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useWorkflows } from '../queries';

function timeout(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export const CreateWorkflowImplementation = gql`
  mutation CreateWorkflowImplementation(
    $workflowId: ID!
    $studioId: ID!
    $filecases: [ID!]
    $events: [ID!]
    $entities: [ID!]
    $startedAt: DateTime!
  ) {
    createWorkflowImplementation(
      workflowId: $workflowId
      studioId: $studioId
      filecases: $filecases
      events: $events
      entities: $entities
      startedAt: $startedAt
    ) {
      id
    }
  }
`;

export const WorkflowsPopoverBody = ({
  onClick,
  filecases = [],
  entities = [],
  events = [],
  ...rest
}) => {
  const { loading, workflows } = useWorkflows();
  const studioId = useStudioId();
  const apolloClient = useApolloClient();

  const navigate = useNavigate();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [createWorkflowImplementation] = useMutation(
    CreateWorkflowImplementation,
  );

  // TODO: Move to hook
  const [createCalendarEvent] = useMutation(CREATE_EVENT);

  const handleWorkflowAccept = async (workflow) => {
    const createdWorkflow = await createWorkflowImplementation({
      variables: {
        workflowId: workflow.id,
        studioId,
        filecases,
        events,
        entities,
        startedAt: dayjs().toISOString(),
      },
    }).then((res) => res.data.createWorkflowImplementation);

    const dates = workflow.steps.reduce((acc, step, index) => {
      if (index === 0) {
        // TODO: Replace this for user given starting date, atm it'll be now
        const start = dayjs();
        const end = start.add(step.data.value, step.data.unit.value);
        return [[start, end]];
      }

      return [
        ...acc,
        [
          acc[acc.length - 1][1],
          acc[acc.length - 1][1].add(step.data.value, step.data.unit.value),
        ],
      ];
    }, []);

    await Promise.all([
      workflow.steps.map((step, index) => {
        switch (step.type) {
          case 'EventCreation':
            return createCalendarEvent({
              variables: {
                ...step.data,
                type: step.data?.type ?? step.data?.type?.value ?? 'task',
                workflowId: createdWorkflow.id,
                timezone:
                  Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
                studio: studioId,
                calendar: step.data.calendar?.value ?? step.data.calendar,
                // TODO: figure out how to link more filecases to the event
                filecase: filecases?.[0],
                entities,
                todos: step.data.todos,
                // ...removeNullKeys({
                // entities:
                //   Array.isArray(step.data.entities) &&
                //   step.data.entities.map((entity) => entity.value ?? entity),
                assigned: step.data?.assigned?.map((asignee) => asignee.value),
                // filecase: step.data.filecase && step.data.filecase.value,
                taskState: step.data.taskState && step.data.taskState.value,
                // }),
                start: dates[index][0].toISOString(),
                end: dates[index][1].toISOString(),
              },
            });

          // case 'Comment':
          //   return;

          default:
            break;
        }

        return null;
      }),
    ]);

    await timeout(1000);

    await apolloClient.refetchQueries({
      include: ['filecaseSingleData', 'getEntitySinglePage'],
    });
  };

  const handleClick = (workflow) => {
    dispatchWarningPromptDialog('WorkflowsPopoverBodyWorkflowClick', {
      onAccept: () => handleWorkflowAccept(workflow),
      title: `Implementar flujo de trabajo ${workflow.name}`,
      content: `Este flujo cuenta con ${workflow.steps.length} pasos ¿deseas proceder con la implementación de este flujo de trabajo?`,
    });
  };

  return (
    <Box {...rest}>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          p: 2,
          display: 'flex',
        }}
      >
        <Typography variant="h6">Flujos de trabajo</Typography>
      </Box>

      <Divider />

      <MenuList>
        {!loading &&
          workflows.map((workflow) => (
            <MenuItem key={workflow.id} onClick={() => handleClick(workflow)}>
              <ListItemIcon>
                <LSSendTemplate color="primary" />
              </ListItemIcon>

              <ListItemText>{workflow.name}</ListItemText>
            </MenuItem>
          ))}

        {!loading && !workflows.length && (
          <MenuItem
            disabled
            sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
          >
            <Typography>No encontramos flujos de trabajo</Typography>
          </MenuItem>
        )}

        {loading &&
          Array.from({ length: 5 }).map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index}>
              <ListItemText>
                <Skeleton variant="text" width={200} />
              </ListItemText>
            </MenuItem>
          ))}
      </MenuList>
    </Box>
  );
};
