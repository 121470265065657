import React from 'react';

import { WhatsAppButton } from 'src/popovers/NotificationTemplatesPopover/WhatsappButton';

export const TemplateCell = ({ row: sale }) => {
  return (
    <WhatsAppButton
      phone={sale?.entity?.phone}
      entity={sale?.entity}
      data={{ sale, filecase: sale?.filecase }}
    />
  );
};
