import React from 'react';
import Page from 'src/components/v2/Page';
import DirectoriesTable from 'src/tables/DirectoriesTable';

const Directories = () => (
  <Page title="Directorio | LegalSurf">
    <DirectoriesTable />
  </Page>
);

export default Directories;
