import React from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import { LSWorkflowIcon } from 'src/components/icons';
import { useDialog } from '../Dialogs';

export const WorkflowInfo = () => {
  const navigate = useNavigate();
  const dispatchWorkflowFormDialog = useDialog('workflowsForm');

  return (
    <Page>
      <Card>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 items-start">
              <Stack gap={1} padding={4}>
                <Typography variant="h4">Flujos de trabajo</Typography>

                <Typography variant="body1">
                  Automatiza la creación de tareas y procedimientos con la
                  creación de flujos de trabajo
                </Typography>

                <Stack gap={1} sx={{ pl: 2, mt: 2 }}>
                  <Stack direction="row" gap={1}>
                    <LSWorkflowIcon color="primary" />
                    <Typography fontWeight={500} variant="body2">
                      Proceso migratorio residente no nacionalizado
                    </Typography>
                  </Stack>

                  <Stack direction="row" gap={1}>
                    <LSWorkflowIcon color="primary" />
                    <Typography fontWeight={500} variant="body2">
                      Proceso accidente de tránsito ART
                    </Typography>
                  </Stack>

                  <Stack direction="row" gap={1}>
                    <LSWorkflowIcon color="primary" />
                    <Typography fontWeight={500} variant="body2">
                      Proceso demanda laboral despido involuntario
                    </Typography>
                  </Stack>

                  <Button
                    sx={{ maxWidth: 260, mt: 2 }}
                    variant="contained"
                    onClick={() =>
                      dispatchWorkflowFormDialog('WorkflowsTable', {
                        onSubmit: (values) => {
                          navigate('create', {
                            state: { workflowDraft: values },
                          });
                        },
                      })
                    }
                  >
                    Nuevo flujo de trabajo
                  </Button>
                </Stack>
              </Stack>
            </div>
          </div>
        </div>
      </Card>
    </Page>
  );
};
