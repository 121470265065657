import React from 'react';
import { Fade, Box, CircularProgress } from '@mui/material';

const DisabledBackdrop = ({ in: inProp, withIndicator, children }) => (
  <Box sx={{ position: 'relative', borderRadius: 1 }}>
    <Fade in={inProp}>
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, .33)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 5,
        }}
      >
        {withIndicator && <CircularProgress size={30} />}
      </Box>
    </Fade>
    {children}
  </Box>
);

export default DisabledBackdrop;
