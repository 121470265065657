import { darken } from '@mui/material';
import { palette } from '../../../foundations';

export const WARNING_DASHED = {
  props: { variant: 'dashed', color: 'warning' },
  style: {
    border: `1px dashed ${palette.grey[400]}`,
    color: palette.grey[600],

    '&:hover': {
      border: `1px dashed ${palette.warning.main}`,
      backgroundColor: palette.warning.light,
      color: palette.warning.dark,
    },
  },
};

export const PRIMARY_DASHED = {
  props: { variant: 'dashed', color: 'primary' },
  style: {
    border: `1px dashed ${palette.grey[400]}`,
    color: palette.grey[600],

    '&:hover': {
      border: `1px dashed ${palette.primary.main}`,
      backgroundColor: palette.secondary[100],
      color: palette.primary.main,
    },
  },
};

export const PRIMARY_DARK = {
  props: { variant: 'dark', color: 'primary' },
  style: {
    backgroundColor: 'primary.dark',
    color: 'white',
  },
};

export const DASHED_GRAY = {
  props: { variant: 'dashed', color: 'gray' },
  style: {
    border: `1px dashed ${palette.divider}`,
    color: `1px dashed ${palette.divider}`,
  },
};

export const LIGHT_SECONDARY = {
  props: { variant: 'light', color: 'secondary' },
  style: {
    backgroundColor: palette.secondary[100],
    color: palette.primary.main,

    '&:hover': {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
    },
  },
};

export const LIGHT_WARNING = {
  props: { variant: 'light', color: 'warning' },
  style: {
    backgroundColor: palette.warning.light,
    color: palette.warning.dark,

    '&:hover': {
      backgroundColor: darken(palette.warning.main, 0.1),
    },
  },
};

export const LIGHT_PRIMARY = {
  props: { variant: 'light', color: 'primary' },
  style: {
    backgroundColor: palette.primary.light,
    color: palette.primary.dark,

    '&:hover': {
      backgroundColor: darken(palette.primary.main, 0.1),
    },
  },
};

export const LIGHT_ERROR = {
  props: { variant: 'light', color: 'error' },
  style: {
    backgroundColor: palette.error.light,
    color: palette.error.dark,

    '&:hover': {
      backgroundColor: darken(palette.error.main, 0.1),
    },
  },
};

export default [
  WARNING_DASHED,
  PRIMARY_DASHED,
  LIGHT_PRIMARY,
  DASHED_GRAY,
  LIGHT_SECONDARY,
  LIGHT_WARNING,
  LIGHT_ERROR,
  PRIMARY_DARK,
];
