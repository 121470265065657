import React, { useRef, useState } from 'react';
import {
  Grow,
  Stack,
  Checkbox,
  Typography,
  IconButton,
  TextField,
  ClickAwayListener,
} from '@mui/material';
import { LSX as LSXIcon } from 'src/components/icons';

// TODO: Move to another place if necessary
const TodoRow = ({ id, done, title, onDelete, onBlur }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [content, setContent] = useState(title);
  const inputRef = useRef(null);

  const handleChangeContent = (event) => {
    setContent(event.target.value);
  };

  const handleBlur = () => {
    if (!isEditMode) return;
    setIsEditMode(false);
    onBlur({ id, title: content, done });
  };

  const handleEnter = (event) => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  const handleToggleCheck = (event) => {
    onBlur({ id, done: event.target.checked, title: content });
  };

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <Stack
        alignItems="center"
        direction="row"
        key={id}
        spacing={1}
        sx={{
          borderRadius: 0.4,
          cursor: 'pointer',
          transition: 'background-color 0.2s',
          p: 1,

          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, .04)',
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Checkbox
          checked={done}
          color="success"
          size="small"
          sx={{ p: '1px !important' }}
          onChange={handleToggleCheck}
        />
        {!isEditMode && (
          <Typography
            flex={1}
            fontWeight={500}
            minHeight={20}
            onClick={() => setIsEditMode(true)}
          >
            {content}
          </Typography>
        )}
        {isEditMode && (
          <TextField
            ref={inputRef}
            size="small"
            sx={{
              flex: 1,
              padding: 0,

              '& .MuiOutlinedInput-root': {
                borderRadius: 0.3,
                outline: 'none',

                '& fieldset': {
                  backgroundColor: 'transparent',
                  border: 'none',
                },
              },

              '& .MuiOutlinedInput-input': {
                p: 0.5,
              },
            }}
            value={content}
            variant="outlined"
            onBlur={handleBlur}
            onChange={handleChangeContent}
            onKeyDown={handleEnter}
          />
        )}
        {!isEditMode && (
          <Grow in={isHovered}>
            <IconButton size="small" onClick={onDelete}>
              <LSXIcon fontSize="small" />
            </IconButton>
          </Grow>
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default TodoRow;
