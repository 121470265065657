import { useUser } from '@clerk/clerk-react';
import { useEffect, useState } from 'react';

export const useStudioInvitations = () => {
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [invitations, setInvitations] = useState([]);

  useEffect(() => {
    if (user) {
      setLoading(true);

      user
        .getOrganizationInvitations()
        .then(({ data }) => {
          setInvitations(
            data.filter((invitation) => invitation.status === 'pending'),
          );
        })
        .finally(() => setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return { invitations, loading };
};
