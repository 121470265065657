import { gql } from '@apollo/client';

export const CreateContractTemplateMutation = gql`
  mutation CreateContractTemplateMutation(
    $studioId: ID!
    $name: String!
    $content: String!
  ) {
    createContractTemplate(
      studioId: $studioId
      name: $name
      content: $content
    ) {
      id
      name
      content
      studioId
    }
  }
`;

export const UpdateContractTemplateMutation = gql`
  mutation UpdateContractTemplateMutation(
    $studioId: ID!
    $id: ID!
    $name: String!
    $content: String!
  ) {
    updateContractTemplate(
      studioId: $studioId
      id: $id
      name: $name
      content: $content
    ) {
      id
      name
      content
      studioId
    }
  }
`;

export const DeleteContractTemplateMutation = gql`
  mutation DeleteContractTemplateMutation($id: ID!, $studioId: ID!) {
    deleteContractTemplate(id: $id, studioId: $studioId) {
      id
      name
      content
      studioId
    }
  }
`;
