import React from 'react';
import { Popover } from '@mui/material';

import TaskStatusPopoverContent from './TaskStatusPopoverContent';

const TaskStatusPopover = React.forwardRef(
  (
    { open, children, onClickAway, eventId, calendarId, state, ...props },
    anchorEl,
  ) => (
    <>
      {children}

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClickAway}
        {...props}
      >
        <TaskStatusPopoverContent
          calendarId={calendarId}
          eventId={eventId}
          state={state}
          onClickAway={onClickAway}
        />
      </Popover>
    </>
  ),
);

export default TaskStatusPopover;
