import { Stack, Typography } from '@mui/material';
import React from 'react';

const SettingsSection = ({ title, children }) => (
  <Stack
    direction="column"
    gap={2}
    sx={{
      '& a': {
        textDecoration: 'none',
      },
    }}
  >
    <Typography variant="h5">{title}</Typography>

    <Stack direction="column" gap={1}>
      {children}
    </Stack>
  </Stack>
);

export default SettingsSection;
