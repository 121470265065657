import React from 'react';

import Page from 'src/components/v2/Page';
import ExpensesTable from 'src/tables/ExpensesTable';

const Expenses = () => (
  <Page title="Gastos | LegalSurf">
    <ExpensesTable />
  </Page>
);

export default Expenses;
