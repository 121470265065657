import React, { forwardRef } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot } from 'lexical';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { EditorBlurPlugin, ToolbarPlugin } from './plugins';
import { RichEditorPlaceholder } from './components';
import { theme } from './theme';
import { generateEditorState } from './utils';
import { DocumentNode, DocumentsPlugin } from './plugins/DocumentsPlugin';
import {
  SpecialKeywordNode,
  SpecialKeywordPlugin,
} from './plugins/SpecialKeywordPlugin';

const editorConfig = {
  onError(error) {
    // @ts-ignore
    console.error(error);
    Sentry.captureException(error);
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    AutoLinkNode,
    LinkNode,
    DocumentNode,
    SpecialKeywordNode,
  ],

  theme,
};

export const RichEditor = forwardRef(
  (
    {
      placeholder = 'Inserte texto...',
      onChange,
      onBlur: handleBlur,
      dateProps,
      filesFormProps,
      initialValue,
      disableUpload,
      disabled,
      enableDynamicFields,
      toolbarStartComponent,
      toolbarEndComponent,
      sx = {},
    },
    ref,
  ) => {
    // const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);

    const handleChange = (editorState, editor) => {
      editorState.read(() => {
        if (!onChange) {
          return;
        }

        // Read the contents of the EditorState here.
        const root = $getRoot();
        const text = root.getTextContent();
        const html = $generateHtmlFromNodes(editor);

        if (ref) {
          // eslint-disable-next-line no-param-reassign
          ref.current = editor;
        }

        onChange(html, text);
      });
    };

    // const onRef = (_floatingAnchorElem) => {
    //   if (_floatingAnchorElem !== null) {
    //     setFloatingAnchorElem(_floatingAnchorElem);
    //   }
    // };

    return (
      <LexicalComposer
        initialConfig={{
          ...editorConfig,
          ...(initialValue
            ? {
              editorState: (editor) =>
                generateEditorState(initialValue, editor),
            }
            : {}),
          editable: !disabled,
        }}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <RichTextPlugin
              ErrorBoundary={LexicalErrorBoundary}
              contentEditable={
                <Box
                  // ref={onRef}
                  sx={{
                    resize: 'none',
                    fontSize: '15px',
                    caretColor: 'rgb(5, 5, 5)',
                    position: 'relative',
                    tabSize: '1',

                    '& > *[contenteditable]': {
                      outline: '0',
                    },

                    '& p': {
                      fontWeight: '500',
                      margin: '0',
                    },

                    '& a': {
                      color: 'secondary.light',
                      cursor: 'pointer',
                    },
                    ...sx,
                  }}
                >
                  <ContentEditable />
                </Box>
              }
              placeholder={
                <RichEditorPlaceholder>{placeholder}</RichEditorPlaceholder>
              }
            />
            <OnChangePlugin onChange={handleChange} />
            <EditorBlurPlugin onBlur={handleBlur} />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <ToolbarPlugin
              dateProps={dateProps}
              disableUpload={disableUpload}
              enableDynamicFields={enableDynamicFields}
              filesFormProps={filesFormProps}
              toolbarEndComponent={toolbarEndComponent}
              toolbarStartComponent={toolbarStartComponent}
            />
            <DocumentsPlugin />
            <SpecialKeywordPlugin />

            {/* {floatingAnchorElem && (
              <Box translate="no">
                <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                />
              </Box>
            )} */}

            <ClearEditorPlugin />
          </Box>
        </Box>
      </LexicalComposer>
    );
  },
);
