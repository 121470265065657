import React, { useMemo } from 'react';
import { lighten, Stack } from '@mui/material';
import { Formik } from 'formik';
import { useCreateStudio } from '@legalsurf/hooks';
import { useNavigate } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import { useUser } from 'src/utils/hooks/useUser';
import StudioCreationForm, { Schema } from './StudioCreationForm';

import StudioCanva from './StudioCanva';
import { useOrganizationList } from '@clerk/clerk-react';
import { usePostHog } from 'posthog-js/react';

export const StudioCreation = () => {
  const [createStudio] = useCreateStudio();
  const navigate = useNavigate();
  const { user } = useUser();
  const { setActive } = useOrganizationList();
  const posthog = usePostHog();

  const handleSubmit = async (values) => {
    const {
      data: { createStudio: studio },
    } = await createStudio({
      variables: {
        name: values.name,
        picture: values.image,
        country: values.country.value,
        metadata: {
          filecasesEstimateResponse: values.filecases.value,
          teamEstimateResponse: values.size.value,
          studioTypeEstimateResponse: values.type.value,
        },
      },
    });

    posthog.group('studio', studio.id, {
      country: values.country.value,
      name: values.name,
      onboarding: {
        filecases: values.filecases.value,
        size: values.size.value,
        type: values.type.value,
      },
    });

    posthog.capture('webappOnboarding', {
      step: 'studioCreation',
      size: values.size.value,
      type: values.type.value,
      filecases: values.filecases.value,
    });

    setActive({ organization: studio.id });
    navigate(`/onboarding/filecase`, { replace: true });
  };

  const initialValues = useMemo(
    () => ({
      country: '',
      size: null,
      filecases: null,
      image: null,
    }),
    [user],
  );

  return (
    <Page
      sx={{
        p: 0,
      }}
      title="Crea tu estudio | LegalSurf"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {(formik) => (
          <Stack direction="row" sx={{ minHeight: 'calc(100vh - 64px)' }}>
            <Stack
              display={['none', 'none', 'flex', 'flex']}
              flex={1.5}
              sx={{
                backgroundColor: (theme) =>
                  lighten(theme.palette.primary[900], 0.05),
                overflow: 'hidden',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StudioCanva
                studio={formik.values}
                sx={{
                  maxWidth: 500,
                  position: 'absolute',
                  bottom: -24,
                  right: -12,
                }}
              />
            </Stack>

            <Stack
              sx={{ bgcolor: 'white' }}
              alignItems="center"
              justifyContent="center"
              px={[4, 6, 16]}
              flex={1}
            >
              <StudioCreationForm {...formik} />
            </Stack>
          </Stack>
        )}
      </Formik>
    </Page>
  );
};
