import React from 'react';
import { SvgIcon } from '@mui/material';

const LSContracts = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M17.7197 13.7197C18.0126 13.4268 18.4874 13.4268 18.7803 13.7197L20.7803 15.7197C21.0732 16.0126 21.0732 16.4874 20.7803 16.7803L19.2808 18.2798C19.2807 18.28 19.281 18.2797 19.2808 18.2798C19.2807 18.28 19.28 18.2807 19.2798 18.2808L14.7803 22.7803C14.6397 22.921 14.4489 23 14.25 23H12.25C11.8358 23 11.5 22.6642 11.5 22.25V20.25C11.5 20.0511 11.579 19.8603 11.7197 19.7197L17.7197 13.7197ZM16.75 16.8107L13 20.5607V21.5H13.9393L17.6893 17.75L16.75 16.8107ZM18.75 16.6893L17.8107 15.75L18.25 15.3107L19.1893 16.25L18.75 16.6893Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M5.93793 5.24047C4.95337 6.48757 4.5 8.62353 4.5 12.25C4.5 15.8765 4.95337 18.0124 5.93793 19.2595C6.87634 20.4482 8.44799 21 11.25 21C11.6642 21 12 21.3358 12 21.75C12 22.1642 11.6642 22.5 11.25 22.5C8.31672 22.5 6.13837 21.9342 4.7606 20.189C3.42898 18.5023 3 15.8882 3 12.25C3 8.61176 3.42898 5.99772 4.7606 4.311C6.13837 2.56583 8.31672 2 11.25 2C11.6364 2 12.0079 2.00982 12.3648 2.03053C12.549 2.04121 12.7227 2.11935 12.8528 2.25004L19.1309 8.55411C19.2506 8.67435 19.326 8.83166 19.3448 9.00031C19.4523 9.96557 19.5 11.0462 19.5 12.25C19.5 12.9761 19.4827 13.6567 19.4454 14.2938C19.4213 14.7073 19.0664 15.0229 18.6529 14.9987C18.2394 14.9746 17.9238 14.6197 17.948 14.2062C17.9832 13.6037 18 12.9528 18 12.25C18 11.3634 17.9732 10.5602 17.9167 9.83334H14.3214C12.8026 9.83334 11.5714 8.60212 11.5714 7.08334V3.50251C11.466 3.50083 11.3588 3.5 11.25 3.5C8.44799 3.5 6.87634 4.05182 5.93793 5.24047ZM13.0714 4.59524L16.7941 8.33334H14.3214C13.6311 8.33334 13.0714 7.7737 13.0714 7.08334V4.59524ZM7.5 13.25C7.5 12.8358 7.83579 12.5 8.25 12.5H12.25C12.6642 12.5 13 12.8358 13 13.25C13 13.6642 12.6642 14 12.25 14H8.25C7.83579 14 7.5 13.6642 7.5 13.25ZM7.5 17.25C7.5 16.8358 7.83579 16.5 8.25 16.5H11.25C11.6642 16.5 12 16.8358 12 17.25C12 17.6642 11.6642 18 11.25 18H8.25C7.83579 18 7.5 17.6642 7.5 17.25Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSContracts;
