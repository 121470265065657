import { gql } from '@apollo/client';

export const GET_CALENDARS = gql`
  query getCalendars($studio: ID!) {
    calendars(studio: $studio) {
      id
      color
      name
      timezone
      canBeSeenBy {
        id
        name
        email
        picture
      }
    }
  }
`;
