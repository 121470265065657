import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { Alert, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useCustomFields } from 'src/dialogs/CustomFieldFormDialog/utils';
import { UpdateCustomFieldMutation } from 'src/dialogs/CustomFieldFormDialog';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { customFieldTypes } from '@legalsurf/common';
import { OrganizersTabToolbar } from './OrganizerTabToolbar';

const DeleteCustomField = gql`
  mutation DeleteCustomField($id: ID!, $studioId: ID!) {
    deleteCustomField(id: $id, studioId: $studioId) {
      id
      name
      studioId
    }
  }
`;

const Actions = ({ row }) => {
  const dispatchCustomFieldFormDialog = useDialog('customFieldForm');
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteCustomField] = useMutation(DeleteCustomField, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteCustomField));
      cache.gc();
    },
  });

  return [
    <Tooltip title="Editar campo personalizado">
      <GridActionsCellItem
        color="inherit"
        icon={<LSEdit color="primary" fontSize="small" />}
        label="Edit"
        onClick={() =>
          dispatchCustomFieldFormDialog(`CustomFieldsTab-${row.id}`, {
            customField: row,
          })
        }
      />
    </Tooltip>,
    <Tooltip title="Eliminar campo personalizado">
      <GridActionsCellItem
        color="inherit"
        icon={<LSTrash color="error" fontSize="small" />}
        label="Delete"
        onClick={() =>
          dispatchWarningPromptDialog(`CustomFieldsTab-${row.id}`, {
            onAccept: () =>
              deleteCustomField({
                variables: {
                  id: row.id,
                  studioId,
                },
              }),
            title: `Eliminar campo personalizado "${row.name}"`,
            content: (
              <Alert severity="error">
                ¿Estás seguro que deseas eliminar el campo personalizado?
                <br />
                <br />
                La información aplicada a este campo personalizado se perderá y
                tendras que volver a cargar todo.
              </Alert>
            ),
          })
        }
      />
    </Tooltip>,
  ];
};

export const CustomFieldsTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const dispatchCustomFieldFormDialog = useDialog('customFieldForm');
  const { value } = useTabContext();
  const studioId = useStudioId();
  const { customFields, loading } = useCustomFields({ skip: value !== index });
  const [updateCustomField] = useMutation(UpdateCustomFieldMutation);

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: false,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      headerName: 'Tipo',
      width: 110,
      editable: false,
      renderCell: ({ row }) =>
        customFieldTypes.find(
          (customFieldType) => row.type === customFieldType.value,
        )?.label || row?.type,
    },
    {
      field: 'filecase',
      type: 'actions',
      headerName: 'Ver en Expedientes',
      width: 160,
      cellClassName: 'actions',

      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: ({ row }) => {
        const handleSubmit = async (e) => {
          await updateCustomField({
            variables: {
              id: row.id,
              studioId,
              onFilecases: !row.onFilecases,
            },
          });

          openSnackbar(
            e.target.checked
              ? `Campo personalizado "${row.name}" habilitado en expedientes`
              : `Campo personalizado "${row.name}" deshabilitado en expedientes`,
            {
              severity: 'success',
            },
          );
        };

        return [
          <GridActionsCellItem
            icon={row.onFilecases ? <CheckBox /> : <CheckBoxOutlineBlank />}
            label="Expedientes"
            sx={{
              color: 'primary.main',
            }}
            onClick={handleSubmit}
          />,
        ];
      },
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      headerName: 'Ver en Directorios',
      width: 160,
      type: 'actions',
      className: 'actions',
      field: 'entity',
      editable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: ({ row }) => {
        const handleSubmit = async (e) => {
          await updateCustomField({
            variables: {
              id: row.id,
              studioId,
              onEntities: !row.onEntities,
            },
          });

          openSnackbar(
            e.target.checked
              ? `Campo personalizado "${row.name}" habilitado en Directorio`
              : `Campo personalizado "${row.name}" deshabilitado en Directorio`,
            {
              severity: 'success',
            },
          );
        };

        return [
          <GridActionsCellItem
            icon={row.onEntities ? <CheckBox /> : <CheckBoxOutlineBlank />}
            label="Expedientes"
            sx={{
              color: 'primary.main',
            }}
            onClick={handleSubmit}
          />,
        ];
      },
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 180,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      editable: false,
      getActions: Actions,
    },
  ];

  return (
    <TabPanel value={index}>
      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        components={{
          toolbar: OrganizersTabToolbar,
        }}
        componentsProps={{
          toolbar: {
            label: 'Crear campo personalizado',
            onClick: () => dispatchCustomFieldFormDialog('CustomFieldsTab')
          },
        }}
        editMode="row"
        loading={loading}
        pageSize={10}
        rowCount={customFields?.length}
        rows={customFields}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe un campo personalizado con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
        onRowClick={(params) => {
          dispatchCustomFieldFormDialog(`CustomFieldsTab-${params.row.id}`, {
            customField: params.row,
          });
        }}
      />
    </TabPanel>
  );
};
