import React from 'react';
import { Card, CardContent } from '@mui/material';

import Page from 'src/components/v2/Page';

import { useNavigate, useParams } from 'react-router-dom';
import { EntityDetail } from 'src/components/v2/EntityDetail';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const DirectorySingle = () => {
  const params = useParams();
  const studioId = useStudioId();
  const navigate = useNavigate();

  return (
    <Page title="Directorio | LegalSurf">
      <Card sx={{ width: 900, mx: 'auto' }}>
        <CardContent>
          <EntityDetail
            entityId={params.entity}
            onClose={() => navigate(`/dashboard/${studioId}/directories`)}
          />
        </CardContent>
      </Card>
    </Page>
  );
};

export default DirectorySingle;
