import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { darken } from '@mui/material';

import { LS_PERMISSIONS_MAP, ROLES_MAP } from '@legalsurf/common';
import { UPDATE_ENTITY } from 'src/graphql/mutations/entities';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { Badge } from 'src/components/Badge';
import EntityStatusPopover from '.';

export const EntityStateButton = ({ entity, badgeProps = {} }) => {
  const [open, setOpen] = useState(false);
  const cellRef = useRef(null);
  const { permissions, role } = usePermissions();

  const hasEditPermissions = permissions.includes(
    LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
  );
  const isHighRole = role === ROLES_MAP.ADMIN || role === ROLES_MAP.OWNER;

  const [updateEntity] = useMutation(UPDATE_ENTITY);

  const handleTogglePop = (event) => {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    setOpen(!open);
  };

  const title = entity.state?.name
    ? entity.state.name
    : // ? `${state.name} - Ultima actualización: ${dayjs(entity.stateUpdatedAt).format('LL')}`
      'Sin estado';

  return (
    <>
      <Badge
        tooltipLabel={title}
        tooltipProps={{ arrow: true, placement: 'left' }}
        ref={cellRef}
        {...((hasEditPermissions || isHighRole) && {
          onClick: handleTogglePop,
          sx: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: darken(entity.state?.color ?? '#F8F5FF', 0.2),
            },
          },
        })}
        label={entity.state?.name ?? '+'}
        backgroundColor={entity.state?.color ?? '#F8F5FF'}
        {...badgeProps}
      />

      <EntityStatusPopover
        anchorEl={cellRef.current}
        open={(isHighRole || hasEditPermissions) && open}
        onClickStatus={(option) =>
          updateEntity({
            variables: {
              id: entity.id,
              state: option.name,
            },
            optimisticResponse: {
              updateEntity: {
                __typename: 'Entity',
                ...entity,
                id: entity.id,
                state: {
                  id: option.id,
                  name: option.name,
                  color: option.color,
                },
                updatedAt: new Date().toISOString(),
              },
            },
          })
        }
        onClose={handleTogglePop}
      />
    </>
  );
};
