import * as Yup from 'yup';

import { formatBytes, humanizedCategories } from 'src/utils/formatters';
import { fileTypes } from 'src/config/file-types';

const documentThreshold = 10485760 * 25;

const humanizedFileCategoriesStr = Object.keys(humanizedCategories)
  .map((key) => humanizedCategories[key])
  .join(', ');

export const isGoogleDriveFile = (file) => file.kind === 'drive';
export const isOneDriveFile = (file) => file.kind === 'onedrive';
export const isDropboxFile = (file) => file.kind === 'dropbox';

const DocumentsFormValidationSchema = Yup.object({
  documents: Yup.array()
    .min(1, 'Debe adjuntar por lo menos un documento')
    .required('Debe adjuntar por lo menos un documento')
    .test(
      'file-type',
      `Los archivos deben ser de un formato permitido (${humanizedFileCategoriesStr})`,
      (files) => {
        const fileTypesValues = Object.values(fileTypes);

        return files
          .filter(
            (file) =>
              !isGoogleDriveFile(file) &&
              !isOneDriveFile(file) &&
              !isDropboxFile(file),
          )
          .every((file) => fileTypesValues.includes(file.type));
      },
    )
    .test(
      'file-size',
      `Los archivo deben ser inferior a ${formatBytes(documentThreshold)}`,
      (files) =>
        files
          .filter(
            (file) =>
              !isGoogleDriveFile(file) &&
              !isOneDriveFile(file) &&
              !isDropboxFile(file),
          )
          .every((file) => file.size <= documentThreshold),
    ),
});

export default DocumentsFormValidationSchema;
