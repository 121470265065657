import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Box } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import { useDialog } from 'src/dialogs/Dialogs';
import {
  getCategorizedFileTypes,
  getDocumentTypeOptions,
} from 'src/config/constants';
import SectionHeader from 'src/components/v2/SectionHeader';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { getFilesTableFiltersQuery, getFilesTableDocumentsQuery } from '../gql';

const initialValues = {
  search: '',
  extension: null,
  filecase: null,
};

const queryInitialValues = {
  search: '',
  extensions: [],
  filecaseId: null,
};

const Header = ({ totalItems, refetch, loading, selectedItems }) => {
  const { studioId } = useParams();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const { isDeleting, handleDeleteDocument } = useDigitalOceanSpace({
    refetchQueries: [getFilesTableDocumentsQuery],
  });

  const { hasPermissions, isHighRole } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
  ]);

  const { hasPermissions: canDelete } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.DELETE,
  ]);

  const canCreate = hasPermissions || isHighRole;

  const dispatchDocumentsFormDialog = useDialog('documentsForm');

  const { data: { filecases } = { filecases: [] }, loading: loadingFilters } =
    useQuery(getFilesTableFiltersQuery, {
      variables: {
        studioId,
      },
    });

  return (
    <Box sx={{ p: 2 }}>
      <SectionHeader
        actions={[
          ...[
            canDelete
              ? {
                  title: `Eliminar ${
                    selectedItems.length ? `(${selectedItems.length})` : ''
                  }`,
                  handleChange: () =>
                    dispatchWarningPromptDialog('SingleFilecaseDocuments', {
                      onAccept: () => handleDeleteDocument(selectedItems),
                      title: 'Borrar documento',
                      content: `¿Estas seguro que deseas borrar los documentos seleccionados? (Esta acción es permanente)`,
                    }),
                  icon: DeleteIcon,
                  disabled: isDeleting || !selectedItems.length,
                }
              : {},
          ],
        ]}
        createButton={
          canCreate
            ? [
                {
                  title: 'Subir documentos',
                  handleChange: () =>
                    dispatchDocumentsFormDialog('DocumentsTableDocForm'),
                },
              ]
            : []
        }
        filters={{
          initialValues,
          loading: loading || loadingFilters,
          onSubmit: (values) =>
            refetch({
              studioId,
              search: values.search,
              filecaseId: values.filecase?.value,
              extensions: values.extension?.value
                ? getCategorizedFileTypes()[values.extension.value]?.value
                : null,
            }),
          onReset: () => refetch(queryInitialValues),
          data: [
            {
              title: 'Tipo de archivo',
              name: 'extension',
              options: getDocumentTypeOptions(),
            },
            {
              title: 'Expediente',
              name: 'filecase',
              options: filecases.map((filecase) => ({
                value: filecase.id,
                label: filecase.title,
              })),
            },
          ],
          search: {
            placeholder: 'Buscar documento',
            name: 'search',
          },
        }}
        subtitle={`${totalItems} totales`}
        title="Documentos"
      />
    </Box>
  );
};

export default Header;
