import React, { useState } from 'react';

import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import Table from 'src/components/v3/Table';
import { getEventRowId } from 'src/utils/dates';
import { useColumns } from 'src/tables/FilecasesTable/columns';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { Box } from '@mui/material';
import FilecasesPopover from 'src/popovers/FilecasesPopover';
import { gql, useMutation } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const UpdateEntityFilecasesMutation = gql`
  mutation UpdateEntityFilecases($id: ID!, $filecases: [ID!], $studioId: ID!) {
    updateEntityFilecases(id: $id, filecases: $filecases, studioId: $studioId) {
      id
      type
      displayName
      createdAt
      updatedAt

      filecases {
        id
        title
        externalId
      }
    }
  }
`;

export const FilecasesDetail = ({ entity }) => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const dispatchFilecaseForm = useDialog('filecaseForm');
  const studioId = useStudioId();
  const columns = useColumns({ short: true });
  const [anchorEl, setAnchorEl] = useState(null);

  const [updateEntityFilecasesMutation] = useMutation(
    UpdateEntityFilecasesMutation,
  );

  const handleFilecasePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilecasePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFilecasePopoverSubmit = (values) =>
    updateEntityFilecasesMutation({
      variables: {
        id: entity.id,
        filecases: values.filecases?.map((option) => option?.value || option),
        studioId,
      },
    });

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.ACCESS,
  ]);

  const { hasPermissions: hasCreatePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.CREATE,
  ]);

  return (
    <Box>
      <Table
        checkboxSelection={false}
        columns={columns}
        getRowId={getEventRowId}
        loading={false}
        pageSize={entity.filecases.length}
        paginationMode="client"
        rows={entity.filecases ?? []}
        sxCard={{
          boxShadow: 'none',
        }}
        onRowClick={(params) =>
          hasPermissions &&
          dispatchFilecaseDialog(params.row.id, {
            id: params.row.id,
          })
        }
      />

      {hasPermissions && (
        <>
          <FilecasesPopover
            multiple
            anchorEl={anchorEl}
            anchorOrigin={{
              horizontal: 'left',
            }}
            filecases={entity.filecases.map(({ id }) => id)}
            open={Boolean(anchorEl)}
            transformOrigin={{
              horizontal: 'right',
            }}
            onClose={handleFilecasePopoverClose}
            onSubmit={handleFilecasePopoverSubmit}
          />

          <CreationInfoBanner
            buttonStr="Vincular"
            description="Asocia expedientes a tus clientes y ten control eficiente de la información, vincula un expediente o crea uno nuevo ya vinculado a tu directorio."
            secondaryButton={
              hasCreatePermissions
                ? {
                    buttonStr: 'Crear',
                    onClick: () =>
                      dispatchFilecaseForm('SingleEntiyDocumentsForm', {
                        entities: [entity.id],
                      }),
                  }
                : undefined
            }
            title="Expedientes vinculados"
            onClick={handleFilecasePopoverOpen}
          />
        </>
      )}
    </Box>
  );
};
