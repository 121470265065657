import React from 'react';

import { ContractTemplatePopoverBody } from './ContractTemplatePopoverBody';
import { PopoverBase } from '../PopoverBase';

export const ContractTemplatePopover = ({
  onClick,
  onClose,
  transformOrigin,
  anchorOrigin,
  open,
  anchorEl,
  ...props
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    open={open}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <ContractTemplatePopoverBody onClick={onClick} {...props} />
  </PopoverBase>
);
