import React, { useEffect } from 'react';
import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react';
import { Box, Button, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { Facebook, LinkedIn, Twitter, WhatsApp } from '@mui/icons-material';
import { create } from 'zustand';
import dayjs from 'dayjs';
import { useUser } from '@clerk/clerk-react';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { LSWhatsappIcon } from '../icons';

const localStoreKey = 'ShareModalShown_v1';

const shareMessages = {
  linkedin: {
    message: "Hey colegas, ¿buscando formas de hacer que tu trabajo en la firma legal sea más sencillo? ¡Acabo de descubrir una herramienta genial que realmente ha marcado la diferencia para mí! Si te gustaría probarla y ver cómo puede hacer tu vida más fácil, visita la plataforma en https://legal.surf. #LegalTech #TransformaciónDigital",
    url: 'https://legal.surf'
  },
  twitter: {
    message: "Hola a todos mis colegas, Solo quería compartir con ustedes una gran herramienta que he estado usando últimamente para trabajar. Si estás buscando simplificar tu gestion legal, definitivamente deberías echarle un vistazo #LegalTech #TransformaciónDigital",
    url: 'https://legal.surf'
  },
  facebook: {
    message: "Hey amigos abogados! Solo quería dejar esto aquí por si alguien está buscando formas de hacer que su trabajo en la firma legal sea un poco menos estresante. Recientemente descubrí una herramienta increíble que ha sido un verdadero cambio de juego para mí. La plataforma es https://legal.surf",
    url: 'https://legal.surf'
  },
  whatsapp: {
    message: "Hola, solo quería compartir algo contigo que creo que podría interesarte. He estado usando una herramienta recientemente que ha hecho maravillas para mí en mi trabajo legal. Si estás buscando simplificar tus procesos, definitivamente deberías probarla, la plataforma es https://legal.surf",
    url: 'https://legal.surf'
  },
};

function generateShareLinks(type, websiteUrl, message) {
  const links = {
    linkedin: `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(websiteUrl)}&title=${encodeURIComponent(message)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(websiteUrl)}&text=${encodeURIComponent(message)}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(websiteUrl)}&quote=${encodeURIComponent(message)}`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(message)}`
  };

  return links[type];
}


export const useShareModalStore = create((set) => ({
  isOpen: false,
  openModal: () => set({ isOpen: true }),
  closeModal: () => {
    set({ isOpen: false });
    localStorage.setItem(localStoreKey, true);
  },
}));

export const useShareModalEffect = () => {
  const { user, isLoaded } = useUser();
  const studioId = useStudioId();
  const { isOpen, openModal } = useShareModalStore();
  const isModalShown = localStorage.getItem(localStoreKey)

  useEffect(() => {
    if (!isModalShown && studioId && isLoaded && !isOpen && dayjs(user?.createdAt).isBefore(dayjs().add(1, 'week'))) {

      setTimeout(() => {
        openModal();
        localStorage.setItem(localStoreKey, true);
      }, 2000)
    }
  }, [isLoaded, isModalShown, isOpen, openModal, studioId, user?.createdAt])
}

const ControlModalContent = () => {
  const posthog = usePostHog();

  const handleClick = (type) => () => {
    window.open(generateShareLinks(type, shareMessages[type].url, shareMessages[type].message))
    posthog.capture('Share', {
      type
    })
  }

  return (
    <Stack gap={2}>
      <Typography variant='h5'>
        ¿Te resulta util LegalSurf?
      </Typography>

      <Typography fontWeight={500} marginBottom={1} variant='body2'>
        Ayuda a fortalecer nuestra comunidad legal y comparte esta poderosa herramienta con tus colegas. Juntos, podemos crear un impacto positivo y facilitar el acceso a la información legal para todos.
      </Typography>

      <Stack alignItems="center" direction="row" gap={1}>
        <Typography>
          Compartir por:
        </Typography>

        <Stack direction="row" gap={0.5}>
          <IconButton onClick={handleClick('whatsapp')}>
            <WhatsApp htmlColor='lightgreen' />
          </IconButton>
          <IconButton onClick={handleClick('facebook')}>
            <Facebook htmlColor='darkblue' />
          </IconButton>
          <IconButton onClick={handleClick('twitter')}>
            <Twitter htmlColor="lightblue" />
          </IconButton>
          <IconButton onClick={handleClick('linkedin')}>
            <LinkedIn htmlColor='darkblue' />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

const ImageModalContent = () => {
  const posthog = usePostHog();

  const handleClick = () => {
    window.open('https://api.whatsapp.com/send?phone=&text=Si%20recibes%20este%20mensaje%20creo%20que%20necesitas%20actualizarte%20como%20abogado%20%F0%9F%A6%96%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%E2%9A%96%EF%B8%8F%F0%9F%A7%91%F0%9F%8F%BB%E2%80%8D%E2%9A%96%EF%B8%8F%0A%0A%C2%A1Es%20broma!%20%F0%9F%A4%A3%20Solo%20estoy%20utilizando%20la%20APP%20legalsurf%20para%20digitalizar%20mi%20despacho%20y%20quiero%20recomend%C3%A1rtelo%20%F0%9F%8F%84%F0%9F%8F%BC%E2%80%8D%E2%99%82%EF%B8%8F%0A%0AAqu%C3%AD%20te%20comparto%20su%20web%20para%20que%20eches%20un%20vistazo%20%F0%9F%91%89%F0%9F%8F%BC%20https%3A%2F%2Flegal.surf')
    posthog.capture('Share', {
      type: 'rex'
    })
  }

  return (
    <Stack alignItems="center" gap={2} px={4}>
      <Box component="img" src="/trexfer.gif" />
      <Typography fontSize={26} fontWeight={600} maxWidth={300} textAlign="center" variant="h4">
        ¿Tienes un colega dinosaurio?
      </Typography>

      <Typography>
        Recomienda LegalSurf y ayúdalo a evolucionar.
      </Typography>

      <Button color="success" startIcon={<LSWhatsappIcon fontSize="large" />} variant='contained' onClick={handleClick}>
        Recomendar a un amigo
      </Button>
    </Stack>
  )
}

export const ShareWithFriendsModal = () => {
  const { isOpen, closeModal } = useShareModalStore()
  const variant = useFeatureFlagVariantKey('share-legalsurf');

  if (!variant) {
    return null
  }

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={closeModal}
    >
      <DialogContent>
        {variant === 'image' ? <ImageModalContent /> : <ControlModalContent />}
      </DialogContent>
    </Dialog>
  )

}