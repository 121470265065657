import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';

import FilecaseForm from 'src/forms/FilecaseForm';
import { useBreakpoints } from 'src/utils/hooks/common';
import { useFilecaseForm } from './useFilecaseForm';

const FilecaseFormDialog = ({
  open,
  onClose,
  filecase = {},
  entities,
  defaultAssigned,
}) => {
  const { isMobile } = useBreakpoints();
  const { initialValues, handleSubmit } = useFilecaseForm({
    filecase,
    entities,
    onSubmit: onClose,
  });

  return (
    <Dialog
      PaperProps={{
        sx: {
          maxWidth: 650,
        },
      }}
      fullScreen={isMobile}
      open={open}
    >
      <DialogTitle>Expediente</DialogTitle>

      <FilecaseForm
        filecaseId={filecase?.id}
        hideAssigned={!!defaultAssigned}
        initialValues={initialValues}
        onCancel={onClose}
        onSubmit={handleSubmit}
      />
    </Dialog>
  );
};

export default FilecaseFormDialog;
