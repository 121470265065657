import { gql } from '@apollo/client';

export const GET_SIDES = gql`
  query sides($studioId: ID!) {
    sides(studio: $studioId) {
      id
      name
      studioId
      updatedAt
    }
  }
`;
