import React from 'react';
import { SvgIcon } from '@mui/material';

const LSX = (props) => (
  <SvgIcon {...props}>
    <g
      fill="inherit"
      transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
    >
      <rect fill="inherit" height="2" rx="1" width="16" x="0" y="7" />
      <rect
        fill="#000000"
        height="2"
        opacity="0.5"
        rx="1"
        transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)"
        width="16"
        x="0"
        y="7"
      />
    </g>
  </SvgIcon>
);

export default LSX;
