const MuiFormLabel = {
  styleOverrides: {
    // root: {
    //   fontWeight: 700,
    //   marginBottom: 12,
    //   transform: 'none !important',
    //   position: 'static !important',
    // },
  },
};

export default MuiFormLabel;
