import React from 'react';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

import CalendarDayChip from 'src/components/v3/CalendarDayChip';

import { generateDateRange, getWeeklyStyles, useCalendarTableEvents } from '../utils';

export const CalendarAgendaDays = ({
  selectedCalendars,
  filters,
  selectedDate,
  onSelectDate,
  weekly,
}) => {
  const dateRanges = generateDateRange(selectedDate);
  const { data: monthlyEvents } = useCalendarTableEvents(selectedDate, selectedCalendars, filters, {
    fetchPolicy: 'cache-only',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {dateRanges.map((date, idx) => {
        const tooltipEvents = monthlyEvents?.filter((event) => {
          if (event.allDay) {
            return dayjs(event.start, { utc: true }).isSame(date, 'day');
          }

          return dayjs(event.start).isSame(date, 'day');
        });

        return (
          <CalendarDayChip
            active={date.isSame(selectedDate, 'day')}
            date={date}
            events={tooltipEvents}
            fade={idx === 0 || idx === dateRanges.length - 1}
            key={date}
            sx={
              weekly && idx > 0 && idx < dateRanges.length - 1
                ? getWeeklyStyles(date)
                : {}
            }
            weekly={weekly}
            onClick={() => onSelectDate(date)}
          />
        );
      })}
    </Box>
  );
};
