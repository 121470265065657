import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Typography,
  Chip,
  Tooltip,
  Avatar,
} from '@mui/material';

import { stringAvatar } from 'src/utils/formatters';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { entityTypesObj } from '@legalsurf/common';
import { WhatsApp } from '@mui/icons-material';
import { NotificationTemplatesPopover } from 'src/popovers/NotificationTemplatesPopover';
import {
  isValidWhatsappNumber,
  sendWhatsappMsg,
} from 'src/utils/notificationTemplates';

export const CalendarEventDetailsEntity = ({ filecase, entity, event }) => {
  const studioId = useStudioId();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenFilecasesPopover = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilecasesPopover = () => {
    setAnchorEl(null);
  };

  const isWhatsappValid = isValidWhatsappNumber(entity.phone);
  const isPhoneEmpty = entity.phone === null || entity.phone?.length === 0;

  return (
    <>
      <NotificationTemplatesPopover
        anchorEl={anchorEl}
        data={{ filecase, event }}
        entity={entity}
        open={Boolean(anchorEl)}
        onClick={(msg) => {
          sendWhatsappMsg(entity.phone, msg);
        }}
        onClose={handleCloseFilecasesPopover}
      />

      <Box
        key={entity.id}
        sx={{
          display: 'flex',
          alignItems: 'center',
          p: 1,
          borderRadius: 0.4,
          cursor: 'pointer',
          '& > * + *': {
            marginLeft: 1,
          },
          '&:hover': {
            backgroundColor: 'primary.100',
          },
        }}
        onClick={() =>
          window.open(
            `/dashboard/${studioId}/directories/${entity.id}`,
            '_blank',
          )
        }
      >
        <Avatar
          {...stringAvatar(entity.displayName, {
            width: 30,
            height: 30,
            fontSize: 12,
          })}
        />

        <Chip
          color="primary"
          label={entityTypesObj[entity.type]}
          size="small"
          variant="light"
        />

        <Typography sx={{ ml: 1 }}>{entity.displayName}</Typography>

        <Box sx={{ ml: 'auto' }}>
          <Tooltip
            disableFocusListener={!isPhoneEmpty}
            disableHoverListener={!isPhoneEmpty}
            disableTouchListener={!isPhoneEmpty}
            title="Envia plantillas personalizados por Whatsapp agregando primero un número de teléfono valido"
          >
            <span>
              <IconButton
                disabled={!isWhatsappValid}
                variant="text"
                onClick={handleOpenFilecasesPopover}
              >
                <WhatsApp
                  sx={{
                    color: isWhatsappValid ? '#24d366' : undefined,
                    opacity: isWhatsappValid ? 1 : 0.85,
                  }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </>
  );
};
