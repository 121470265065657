import React from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox as MuiCheckboxField,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';

const CheckField = ({
  gridProps = { xs: 12 },
  disabled,
  label,
  labelPlacement = 'end',
  name,
  onChange,
  ...props
}) => {
  const { isSubmitting, isValidating } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const handleChangeCheckField = (e) => {
    helpers.setValue(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Grid item {...gridProps}>
      <FormControlLabel
        {...field}
        control={<MuiCheckboxField checked={field.value} {...props} />}
        disabled={isSubmitting || isValidating || disabled}
        label={label}
        labelPlacement={labelPlacement}
        value={field.value}
        onChange={handleChangeCheckField}
      />

      {meta.error && (
        <Typography sx={{ color: 'error.main', marginTop: 1 }}>
          {meta.error}
        </Typography>
      )}
    </Grid>
  );
};

export default CheckField;
