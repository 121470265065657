import React from 'react';
import { SvgIcon } from '@mui/material';

const LSClock = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M9.25 3C9.25 2.58579 9.58579 2.25 10 2.25H14C14.4142 2.25 14.75 2.58579 14.75 3C14.75 3.41421 14.4142 3.75 14 3.75H10C9.58579 3.75 9.25 3.41421 9.25 3ZM12 5.75C7.99594 5.75 4.75 8.99594 4.75 13C4.75 17.0041 7.99594 20.25 12 20.25C16.0041 20.25 19.25 17.0041 19.25 13C19.25 8.99594 16.0041 5.75 12 5.75ZM3.25 13C3.25 8.16751 7.16751 4.25 12 4.25C16.8325 4.25 20.75 8.16751 20.75 13C20.75 17.8325 16.8325 21.75 12 21.75C7.16751 21.75 3.25 17.8325 3.25 13ZM12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V12.6893L14.5303 14.4697C14.8232 14.7626 14.8232 15.2374 14.5303 15.5303C14.2374 15.8232 13.7626 15.8232 13.4697 15.5303L11.4697 13.5303C11.329 13.3897 11.25 13.1989 11.25 13V9C11.25 8.58579 11.5858 8.25 12 8.25Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSClock;
