import React, { useState, useCallback } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import HoursTrackingTable from 'src/tables/HoursTrackingTable';
import HoursWorkedPerMemberTable from 'src/tables/HoursWorkedPerMemberTable';
import SectionHeader from 'src/components/v2/SectionHeader';
import HoursTrackingDialog from 'src/dialogs/HoursTrackingDialog';

export const getHoursTrackingTableFilters = gql`
  query getHoursTrackingTableFilters($studioId: ID!) {
    members(studio: $studioId, status: ACTIVE) {
      id
      name
      email
      picture
      status
      role
    }

    filecases(studio: $studioId) {
      id
      title
    }
  }
`;

export const getHoursTrackingTableDataQuery = gql`
  query getHoursTrackingTableData(
    $studioId: ID!
    $filecases: [ID!]
    $assigned: [ID!]
    $limit: Int
    $offset: Int
  ) {
    getHoursTracking(
      studioId: $studioId
      filecases: $filecases
      assigned: $assigned
      offset: $offset
      limit: $limit
    ) {
      data {
        id
        amount
        createdAt
        assignedTo {
          id
          name
          picture
        }
        filecase {
          id
          title
        }
      }
      count
    }

    hoursTrackingPerUser(
      studioId: $studioId
      assigned: $assigned
      filecases: $filecases
    ) {
      hours
      assigned {
        picture
        name
        id
      }
    }
  }
`;

const hoursTrackingFiltersInitialValues = {
  assigned: [],
  filecases: [],
};

const HoursTracking = () => {
  const { studioId } = useParams();
  const [openHoursTrackingDialog, setOpenHoursTrackingDialog] = useState(false);

  const { data: filtersData, loading } = useQuery(
    getHoursTrackingTableFilters,
    {
      variables: {
        studioId,
      },
    },
  );

  const hoursWorkedPerMemberTableQueryData = useQuery(
    getHoursTrackingTableDataQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        studioId,
        limit: 10,
        offset: 0,
      },
    },
  );

  const handleFiltersSubmit = useCallback(
    (values) =>
      hoursWorkedPerMemberTableQueryData.refetch({
        studioId,
        assigned: values.assigned?.map((assigned) => assigned.value),
        filecases: values.filecases?.map((filecase) => filecase.value),
      }),
    [hoursWorkedPerMemberTableQueryData, studioId],
  );

  return (
    <Page title="Carga de Horas | LegalSurf">
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <SectionHeader
            createButton={{
              title: 'Cargar horas',
              handleChange: () => setOpenHoursTrackingDialog(true),
            }}
            filters={{
              initialValues: hoursTrackingFiltersInitialValues,
              loading,
              onSubmit: handleFiltersSubmit,
              onReset: () =>
                hoursWorkedPerMemberTableQueryData.refetch({
                  studioId,
                  ...hoursTrackingFiltersInitialValues,
                }),
              data: [
                {
                  title: 'Usuarios',
                  name: 'assigned',
                  isMulti: true,
                  options: filtersData?.members?.map((member) => ({
                    label: member.name,
                    value: member.id,
                  })),
                },
                {
                  title: 'Expedientes',
                  name: 'filecases',
                  isMulti: true,
                  options: filtersData?.filecases?.map((filecase) => ({
                    label: filecase.title,
                    value: filecase.id,
                  })),
                },
              ],
            }}
            title="Time tracking"
          />
        </CardContent>
      </Card>

      <Grid container spacing={2}>
        <Grid item md={5} xs={12}>
          <HoursWorkedPerMemberTable {...hoursWorkedPerMemberTableQueryData} />
        </Grid>

        <Grid item md={7} xs={12}>
          <HoursTrackingTable {...hoursWorkedPerMemberTableQueryData} />
        </Grid>
      </Grid>

      <HoursTrackingDialog
        open={openHoursTrackingDialog}
        onClose={() => setOpenHoursTrackingDialog(false)}
      />
    </Page>
  );
};

export default HoursTracking;
