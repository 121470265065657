import * as Yup from 'yup';

const CalendarEventFormSchema = Yup.object({
  title: Yup.string()
    .min(4, 'Minimo 4 caracteres.')
    .max(144, 'Maximo 144 caracteres.')
    .required('Este campo es requerido.'),
  calendar: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().nullable().required('Debes seleccionar un calendario.')
      : Yup.object().nullable().required('Debes seleccionar un calendario.'),
  ),
  taskState: Yup.object()
    .nullable()
    .when('type', {
      is: 'task',
      then: Yup.lazy((value) =>
        typeof value === 'string'
          ? Yup.string().nullable().required('El estado es requerido.')
          : Yup.object().nullable().required('El estado es requerido.'),
      ),
    }),
});

export default CalendarEventFormSchema;
