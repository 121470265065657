import React, { useMemo, useRef, useState } from 'react';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';

import { CheckListPopover } from 'src/popovers/CheckListPopover';
import { useCreateTodo, useDeleteTodo, useUpdateTodo } from '@legalsurf/hooks';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const CheckListCell = ({ event }) => {
  const [open, setOpen] = useState(false);
  const studioId = useStudioId();
  const popoverRef = useRef(null);
  const [porcentage, title] = useMemo(() => {
    const porcetange = event.todos.length
      ? Math.floor(
          (event.todos.filter((todo) => todo.done).length /
            event.todos.length) *
            100,
        )
      : null;

    const porcentageTitle =
      porcetange === null
        ? 'Click acá para crear una lista de subtareas'
        : `${porcetange}% completado`;

    return [porcetange, porcentageTitle];
  }, [event.todos]);

  const [createTodo] = useCreateTodo();
  const [deleteTodo] = useDeleteTodo();
  const [updateTodo] = useUpdateTodo();

  return (
    <>
      <CheckListPopover
        anchorEl={popoverRef.current}
        open={open}
        todos={event?.todos}
        onClose={() => setOpen(false)}
        onCreate={() =>
          createTodo({
            optimisticResponse: {
              createEventTodo: {
                __typename: 'EventTodo',
                id: Math.random().toString(),
                calendarId: event.calendarId,
                eventId: event.id,
                studioId,
                title: '',
                done: false,
              },
            },
            variables: {
              studioId,
              eventId: event.id,
              calendarId: event.calendarId,
              title: '',
            },
          })
        }
        onDelete={(todo) =>
          deleteTodo({
            variables: {
              calendarId: event.calendarId,
              studioId,
              id: todo.id,
            },
          })
        }
        onUpdate={(todo) =>
          updateTodo({
            variables: {
              studioId,
              calendarId: event.calendarId,
              eventId: event.id,
              ...todo,
            },
            optimisticResponse: {
              updateEventTodo: {
                studioId,
                calendarId: event.calendarId,
                eventId: event.id,
                ...todo,
              },
            },
          })
        }
      />

      <Tooltip title={title} visibility={porcentage ? 'initial' : 'hidden'}>
        <IconButton
          ref={popoverRef}
          onClick={(e) => {
            e?.stopPropagation();
            setOpen(true);
          }}
        >
          <Box sx={{ position: 'relative', height: 25, width: 25 }}>
            <CircularProgress
              size={25}
              sx={{
                mr: 2,
                color: 'grey.100',
                position: 'absolute',
              }}
              thickness={8}
              value={100}
              variant="determinate"
            />

            <CircularProgress
              color={porcentage === 100 ? 'success' : 'primary'}
              size={25}
              sx={{ width: 100, borderRadius: 1, height: 10 }}
              thickness={8}
              value={porcentage}
              variant="determinate"
            />
          </Box>
        </IconButton>
      </Tooltip>
    </>
  );
};
