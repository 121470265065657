import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import React from 'react';

export const CreationInfoBanner = ({
  title,
  buttonStr,
  description,
  onClick: handleClick,

  secondaryButton: {
    onClick: handleSecondaryButtonClick,
    buttonStr: secondaryButtonStr,
  } = {},
  sx,
}) => (
  <Card
    sx={{
      border: '1px dashed',
      borderColor: 'primary.main',
      backgroundColor: 'primary.100',
      boxShadow: 0,
      ...sx,
    }}
  >
    <CardContent>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography sx={{ fontWeight: '800', fontSize: 14 }} variant="h6">
            {title}
          </Typography>

          <Typography
            sx={{
              fontWeight: '600',
              fontSize: 14,
              color: 'rgb(126, 130, 153)',
            }}
            variant="h6"
          >
            {description}
          </Typography>
        </Box>

        <ButtonGroup orientation="vertical" sx={{ gap: 0.5 }}>
          <Button color="primary" variant="contained" onClick={handleClick}>
            {buttonStr}
          </Button>

          {!!secondaryButtonStr && (
            <Button
              color="info"
              variant="contained"
              onClick={handleSecondaryButtonClick}
            >
              {secondaryButtonStr}
            </Button>
          )}
        </ButtonGroup>
      </Box>
    </CardContent>
  </Card>
);
