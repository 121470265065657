import React, { useState, useRef } from 'react';
import { Avatar, Box, Button, IconButton, Stack, Tooltip } from '@mui/material';

import { AssigneePopoverBody } from './AssigneePopoverBody';
import { Add } from '@mui/icons-material';

export const AssigneePopover = ({
  selectedAssignee = null,
  touchable,
  onSubmit,
  sx = {},
}) => {
  const [open, setOpen] = useState(false);
  const popoverRef = useRef(null);

  return (
    <AssigneePopoverBody
      anchorEl={popoverRef.current}
      open={open}
      selectedAssignee={selectedAssignee}
      onClickAway={() => {
        setOpen(false);
      }}
      onSubmit={onSubmit}
    >
      <Box ref={popoverRef}>
        {selectedAssignee ? (
          <Button
            variant="text"
            onClick={(event) => {
              event.stopPropagation();
              setOpen(true);
            }}
            sx={{ minWidth: 'initial', ...sx }}
            disabled={!touchable}
          >
            <Tooltip key={selectedAssignee.id} title={selectedAssignee.name}>
              <Avatar
                alt="Avatar"
                size="small"
                sx={{ bgcolor: 'blue' }}
                src={selectedAssignee.picture}
              />
            </Tooltip>
          </Button>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={sx}
          >
            <Tooltip title="Click para asignar personas">
              <IconButton
                disabled={!touchable}
                color="primary"
                size="small"
                sx={{ bgcolor: 'background.input' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(true);
                }}
              >
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Box>
    </AssigneePopoverBody>
  );
};
