import React from 'react';
import {
  Box,
  ListItem,
  IconButton,
  ListItemText,
  Typography,
  Chip,
  Tooltip,
  Stack,
} from '@mui/material';

import { getEntityType } from 'src/utils/formatters';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LSTrash } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import { DELETE_ENTITY_FROM_FILECASE } from 'src/graphql/mutations/filecases';
import { useMutation } from '@apollo/client';
import { WhatsAppButton } from 'src/popovers/NotificationTemplatesPopover/WhatsappButton';

const Entity = ({ isWithActions = true, filecase, entity }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.UPDATE,
  ]);

  const [deleteEntityFromFilecase] = useMutation(DELETE_ENTITY_FROM_FILECASE, {
    variables: {
      entity: entity.id,
      filecase: filecase.id,
      studioId,
    },
  });

  const handleEntityDelete = () =>
    dispatchWarningPromptDialog(`SingleFilecaseEntities-${entity.id}`, {
      onAccept: deleteEntityFromFilecase,
      title: 'Desvincular entidad',
      content: `Estas seguro que deseas desvincular a ${entity.displayName} de tu expediente?`,
    });

  return (
    <ListItem
      key={entity.id}
      secondaryAction={
        <Stack direction="row" gap={0.5}>
          <WhatsAppButton
            phone={entity.phone}
            entity={entity}
            data={{ filecase }}
          />

          {isWithActions && hasPermissions && (
            <Tooltip title="Desvincular entidad">
              <IconButton variant="text" onClick={handleEntityDelete}>
                <LSTrash color="error" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: 1,
        p: 1,
        '&:hover': {
          backgroundColor: 'primary.100',
        },
      }}
    >
      <ListItemText
        primary={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography
              color="textSecondary"
              fontWeight="bold"
              sx={{ maxWidth: 380 }}
            >
              {entity.displayName}
            </Typography>

            <Chip
              color="primary"
              label={getEntityType(entity.type)}
              variant="light"
            />
          </Box>
        }
        onClick={() =>
          window.open(
            `/dashboard/${studioId}/directories/${entity.id}`,
            '_blank',
          )
        }
      />
    </ListItem>
  );
};

export default Entity;
