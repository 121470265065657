import React, { useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { darken } from '@mui/material';

import { LS_PERMISSIONS_MAP, ROLES_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { Badge } from 'src/components/Badge';
import { UPDATE_FILECASE } from 'src/graphql/mutations/filecases';
import StatusPopover from '.';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const FilecaseStateButton = ({ filecase, badgeProps = {} }) => {
  const [open, setOpen] = useState(false);
  const studioId = useStudioId();
  const cellRef = useRef(null);
  const { permissions, role } = usePermissions();

  const hasEditPermissions = permissions.includes(
    LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
  );
  const isHighRole = role === ROLES_MAP.ADMIN || role === ROLES_MAP.OWNER;

  const [updateFilecase] = useMutation(UPDATE_FILECASE);

  const handleTogglePop = (event) => {
    if (event?.stopPropagation) {
      event.stopPropagation();
    }

    setOpen(!open);
  };

  const title = filecase.state?.name ? filecase.state.name : 'Sin estado';

  return (
    <>
      <Badge
        tooltipLabel={title}
        tooltipProps={{ arrow: true, placement: 'left' }}
        ref={cellRef}
        {...((hasEditPermissions || isHighRole) && {
          onClick: handleTogglePop,
          sx: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: darken(filecase.state?.color ?? '#F8F5FF', 0.2),
            },
          },
        })}
        label={filecase.state?.name ?? '+'}
        backgroundColor={filecase.state?.color ?? '#F8F5FF'}
        {...badgeProps}
      />

      <StatusPopover
        anchorEl={cellRef.current}
        open={(isHighRole || hasEditPermissions) && open}
        onClickStatus={(option) =>
          updateFilecase({
            variables: {
              filecase: filecase.id,
              studio: studioId,
              state: option.name,
              title: filecase.title,
            },
            optimisticResponse: {
              updateFilecase: {
                __typename: 'Filecase',
                ...filecase,
                id: filecase.id,
                state: {
                  id: option.id,
                  name: option.name,
                  color: option.color,
                },
                updatedAt: new Date().toISOString(),
              },
            },
          })
        }
        onClose={handleTogglePop}
      />
    </>
  );
};
