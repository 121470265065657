import React from 'react';

import { Alert, AlertTitle, Box } from '@mui/material';
import { PricingTableDialog } from './PricingTableDialog';
import { useSubscription } from './useSubscription';

export const SubscriptionWatcher = () => {
  const { subscription } = useSubscription();

  return (
    <>
      {subscription?.alertMsg && (
        <Box sx={{ m: [0.5, 2], mb: 0 }}>
          <Alert
            severity={subscription.alertSeverity || 'error'}
            variant="filled"
          >
            {subscription.alertTitle && (
              <AlertTitle>{subscription.alertTitle}</AlertTitle>
            )}

            {subscription.alertMsg}
          </Alert>
        </Box>
      )}

      <PricingTableDialog />
    </>
  );
};
