import React from 'react';
import { SvgIcon } from '@mui/material';

const LSPin = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12.7195 6.18384C11.5463 6.98774 10.0137 8.21765 7.94501 10.0118C7.92513 10.029 7.90436 10.0452 7.88278 10.0603C7.35255 10.4302 7.27417 11.2106 7.79915 11.7356L10.2752 14.2117C10.2769 14.2134 10.2786 14.215 10.2803 14.2167C10.282 14.2184 10.2837 14.2201 10.2854 14.2218L12.7615 16.6979C13.2865 17.2229 14.0669 17.1445 14.4368 16.6143L15.0519 17.0435L14.4368 16.6143C15.9889 14.3899 17.1064 12.773 17.8776 11.5534C18.6597 10.3166 19.0359 9.56274 19.1693 9.04713C19.2318 8.80521 19.2323 8.64552 19.2119 8.52973C19.1919 8.41583 19.1437 8.29733 19.0401 8.14756C18.9307 7.9892 18.7721 7.81351 18.5413 7.58296C18.4354 7.47723 18.3209 7.36632 18.1937 7.24309L18.1647 7.21499C18.0273 7.08189 17.8776 6.9366 17.7197 6.77865C17.5021 6.56105 17.2967 6.34396 17.1119 6.1486C17.0306 6.0627 16.9533 5.98099 16.8807 5.90529C16.6317 5.64578 16.4264 5.44438 16.2304 5.28996C15.8742 5.00924 15.5826 4.90483 15.1558 4.96986C14.6524 5.04657 13.9082 5.36932 12.7195 6.18384ZM9.74998 15.8078L11.7008 17.7586C12.8173 18.8751 14.7086 18.8462 15.667 17.4726C17.214 15.2556 18.3524 13.6091 19.1454 12.3551C19.9274 11.1185 20.4234 10.1888 20.6215 9.4227C20.7246 9.02377 20.7548 8.64306 20.6894 8.2704C20.6236 7.89584 20.4698 7.57783 20.274 7.29452C20.084 7.0198 19.8458 6.76584 19.6013 6.52165C19.4869 6.40738 19.3642 6.2886 19.2392 6.16754L19.2084 6.13764C19.0712 6.00477 18.9291 5.86675 18.7803 5.71799C18.5774 5.5151 18.3974 5.3247 18.2195 5.1367C18.1343 5.04659 18.0496 4.95703 17.9631 4.8669C17.7075 4.60039 17.4418 4.33477 17.1588 4.11175C16.557 3.6376 15.8584 3.34549 14.9298 3.48698C14.0779 3.61679 13.1016 4.10363 11.8716 4.94646C10.6316 5.79614 9.05567 7.06388 6.9897 8.85475C5.65052 9.82058 5.63151 11.6893 6.73849 12.7963L8.68932 14.7471L2.21967 21.2167C1.92678 21.5096 1.92678 21.9845 2.21967 22.2774C2.51256 22.5703 2.98744 22.5703 3.28033 22.2774L9.74998 15.8078Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.21967 9.21967C4.51256 8.92678 4.98744 8.92678 5.28033 9.21967L14.7803 18.7197C15.0732 19.0126 15.0732 19.4874 14.7803 19.7803C14.4874 20.0732 14.0126 20.0732 13.7197 19.7803L4.21967 10.2803C3.92678 9.98744 3.92678 9.51256 4.21967 9.21967Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.2197 2.21967C14.5126 1.92678 14.9874 1.92678 15.2803 2.21967L22.2803 9.21967C22.5732 9.51256 22.5732 9.98744 22.2803 10.2803C21.9874 10.5732 21.5126 10.5732 21.2197 10.2803L14.2197 3.28033C13.9268 2.98744 13.9268 2.51256 14.2197 2.21967Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M12.7195 6.18384C11.5463 6.98774 10.0137 8.21765 7.94501 10.0118C7.92513 10.029 7.90436 10.0452 7.88278 10.0603C7.35255 10.4302 7.27417 11.2106 7.79915 11.7356L10.2752 14.2117C10.2769 14.2134 10.2786 14.215 10.2803 14.2167C10.282 14.2184 10.2837 14.2201 10.2854 14.2218L12.7615 16.6979C13.2865 17.2229 14.0669 17.1445 14.4368 16.6143L15.0519 17.0435L14.4368 16.6143C15.9889 14.3899 17.1064 12.773 17.8776 11.5534C18.6597 10.3166 19.0359 9.56274 19.1693 9.04713C19.2318 8.80521 19.2323 8.64552 19.2119 8.52973C19.1919 8.41583 19.1437 8.29733 19.0401 8.14756C18.9307 7.9892 18.7721 7.81351 18.5413 7.58296C18.4354 7.47723 18.3209 7.36632 18.1937 7.24309L18.1647 7.21499C18.0273 7.08189 17.8776 6.9366 17.7197 6.77865C17.5021 6.56105 17.2967 6.34396 17.1119 6.1486C17.0306 6.0627 16.9533 5.98099 16.8807 5.90529C16.6317 5.64578 16.4264 5.44438 16.2304 5.28996C15.8742 5.00924 15.5826 4.90483 15.1558 4.96986C14.6524 5.04657 13.9082 5.36932 12.7195 6.18384ZM9.74998 15.8078L11.7008 17.7586C12.8173 18.8751 14.7086 18.8462 15.667 17.4726C17.214 15.2556 18.3524 13.6091 19.1454 12.3551C19.9274 11.1185 20.4234 10.1888 20.6215 9.4227C20.7246 9.02377 20.7548 8.64306 20.6894 8.2704C20.6236 7.89584 20.4698 7.57783 20.274 7.29452C20.084 7.0198 19.8458 6.76584 19.6013 6.52165C19.4869 6.40738 19.3642 6.2886 19.2392 6.16754L19.2084 6.13764C19.0712 6.00477 18.9291 5.86675 18.7803 5.71799C18.5774 5.5151 18.3974 5.3247 18.2195 5.1367C18.1343 5.04659 18.0496 4.95703 17.9631 4.8669C17.7075 4.60039 17.4418 4.33477 17.1588 4.11175C16.557 3.6376 15.8584 3.34549 14.9298 3.48698C14.0779 3.61679 13.1016 4.10363 11.8716 4.94646C10.6316 5.79614 9.05567 7.06388 6.9897 8.85475C5.65052 9.82058 5.63151 11.6893 6.73849 12.7963L8.68932 14.7471L2.21967 21.2167C1.92678 21.5096 1.92678 21.9845 2.21967 22.2774C2.51256 22.5703 2.98744 22.5703 3.28033 22.2774L9.74998 15.8078Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M4.21967 9.21967C4.51256 8.92678 4.98744 8.92678 5.28033 9.21967L14.7803 18.7197C15.0732 19.0126 15.0732 19.4874 14.7803 19.7803C14.4874 20.0732 14.0126 20.0732 13.7197 19.7803L4.21967 10.2803C3.92678 9.98744 3.92678 9.51256 4.21967 9.21967Z"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M14.2197 2.21967C14.5126 1.92678 14.9874 1.92678 15.2803 2.21967L22.2803 9.21967C22.5732 9.51256 22.5732 9.98744 22.2803 10.2803C21.9874 10.5732 21.5126 10.5732 21.2197 10.2803L14.2197 3.28033C13.9268 2.98744 13.9268 2.51256 14.2197 2.21967Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSPin;
