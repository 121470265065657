import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import {
  CalendarsAutocomplete,
  TaskStatusAutocomplete,
} from 'src/autocompletes';
import Form, {
  SelectField,
  TextField,
} from 'src/components/ui-components/Form';
import { tasksStatesObj } from '@legalsurf/common';

const WorkflowTimelineItemEventDialogFormValidationSchema = Yup.object({
  duration: Yup.object().test({
    message: 'Debes agregar una duración valida',
    test(value, { parent }) {
      // eslint-disable-next-line no-restricted-globals
      return !(isNaN(parent.value) || parent.value <= 0);
    },
  }),
  title: Yup.string()
    .min(4, 'Minimo 4 caracteres.')
    .max(144, 'Maximo 144 caracteres.')
    .required('Este campo es requerido.'),
  calendar: Yup.lazy((value) =>
    typeof value === 'string'
      ? Yup.string().nullable().required('Debes seleccionar un calendario.')
      : Yup.object().nullable().required('Debes seleccionar un calendario.'),
  ),
  taskState: Yup.object()
    .nullable()
    .when('type', {
      is: 'task',
      then: Yup.lazy((value) =>
        typeof value === 'string'
          ? Yup.string().nullable().required('El estado es requerido.')
          : Yup.object().nullable().required('El estado es requerido.'),
      ),
    }),
});

export const timeUnitOptions = [
  {
    label: 'horas',
    value: 'hours',
  },
  {
    label: 'minutos',
    value: 'minutes',
  },
];

export const defaultWorkflowTimelineItemEventInitialValues = {
  unit: timeUnitOptions[0],
  title: '',
  description: '',
  value: '',
  taskState: tasksStatesObj.pending,
};

export const WorkflowTimelineItemEventDialogForm = ({
  initialValues = defaultWorkflowTimelineItemEventInitialValues,
  onSubmit,
  onClose,
  open,
}) => (
  <Dialog open={open} onClose={onClose}>
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={WorkflowTimelineItemEventDialogFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit, errors }) => (
        <>
          <DialogTitle>Agregar nueva tarea</DialogTitle>

          <DialogContent>
            <Form sx={{ pt: 1 }}>
              <TaskStatusAutocomplete
                label="Estado"
                name="taskState"
                placeholder="Ej. Cerrado"
              />

              <CalendarsAutocomplete
                // getOptionDisabled={
                //   initialValues?.id
                //     ? (option) => !!option.googleCalendarId && !clone
                //     : undefined
                // }
                name="calendar"
              />

              <TextField
                label="Titulo"
                name="title"
                placeholder="Ej. Junta con cliente"
              />

              <TextField
                multiline
                label="Description"
                name="description"
                rows={3}
              />

              <Grid item>
                <Grid container alignItems="center" gap={2}>
                  <Grid item>
                    <Typography>Duración: </Typography>
                  </Grid>


                  <TextField
                    gridProps={{ xs: 2, md: 4 }}
                    label=""
                    name="value"
                    placeholder="00"
                    type="number"
                  />

                  <SelectField
                    clearIcon={null}
                    gridProps={{ xs: 4 }}
                    name="unit"
                    options={timeUnitOptions}
                    searchable={false}
                  />

                  {errors.duration && (
                    <Grid item xs={12}>
                      <Typography color="error">{errors.duration}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Form>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!!isSubmitting}
              sx={{ mr: 'auto' }}
              onClick={onClose}
            >
              Cancelar
            </Button>

            <LoadingButton
              disabled={isSubmitting}
              loading={isSubmitting}
              sx={{ px: 4 }}
              type="click"
              variant="contained"
              onClick={(values) => {
                handleSubmit(values);
                onClose();
              }}
            >
              Guardar
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Formik>
  </Dialog>
);
