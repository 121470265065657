import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import WilsonIMG from 'src/assets/images/wilson.jpg';
import OppsIMG from 'src/assets/images/oppsLegal.jpeg';

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <Page title="404 | LegalSurf">
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        <img
          alt="Opps"
          src={OppsIMG}
          style={{
            width: '60%',
          }}
        />

        <Typography color="initial" variant="h2">
          404 - PAGE NOT FOUND
        </Typography>

        <img
          alt="Wilson"
          src={WilsonIMG}
          style={{
            width: '30%',
          }}
        />

        <Typography align="center" color="initial" variant="subtitle">
          La página que está buscando podría haber sido eliminada, tenía su
          nombre
          <br />
          cambiada o no está disponible temporalmente
        </Typography>

        <Button
          color="primary"
          sx={{ mt: 2 }}
          variant="contained"
          onClick={() => navigate('/')}
        >
          Ir a Inicio
        </Button>
      </Box>
    </Page>
  );
};

export default ErrorPage;
