import React from 'react';
import { Stack } from '@mui/material';

const getStylesByVariant = (variant) => {
  switch (variant) {
    case 'primary':
    default:
      return {
        backgroundColor: 'primary.100',
        borderColor: 'primary.main',
      };
  }
};

const EmptyState = ({ children, sx, variant, ...props }) => (
  <Stack
    direction="column"
    {...props}
    gap={2}
    p={2}
    sx={{
      ...getStylesByVariant(variant),
      borderRadius: 1,
      borderWidth: '1px',
      borderStyle: 'dashed',
      p: 2,
      ...sx,
    }}
  >
    {children}
  </Stack>
);

export default EmptyState;
