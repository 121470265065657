import { gql, useLazyQuery } from '@apollo/client';
import ExcelJS from 'exceljs';
import { useUser } from 'src/utils/hooks/useUser';
import dayjs from 'dayjs';
import { entityTypesObj } from '@legalsurf/common';

const SalesToExportQuery = gql`
  query SalesToExportQuery(
    $studio: ID!
    $filecases: [ID!]
    $entities: [ID!]
    $responsibles: [ID!]
    $ids: [ID!]
  ) {
    sales(
      studio: $studio
      filters: {
        ids: $ids
        filecases: $filecases
        entities: $entities
        responsibles: $responsibles
      }
    ) {
      id
      date
      concept
      amount
      hours
      charged

      charges {
        id
        date
        method
        concept
        amount
        createdAt
        chargedBy {
          id
          name
        }
      }

      entity {
        id
        type
        displayName
      }

      responsible {
        id
        name
      }

      filecase {
        id
        title
      }

      issuedBy {
        id
        name
      }

      tags {
        id
        name
        studioId
      }
    }
  }
`;

const excelHeaders = [
  'Tipo',
  'Monto',
  'Cobrado',
  'Cliente',
  'Responsable',
  'Expediente',
  'Concepto',
  'Horas Contabilizadas',
  'Etiquetas',
  'Creador por',
  'Fecha de honorario/cobro',
  'Fecha de creación',
];

const saleKeyPerCell = [
  'id',
  'amount',
  'charged',
  'entity',
  'responsible',
  'filecase',
  'concept',
  'hours',
  'tags',
  ['issuedBy', 'chargedBy'],
  'date',
  'createdAt',
];

const getValueFromKey = (transaction, key) => {
  switch (key) {
    case 'filecase':
      return transaction[key].title;

    case 'entity':
      return `${transaction[key].displayName} - ${
        entityTypesObj[transaction[key].type]
      }`;

    case 'issuedBy':
    case 'chargedBy':
    case 'responsible':
      return transaction[key]?.name;

    case 'createdAt':
    case 'date':
      return dayjs(transaction[key]).format('LLL');

    case 'tags':
      return transaction[key].map((val) => val.name).join(', ');

    case 'id':
      return transaction?.entity ? 'Honorario' : 'Cobro';

    default:
      return undefined;
  }
};

export const useSalesToExport = () => {
  const [getSalesToExport, { loading }] = useLazyQuery(SalesToExportQuery, {
    fetchPolicy: 'network-only',
  });
  const { user } = useUser();

  return {
    loading,
    getSalesToExport: (variables) =>
      getSalesToExport({ variables }).then((res) => {
        const { sales = [] } = res.data ?? {};

        const workbook = new ExcelJS.Workbook();

        workbook.creator = user.name;
        workbook.created = new Date();

        const worksheet = workbook.addWorksheet('Honorarios');

        // Set column headers strings
        excelHeaders.forEach((header, index) => {
          worksheet.getRow(1).getCell(index + 1).value = header;
        });

        const transactions = sales
          .map((sale) => [sale, ...(sale.charges || [])])
          .flat();

        transactions.forEach((transaction, index) => {
          // Plus 2 because = exceljs starts at 1 and columns headers row
          const saleRow = worksheet.getRow(2 + index);

          saleKeyPerCell.forEach((key, cellKey) => {
            const transactionCellKey = 1 + cellKey;

            if (!Array.isArray(key)) {
              if (!transaction[key] || transaction[key]?.length === 0) {
                return;
              }
            } else if (
              !key.some(
                (arrKey) =>
                  !transaction[arrKey] || transaction[arrKey]?.length === 0,
              )
            ) {
              return;
            }

            const value = Array.isArray(key)
              ? getValueFromKey(
                  transaction,
                  key.find((arrkey) => getValueFromKey(transaction, arrkey)),
                )
              : getValueFromKey(transaction, key);

            saleRow.getCell(transactionCellKey).value =
              value ?? transaction[key];
          });
        });

        // Downloads the xlsx into the browser
        worksheet.workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `Honorarios ${new Date()}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      }),
  };
};
