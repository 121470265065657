import { ROLES_MAP } from '@legalsurf/common';
import { useMemo } from 'react';
import { useUser } from './useUser';

// Checks the consumer has every asked permission on a specific module
const itHasEveryPermissions = (memberPermissions, modulePermissions = []) =>
  modulePermissions.every((modulePermission) =>
    memberPermissions.some(
      (memberPermission) => modulePermission === memberPermission,
    ),
  );

const itHasSomesPermissions = (memberPermissions, modulePermissions = []) =>
  modulePermissions.some((modulePermission) =>
    memberPermissions.some(
      (memberPermission) => modulePermission === memberPermission,
    ),
  );

export const usePermissions = (permissions) => {
  const { loading, user, isSignedIn } = useUser();

  const hasPermissions = useMemo(
    () =>
      user.role === 'owner' ||
      user.role === 'admin' ||
      itHasEveryPermissions(user.permissions, permissions),
    [permissions, user.permissions, user.role],
  );

  const hasSomePermissions = useMemo(
    () =>
      hasPermissions || itHasSomesPermissions(user.permissions, permissions),
    [hasPermissions, permissions, user.permissions],
  );

  return useMemo(
    () => ({
      user,
      isSignedIn,
      loading,
      role: user.role,
      permissions: user.permissions,
      hasPermissions,
      hasSomePermissions,
      isHighRole:
        user?.role === ROLES_MAP.ADMIN || user?.role === ROLES_MAP.OWNER,
    }),
    [hasPermissions, hasSomePermissions, isSignedIn, loading, user],
  );
};
