import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

import { Pressable } from 'src/components/ui-components/Pressable';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import logonavbar from '../../assets/images/logo.svg';

export const LogoButton = () => {
  const studioId = useStudioId();

  if (!studioId) {
    return (
      <Box
        alt="LegalSurf"
        component="img"
        src={logonavbar}
        sx={{
          height: 24,
          width: 110,
          borderRadius: 0,
          objectFit: 'fill',
        }}
      />
    );
  }

  return (
    <Pressable sx={{ borderRadius: 1 }}>
      <Link to={studioId ? `/dashboard/${studioId}/home` : null}>
        <Box
          alt="LegalSurf"
          component="img"
          src={logonavbar}
          sx={{
            height: 24,
            width: 110,
            borderRadius: 0,
            objectFit: 'fill',
          }}
        />
      </Link>
    </Pressable>
  );
};
