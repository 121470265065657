import { gql } from '@apollo/client';

export const CREATE_SIDE = gql`
  mutation createSide($studioId: ID!, $name: String!) {
    createSide(studioId: $studioId, name: $name) {
      id
      name
      studioId
      updatedAt
    }
  }
`;

export const UPDATE_SIDE = gql`
  mutation updateSide($studioId: ID!, $id: ID!, $name: String!) {
    updateSide(studioId: $studioId, id: $id, name: $name) {
      id
      name
      studioId
      updatedAt
    }
  }
`;

export const DELETE_SIDE = gql`
  mutation deleteSide($studioId: ID!, $id: ID!) {
    deleteSide(studioId: $studioId, id: $id) {
      id
      name
      studioId
      updatedAt
    }
  }
`;
