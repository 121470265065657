import React from 'react';
import { Box, Tooltip, LinearProgress } from '@mui/material';

const normalize = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);

export const PaidCell = ({ row }) => (
  <Tooltip key={row.id} title={`Pagado: ${row.currency?.name} ${row.paid}`}>
    <Box sx={{ width: 120 }}>
      <LinearProgress
        color="error"
        sx={{ height: 8, borderRadius: 5 }}
        value={normalize(row.paid, 0, row.amount)}
        variant="determinate"
      />
    </Box>
  </Tooltip>
);
