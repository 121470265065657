import { gql, useQuery } from '@apollo/client';
import defaultAvatar from 'src/assets/images/avatar2.png';
import { useOrganization } from '@clerk/clerk-react';

import { useStudioId } from './useStudioId';

export const GetStudioQuery = gql`
  query GetStudioQuery($id: ID!) {
    studio(id: $id) {
      id
      picture
      name
      slug
      address {
        phone
        country
        city
        floor
        street
        city
      }
    }
  }
`;

export const useStudio = (options) => {
  const studioId = useStudioId();
  const query = useQuery(GetStudioQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: studioId,
      ...options?.variables,
    },
    skip: !studioId,
    ...options,
  });
  const { organization } = useOrganization();

  return {
    ...query,
    studio: {
      ...query.data?.studio,
      picture: query.data?.studio?.picture || defaultAvatar,
      metadata: studioId ? organization?.publicMetadata : null,
    },
  };
};
