import { useState, useCallback } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import dayjs from 'dayjs';

import { useSnackbar } from 'src/components/v3/Snackbar';
import { downloadFileByBlobStrategy } from 'src/utils/files';
import { useStudioId } from './useStudioId';

export const UploadFilesMutation = gql`
  mutation uploadFiles(
    $files: [FileUpload!]!
    $studioId: ID!
    $filecases: [ID!]
    $entities: [ID!]
  ) {
    uploadFiles(
      files: $files
      studioId: $studioId
      filecases: $filecases
      entities: $entities
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

export const UpdateDocumentMutation = gql`
  mutation UpdateDocumentMutation(
    $documentId: ID!
    $name: String
    $filecases: [ID!]
    $entities: [ID!]
  ) {
    updateDocument(
      documentId: $documentId
      name: $name
      filecases: $filecases
      entities: $entities
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
      filecases {
        id
        title
      }
      entities {
        id
        type
        displayName
      }
    }
  }
`;

export const CHANGE_DOCUMENT_NAME = gql`
  mutation changeDocumentName($key: ID!, $name: String!) {
    changeDocumentName(key: $key, name: $name) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation deleteDocument($documentIds: [ID!]!, $studioId: ID!) {
    deleteDocument(documentIds: $documentIds, studioId: $studioId)
  }
`;

export const UNLINK_DOCUMENT_FROM_FILECASE = gql`
  mutation unlinkDocumentFromFilecase($documentId: ID!, $filecaseId: ID!) {
    unlinkDocumentFromFilecase(
      documentId: $documentId
      filecaseId: $filecaseId
    ) {
      id
      name
      extension
      size
      url
      kind
      isFolder
    }
  }
`;

export const UNLINK_DOCUMENT_FROM_ENTITY = gql`
  mutation unlinkDocumentFromEntity($documentId: ID!, $entityId: ID!) {
    unlinkDocumentFromEntity(documentId: $documentId, entityId: $entityId) {
      id
      name
      extension
      size
      url
      kind
      isFolder
      entities {
        id
        displayName
        type
      }
    }
  }
`;

export const LINK_DOCUMENT_TO_FILECASE = gql`
  mutation linkDocumentToFilecase($documentId: ID!, $filecaseId: ID!) {
    linkDocumentToFilecase(documentId: $documentId, filecaseId: $filecaseId) {
      id
      name
      extension
      size
      url
      kind
      isFolder

      filecases {
        id
        title
        externalId
        studioId
      }
    }
  }
`;

export const LinkDocumentToEntityMutation = gql`
  mutation LinkDocumentToEntityMutation($documentId: ID!, $entityId: ID!) {
    linkDocumentToEntity(documentId: $documentId, entityId: $entityId) {
      id
      name
      extension
      size
      url
      kind
      isFolder

      entities {
        id
        type
        displayName
      }
    }
  }
`;

export const GENERATE_DOCUMENT_SIGNED_URL = gql`
  query generateDocumentSignedUrl($documentId: ID!) {
    generateDocumentSignedUrl(documentId: $documentId)
  }
`;

const useDigitalOceanSpace = (mutationOptions) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const [isGeneratingSignedUrl, setIsGeneratingSignedUrl] = useState(false);
  const studioId = useStudioId();

  const [temporarySignedDocument, setTemporarySignedDocument] = useState(null);

  const { openSnackbar } = useSnackbar();

  const [uploadFiles] = useMutation(UploadFilesMutation, {
    ...mutationOptions,
  });

  const [changeDocumentName] = useMutation(CHANGE_DOCUMENT_NAME, {
    ...mutationOptions,
  });

  const [deleteDocument] = useMutation(DELETE_DOCUMENT, {
    ...mutationOptions,
  });

  const [unlinkDocument] = useMutation(UNLINK_DOCUMENT_FROM_FILECASE, {
    ...mutationOptions,
  });

  const [unlinkDocumentFromEntity] = useMutation(UNLINK_DOCUMENT_FROM_ENTITY, {
    ...mutationOptions,
  });

  const [linkDocument] = useMutation(LINK_DOCUMENT_TO_FILECASE, {
    ...mutationOptions,
  });

  const [linkDocumentToEntity] = useMutation(LinkDocumentToEntityMutation, {
    ...mutationOptions,
  });

  const [updateDocument, { loading: isUpdatingDocument }] = useMutation(
    UpdateDocumentMutation,
    {
      ...mutationOptions,
    },
  );

  const [findDocument] = useLazyQuery(GENERATE_DOCUMENT_SIGNED_URL, {
    ...mutationOptions,
  });

  const [generateDocumentSignedUrl] = useLazyQuery(
    GENERATE_DOCUMENT_SIGNED_URL,
    {
      ...mutationOptions,
    },
  );

  const handleUpload = useCallback(
    async ({ documents, filecases, entities }) => {
      setIsUploading(true);

      try {
        const res = await uploadFiles({
          variables: {
            files: documents.map((file) => ({
              id: file.id,
              name: file.name,
              url: file.url,
              mimeType: file.mimeType || '',
              isFolder: file.isFolder,
              kind: file instanceof File ? 'spaces' : file.kind,
              file: file instanceof File ? file : null,
            })),
            studioId,
            filecases: filecases?.map(({ value }) => value),
            entities: entities?.map(({ value }) => value),
          },
        });

        openSnackbar('Sus documentos se han subido con exito.', {
          severity: 'success',
        });

        return res?.data?.uploadFiles;
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsUploading(false);
      }
    },
    [openSnackbar, studioId, uploadFiles],
  );

  const handleEditDocumentName = useCallback(
    async ({ key, name }) => {
      try {
        await changeDocumentName({
          variables: {
            key,
            name,
          },
        });

        openSnackbar(`Se ha actualizado el nombre a: ${name}`, {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      }
    },
    [openSnackbar, changeDocumentName],
  );

  const handleUpdateDocument = useCallback(
    async ({ documentId, name, filecases, entities }) => {
      try {
        const doc = await updateDocument({
          variables: {
            documentId,
            name,
            filecases,
            entities,
          },
        });

        openSnackbar(
          `Se ha actualizado el documento: ${doc.data?.updateDocument?.name} `,
          {
            severity: 'success',
          },
        );
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      }
    },
    [openSnackbar, updateDocument],
  );

  const handleDeleteDocument = useCallback(
    async (documentIds) => {
      setIsDeleting(true);
      try {
        await deleteDocument({
          variables: {
            studioId,
            documentIds,
          },
          update: (cache) => {
            documentIds.forEach((documentId) => {
              cache.evict(
                cache.identify({
                  __typename: 'Document',
                  __ref: documentId,
                }),
              );
            });
            cache.gc();
          },
        });

        openSnackbar(`Se ha eliminado el documento.`, {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsDeleting(false);
      }
    },
    [openSnackbar, studioId, deleteDocument],
  );

  const handleUnlinkDocument = useCallback(
    async ({ documentId, filecaseId }, options = {}) => {
      setIsUnlinking(true);
      try {
        await unlinkDocument({
          variables: {
            documentId,
            filecaseId,
            studioId,
          },
          update: (cache, { data }) => {
            cache.modify({
              id: cache.identify(data?.unlinkDocumentFromFilecase),
              fields: {
                filecase() {
                  return undefined;
                },
              },
            });
          },
          ...options,
        });

        openSnackbar('Desvinculaste el documento con exito.', {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsUnlinking(false);
      }
    },
    [openSnackbar, studioId, unlinkDocument],
  );

  const handleUnlinkDocumentFromEntity = useCallback(
    async ({ documentId, entityId }) => {
      setIsUnlinking(true);
      try {
        await unlinkDocumentFromEntity({
          variables: {
            documentId,
            entityId,
            studioId,
          },
        });

        openSnackbar('Desvinculaste el documento con exito.', {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsUnlinking(false);
      }
    },
    [openSnackbar, studioId, unlinkDocumentFromEntity],
  );

  const handleDownload = useCallback(
    async (documentId) => {
      setIsDownloading(true);

      try {
        let { data: { generateDocumentSignedUrl: file } = {} } =
          await findDocument({
            variables: {
              documentId,
            },
          });

        const fileUrl = new URL(file.link);
        const expiresEpoch = fileUrl.searchParams.get('Expires');

        if (
          expiresEpoch &&
          dayjs.unix(Number(expiresEpoch)).isBefore(dayjs())
        ) {
          file = (
            await findDocument({
              fetchPolicy: 'network-only',
              variables: {
                documentId,
              },
            })
          ).data?.generateDocumentSignedUrl;
        }

        await downloadFileByBlobStrategy(file.link, {
          name: file.name,
          ext: file.extension,
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsDownloading(false);
      }
    },
    [openSnackbar, findDocument],
  );

  const handleGenerateSignedUrl = useCallback(
    async (documentId, options) => {
      setIsGeneratingSignedUrl(true);
      try {
        const {
          data: { generateDocumentSignedUrl: signedDocument },
        } = await generateDocumentSignedUrl({
          fetchPolicy: 'network-only',
          variables: {
            documentId,
          },
          ...options,
        });

        setTemporarySignedDocument(signedDocument);
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsGeneratingSignedUrl(false);
      }
    },
    [openSnackbar, generateDocumentSignedUrl],
  );

  const handleLinkDocument = useCallback(
    async ({ documentId, filecaseId }) => {
      setIsLinking(true);
      try {
        await linkDocument({
          variables: {
            documentId,
            filecaseId,
          },
        });

        openSnackbar('Vinculaste el documento con exito.', {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsLinking(false);
      }
    },
    [openSnackbar, linkDocument],
  );
  const handleEntityToDocumentLink = useCallback(
    async ({ documentId, entityId }) => {
      setIsLinking(true);
      try {
        await linkDocumentToEntity({
          variables: {
            documentId,
            entityId,
          },
        });

        openSnackbar('Vinculaste el documento con exito.', {
          severity: 'success',
        });
      } catch (error) {
        openSnackbar(error.message, { severity: 'error' });

        throw new Error(error);
      } finally {
        setIsLinking(false);
      }
    },
    [openSnackbar, linkDocumentToEntity],
  );

  return {
    isUploading,
    isDeleting,
    isUnlinking,
    isDownloading,
    isLinking,
    isUpdatingDocument,
    isGeneratingSignedUrl,

    temporarySignedDocument,

    handleDownload,
    handleUpload,
    handleEditDocumentName,
    handleDeleteDocument,
    handleUnlinkDocument,
    handleLinkDocument,
    handleEntityToDocumentLink,
    handleUpdateDocument,
    handleGenerateSignedUrl,
    handleUnlinkDocumentFromEntity,
  };
};

export default useDigitalOceanSpace;
