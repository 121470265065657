import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { GET_SIDES } from 'src/graphql/queries/side';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const useFilecaseSides = (options = {}) => {
  const studioId = useStudioId();
  const { data, loading } = useQuery(GET_SIDES, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: {
      studioId,
    },
  });

  return { sides: data?.sides || [], loading };
};

const SidesAutocomplete = ({
  disabled,
  label = 'Parte',
  placeholder = 'Ej. Vendedor',
  ...rest
}) => {
  const { loading, sides } = useFilecaseSides();
  const options = useMemo(
    () => sides.map((val) => ({ value: val.name, label: val.name })) || [],
    [sides],
  );

  return (
    <SelectField
      disabled={disabled}
      label={label}
      loading={loading}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default SidesAutocomplete;
