import React, { useMemo } from 'react';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';

import TitleCell from './TitleCell';
import TypeCell from './TypeCell';
import StateCell from './StateCell';
import { TemplateCell } from './TemplateCell';
import AssignedCell from './AssignedCell';
import { LogCell } from 'src/components/v3/Table/LogCell';

export const useColumns = () => {
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DIRECTORY.READ,
  ]);

  return useMemo(
    () => [
      {
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        headerName: 'Directorio',
        field: 'displayName',
        flex: 1,
        minWidth: 400,
        renderCell: (cell) => <TitleCell {...cell} />,
      },
      {
        editable: false,
        hideable: false,
        disableColumnMenu: true,
        filterable: false,
        field: 'type',
        minWidth: 100,
        headerName: 'Tipo',
        renderCell: (cell) => <TypeCell {...cell} />,
      },
      {
        editable: false,
        hideable: false,
        filterable: false,
        headerName: 'Estado',
        field: 'state',
        width: 140,
        renderCell: (cell) => <StateCell {...cell} />,
      },
      {
        editable: false,
        hideable: false,
        filterable: false,
        width: 120,
        headerName: 'Asignados',
        field: 'assigned',
        renderCell: AssignedCell,
      },
      {
        hideable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        headerName: 'WhatsApp',
        field: 'whatsapp',
        width: 90,
        renderCell: (cell) => <TemplateCell {...cell} />,
      },
      {
        hideable: false,
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        headerName: 'Ultima actividad',
        field: 'latestLog',
        width: 240,
        maxWidth: 240,
        renderCell: (cell) =>
          cell.row.latestLog && <LogCell log={cell.row.latestLog} />,
      },
    ],
    [hasPermissions],
  );
};
