import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Article } from '@mui/icons-material';

import { LSAdd } from 'src/components/icons';
import Page from 'src/components/v2/Page';
import { ContractTemplatePreview } from 'src/popovers/ContractTemplatePopover/ContractTemplatePopoverBody/ContractTemplatePreview';
import { useContractTemplates } from './queries';

export const ContractsTable = () => {
  const navigate = useNavigate();
  const { loading, contracts } = useContractTemplates();
  const [hoveredContract, setHoveredNotificationTemplate] = useState(null);
  const previewedContract = hoveredContract || contracts?.[0];

  return (
    <Page>
      <Paper>
        <Stack direction="column" gap={2}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              p: 2,
              display: 'flex',
            }}
          >
            <Box>
              <Typography variant="h6">Plantillas</Typography>

              <Typography variant="subtitle1">
                Totales: {contracts.length || 0}
              </Typography>
            </Box>

            <Tooltip title="Crear plantilla">
              <Button
                color="primary"
                endIcon={<LSAdd />}
                variant="contained"
                onClick={() => navigate('create')}
              >
                Crear plantilla
              </Button>
            </Tooltip>
          </Box>

          <Divider />

          <Stack direction="row" gap={2}>
            <Box flex={1}>
              <MenuList>
                {!loading &&
                  contracts.map((template) => (
                    <Tooltip title="Ingresa a un expediente para implementar tu plantilla">
                      <MenuItem
                        key={template.id}
                        onClick={() =>
                          navigate(`create?templateId=${template?.id}`, {
                            state: { template },
                          })
                        }
                        onMouseEnter={() =>
                          setHoveredNotificationTemplate(template)
                        }
                      >
                        <ListItemIcon>
                          <Article color="primary" />
                        </ListItemIcon>

                        <ListItemText>{template.name}</ListItemText>
                      </MenuItem>
                    </Tooltip>
                  ))}

                {!loading && !contracts.length && (
                  <MenuItem
                    disabled
                    sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
                  >
                    <Typography>No encontramos Plantillas :(</Typography>
                  </MenuItem>
                )}

                {loading && (
                  <MenuItem
                    disabled
                    sx={{ justifyContent: 'center', display: 'flex' }}
                  >
                    <CircularProgress color="primary" size={25} />
                  </MenuItem>
                )}
              </MenuList>
            </Box>

            {previewedContract && (
              <Box flex={3}>
                <ContractTemplatePreview
                  disabled
                  contract={previewedContract}
                  sx={{ fontSize: 10 }}
                />
              </Box>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Page>
  );
};
