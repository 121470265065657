import dayjs from 'dayjs';

export const calculateWorkflowTotalDuration = (steps = []) => {
  if (!steps.length) {
    return 0;
  }

  const durationsObj = steps.reduce(
    (acc, val) => {
      acc[val.data.unit.value] += val.data.value;

      return acc;
    },
    {
      minutes: 0,
      hours: 0,
    },
  );

  return dayjs.duration(durationsObj).humanize();
};

export const calculateWorkflowDiffCalendars = (steps = []) => {
  const map = new Set(steps.map((step) => step.data.calendar.value));

  return map.size;
};
