import React from 'react';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@mui/material';

import { useDialog } from 'src/dialogs/Dialogs';
import { LSAdd, LSWorkflowIcon } from 'src/components/icons';
import Page from 'src/components/v2/Page';
import { useWorkflows } from 'src/popovers/WorkflowsPopover/queries';
import { WorkflowInfo } from 'src/dialogs/WorkflowFormDialog/WorkflowInfo';
import { useNavigate } from 'react-router-dom';

export const WorkflowsTable = () => {
  const { loading, workflows } = useWorkflows();
  const dispatchWorkflowFormDialog = useDialog('workflowsForm');
  const navigate = useNavigate();

  if (loading) {
    return (
      <Page sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="primary" size={26} />
      </Page>
    );
  }

  if (workflows?.length === 0) {
    return <WorkflowInfo />;
  }

  return (
    <Page>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              p: 2,
              display: 'flex',
            }}
          >
            <Box>
              <Typography variant="h6">Flujos de trabajo</Typography>

              <Typography variant="subtitle1">
                Totales: {workflows.length || 0}
              </Typography>
            </Box>

            <Tooltip title="Crear flujo de trabajo">
              <Button
                color="primary"
                endIcon={<LSAdd />}
                onClick={() =>
                  dispatchWorkflowFormDialog('WorkflowsTable', {
                    onSubmit: (values) => {
                      navigate('create', { state: { workflowDraft: values } });
                    },
                  })
                }
              >
                Crear flujo de trabajo
              </Button>
            </Tooltip>
          </Box>

          <Divider />

          <MenuList>
            {!loading &&
              workflows.map((workflow) => (
                <MenuItem
                  key={workflow.id}
                  onClick={() => navigate(`create?workflowId=${workflow.id}`)}
                >
                  <ListItemIcon>
                    <LSWorkflowIcon color="primary" />
                  </ListItemIcon>

                  <ListItemText>{workflow.name}</ListItemText>
                </MenuItem>
              ))}

            {!loading && !workflows.length && (
              <MenuItem
                disabled
                sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
              >
                <Typography>No encontramos flujos de trabajo :(</Typography>
              </MenuItem>
            )}

            {loading && (
              <MenuItem
                disabled
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <CircularProgress color="primary" size={25} />
              </MenuItem>
            )}
          </MenuList>
        </Box>
      </Card>
    </Page>
  );
};
