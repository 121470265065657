import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import {
  LSFilecase,
  LSDirectory as LSDirectoryIcon,
  LSTreasury as LSTreasuryIcon,
  LSDocument as LSDocumentIcon,
  LSPin,
  LSDangerSign,
} from 'src/components/icons';
import { HourglassEmpty } from '@mui/icons-material';

const getStylesByVariant = (variant) => ({
  ...(variant === 'filecases' && {
    borderColor: 'primary.main',
    backgroundColor: 'primary.200',
  }),

  ...(variant === 'directory' && {
    backgroundColor: 'primary.200',
    borderColor: 'primary.500',
  }),

  ...(variant === 'sales' && {
    borderColor: 'success.main',
    backgroundColor: 'success.200',
  }),

  ...(variant === 'documents' && {
    borderColor: 'primary.main',
    backgroundColor: 'primary.200',
  }),

  ...(variant === 'expenses' && {
    borderColor: 'error.main',
    backgroundColor: 'rgb(244, 200, 213)',
  }),

  ...(variant === 'time-tracking' && {
    borderColor: 'error.main',
    backgroundColor: '#ffebe3',
  }),
});

const getCopyByVariant = (variant) => {
  switch (variant) {
    case 'filecases':
      return {
        title: 'No encontramos ningún expediente :(',
        subtitle:
          'Comienza ahora mismo a digitalizar tus casos. Crear tus expedientes en LegalSurf.',
        icon: <LSFilecase color="primary" sx={{ height: 26, width: 26 }} />,
      };

    case 'directory':
      return {
        title: 'Aún no posees ninguna persona en tu directorio :(',
        subtitle:
          'Comienza ahora mismo a digitalizar tus clientes, juzgados, partes intervinientes y mucho más.',
        icon: (
          <LSDirectoryIcon color="primary" sx={{ height: 26, width: 26 }} />
        ),
      };

    case 'sales':
      return {
        title: 'Aún no posees Honorarios :(',
        subtitle:
          'Comienza ahora mismo a cargar tus Honorarios y Cobros. Lleva una mejor control de tus ventas con LegalSurf.',
        icon: <LSTreasuryIcon color="success" sx={{ height: 26, width: 26 }} />,
      };

    case 'documents':
      return {
        title: 'Aún no has cargado ningún documento :(',
        subtitle: 'Sube archivos y vinculalos a tus expedientes de LegalSurf.',
        icon: <LSDocumentIcon color="primary" sx={{ height: 26, width: 26 }} />,
      };

    case 'tasks':
      return {
        title: 'No encontramos ninguna tarea en tu Calendario :(',
        subtitle: 'Carga tus tareas y actividades en el legalsurf.',
        icon: <LSPin color="success" sx={{ height: 26, width: 26 }} />,
      };

    case 'alerts':
      return {
        title: 'No encontramos ninguna alerta :(',
        subtitle:
          'Carga tus eventos, notificaciones y alertas en el legalsurf.',
        icon: <LSDangerSign color="warning" sx={{ height: 26, width: 26 }} />,
      };

    case 'expenses': {
      return {
        title: 'Aún no posees gastos :(',
        subtitle:
          'Comienza ahora mismo a cargar tus gastos y pagos. Lleva una mejor control de tu tesoreria con LegalSurf.',
        icon: <LSTreasuryIcon color="error" sx={{ height: 26, width: 26 }} />,
      };
    }

    case 'time-tracking': {
      return {
        title: 'Aún no posees horas cargadas en el Time Tracking :(',
        subtitle:
          'Lleva un detalle de las horas trabajas de tu equipo cargando horas del TimeTracking.',
        icon: <HourglassEmpty color="primary" sx={{ height: 26, width: 26 }} />,
      };
    }

    case 'time-tracking-by-group': {
      return {
        title: '¡Comienza a contabilizar tu esfuerzo en horas!',
        subtitle:
          'Agrupa las horas de tus compañeros y ten un vistazo mas claro de tu productividad',
        icon: <HourglassEmpty color="primary" sx={{ height: 26, width: 26 }} />,
      };
    }

    case 'notification-templates': {
      return {
        title: 'Aun no posees plantillas :(',
        subtitle: 'Crea tus plantillas y comienza a enviarlas a tus clientes.',
        // icon: <HourglassEmpty color="primary" sx={{ height: 26, width: 26 }} />,
      };
    }

    default:
      return {
        title: 'No encontramos lo que buscabas :(',
        subtitle: 'Comienza a digitalizar tu estudio con LegalSurf.',
      };
  }
};

const NoRowsOverlay = ({ variant, sx, ...props }) => {
  const copy = getCopyByVariant(variant);

  return (
    <Box
      {...props}
      sx={{
        ...getStylesByVariant(variant),
        borderRadius: 1,
        borderWidth: '1px',
        borderStyle: 'dashed',
        p: 2,
        mx: 2,
        ...sx,
      }}
    >
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item>{copy.icon}</Grid>
        <Grid item xs>
          <Typography fontSize={16} fontWeight={600} variant="h6">
            {copy.title}
          </Typography>

          <Typography
            color="grey.600"
            fontSize={16}
            fontWeight={500}
            variant="subtitle1"
          >
            {copy.subtitle}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NoRowsOverlay;
