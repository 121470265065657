import { useQuery } from '@apollo/client';
import { SingleCalendarEventQuery } from '@legalsurf/queries';

export const useCalendarSingleEvent = ({ eventId, calendarId, ...args }) => {
  const query = useQuery(SingleCalendarEventQuery, {
    nextFetchPolicy: 'network-only',
    variables: {
      id: eventId,
      calendarId,
      ...args,
    },
    skip: !eventId || !calendarId,
  });

  return {
    ...query,
    data: query.data?.event,
  };
};
