import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { GET_STUDIO_MEMBERS } from 'src/graphql/queries/studios';

const AssigneesAutocomplete = ({
  disabled,
  label = 'Asignar a',
  placeholder = 'Ej. Mauri Riccieri',
  multiple = false,
  ...rest
}) => {
  const studioId = useStudioId();
  const { loading, data: { members = [] } = {} } = useQuery(
    GET_STUDIO_MEMBERS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        status: ['ACTIVE'],
        studio: studioId,
      },
    },
  );

  const options = useMemo(
    () =>
      members?.map((member) => ({
        value: member.id,
        label: member.name,
        picture: member.picture,
      })) || [],
    [members],
  );

  return (
    <SelectField
      disabled={disabled || loading}
      label={label}
      loading={loading}
      multiple={multiple}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default AssigneesAutocomplete;
