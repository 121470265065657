import React from 'react';
import { Box } from '@mui/material';
import LoadingGIF from '../assets/loader.gif';

export const FullPageLoader = ({ children }) => (
  <Box
    sx={{
      backgroundColor: 'primary.700',
      minHeight: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <img alt="loading" src={LoadingGIF} />

    {children && <Box sx={{ mt: 2 }}>{children}</Box>}
  </Box>
);
