import React, { useEffect } from 'react';

import { FullPageLoader } from 'src/components/FullPageLoader';

export const CallbackAuth = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace('/');
    }, 3000);
  });

  return <FullPageLoader />;
};
