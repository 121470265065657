import React, { useState } from 'react';
import * as Yup from 'yup';
import {
  Button,
  Typography,
  Stack,
  Box,
  Fade,
  FormControlLabel,
  Checkbox,
  Avatar,
} from '@mui/material';

import Form, {
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { useFormikContext } from 'formik';
import { ChevronRight } from '@mui/icons-material';
import Page from 'src/components/v2/Page';
import GoogleCalendarIMG from 'src/assets/images/googlecalendar31.png';
import calendarEventPopupPNG from '../../calendar-popup.png';
import { usePostHog } from 'posthog-js/react';

export const Schema = Yup.object({
  name: Yup.string().required('Este campo es requerido'),
  state: Yup.object().nullable().required('Este campo es requerido'),
  category: Yup.object().nullable().required('Este campo es requerido'),
});

export const defaultFilecaseStatuses = [
  {
    value: 'Iniciado',
    label: 'Iniciado',
    color: '#15E0E3',
  },
  {
    value: 'En Proceso',
    label: 'En Proceso',
    color: '#B020ED',
  },
  {
    value: 'Demorado',
    label: 'Demorado',
    color: '#4335E8',
  },
  {
    value: 'Archivado',
    label: 'Archivado',
    color: '#474D5F',
  },
];

const filecasesDefaultCategories = [
  'Laboral',
  'Corporativo',
  'Civil',
  'Penal',
  'Fiscal',
  'Compliance',
  'Administrativo',
  'Comercial',
  'Migratorio',
  'Suceción',
  'Inmobiliario',
];

const OnboardingStepContainer = ({ children, ...props }) => (
  <Fade in>
    <Stack
      gap={4}
      px={10}
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      {children}
    </Stack>
  </Fade>
);

const FilecaseCategories = ({ onSubmit }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <OnboardingStepContainer>
      <Typography fontSize={22} fontWeight="500" textAlign="center">
        Selecciona las categorías de tus asuntos legales:
      </Typography>

      <Stack gap={1} direction="row" flexWrap="wrap">
        {filecasesDefaultCategories.map((category) => (
          <FormControlLabel
            key={category}
            sx={{
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: values.categories?.includes(category)
                ? 'primary.main'
                : '#DCE4EC',
              borderRadius: 1,
              pr: 4,
            }}
            control={
              <Checkbox
                checked={values.categories?.includes(category)}
                sx={{ visibility: 'hidden', ml: -2 }}
                onChange={(event) =>
                  setFieldValue(
                    'categories',
                    event.target.checked
                      ? [...values.categories, category]
                      : values.categories.filter((c) => c !== category),
                  )
                }
              />
            }
            label={category}
          />
        ))}
      </Stack>

      <Typography
        color="primary"
        variant="body2"
        fontStyle="italic"
        fontWeight="600"
      >
        * Podrás agregar más categorías luego
      </Typography>

      <Button
        onClick={onSubmit}
        variant="contained"
        color="primary"
        endIcon={<ChevronRight />}
        sx={{ textTransform: 'none', py: 2 }}
      >
        Siguiente
      </Button>
    </OnboardingStepContainer>
  );
};

const FilecaseData = ({ onSubmit }) => {
  const { values, errors } = useFormikContext();

  return (
    <OnboardingStepContainer sx={{ px: 8 }}>
      <Typography fontSize={22} fontWeight="500" textAlign="center">
        Ayudanos a crear tu primer expediente digital:
      </Typography>

      <Form>
        <TextField
          noFormControlLabel
          label="Nombre de tu expediente"
          name="name"
          placeholder="Ej: Demanda laboral Jorge M"
        />

        <SelectField
          label="Estado de tu expediente"
          name="state"
          options={defaultFilecaseStatuses}
        />

        <SelectField
          creatable
          label="Selecciona un categoría"
          name="category"
          options={(values.categories?.length
            ? values.categories
            : filecasesDefaultCategories
          ).map((category) => ({
            value: category,
            label: category,
          }))}
        />
      </Form>

      <Button
        disabled={errors.name || errors.state || errors.category}
        onClick={onSubmit}
        variant="contained"
        color="primary"
        endIcon={<ChevronRight />}
        sx={{ textTransform: 'none', py: 2 }}
      >
        Siguiente
      </Button>
    </OnboardingStepContainer>
  );
};

const GoogleCalendarSync = () => {
  const { setFieldValue, handleSubmit } = useFormikContext();

  return (
    <OnboardingStepContainer sx={{ px: [4, 8, 16] }}>
      <Box
        component="img"
        alt=""
        src={calendarEventPopupPNG}
        sx={{ maxWidth: 180, mx: 'auto' }}
      />

      <Typography variant="h2" fontWeight="600" textAlign="center">
        ¿Quieres integrar tu agenda de Google?
      </Typography>

      <Button
        onClick={() => {
          setFieldValue('syncGoogleCalendar', true);
          handleSubmit();
        }}
        color="primary"
        sx={{ py: 2, color: '#333', bgcolor: 'background.input' }}
        startIcon={
          <Avatar
            src={GoogleCalendarIMG}
            sx={{ borderRadius: 0, width: 32, height: 32 }}
          />
        }
      >
        Integrar Google Calendar
      </Button>

      <Button
        onClick={handleSubmit}
        variant="text"
        color="primary"
        endIcon={<ChevronRight />}
        sx={{ textTransform: 'none', py: 2 }}
      >
        Lo haré más tarde
      </Button>
    </OnboardingStepContainer>
  );
};

export const FilecaseOnboardingForm = ({ id, ...props }) => {
  const [step, setStep] = useState(1);
  const posthog = usePostHog();
  const { values } = useFormikContext();

  return (
    <Page>
      {step === 1 && (
        <FilecaseCategories
          onSubmit={() => {
            setStep(step + 1);
            posthog.capture('webappOnboarding', {
              step: 'filecaseCategories',
              createdCategories: values?.categories?.length,
            });
          }}
          id={id}
          {...props}
        />
      )}

      {step === 2 && (
        <FilecaseData
          onSubmit={() => {
            setStep(step + 1);
            posthog.capture('webappOnboarding', {
              step: 'filecaseFormData',
              filecaseOnboardingCategory: values.category?.value,
              filecaseOnboardingState: values.state?.value,
            });
          }}
        />
      )}

      {step === 3 && <GoogleCalendarSync />}
    </Page>
  );
};
