import React from 'react';
import { SvgIcon } from '@mui/material';

const LSTreasury = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12ZM12 5.25C12.4142 5.25 12.75 5.58579 12.75 6V7.25H15C15.4142 7.25 15.75 7.58579 15.75 8C15.75 8.41421 15.4142 8.75 15 8.75H11C10.7867 8.75 10.45 8.82016 10.1882 9.00865C9.96468 9.1696 9.75 9.44444 9.75 10C9.75 10.5556 9.96468 10.8304 10.1882 10.9913C10.45 11.1798 10.7867 11.25 11 11.25H13C13.4534 11.25 14.1167 11.3798 14.6882 11.7913C15.298 12.2304 15.75 12.9556 15.75 14C15.75 15.0444 15.298 15.7696 14.6882 16.2087C14.1167 16.6202 13.4534 16.75 13 16.75H12.75V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V16.75H9C8.58579 16.75 8.25 16.4142 8.25 16C8.25 15.5858 8.58579 15.25 9 15.25H13C13.2133 15.25 13.55 15.1798 13.8118 14.9913C14.0353 14.8304 14.25 14.5556 14.25 14C14.25 13.4444 14.0353 13.1696 13.8118 13.0087C13.55 12.8202 13.2133 12.75 13 12.75H11C10.5466 12.75 9.88332 12.6202 9.31177 12.2087C8.70198 11.7696 8.25 11.0444 8.25 10C8.25 8.95556 8.70198 8.2304 9.31177 7.79135C9.88332 7.37984 10.5466 7.25 11 7.25H11.25V6C11.25 5.58579 11.5858 5.25 12 5.25Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSTreasury;
