import React from 'react';

import { WorkflowsPopoverBody } from './WorkflowsPopoverBody';
import { PopoverBase } from '../PopoverBase';

export const WorkflowsPopover = ({
  onClick,
  filecases,
  anchorEl,
  ...props
}) => (
  <PopoverBase anchorEl={anchorEl} {...props}>
    <WorkflowsPopoverBody filecases={filecases} onClick={onClick} {...props} />
  </PopoverBase>
);
