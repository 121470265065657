import { gql } from '@apollo/client';

export const CREATE_ENTITY_STATE = gql`
  mutation createEntityState($studioId: ID!, $name: String!, $color: String!) {
    createEntityState(studioId: $studioId, name: $name, color: $color) {
      id
      name
      color
      studioId
    }
  }
`;

export const UPDATE_ENTITY_STATE = gql`
  mutation updateEntityState($id: ID!, $name: String!, $color: String) {
    updateEntityState(id: $id, name: $name, color: $color) {
      name
      id
      color
      studioId
    }
  }
`;

export const DELETE_ENTITY_STATE = gql`
  mutation deleteEntityState($id: ID!, $studioId: ID!) {
    deleteEntityState(id: $id, studioId: $studioId) {
      id
      studioId
    }
  }
`;
