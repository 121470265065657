import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const CalendarsAutocompleteQuery = gql`
  query CalendarsAutocomplete($studio: ID!) {
    calendars(studio: $studio) {
      id
      name
      color
      googleCalendarId
    }
  }
`;

const CalendarsAutocomplete = ({
  disabled,
  label = 'Calendario',
  placeholder = 'Ej. Calendario #2',
  ...rest
}) => {
  const studioId = useStudioId();
  const { loading, data: { calendars } = {} } = useQuery(
    CalendarsAutocompleteQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        studio: studioId,
      },
    },
  );

  const options =
    (Array.isArray(calendars) &&
      calendars.map((calendar) => ({
        value: calendar.id,
        label: calendar.name,
        color: calendar.color,
        googleCalendarId: calendar.googleCalendarId,
      }))) ||
    [];

  return (
    <SelectField
      disabled={disabled}
      label={label}
      loading={loading}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default CalendarsAutocomplete;
