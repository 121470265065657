import React from 'react';

import { useSnackbar } from 'src/components/v3/Snackbar';

import { useFilecaseCategories } from 'src/autocompletes/CategoriesAutocomplete';
import { useMutation } from '@apollo/client';
import { CREATE_CATEGORY } from 'src/graphql/mutations/category';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { PopoverBase } from '../PopoverBase';
import { FilecaseCategoriesPopoverBody } from './FilecaseCategoriesPopoverBody';

export const FilecaseCategoriesPopover = ({
  onSubmit,
  open,
  anchorEl,
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
  ...props
}) => {
  const { openSnackbar } = useSnackbar();
  const studioId = useStudioId();
  const { categories } = useFilecaseCategories();

  const [createCategory] = useMutation(CREATE_CATEGORY);

  const categoriesOptions = categories.map((val) => ({
    value: val.name,
    label: val.name,
  }));

  const handleSubmit = async (values) => {
    const uniqueCategories = values.categories.filter(
      (category) => !categories.some((val) => val.name === category.value),
    );

    await Promise.all(
      uniqueCategories.map(({ value }) =>
        createCategory({
          variables: {
            studioId,
            name: value,
          },
          update: (cache, { data }) =>
            cache.modify({
              fields: {
                categories(existing, { toReference }) {
                  return [...(existing ?? []), toReference(data.createCategory, true)];
                },
              },
            })
        })
      ),
    );

    if (uniqueCategories.length) {
      openSnackbar(
        uniqueCategories?.length > 1
          ? 'Categorias creadas'
          : 'Categoría creada',
        {
          severity: 'success',
          autoDismiss: true,
        },
      );

      onSubmit(values);
    }
  };

  return (
    <PopoverBase
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      open={open}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <FilecaseCategoriesPopoverBody
        categories={categoriesOptions}
        sx={{ maxWidth: 400 }}
        onSubmit={handleSubmit}
        {...props}
      />
    </PopoverBase>
  );
};
