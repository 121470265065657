import React, { useState, useRef } from 'react';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';

import { AssigneesPopoverBody } from './AssigneesPopoverBody';
import { Add } from '@mui/icons-material';

export const AssigneesPopover = ({
  selectedAssignees = [],
  touchable,
  onSubmit,
  sx = {},
}) => {
  const [open, setOpen] = useState(false);
  const popoverRef = useRef(null);

  return (
    <AssigneesPopoverBody
      anchorEl={popoverRef.current}
      open={open}
      selectedAssignees={selectedAssignees}
      onClickAway={() => {
        setOpen(false);
      }}
      onSubmit={onSubmit}
    >
      <Box ref={popoverRef}>
        {selectedAssignees?.length ? (
          <Button
            variant="text"
            onClick={(event) => {
              event.stopPropagation();
              setOpen(true);
            }}
            sx={{ minWidth: 'initial' }}
            disabled={!touchable}
          >
            <AvatarGroup
              max={3}
              spacing="small"
              sx={{
                cursor: 'pointer',
                '&:hover': { opacity: 0.8 },
                '& > div': { border: 'none' },
                ...sx,
              }}
              total={selectedAssignees.length}
              slotProps={{
                additionalAvatar: {
                  sx: {
                    borderWidth: '0 !important',
                  },
                },
              }}
            >
              {selectedAssignees.map((assignee) => (
                <Tooltip key={assignee.id} title={assignee.name}>
                  <Avatar alt="Avatar" size="small" src={assignee.picture} />
                </Tooltip>
              ))}
            </AvatarGroup>
          </Button>
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            sx={sx}
          >
            <Tooltip title="Click para asignar personas">
              <IconButton
                disabled={!touchable}
                color="primary"
                size="small"
                sx={{ bgcolor: 'background.input' }}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(true);
                }}
              >
                <Add fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Box>
    </AssigneesPopoverBody>
  );
};
