import React from 'react';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import { create } from 'zustand';

export const useSnackbar = create((set) => ({
  isOpen: false,
  message: '',
  severity: 'success',
  openSnackbar: (message, { severity = 'success' }) =>
    set({ isOpen: true, message, severity }),
  hideSnackbar: () => set({ isOpen: false }),
}));

export const SnackbarProvider = ({ children }) => {
  const store = useSnackbar();

  return (
    <>
      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        autoHideDuration={4000}
        open={store.isOpen}
        onClose={store.hideSnackbar}
      >
        <Alert severity={store.severity}>{store.message}</Alert>
      </Snackbar>

      {children}
    </>
  );
};
