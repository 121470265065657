import { useEffect, useRef } from 'react';

export function useConditionalEffect(callback, dependencies) {
  const dependenciesRef = useRef(dependencies);

  useEffect(() => {
    // Check if any dependencies have changed
    const dependenciesChanged = dependencies.some(
      (dep, index) => dep !== dependenciesRef.current[index],
    );

    if (dependenciesChanged) {
      // Update the dependenciesRef with the current dependencies
      dependenciesRef.current = dependencies;

      callback();
    }
  }, [...dependencies, callback]); // eslint-disable-line react-hooks/exhaustive-deps
}
