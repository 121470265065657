import { useCallback, useEffect, useState } from 'react';
import { useGoogleCalendar } from 'src/utils/hooks/useGoogleCalendar';

// TODO: check infinite loop not tested
const getCalendars = (
  accessToken,
  apiKey = import.meta.env.VITE_GOOGLE_DEVELOPER_KEY,
) =>
  new Promise((resolve, reject) => {
    function initiate() {
      window.gapi.client
        .init({
          apiKey,
        })
        .then(() =>
          window.gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/users/me/calendarList?minAccessRole=writer`,
            headers: {
              'Content-type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          }),
        )
        .then(
          (response) => resolve(response.result.items),
          (err) => reject(err),
        );
    }

    window.gapi.load('client', initiate);
  });

export const useGoogleCalendars = () => {
  const {
    hasGoogleCalendar,
    // loading: googleCalendarLoading,
    getGoogleCalendarAccessToken,
  } = useGoogleCalendar();

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [calendars, setCalendars] = useState([]);

  const fetchCalendars = useCallback(async () => {
    if (!hasGoogleCalendar || loaded) {
      return;
    }

    try {
      setLoading(true);
      const accessToken = await getGoogleCalendarAccessToken();
      const gcalendars = await getCalendars(accessToken);

      setCalendars(
        gcalendars.map((gcalendar) => ({
          ...gcalendar,
          value: gcalendar.id,
          label: gcalendar.summary,
        })),
      );
    } finally {
      setLoading(false);
      setLoaded(true);
    }
  }, [hasGoogleCalendar, loaded]);

  useEffect(() => {
    if (hasGoogleCalendar && !loading && !loaded) {
      fetchCalendars();
    }
  }, [fetchCalendars, hasGoogleCalendar, loaded, loading]);

  return {
    loading,
    hasGoogleCalendar,
    calendars,
  };
};
