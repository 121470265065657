import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button, Typography, Stack, Fade } from '@mui/material';
import { CountriesAutocomplete } from 'src/autocompletes';

import Form, {
  TextField,
  ImageField,
  SelectField,
} from 'src/components/ui-components/Form';
import { useFormikContext } from 'formik';
import { ChevronRight, PhotoCamera } from '@mui/icons-material';
import Page from 'src/components/v2/Page';
import { usePostHog } from 'posthog-js/react';
import { calificationProps } from '@legalsurf/common';

export const Schema = Yup.object({
  country: Yup.object().required('Este campo es requerido').nullable(),
  name: Yup.string().required('Este campo es requerido'),
  size: Yup.object().nullable().required('Este campo es requerido'),
  filecases: Yup.object().nullable().required('Este campo es requerido'),
  type: Yup.object().nullable().required('Este campo es requerido'),
});

const OnboardingStepContainer = ({ children }) => (
  <Fade in>
    <Stack gap={4} alignItems="center" justifyContent="center">
      {children}
    </Stack>
  </Fade>
);

const StudioCreationName = ({ onSubmit, id, ...props }) => {
  const { errors } = useFormikContext();

  return (
    <OnboardingStepContainer>
      <Stack gap={0.5}>
        <Typography variant="h1" fontWeight="600" textAlign="center">
          ¡Comencemos!
        </Typography>

        <Typography fontSize={22} fontWeight="500" textAlign="center">
          Creemos juntos tu espacio de trabajo
        </Typography>
      </Stack>

      <Form id={id} {...props} spacing={2} style={{ width: '100%' }}>
        <TextField
          noFormControlLabel
          label="Nombre de tu estudio legal"
          name="name"
          placeholder="Ej. Instituto Legal..."
        />

        <CountriesAutocomplete clearIcon={null} label="País" name="country" />
      </Form>

      <Button
        onClick={onSubmit}
        disabled={!!errors.name || !!errors.country}
        variant="contained"
        color="primary"
        endIcon={<ChevronRight />}
        sx={{ textTransform: 'none', py: 2 }}
      >
        Siguiente
      </Button>
    </OnboardingStepContainer>
  );
};

const StudioCreationImg = ({ onSubmit, id, ...props }) => (
  <OnboardingStepContainer>
    <Typography
      variant="h1"
      fontSize={22}
      fontWeight="600"
      textAlign="center"
      whiteSpace="nowrap"
    >
      ¿Quieres agregar tu logo?
    </Typography>

    <Form
      id={id}
      {...props}
      sx={{ justifyContent: 'center' }}
      style={{ width: '100%' }}
    >
      <ImageField
        fallbackImage={<PhotoCamera sx={{ fontSize: 36 }} />}
        gridProps={{
          xs: undefined,
        }}
        name="image"
        label={useFormikContext().values.name}
      />
    </Form>

    <Button
      onClick={onSubmit}
      variant="contained"
      color="primary"
      endIcon={<ChevronRight />}
      sx={{ textTransform: 'none', py: 2 }}
    >
      Siguiente
    </Button>
  </OnboardingStepContainer>
);

const StudioCreationSize = ({ onSubmit, id, ...props }) => {
  const { errors } = useFormikContext();

  return (
    <OnboardingStepContainer>
      <Typography
        variant="h1"
        fontSize={22}
        fontWeight="600"
        textAlign="center"
      >
        Cuentanos un poco más sobre tu equipo legal...
      </Typography>

      <Form id={id} {...props} style={{ width: '100%' }}>
        <SelectField
          label="¿Cuál de estos casos se parece más al tuyo?"
          options={calificationProps.types}
          name="type"
        />

        <SelectField
          label="¿Cuántas personas trabajan contigo?"
          name="size"
          options={calificationProps.teamSizes}
        />

        <SelectField
          label="¿Con cuantos expedientes trabajas?"
          name="filecases"
          options={calificationProps.filecases}
        />
      </Form>

      <Button
        onClick={onSubmit}
        disabled={!!errors.size || !!errors.filecases}
        variant="contained"
        color="primary"
        endIcon={<ChevronRight />}
        sx={{ textTransform: 'none', py: 2 }}
      >
        Siguiente
      </Button>
    </OnboardingStepContainer>
  );
};

const StudioCreationForm = ({ id, ...props }) => {
  const { handleSubmit, values } = useFormikContext();
  const posthog = usePostHog();
  const [step, setStep] = useState(1);

  return (
    <Page sx={{ px: [4, 12] }}>
      {step === 1 && (
        <StudioCreationName
          onSubmit={() => {
            setStep(step + 1);
            posthog.capture('webappOnboarding', {
              step: 'studioName',
              country: values?.country?.value,
            });
          }}
          id={id}
          {...props}
        />
      )}

      {step === 2 && (
        <StudioCreationImg
          onSubmit={() => {
            setStep(step + 1);
            posthog.capture('webappOnboarding', {
              step: 'studioImage',
              addedImage: !!values?.image,
            });
          }}
          id={id}
          {...props}
        />
      )}

      {step === 3 && (
        <StudioCreationSize onSubmit={handleSubmit} id={id} {...props} />
      )}
    </Page>
  );
};

export default StudioCreationForm;
