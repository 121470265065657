import React from 'react';
import { Typography } from '@mui/material';

export const RichEditorPlaceholder = ({ children }) => (
  <Typography
    color="textSecondary"
    sx={{
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      top: 0,
      left: (theme) => theme.spacing(1),
      fontSize: '15px',
      userSelect: 'none',
      display: 'inline-block',
      pointerEvents: 'none',
    }}
  >
    {children}
  </Typography>
);
