import React from 'react';
import Page from 'src/components/v2/Page';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useGoogleDrive } from 'src/utils/hooks/useGoogleDrive';

import GoogleDriveIcon from '../logo_drive_2020q4_color_2x_web_64dp.png';
import GoogleDriveIMG from '../googledrive.png';

const GoogleDriveSetting = () => {
  const {
    loading,
    hasGoogleDrive,
    redirectToGoogleDriveAuthorzation,
    unsyncGoogleScope,
  } = useGoogleDrive();

  return (
    <Page title="Integrar google drive | LegalSurf">
      <Card sx={{ width: 800, maxWidth: '100%', margin: '0 auto', mt: 6 }}>
        <CardContent>
          <Box
            component="img"
            src={GoogleDriveIMG}
            sx={{ display: 'flex', height: 120, mx: 'auto', mb: 3 }}
          />

          <Typography align="center" sx={{ px: [null, 12] }} variant="h5">
            Conecta tu cuenta de Google Drive y adjunta tus documentos de Google
            en tus expedientes de LegalSurf
          </Typography>

          <Box sx={{ p: 3, mt: 2 }}>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
              }}
              variant="body1"
            >
              {hasGoogleDrive
                ? 'Ya estas vinculado a google drive ✅'
                : 'Asociar tu cuenta de google drive'}
            </Typography>

            <Typography variant="body2">
              Google Drive le permite almacenar archivos de forma segura en
              línea, acceder a ellos desde cualquier lugar y colaborar con
              otros. Agrega la aplicación Google Drive para crear Google Docs,
              importar cualquier tipo de archivo desde Google Drive y recibir
              notificaciones sobre nuevos comentarios, archivos y solicitudes de
              acceso, todo esto sin dejar tu estudio digital online en
              LegalSurf.
            </Typography>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              {!hasGoogleDrive ? (
                <LoadingButton
                  loading={loading}
                  startIcon={<Avatar src={GoogleDriveIcon} />}
                  sx={{ textTransform: 'none' }}
                  variant="text"
                  onClick={redirectToGoogleDriveAuthorzation}
                >
                  Vincular Google Drive
                </LoadingButton>
              ) : (
                <LoadingButton loading={loading} onClick={unsyncGoogleScope}>
                  Desvincular Google Drive
                </LoadingButton>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Page>
  );
};

export default GoogleDriveSetting;
