import { useMutation, MutationHookOptions } from '@apollo/client';
import { UpdateCalendarEventAssignees } from '@legalsurf/queries';

import { EventVariables } from '../@types';

export const useUpdateCalendarEventAssignees = (
  options: MutationHookOptions<any, EventVariables>,
) => {
  return useMutation(UpdateCalendarEventAssignees, {
    ...options,
  });
};
