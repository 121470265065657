import React from 'react';
import * as Sentry from '@sentry/react';
import { DialogTitle, Dialog, Divider } from '@mui/material';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';

import DirectoryForm from 'src/forms/DirectoryForm';
import { useBreakpoints } from 'src/utils/hooks/common';

import { CREATE_ENTITY, UPDATE_ENTITY } from 'src/graphql/mutations/entities';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { GetEntitiesAutocompleteQuery } from 'src/autocompletes/EntitiesAutocomplete';
import { parseCustomFieldsToInitialValues } from 'src/components/ui-components/Form';
import { entityTypesObj } from '@legalsurf/common';

const defaultFormValues = {
  firstname: '',
  lastname: '',
  businessname: '',
  phone: '',
  type: null,

  identification: '',
  email: '',
  country: '',
  address: '',

  isLegalEntity: false,
  assigned: [],
  tags: [],
};

export const EntityFormDialog = ({
  onClose,
  open,
  filecaseId,
  title,
  entity,
}) => {
  const studioId = useStudioId();
  const { isMobile } = useBreakpoints();
  const { openSnackbar } = useSnackbar();

  const [updateEntity] = useMutation(UPDATE_ENTITY);

  const [createEntity] = useMutation(CREATE_ENTITY, {
    refetchQueries: ['paginatedEntities', 'entities'],
    update: (cache, result) => {
      if (filecaseId) {
        cache.modify({
          id: cache.identify({
            __typename: 'Filecase',
            id: filecaseId,
          }),
          fields: {
            entities(entities) {
              return [
                ...entities,
                { __ref: cache.identify(result.data.createEntity) },
              ];
            },
          },
        });
      }

      cache.writeQuery({
        query: GetEntitiesAutocompleteQuery,
        variables: {
          studio: studioId,
        },
        data: {
          entities: [
            ...(cache.readQuery({
              query: GetEntitiesAutocompleteQuery,
              variables: {
                studio: studioId,
              },
            })?.entities || []),
            result.data.createEntity,
          ],
        },
      });
    },
  });

  const handleSubmit = async (values, formikBag) => {
    if (entity) {
      await updateEntity({
        variables: {
          ...values,
          state: values?.state?.name,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label ?? tag.name),
          assigned: values?.assigned?.map(
            (assigned) => assigned?.value ?? assigned,
          ),
          id: entity.id,
          type: values.type.value ?? values.type ?? null,
        },
      });

      onClose();
      return;
    }

    try {
      await createEntity({
        variables: {
          ...values,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label),
          assigned: values?.assigned?.map(
            (assigned) => assigned?.value ?? assigned.label,
          ),
          studio: studioId,
          state: values?.state?.name,
          type: values.type.value,

          description: values.description,
          identification: values.identification,
          email: values.email,
          country: values.country,
          address: values.address,

          phone: values.phone,
          firstname: values.firstname ? values.firstname : '',
          lastname: values.lastname ? values.lastname : '',
          businessname: values.businessname ? values.businessname : '',
          filecases: filecaseId ? [filecaseId] : null,
        },
      });

      onClose();
      openSnackbar('Entidad creada exitosamente.', {
        severity: 'success',
      });

      formikBag.resetForm();
    } catch (error) {
      openSnackbar(
        'Hubo un error al crear tu entidad. Intenta de nuevo en unos minutos',
        {
          severity: 'error',
        },
      );
      Sentry.captureException(error);
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const initialValues = entity
    ? {
        ...defaultFormValues,
        ...entity,
        ...parseCustomFieldsToInitialValues(entity?.customFields),
        isLegalEntity: !!entity.businessname,
        type: { label: entityTypesObj[entity?.type], value: entity?.type },
        description: entity?.description ?? '',
        state: entity?.state?.name
          ? {
              label: entity.state.name,
              value: entity.state.name,
              color: entity.state.color,
            }
          : null,
        tags:
          entity?.tags?.map((tag) => ({ label: tag.name, value: tag.name })) ||
          [],
        assigned:
          entity?.assigned?.map((assigned) => ({
            label: assigned.name,
            value: assigned.id,
          })) || [],
      }
    : defaultFormValues;

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={onClose}>
      <DialogTitle>{title ?? 'Nuevo Directorio'} </DialogTitle>

      <Divider />

      <DirectoryForm
        initialValues={initialValues}
        onCancel={onClose}
        onSubmit={handleSubmit}
        entity={entity}
      />
    </Dialog>
  );
};
