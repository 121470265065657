import { gql } from '@apollo/client';

export const MyStudiosQuery = gql`
  query MyStudiosQuery {
    getMyStudios {
      id
      name
      picture

      subscription {
        id
        name
        status
        currentPeriodEnd
        currentPeriodStart
        endedAt
      }
    }
  }
`;

export const StudioMembersQuery = gql`
  query getStudioMembers(
    $status: [MemberStatus!] = [ACTIVE, PENDING, INACTIVE]
    $studio: ID!
  ) {
    members(studio: $studio, status: $status) {
      id
      name
      email
      picture
      status
      role
    }
  }
`;

export const CreateStudioMutation = gql`
  mutation createStudio(
    $name: String!
    $country: String
    $phone: String
    $metadata: StudioCreationMetadata
    $picture: Upload
    $invitations: [ID!]
  ) {
    createStudio(
      name: $name
      country: $country
      phone: $phone
      picture: $picture
      metadata: $metadata
      invitations: $invitations
    ) {
      id
      name
      address {
        phone
        country
        city
        floor
        street
        city
      }
      picture
      createdAt
    }
  }
`;

export const UpdateStudioMutation = gql`
  mutation updateStudio(
    $studio: ID!
    $name: String!
    $phone: String
    $country: String
    $city: String
    $floor: String
    $street: String
    $picture: Upload
  ) {
    updateStudio(
      studio: $studio
      name: $name
      picture: $picture
      address: {
        phone: $phone
        country: $country
        city: $city
        floor: $floor
        street: $street
      }
    ) {
      id
      phone
    }
  }
`;

export const ResolveStudioInvitationMutation = gql`
  mutation ResolveStudioInvitationMutation(
    $studioId: ID!
    $invitationId: ID!
    $consent: Boolean!
  ) {
    resolveInvitation(
      studio: $studioId
      invitationId: $invitationId
      consent: $consent
    ) {
      id
      name
    }
  }
`;
