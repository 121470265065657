import { gql } from '@apollo/client';

export const GET_ENTITY_STATUSES = gql`
  query getStudioStates($studioId: ID!) {
    entityStates(studioId: $studioId) {
      id
      name
      color
      studioId
    }
  }
`;
