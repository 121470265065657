import React from 'react';
import { Box, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

import LSClock from 'src/components/icons/LSClock';

export const DateCell = ({ row }) => (
  <Box sx={{ flex: 1 }}>
    <Tooltip placement="left" title={dayjs(row.date).format('LLL')}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: 'info.main',
          gap: 0.5,
        }}
      >
        <LSClock color="info" />

        {dayjs(row.date).format('L')}
      </Box>
    </Tooltip>
  </Box>
);
