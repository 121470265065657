import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSubscription } from 'src/components/SubscriptionWatcher/useSubscription';
import { useBreakpoints } from 'src/utils/hooks/common';

export const SubscriptionNavbarButton = () => {
  const navigate = useNavigate();
  const { studio } = useSubscription();
  const { isMobile } = useBreakpoints();

  const handleClick = () => {
    if (!studio?.subscription?.id) {
      return;
    }

    return navigate(`/dashboard/${studio?.id}/settings/subscription`);
  };

  if (!studio?.id) {
    return null;
  }

  const label = isMobile
    ? '🏄'
    : `${studio?.subscription?.id ? studio.subscription.name : 'Surfer Free '
    } 🏄`;

  return (
    <Button sx={{ color: 'white' }} onClick={handleClick} disableRipple={!studio?.subscription?.id}>
      {label}
    </Button>
  );
};
