import React from 'react';
import { Box, Stack } from '@mui/material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import Page from 'src/components/v2/Page';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { usePermissions } from 'src/utils/hooks/usePermissions';

import FilecasesSection from './FilecasesSection';
import EventsSection from './EventsSection';
import AuthGuard from './AuthGuard';
import { OrganizationBanner } from './OrganizationBanner';
import { UserStudioCard } from './UserStudioCard';
import { HomeLogs } from './HomeLogs';
import { CongratsModal } from './useOnboardingCheck';
import SalesSection from './SalesSection';

const layoutGap = [1, 1, 1, 2];

const Home = () => {
  const { permissions, role } = usePermissions();

  if (!permissions.length && role !== 'admin' && role !== 'owner') {
    return (
      <Page title="Dashboard | LegalSurf">
        <AuthGuard />
      </Page>
    );
  }

  return (
    <Page title="Dashboard | LegalSurf">
      <OrganizationBanner />

      <CongratsModal />

      <Stack direction={['column', 'row']} gap={layoutGap}>
        <Stack
          gap={layoutGap}
          minWidth={['initial', 'initial', 240]}
          maxWidth={['initial', 'initial', 300]}
        >
          <UserStudioCard />

          <HomeLogs />
        </Stack>

        <Stack gap={layoutGap} flex={1}>
          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.CALENDAR.ACCESS]}>
            <EventsSection />
          </PermissionGuard>

          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.FILECASES.ACCESS]}>
            <FilecasesSection />
          </PermissionGuard>

          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SALES.ACCESS]}>
            <Box maxWidth={500}>
              <SalesSection />
            </Box>
          </PermissionGuard>
        </Stack>
      </Stack>
    </Page>
  );
};

export default Home;
