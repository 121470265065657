import React from 'react';
import { Typography, Box } from '@mui/material';
import { formatMoney } from 'src/utils/formatters';

const ChunkCard = ({
  variant = 'dashed',
  title,
  amount,
  icon,
  sx,
  ...props
}) => (
  <Box
    {...props}
    sx={{
      padding: 2,
      borderRadius: 1,
      border: `1px ${variant}`,
      borderColor: 'grey.200',
      ...sx,
    }}
  >
    <Box
      sx={{
        gap: 2,
        display: 'flex',
      }}
    >
      {icon}

      <Typography variant="h4">{amount ? formatMoney(amount) : 0}</Typography>
    </Box>

    <Typography color="textSecondary" variant="h6">
      {title}
    </Typography>
  </Box>
);

export default ChunkCard;
