import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const SubscriptionManagementSkeleton = () => (
  <Box sx={{ p: 4 }}>
    <Skeleton
      animation="wave"
      height={28}
      width="50%"
      variant="rectangular"
      sx={{ mb: 4 }}
    />

    <Box
      sx={{
        gap: 4,
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      }}
    >
      <Skeleton animation="wave" height={20} variant="rectangular" />
      <Skeleton animation="wave" height={20} variant="rectangular" />
      <Skeleton animation="wave" height={20} variant="rectangular" />
      <Skeleton animation="wave" height={20} variant="rectangular" />

      <Skeleton animation="wave" height={20} variant="rectangular" />
      <Skeleton animation="wave" height={20} variant="rectangular" />
      <Skeleton animation="wave" height={20} variant="rectangular" />
      <Skeleton animation="wave" height={20} variant="rectangular" />
    </Box>
  </Box>
);
