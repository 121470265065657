import React from 'react';
import { Grow, InputBase, Box, CircularProgress } from '@mui/material';

import { LSLen as LSLenIcon } from '../../../icons';

const SearchField = ({ loading, ...rest }) => (
  <Box
    sx={{
      py: 0.5,
      px: 1,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'background.input',
      borderRadius: 1,
      ...rest.sx,
    }}
  >
    <InputBase
      fullWidth
      endAdornment={
        <Grow in={loading}>
          <Box sx={{ pr: 2, ml: 'auto' }}>
            <CircularProgress color="primary" size={12} />
          </Box>
        </Grow>
      }
      inputProps={{
        autoComplete: 'off',
        autoCorrect: 'off',
      }}
      startAdornment={<LSLenIcon color="primary" />}
      {...rest}
    />
  </Box>
);

export default SearchField;
