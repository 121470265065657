import React from 'react';
import { Box } from '@mui/material';

import { getEventIconByType } from './utils';

const CalendarEventTypeIcon = ({ type }) => {
  const Icon = getEventIconByType(type);

  return (
    <Box
      sx={{
        borderRadius: '50%',
        textAlign: 'center',
        width: 35,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        ...(type === 'alert' && {
          backgroundColor: 'warning.200',
        }),

        ...(type === 'task' && {
          backgroundColor: 'success.200',
        }),
      }}
    >
      <Icon
        color={type === 'task' ? 'success' : 'warning'}
        sx={{ fontSize: 17 }}
      />
    </Box>
  );
};

export default CalendarEventTypeIcon;
