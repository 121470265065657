import React from 'react';
import { Box } from '@mui/material';

import { SelectField } from 'src/components/ui-components/Form';
import { DEFAULT_TASK_STATES } from '@legalsurf/common';

const getTaskStatusStyles = (option) => ({
  py: 1,
  px: 3,
  borderRadius: 2,
  width: '100%',
  backgroundColor: option.bg,
  color: option.color,
});

const TaskStatusAutocomplete = ({
  disabled,
  label = 'Estado',
  placeholder = 'Ej. En progreso',
  ...rest
}) => (
  <SelectField
    disabled={disabled}
    label={label}
    options={DEFAULT_TASK_STATES}
    placeholder={placeholder}
    renderOption={(option) => (
      <Box
        key={option.key}
        sx={{
          ...getTaskStatusStyles(option),
        }}
      >
        {option.label}
      </Box>
    )}
    {...rest}
  />
);

export default TaskStatusAutocomplete;
