import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query categories($studio: ID!) {
    categories(studio: $studio) {
      name
      id
    }
  }
`;

export default null;
