import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Stack,
} from '@mui/material';

import { useStudio } from 'src/utils/hooks/useStudio';

export const useStudioBlockedInfo = () => {
  const { studio, loading } = useStudio({
    fetchPolicy: 'cache-first',
  });

  return {
    loading,
    info: {
      blocked: studio?.metadata?.blocked,
      type: studio?.metadata?.homeBannerType,
      color: studio?.metadata?.homeBannerBorderColor,
      backgroundColor: studio?.metadata?.homeBannerBackgroundColor,
      title: studio?.metadata?.homeBannerTitle,
      description: studio?.metadata?.homeBannerDescription,
      link: studio?.metadata?.homeBannerLink,
      linkText: studio?.metadata?.homeBannerLinkText,
    },
  };
};

export const OrganizationBanner = () => {
  const { info, loading } = useStudioBlockedInfo();

  if (loading) {
    return null;
  }

  if (info.type === 'warning') {
    return (
      <Card
        sx={{
          border: '1px dashed',
          borderColor: info.color || 'warning.dark',
          backgroundColor: info.backgroundColor || 'warning.light',
          boxShadow: 0,
          m: 2,
          my: 0,
        }}
      >
        <CardContent>
          <Stack gap={2}>
            <Box>
              <Typography sx={{ fontWeight: '800', fontSize: 16 }} variant="h6">
                {info.title ||
                  'IMPORTANTE: Tu periodo de prueba está por finalizar 🚨'}
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: 15,
                  color: 'rgb(126, 130, 153)',
                }}
                variant="h6"
              >
                {info.description || (
                  <Box>
                    Parece que has superado los límites de tu Plan Surfer Free.
                    No te preocupes, es hora de llevar tu firma legal al
                    siguiente nivel.
                    <br />
                    Actualiza tu plan ahora y desbloquea un mundo de
                    posibilidades adicionales que te ayudarán a impulsar tu
                    éxito legal.
                    <br />
                    ¡No te quedes atrás, sigue avanzando con nosotros!
                  </Box>
                )}
              </Typography>
            </Box>

            <Box>
              <Button
                LinkComponent={Link}
                color="primary"
                target="_blank"
                to={
                  info.link ||
                  'https://api.whatsapp.com/send?phone=5491171250058&text=Hola%20LegalSurf!%20necesito%20ayuda%20con%20mi%20suscripcion%F0%9F%8F%84%E2%80%8D%E2%99%82%EF%B8%8F'
                }
                variant="contained"
              >
                {info.linkText || 'Hablar con un asesor'}
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return null;
};
