import React, { useState } from 'react';
import { Box, Chip, Stack } from '@mui/material';

import HoursTrackingDialog from 'src/dialogs/HoursTrackingDialog';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';

import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { gql, useMutation } from '@apollo/client';
import { useColumns } from './HoursTrackingColumns';
import { humanizeHoursTrackingTime } from './utils';

export const deleteHourMutation = gql`
  mutation deleteHourMutation($id: ID!) {
    deleteHourTracking(id: $id) {
      id
    }
  }
`;

const HoursTrackingSection = ({ filecase }) => {
  const [openHoursTrackingDialog, setOpenHoursTrackingDialog] = useState(false);

  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteHourTracking] = useMutation(deleteHourMutation);

  const handleRemove = (hour) =>
    dispatchWarningPromptDialog('SingleFilecaseDocuments', {
      onAccept: () =>
        deleteHourTracking({
          variables: {
            id: hour.id,
          },
          update: (cache) => {
            const normalizedId = cache.identify({
              id: hour.id,
              __typename: 'Hour',
            });

            cache.evict({ id: normalizedId });
            cache.gc();
          },
        }),
      title: 'Eliminar hora cargada',
    });

  const columns = useColumns({ onDelete: handleRemove });

  return (
    <Stack gap={2}>
      {(filecase?.chargedHours ?? []).length > 0 && (
        <Box>
          <Chip
            label={`Total de horas cargadas: ${humanizeHoursTrackingTime(
              filecase?.totalHours,
            )}`}
          />
        </Box>
      )}

      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        loading={false}
        pageSize={filecase.chargedHours?.length}
        paginationMode="client"
        rows={filecase.chargedHours}
        sxCard={{
          boxShadow: 'none',
        }}
      />

      <CreationInfoBanner
        buttonStr="Cargar horas"
        description="Lleva un detalle de las horas trabajas de tu equipo."
        title="Cargar horas de trabajo"
        onClick={() => setOpenHoursTrackingDialog(true)}
      />

      <HoursTrackingDialog
        disabledFields={{ filecase: true }}
        hourTracking={{
          filecase: { value: filecase.id, label: filecase.title },
        }}
        open={openHoursTrackingDialog}
        onClose={() => setOpenHoursTrackingDialog(false)}
      />
    </Stack>
  );
};

export default HoursTrackingSection;
