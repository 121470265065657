import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { useTags } from 'src/autocompletes/TagsAutocomplete';
import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { GET_TAGS } from 'src/graphql/queries/tags';

const DeleteTagMutation = gql`
  mutation DeleteTagMutation($name: ID!, $studioId: ID!) {
    deleteTag(name: $name, studioId: $studioId) {
      id
      name
      studioId
    }
  }
`;

const CreateTagMutation = gql`
  mutation CreateTagMutation($name: ID!, $studioId: ID!) {
    createTag(name: $name, studioId: $studioId) {
      id
      name
      updatedAt
      studioId
    }
  }
`;

const UpdateTagMutation = gql`
  mutation UpdateTagMutation($id: ID!, $name: ID!, $studioId: ID!) {
    updateTag(id: $id, name: $name, studioId: $studioId) {
      id
      name
      updatedAt
      studioId
    }
  }
`;

const Actions = ({ row }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteTag] = useMutation(DeleteTagMutation, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteTag));
      cache.gc();
    },
  });

  return [
    <Tooltip title="Para editar, doble click en los campos editables que deseas actualizar en la tabla">
      <GridActionsCellItem
        color="inherit"
        icon={<LSEdit color="primary" fontSize="small" />}
        label="Edit"
      />
    </Tooltip>,
    <Tooltip title="Eliminar etiqueta">
      <GridActionsCellItem
        color="inherit"
        icon={<LSTrash color="error" fontSize="small" />}
        label="Delete"
        onClick={() =>
          dispatchWarningPromptDialog(`TagsTab-${row.name}`, {
            onAccept: () =>
              deleteTag({
                variables: {
                  name: row.name,
                  studioId,
                },
              }),
            title: 'Eliminar etiqueta',
          })
        }
      />
    </Tooltip>,
  ];
};

export const TagsTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const { value } = useTabContext();
  const studioId = useStudioId();
  const { tags, loading } = useTags({ skip: value !== index });
  const [updateTag] = useMutation(UpdateTagMutation);
  const [createTag] = useMutation(CreateTagMutation, {
    refetchQueries: [GET_TAGS],
  });

  const handleUpdate = async (tag, oldTag) => {
    if (tag.isNew) {
      return (
        await createTag({
          variables: {
            studioId,
            name: tag.name,
          },
        })
      ).data.createTag;
    }

    if (tag.name === oldTag.name) {
      return oldTag;
    }

    const res = await updateTag({
      variables: {
        studioId,
        id: tag.id,
        name: tag.name,
      },
    });

    return res.data.updateTag;
  };

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: true,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 180,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      editable: false,
      getActions: Actions,
    },
  ];

  return (
    <TabPanel value={index}>
      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        editMode="row"
        loading={loading}
        pageSize={10}
        processRowUpdate={handleUpdate}
        rowCount={tags?.length}
        rows={tags}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe una etiqueta con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
      />
    </TabPanel>
  );
};
