import ContrastColor from 'contrast-color';
import { red, green, blue, orange, teal, grey, yellow } from '@mui/material/colors';

const palette = {
  whatsapp: {
    main: '#24d366',
    darker: '#075e54',
    contrastText: '#FFF',
  },
  primary: {
    100: 'rgb(248, 245, 255)',
    200: '#F8F5FF',
    300: '#f1edfb',
    500: '#7239EA',
    600: '#3122c4',
    700: 'rgb(62, 48, 198)',
    800: '#4221c1',
    900: 'rgb(10, 13, 67)',
    light: '#A2A1EE',
    main: '#625FF1',
    dark: '#4E28D8',
    thin: 'rgb(103, 105, 235, 0.2)',
    contrastText: '#FFFFFF',
  },

  secondary: {
    100: '#F1FAFF',
    light: '#42A5f5',
    main: '#0d6efd',
    dark: '#0e62e0',
  },
  error: {
    100: '#ffe7ec',
    200: '#ffdfe6',
    light: '#f386a1',
    main: '#f0416c',
    dark: '#b32649',
  },
  warning: {
    light: '#fcedb8',
    main: '#ffde6a',
    dark: '#ffd43a',
    200: '#fff8dd',
    400: '#FAE1B2',
  },
  info: {
    light: '#a379f8',
    main: '#7239e8',
    dark: '#541ebe',
  },
  success: {
    light: '#9ceee2',
    main: '#50cdba',
    dark: '#2da593',
    contrastText: '#FFFFFF',
    200: '#E8FFF3',
  },
  divider: 'rgba(224, 224, 224, 1)',
  background: {
    input: '#F5F8FA',
    main: 'rgba(224, 224, 224, 1)',
  },
  grey: {
    ...grey,
    100: '#f5f8fa',
    200: '#B5B5C3',
    300: '#f1f1f4',
    400: '#7A7A7A',
    500: '#444444',
    800: '#7E8299',
  },
  icons: {
    document: '#625FF1',
    pdf: red[500],
    excel: green[500],
    doc: blue[500],
    powerpoint: orange[500],
    image: teal[500],
    audio: yellow[500],
    folders: 'transparent',
    default: grey[500],
  },
  computeContrast: (bgColor, config) =>
    ContrastColor.contrastColor({ bgColor, ...config }),
};

export default palette;
