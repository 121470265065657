import React from 'react';
import {
  Button,
  Box,
  Typography,
  Stack,
  Card,
  CardContent,
} from '@mui/material';
import { Link } from 'react-router-dom';

import Page from 'src/components/v2/Page';
import { useStudioBlockedInfo } from 'src/pages/Dashboard/Home/OrganizationBanner';
import { SubscriptionDetails } from 'src/pages/Dashboard/Settings/SubscriptionManagement/SubscriptionDetails';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const StudioBlockedContent = ({ children }) => {
  const { info } = useStudioBlockedInfo();

  if (!info.blocked) {
    return children;
  }

  return (
    <Page sx={{ mt: 2 }}>
      <Card
        sx={{
          border: '1px dashed',
          borderColor: info.color || 'warning.dark',
          backgroundColor: info.backgroundColor || 'warning.light',
          boxShadow: 0,
          m: 2,
          my: 0,
        }}
      >
        <CardContent>
          <Stack gap={2}>
            <Box>
              <Typography sx={{ fontWeight: '800', fontSize: 16 }} variant="h6">
                {info.title || 'Cuenta suspendida'}
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: 15,
                  color: 'rgb(126, 130, 153)',
                }}
                variant="h6"
              >
                {info.description || (
                  <>
                    Su cuenta termino el periodo de prueba o registra una deuda
                    acumulada y ha sido suspendida. Si tienes alguna pregunta o
                    necesitas ayuda, no dudes en contactarnos por mail a
                    cobranzas@legal.surf o escríbenos a nuestro WhatsApp.
                  </>
                )}
              </Typography>
            </Box>

            <SubscriptionDetails />

            <Box>
              <Button
                LinkComponent={Link}
                color="primary"
                target="_blank"
                to={
                  info.link ||
                  'https://api.whatsapp.com/send?phone=5491171250058&text=Hola%20LegalSurf!%20necesito%20ayuda%20con%20mi%20suscripcion%F0%9F%8F%84%E2%80%8D%E2%99%82%EF%B8%8F'
                }
                variant="contained"
              >
                {info.linkText || 'Hablar con un asesor'}
              </Button>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Page>
  );
}

export const StudioBlockedWrapper = ({ children }) => {
  const studioId = useStudioId();

  if (!studioId) {
    return children;
  }

  return (
    <StudioBlockedContent>
      {children}
    </StudioBlockedContent>
  )
};
