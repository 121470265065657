import React from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';

export const OrganizersTabToolbar = ({ label, onClick }) => (
  <GridToolbarContainer sx={{ justifyContent: 'right' }}>
    <Button
      color="primary"
      startIcon={<Add />}
      variant="contained"
      onClick={onClick}
    >
      {label}
    </Button>
  </GridToolbarContainer>
);
