import React from 'react';
import { Typography, Box, Stack } from '@mui/material';

import CalendarEventTypeIcon from '../../CalendarEventTypeIcon';

export const CalendarEventCardDateDescriptor = ({
  isAllDay,
  timeDescriptor,
  type,
  ...props
}) => {
  if (!isAllDay) {
    return (
      <Box
        sx={{
          flexDirection: 'column',
          display: 'flex',
          gap: 2,
          p: 1.5,
          ...props.sx,
        }}
        {...props}
      >
        <Stack alignItems="center" direction="row" mb={1} spacing={1}>
          {type && <CalendarEventTypeIcon type={type} />}
          <Typography
            color={timeDescriptor.color}
            fontSize={16}
            fontWeight={600}
            variant="h4"
          >
            {timeDescriptor.str}
          </Typography>
        </Stack>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Box>
            <Typography
              color="secondaryText"
              fontSize={20}
              fontWeight={600}
              variant="h4"
            >
              {timeDescriptor.dates[0].timeStr} hs
            </Typography>
            <Typography
              color="secondaryText"
              fontSize={13}
              fontWeight={500}
              variant="h6"
            >
              {timeDescriptor.dates[0].dateStr}
            </Typography>
          </Box>

          <Typography
            color="secondaryText"
            fontSize={24}
            fontWeight={400}
            variant="h2"
          >
            {' > '}
          </Typography>

          <Box>
            <Typography
              color="secondaryText"
              fontSize={20}
              fontWeight={600}
              variant="h4"
            >
              {timeDescriptor.dates[1].timeStr} hs
            </Typography>
            <Typography
              color="secondaryText"
              fontSize={13}
              fontWeight={500}
              variant="h6"
            >
              {timeDescriptor.dates[1].dateStr}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        minWidth: 360,
        flexDirection: 'column',
        display: 'flex',
        gap: 1,
        p: 1.5,
        ...props.sx,
      }}
      {...props}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        {type && <CalendarEventTypeIcon type={type} />}

        <Typography fontSize={16} fontWeight={500} variant="h4">
          Todo el dia
        </Typography>
      </Stack>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <Typography
          color="secondaryText"
          fontSize={16}
          fontWeight={600}
          variant="h4"
        >
          {timeDescriptor.dates[0].dateStr}
        </Typography>

        <Typography
          color="secondaryText"
          fontSize={24}
          fontWeight={400}
          variant="h2"
        >
          {' > '}
        </Typography>

        <Typography
          color="secondaryText"
          fontSize={16}
          fontWeight={600}
          variant="h4"
        >
          {timeDescriptor.dates[1].dateStr}
        </Typography>
      </Box>
    </Box>
  );
};
