import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';

import CalendarEventTypeIcon from 'src/components/v3/CalendarEventTypeIcon';

const StartCell = ({ row: event, colDef }) => {
  const label = useMemo(() => {
    if (event.allDay) {
      return 'Todo el día';
    }

    return colDef.weekly
      ? dayjs(event.start).format('DD/MM/YYYY - HH:mm')
      : dayjs(event.start).format('HH:mm [HS]');
  }, [colDef.weekly, event.allDay, event.start]);

  return (
    <Box
      sx={{
        maxWidth: '40rem',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        gap: 1,
      }}
    >
      <CalendarEventTypeIcon type={event.type} />

      <Typography
        role="button"
        sx={{
          fontWeight: '500',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default StartCell;
