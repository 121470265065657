import React, { useState } from 'react';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import {
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Box,
  Grid,
  Tooltip,
  ListItemIcon,
  ListItemText,
  Grow,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';

import { useBreakpoints } from 'src/utils/hooks/common';
import MoreButton from 'src/components/v2/MoreButton';
import Form, {
  CheckField,
  SelectField,
} from 'src/components/ui-components/Form';
import SearchField from 'src/components/ui-components/Form/SearchField';
import { LSList as LSListIcon, LSAdd as LSAddIcon } from 'src/components/icons';
import { Report } from '@mui/icons-material';

const colorsToSwitchField = {
  all: 'primary',
  alert: 'warning',
  Report: 'warning',
  task: 'success',
};

// TODO: Refactor v2 component to change API and add some comp composition
// This component is more bigger than LATAM economics problems all together
// 16/09/2021 - Yes, it is
// 31/10/2022 - Yes, it is
// 14/12/2022 - Yes, it is
// 07/24/2023 - Yes, it is
const SectionHeader = ({
  title,
  subtitle,
  actions,
  actionsButtonProps = {},
  createButton,
  filters,
  switchField,
}) => {
  const { isMobile } = useBreakpoints();
  const [t, setT] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [actionsMenuButtonEl, setActionsMenuButtonEl] = React.useState(null);
  const actionsMenuOpen = Boolean(actionsMenuButtonEl);
  const handleActionsMenuButtonClick = (event) => {
    setActionsMenuButtonEl(event.currentTarget);
  };
  const handleActionsMenuClose = () => {
    setActionsMenuButtonEl(null);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          ...(isMobile && {
            flexDirection: 'column',
            gap: 2,
          }),
        }}
      >
        <Box
          sx={
            isMobile
              ? { display: 'flex', gap: 2, alignItems: 'center' }
              : { mr: 2 }
          }
        >
          <Typography sx={{ mb: 0.5 }} variant="h3">
            {title}
          </Typography>

          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        </Box>

        {filters && (
          <Formik
            initialValues={filters.initialValues}
            onSubmit={(...formik) => filters.onSubmit(...formik)}
          >
            {(sectionHeaderFormik) => {
              // WARN: This approach will not work in a long scale, find a better way
              const isSomeFilterActive =
                Object.keys(sectionHeaderFormik.values).some(
                  (key) =>
                    sectionHeaderFormik.dirty &&
                    String(sectionHeaderFormik.values[key]) !==
                      String(sectionHeaderFormik.initialValues[key]),
                ) && isSubmitted;

              return (
                <Form>
                  <Grid item sx={{ display: 'flex', gap: 1 }}>
                    {filters.search && (
                      <SearchField
                        loading={
                          sectionHeaderFormik.isSubmitting || filters.loading
                        }
                        name={filters.search.name}
                        placeholder={filters.search.placeholder}
                        value={sectionHeaderFormik.values[filters.search.name]}
                        // onChange={sectionHeaderFormik.handleChange}
                        onChange={(event) => {
                          sectionHeaderFormik.handleChange(event);

                          if (t) clearTimeout(t);
                          setT(
                            setTimeout(sectionHeaderFormik.handleSubmit, 750),
                          );
                        }}
                      />
                    )}

                    {filters && filters.data && (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <MoreButton popperHeader="Filtrar por:">
                          {switchField && (
                            <ButtonGroup
                              aria-label="Event type"
                              sx={{ m: 2 }}
                              variant="outlined"
                            >
                              {switchField.options.map((opt) => (
                                <Button
                                  color={colorsToSwitchField[opt.value]}
                                  key={opt.value}
                                  variant={
                                    switchField.value === opt.value
                                      ? 'contained'
                                      : 'outline'
                                  }
                                  onClick={() =>
                                    switchField.handleChange(opt.value)
                                  }
                                >
                                  {opt.label}
                                </Button>
                              ))}
                            </ButtonGroup>
                          )}

                          {filters.data.map((filter) => (
                            <Box key={filter.name} sx={{ p: 2 }}>
                              {filter.type === 'checkbox' && (
                                <CheckField
                                  checkedIcon={filter.checkedIcon}
                                  disabled={
                                    sectionHeaderFormik.isSubmitting ||
                                    filters.loading
                                  }
                                  icon={filter.icon}
                                  label={filter.title || ''}
                                  name={filter.name}
                                />
                              )}

                              {filter.type === 'select' ||
                                (!filter.type && (
                                  // TODO: Accept custom components and types of formcomponents
                                  <SelectField
                                    disabled={
                                      sectionHeaderFormik.isSubmitting ||
                                      filters.loading
                                    }
                                    label={filter.title || ''}
                                    multiple={filter.isMulti}
                                    name={filter.name}
                                    options={filter.options || []}
                                    placeholder=""
                                    onChange={(event, selected) =>
                                      sectionHeaderFormik.setFieldValue(
                                        filter.name,
                                        selected,
                                      )
                                    }
                                  />
                                ))}
                            </Box>
                          ))}

                          <Box
                            sx={{
                              gap: 1,
                              p: 2,
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              disabled={
                                sectionHeaderFormik.isSubmitting ||
                                filters.loading
                              }
                              variant="outlined"
                              onClick={async () => {
                                setIsSubmitted(false);
                                sectionHeaderFormik.setValues(
                                  sectionHeaderFormik.initialValues,
                                );

                                if (filters.onReset) {
                                  try {
                                    sectionHeaderFormik.setSubmitting(true);
                                    await filters.onReset();
                                  } finally {
                                    sectionHeaderFormik.setSubmitting(false);
                                  }
                                }
                              }}
                            >
                              Restaurar
                            </Button>

                            <LoadingButton
                              loading={
                                sectionHeaderFormik.isSubmitting ||
                                filters.loading
                              }
                              variant="contained"
                              onClick={async () => {
                                try {
                                  sectionHeaderFormik.setSubmitting(true);

                                  await filters.onSubmit(
                                    sectionHeaderFormik.values,
                                    sectionHeaderFormik,
                                  );
                                } finally {
                                  sectionHeaderFormik.setSubmitting(false);
                                }
                              }}
                            >
                              Aplicar
                            </LoadingButton>
                          </Box>
                        </MoreButton>

                        <Tooltip title="Tiene filtros activos">
                          <Grow in={isSomeFilterActive}>
                            <Report color="warning" />
                          </Grow>
                        </Tooltip>
                      </Box>
                    )}
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}

        {/* TODO!:REFACTOREAR PELIGRO!! */}
        {(actions || createButton) && Array.isArray(createButton) && (
          <Box sx={{ ml: 'auto', display: 'flex', gap: 1 }}>
            {createButton.map((button) => (
              <Button
                color={button.color ?? 'primary'}
                fullWidth={isMobile}
                key={button.title}
                startIcon={<LSAddIcon />}
                sx={{
                  ...(isMobile && {
                    order: 2,
                  }),
                  ...(button.sx ?? {}),
                }}
                variant="contained"
                onClick={button.handleChange}
              >
                {button.title}
              </Button>
            ))}
          </Box>
        )}

        {createButton && !Array.isArray(createButton) && (
          <Button
            fullWidth={isMobile}
            startIcon={<LSAddIcon />}
            sx={{
              marginLeft: 'auto',
              ...(isMobile && {
                order: 2,
              }),
            }}
            variant="contained"
            onClick={createButton.handleChange}
          >
            {createButton.title}
          </Button>
        )}

        {/* No chance, negative. To much for a mobile, find a better way to display mobile data */}
        {Array.isArray(actions) && !!actions.length && !isMobile && (
          <Box>
            <Tooltip title="Acciones">
              <IconButton
                aria-controls={actionsMenuOpen ? 'actions-menu' : undefined}
                aria-expanded={actionsMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
                color="primary"
                onClick={handleActionsMenuButtonClick}
                {...actionsButtonProps}
              >
                <LSListIcon />
              </IconButton>
            </Tooltip>

            <Menu
              MenuListProps={{
                'aria-labelledby': 'actions-menu',
              }}
              anchorEl={actionsMenuButtonEl}
              id="actions-menu"
              open={actionsMenuOpen}
              onClose={handleActionsMenuClose}
            >
              {actions.map(({ Component, ...action }) => (
                <MenuItem
                  key={action.title}
                  onClick={action.handleChange}
                  {...action}
                >
                  {Component && <Component {...action} />}

                  {!Component && (
                    <>
                      {action.icon && (
                        <ListItemIcon>
                          <action.icon color="primary" fontSize="small" />
                        </ListItemIcon>
                      )}

                      <ListItemText>{action.title}</ListItemText>
                    </>
                  )}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SectionHeader;
