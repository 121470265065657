import React from 'react';

import countries from 'src/i18n/locales/countries.es.json';
import { SelectField } from 'src/components/ui-components/Form';

function getFlagEmoji(countryCode) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

const CountriesAutocomplete = ({
  disabled,
  label,
  loading,
  placeholder = 'México, Argentina, etc...',
  ...rest
}) => {
  const options =
    countries.map((country) => ({
      value: country.code,
      label: `${getFlagEmoji(country.code)} ${country.name_en}`,
    })) || [];

  return (
    <SelectField
      disabled={disabled}
      label={label}
      loading={loading}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default CountriesAutocomplete;
