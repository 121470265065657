import { useMutation } from '@apollo/client';
import { ArrowBack, HourglassEmpty } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { LSCalendar, LSEdit, LSPin, LSTrash } from 'src/components/icons';

import Page from 'src/components/v2/Page';
import { useDialog } from 'src/dialogs/Dialogs';
import { WorkflowTimeline } from 'src/dialogs/WorkflowFormDialog/WorkflowTimeline';
import {
  CreateWorkflowMutation,
  DeleteWorkflowMutation,
  UpdateWorkflowMutation,
  useWorkflowFormInitialValues,
} from 'src/dialogs/WorkflowFormDialog/utils';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useUser } from 'src/utils/hooks/useUser';
import { GetWorkflowsQuery } from 'src/popovers/WorkflowsPopover/queries';
import {
  calculateWorkflowDiffCalendars,
  calculateWorkflowTotalDuration,
} from './utils';

const WorkflowInfoItem = ({ icon, label, total }) => (
  <Stack alignItems="center" direction="row" gap={1}>
    {icon}

    <Stack alignItems="center" direction="row" gap={0.5}>
      <Typography variant="body2">{label}</Typography>

      <Typography fontWeight={600} variant="body2">
        {total}
      </Typography>
    </Stack>
  </Stack>
);

const WorkflowCreateValidationSchema = Yup.object({
  name: Yup.string().required('El nombre del flujo de trabajo es requerido'),
  steps: Yup.array()
    .min(1, 'Debes agregar al menos un paso a tu flujo de trabajo')
    .required('Debes agregar al menos un paso a tu flujo de trabajo'),
});

export const WorkflowCreate = () => {
  const studioId = useStudioId();
  const { user } = useUser();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const workflowId = searchParams.get('workflowId');
  const initialValues = useWorkflowFormInitialValues(workflowId);
  const navigate = useNavigate();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchWorkflowEventForm = useDialog('workflowsEventForm');
  const dispatchWorkflormInfoFormDialog = useDialog('workflowsForm');
  const [createWorkflow] = useMutation(CreateWorkflowMutation);
  const [updateWorkflow] = useMutation(UpdateWorkflowMutation);
  const [deleteWorkflow] = useMutation(DeleteWorkflowMutation, {
    update: (cache, { data }) => {
      cache.evict({ id: cache.identify(data.deleteWorkflow) });
    },
  });

  const handleClose = () => navigate(-1);

  const handleCloseWarn = () => {
    dispatchWarningPromptDialog(`WorkflowFormDialog-CloseButton`, {
      content: (
        <Alert severity="error">
          Regresar hará que pierdas cualquier cambio no guardado para este flujo
          de trabajo. ¿Desea continuar?
        </Alert>
      ),
      onAccept: handleClose,
    });
  };

  const handleSubmit = async (values) => {
    const mutation = workflowId ? updateWorkflow : createWorkflow;

    const variables = {
      studioId,
      name: values.name,
      description: values.description,
      tags: values.tags?.map((tag) => tag.value ?? tag),
      steps: values.steps
        .filter((step) => !!step)
        .map((step) => ({
          id: step.id,
          type: step.type,
          description: step.description ?? step.data?.title,
          data: step.data,
        })),
    };

    if (workflowId) {
      variables.workflowId = workflowId;
    }

    await mutation({
      variables,
      refetchQueries: [{ query: GetWorkflowsQuery, variables: { studioId } }],
      fetchPolicy: 'network-only',
    });

    handleClose();
  };

  return (
    <Page title="Flujos de trabajo | legalsurf">
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          ...location.state?.workflowDraft,
        }}
        validationSchema={WorkflowCreateValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, setValues, errors, submitForm }) => (
          <Stack direction="row" gap={6}>
            <Stack flex={3} gap={2}>
              <Stack direction="row" justifyContent="space-between">
                <Stack alignItems="center" direction="row" gap={1}>
                  <Stack alignItems="center" direction="row" gap={0.5}>
                    <IconButton size="small" onClick={handleCloseWarn}>
                      <ArrowBack />
                    </IconButton>
                  </Stack>

                  <Typography variant="h5">Flujo de trabajo</Typography>
                </Stack>

                <Box>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() =>
                      dispatchWorkflormInfoFormDialog(
                        ['WorkflowCreate', workflowId].join('-'),
                        {
                          initialValues: values,
                          onSubmit: (workflowInfoValues) => {
                            setValues({ ...values, ...workflowInfoValues });
                          },
                        },
                      )
                    }
                  >
                    <LSEdit />
                  </IconButton>

                  {workflowId && (
                    <Tooltip title="Eliminar flujo de trabajo">
                      <IconButton
                        color="primary"
                        sx={{
                          '&:hover': {
                            color: 'error.main',
                          },
                        }}
                        onClick={() =>
                          dispatchWarningPromptDialog(
                            `WorkflowFormDialog-${workflowId}`,
                            {
                              onAccept: () =>
                                deleteWorkflow({
                                  variables: {
                                    studioId,
                                    workflowId,
                                  },
                                }).then(handleClose),
                            },
                          )
                        }
                      >
                        <LSTrash fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Stack>

              <Divider />

              <Stack gap={2}>
                <Stack gap={1}>
                  <Typography variant="h3">
                    {values.name || '- Nombre de tu flujo de trabajo -'}
                  </Typography>

                  <Typography variant="body2">{values.description}</Typography>
                </Stack>

                {!!values.tags.length && (
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    {values.tags.map((tag) => (
                      <Chip
                        key={tag.label}
                        label={tag.label}
                        size="small"
                        sx={{
                          color: (theme) => theme.palette.primary.dark,
                          backgroundColor: (theme) =>
                            theme.palette.primary[100],
                          fontWeight: '400',
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Stack>

              <Stack
                gap={1}
                sx={{ bgcolor: 'background.input', p: 2, borderRadius: 0.5 }}
              >
                Información sobre el flujo
                <WorkflowInfoItem
                  icon={
                    <Avatar src={user.picture} sx={{ width: 16, height: 16 }} />
                  }
                  label="Creado por: "
                  total={user.name}
                />
                {/* <WorkflowInfoItem
                  icon={
                    <PlayArrow color="primary" sx={{ width: 16, height: 16 }} />
                  }
                  label="Fecha de inicio: "
                  total={dayjs(values.start).format('LL')}
                /> */}
                <WorkflowInfoItem
                  icon={
                    <LSPin color="success" sx={{ width: 16, height: 16 }} />
                  }
                  label="Total tareas: "
                  total={values.steps?.length}
                />
                <WorkflowInfoItem
                  icon={
                    <LSCalendar
                      color="primary"
                      sx={{ width: 16, height: 16 }}
                    />
                  }
                  label="Calendarios involucrados: "
                  total={calculateWorkflowDiffCalendars(values.steps)}
                />
                <WorkflowInfoItem
                  icon={
                    <HourglassEmpty
                      color="primary"
                      sx={{ width: 16, height: 16 }}
                    />
                  }
                  label="Tiempo total del flujo de tareas: "
                  total={calculateWorkflowTotalDuration(values.steps)}
                />
              </Stack>

              <Button variant="contained" onClick={submitForm}>
                Guardar flujo de trabajo
              </Button>

              {(errors.steps || errors.name) && (
                <Typography color="error">
                  {errors.steps ?? errors.name}
                </Typography>
              )}
            </Stack>

            <Stack
              sx={{
                m: -3,
                pt: 4,
                pb: 2,
                flex: 6,
                bgcolor: 'primary.900',
                overflowY: 'scroll',
                minHeight: 'calc(100vh - 54px)',
              }}
            >
              <WorkflowTimeline
                steps={values.steps}
                workflow={values}
                onClick={(step, index = values.steps.length) =>
                  dispatchWorkflowEventForm('WorkflowFormDialog', {
                    initialValues: step?.data,
                    onSubmit: (eventFormValues) => {
                      const arr = [...values.steps];

                      arr[index] = {
                        ...step,
                        index,
                        type: 'EventCreation',
                        description: eventFormValues.title,
                        data: eventFormValues,
                      };

                      setFieldValue('steps', arr);
                    },
                  })
                }
                onDelete={(step, index) =>
                  dispatchWarningPromptDialog(
                    `WorkflowFormDialog-CloseButton`,
                    {
                      content: (
                        <Alert severity="error">
                          ¿Estás seguro que desas eliminar el paso {step.index},{' '}
                          {step.description}?
                        </Alert>
                      ),
                      onAccept: () => {
                        setFieldValue(
                          'steps',
                          values.steps
                            .filter((_, stepIndex) => stepIndex !== index)
                            .map((stepData, stepIndex) => ({
                              ...stepData,
                              index: stepIndex,
                            })),
                        );
                      },
                    },
                  )
                }
                onUpdate={(step) => {
                  const arr = [...values.steps];
                  arr[step.index] = {
                    ...step,
                    type: 'EventCreation',
                    index: step.index,
                    description: step?.data?.title,
                    data: step.data,
                  };

                  setFieldValue('steps', arr);
                }}
              />
            </Stack>
          </Stack>
        )}
      </Formik>
    </Page>
  );
};
