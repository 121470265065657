const typography = {
  fontFamily: ['Poppins'],
  allVariants: {
    fontSize: 14,
    fontWeight: 500,
    color: '#3F4257',
  },
  h1: {
    fontWeight: 800,
    fontSize: 42,
    // TODO: Use _colors_ instead
    color: '#3F4257',
  },
  h2: {
    fontWeight: 800,
    fontSize: 28,
    // TODO: Use _colors_ instead
    color: '#3F4257',
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    // TODO: Use _colors_ instead
    color: '#3F4257',
  },
  h4: {
    fontSize: 20,
    fontWeight: 700,
    color: '#3F4257',
  },
  h5: {
    fontSize: 18,
    fontWeight: 700,
    color: '#3F4257',
  },
  h6: {
    fontSize: 16,
    fontWeight: 700,
    color: '#3F4257',
  },
  body1: {
    fontSize: 14,
    fontWeight: 600,
  },
  body2: {
    fontSize: 13,
    fontWeight: 400,
  },
  subtitle1: {
    fontWeight: 500,
    color: '#B5B5C3',
    fontSize: '1rem',
  },
};

export default typography;
