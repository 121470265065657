import React from 'react';
import { Box, Tooltip, LinearProgress } from '@mui/material';
import { formatMoney } from 'src/utils/formatters';

const normalise = (value, MIN, MAX) => ((value - MIN) * 100) / (MAX - MIN);

export const ChargedCell = ({ row }) => (
  <Tooltip
    key={row.id}
    title={`Cobrado: ${row.currency?.name} ${formatMoney(row.charged)}`}
  >
    <Box sx={{ width: 120 }}>
      <LinearProgress
        color="primary"
        sx={{ height: 8, borderRadius: 5 }}
        value={normalise(row.charged, 0, row.amount)}
        variant="determinate"
      />
    </Box>
  </Tooltip>
);
