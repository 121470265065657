import { useCallback } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import qs from 'query-string';

import { useStudioId } from './useStudioId';

const linkGoogleTokenMutation = gql`
  mutation linkGoogleToken($scope: GoogleScope!, $token: ID!, $studioId: ID!) {
    linkGoogleToken(scope: $scope, token: $token, studioId: $studioId) {
      id
      googleAccessToken(scope: $scope, studioId: $studioId)
      googleToken(studioId: $studioId) {
        id
        googleCalendar
      }
    }
  }
`;

const hasGoogleCalendarQuery = gql`
  query hasGoogleCalendarQuery($studioId: ID!) {
    getUserInfo {
      id
      googleToken(studioId: $studioId) {
        id
        googleCalendar
      }
    }
  }
`;

const GetGoogleCalendarAcessTokenQuery = gql`
  query GetGoogleCalendarAcessTokenQuery($studioId: ID!, $scope: GoogleScope!) {
    getUserInfo {
      id
      googleAccessToken(scope: $scope, studioId: $studioId)
      googleToken(studioId: $studioId) {
        id
        googleCalendar
      }
    }
  }
`;

const revokeGoogleRefreshTokenMutation = gql`
  mutation revokeGoogleRefreshToken($scope: GoogleScope!, $studioId: ID!) {
    revokeGoogleRefreshToken(scope: $scope, studioId: $studioId) {
      id
    }
  }
`;

const ReconnectGoogleCalendarMutation = gql`
  mutation ReconnectGoogleCalendarMutation($code: ID!, $studioId: ID!) {
    reconnectGoogleCalendar(code: $code, studioId: $studioId) {
      id
    }
  }
`;

const generateGoogleCalendarAuthorizationURL = gql`
  query generateGoogleCalendarAuthorizationURL(
    $redirectUri: String!
    $state: String
  ) {
    generateGoogleCalendarAuthorizationURL(
      redirectUri: $redirectUri
      state: $state
    )
  }
`;

const scope = 'googleCalendar';

export const useGoogleCalendar = () => {
  const studioId = useStudioId();

  const { data, loading, refetch } = useQuery(hasGoogleCalendarQuery, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      scope,
      studioId,
    },
    skip: !studioId,
  });

  const [getGoogleCalendarAccessTokenLazy] = useLazyQuery(
    GetGoogleCalendarAcessTokenQuery,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        scope,
        studioId,
      },
    },
  );

  const [linkGoogleToken] = useMutation(linkGoogleTokenMutation, {
    update: (cache, result, { variables }) => {
      cache.writeQuery({
        query: hasGoogleCalendarQuery,
        variables,
        data: {
          getUserInfo: {
            ...result.data?.linkGoogleAccount,
          },
        },
      });
    },
  });

  const [reconnectGoogleCalendar] = useMutation(
    ReconnectGoogleCalendarMutation,
  );

  const hasGoogleCalendar = !!data?.getUserInfo?.googleToken?.googleCalendar;

  const generateGoogleScopeAuthorizationURLState = (extraStateParams) => ({
    redirectUri: `${window.location.origin}/callback/google`,
    state: qs.stringify({
      studioId,
      scope,
      ...extraStateParams,
    }),
  });

  const [getGoogleCalendarAuthorizationURLData] = useLazyQuery(
    generateGoogleCalendarAuthorizationURL,
  );

  const [revokeGoogleRefreshToken] = useMutation(
    revokeGoogleRefreshTokenMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [hasGoogleCalendarQuery],
    },
  );

  const syncGoogleScope = async (code, stateStudioId) =>
    linkGoogleToken({
      variables: {
        scope,
        token: code,
        studioId: stateStudioId,
      },
    });

  const redirectToGoogleCalendarAuthorzation = (extraStateParams) =>
    getGoogleCalendarAuthorizationURLData({
      variables: generateGoogleScopeAuthorizationURLState(extraStateParams),
    }).then((value) => {
      window.location.assign(
        value.data?.generateGoogleCalendarAuthorizationURL,
      );
    });

  const unsyncGoogleScope = async () =>
    revokeGoogleRefreshToken({
      variables: {
        scope,
        studioId,
      },
    });

  const getGoogleCalendarAccessToken = useCallback(
    () =>
      getGoogleCalendarAccessTokenLazy().then(
        (params) => params.data?.getUserInfo?.googleAccessToken,
      ),
    [getGoogleCalendarAccessTokenLazy],
  );

  return {
    refetch,
    getGoogleCalendarAccessToken,
    redirectToGoogleCalendarAuthorzation,
    syncGoogleScope,
    unsyncGoogleScope,
    hasGoogleCalendar,
    loading,
    reconnectGoogleCalendar: (code, _studioId) =>
      reconnectGoogleCalendar({
        variables: {
          code,
          studioId: studioId || _studioId,
        },
      }),
  };
};
