// God bless chat gpt
export function humanizeHoursTrackingTime(hoursFloat) {
  // Extract the integer and decimal parts
  const intPart = Math.floor(hoursFloat);
  const decimalPart = hoursFloat - intPart;

  // Convert the decimal part to minutes
  const minutes = Math.round(decimalPart * 60);

  return `${intPart}h:${minutes}m`;
}
