import React from 'react';

import Page from 'src/components/v2/Page';
import { NotificationTemplatesTable } from 'src/tables/TemplateMessageTable';

export const NotificationTemplatesSetting = () => (
  <Page title="Plantillas Whatsapp | legalsurf">
    <NotificationTemplatesTable />
  </Page>
);
