import React from 'react';
import { Box, Card, CardContent, Skeleton } from '@mui/material';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { File } from 'src/components/v2/FilesDropzone';
import { useDialog } from 'src/dialogs/Dialogs';
import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';
import { useDocuments } from '@legalsurf/hooks';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';

export const DocumentsSection = ({ entity }) => {
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchDocumentsFormDialog = useDialog('documentsForm');
  const studioId = useStudioId();

  const { documents, loading } = useDocuments({
    variables: {
      studioId,
      entityId: entity.id,
    },
  });

  const { handleUnlinkDocumentFromEntity, handleEntityToDocumentLink } =
    useDigitalOceanSpace({
      refetchQueries: ['DocumentsQuery'],
    });

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS,
  ]);

  const { hasPermissions: hasCreatePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.CREATE,
  ]);

  const { hasPermissions: hasDeletePermissions } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE,
    LS_PERMISSIONS_MAP.DIRECTORY.UPDATE,
  ]);

  const handleDocumentSubmit = (files) => {
    handleEntityToDocumentLink({
      entityId: entity.id,
      documentId: files[0].id,
    });
  };

  const handleRemove = (file) =>
    dispatchWarningPromptDialog(`SingleDirectoryDocuments-${file.id}`, {
      onAccept: () =>
        handleUnlinkDocumentFromEntity({
          documentId: file.id,
          entityId: entity.id,
        }),
      title: 'Desvincular document de entidad',
      content: `Estas seguro que deseas desvincular el documento ${file.name} de tu expediente?`,
    });

  if (!hasPermissions) {
    return null;
  }

  if (loading) {
    return (
      <Card>
        <CardContent sx={{ '& > * + *': { marginTop: 4 } }}>
          <Box>
            <Skeleton height={20} width={50} />
            <Skeleton height={14} width={130} />
          </Box>

          <Box>
            <Skeleton height={20} width={50} />
            <Skeleton height={14} width={130} />
          </Box>

          <Box>
            <Skeleton height={20} width={50} />
            <Skeleton height={14} width={130} />
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {hasCreatePermissions && (
        <CreationInfoBanner
          buttonStr="Subir documentos"
          description="Sube archivos y adjuntalo a tu expediente"
          title="Adjuntar documentos"
          onClick={() =>
            dispatchDocumentsFormDialog('SingleEntiyDocumentsForm', {
              onSubmit: handleDocumentSubmit,
              searchable: true,
              hideEntity: true,
              disableFields: {
                entity: true,
              },
              file: {
                entities: [
                  {
                    value: entity.id,
                    label: entity.displayName,
                  },
                ],
              },
            })
          }
        />
      )}

      {documents.map((document) => (
        <File
          document={document}
          downloable={document.kind === 'spaces'}
          key={document.id}
          previewable={document.kind === 'spaces'}
          {...(hasDeletePermissions && {
            onRemove: handleRemove,
          })}
        />
      ))}
    </Box>
  );
};
