import React, { useEffect, useState } from 'react';
import { Typography, Box, Badge } from '@mui/material';
import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents';
import { $getNodeByKey } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { QuestionMark } from '@mui/icons-material';

import { measureFileIcon } from 'src/utils/formatters';
import {
  isDropboxFile,
  isGoogleDriveFile,
  isOneDriveFile,
} from 'src/forms/DocumentsForm/schema';
import GoogleDriveIcon from 'src/pages/Dashboard/Settings/logo_drive_2020q4_color_2x_web_64dp.png';
import DropboxIcon from 'src/pages/Dashboard/Settings/Dropbox_logo.svg';
import OneDriveIcon from 'src/pages/Dashboard/Settings/onedrive.svg';
import { Pressable } from 'src/components/ui-components/Pressable';
import { LSTrash } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';

// FIXME: duplicated code
const badgesByKind = {
  dropbox: DropboxIcon,
  drive: GoogleDriveIcon,
  onedrive: OneDriveIcon,
  spaces: null,
};

const isExternalProvider = (document) =>
  isGoogleDriveFile(document) ||
  isOneDriveFile(document) ||
  isDropboxFile(document);

export const RichEditorDocument = ({
  document,
  className,
  format,
  nodeKey,
}) => {
  const [editor] = useLexicalComposerContext();
  const [editable, setEditable] = useState(() => editor._editable);
  const dispatchDocumentPreviewDialog = useDialog('documentsPreview');

  const ExtensionIcon = document ? measureFileIcon(document) : null;

  const handleClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isExternalProvider(document)) {
      window.open(document.url);
    } else {
      dispatchDocumentPreviewDialog(`RichEditorDocument-${document?.id}`, {
        document,
        downloable: true,
      });
    }
  };

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();

    editor.update(() => {
      const node = $getNodeByKey(nodeKey);

      if (node.getType() === 'document') {
        node.remove();
        editor.focus();
      }
    });
  };

  const removeEditableListener = editor.registerEditableListener((edit) =>
    setEditable(edit),
  );

  useEffect(() => () => removeEditableListener(), [removeEditableListener]);

  if (!document?.id) {
    return (
      <BlockWithAlignableContents
        className={className}
        format={format}
        nodeKey={nodeKey}
      >
        <Box
          sx={{
            borderWidth: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 1,
            borderStyle: 'dashed',
            borderColor: 'primary.main',
            borderRadius: 0.5,
          }}
        >
          <QuestionMark />

          <span>Documento no encontrado</span>
        </Box>
      </BlockWithAlignableContents>
    );
  }

  return (
    <BlockWithAlignableContents
      className={className}
      format={format}
      nodeKey={nodeKey}
    >
      <Pressable
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            borderWidth: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 1,
            borderStyle: 'dashed',
            borderColor: 'primary.main',
            borderRadius: 0.5,
          }}
        >
          <Badge
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <Box
                as="img"
                src={badgesByKind[document.kind]}
                sx={{ width: 10, height: 10, bgcolor: 'transparent' }}
              />
            }
            invisible={!badgesByKind[document.kind]}
            overlap={document.isFolder ? 'circular' : 'rectangular'}
          >
            <ExtensionIcon sx={{ color: 'primary.main' }} />
          </Badge>

          <Typography color="primary">{document.name}</Typography>

          {editable && (
            <LSTrash
              color="primary"
              sx={{ height: 18, width: 18 }}
              onClick={handleDelete}
            />
          )}
        </Box>
      </Pressable>
    </BlockWithAlignableContents>
  );
};
