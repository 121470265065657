import React from 'react';
import { Box, Typography, LinearProgress, Avatar, Stack } from '@mui/material';

import { humanizeHoursTrackingTime } from 'src/components/v2/FilecaseDetail/HoursTrackingSection/utils';
import Table from 'src/components/v3/Table';
import { HourglassEmpty } from '@mui/icons-material';

const HoursWorkedPerMemberTable = ({
  data = { hoursTrackingPerUser: [] },
  loading,
}) => {
  const totalHours = (data.hoursTrackingPerUser ?? []).reduce(
    (acc, curr) => acc + curr.hours,
    0,
  )

  const columns = [
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: 'Usuario',
      field: 'avatar',
      flex: 1,
      renderCell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Avatar src={row.assigned.picture} sx={{ width: 40, height: 40 }} />

          <Typography>{row.assigned.name}</Typography>
        </Box>
      ),
    },
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: 'Horas totales',
      field: 'hours',
      renderCell: ({ row }) => humanizeHoursTrackingTime(row.hours),
    },
    {
      hideable: false,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      headerName: '',
      field: 'test',
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <Typography>{((row.hours / totalHours) * 100).toFixed(1)}%</Typography>

          <LinearProgress
            primary
            sx={{ width: 60, height: 3 }}
            value={(row.hours / totalHours) * 100}
            variant="determinate"
          />
        </Box>
      ),
    },
  ];

  const hoursCount = data.hoursTrackingPerUser?.reduce(
    (acc, val) => acc + val.hours,
    0,
  );

  return (
    <Stack>
      <Stack sx={{ p: 2, backgroundColor: 'white', gap: 1 }}>
        <Typography variant='h5'>
          Totales horas trabajadas por usuario
        </Typography>

        <Stack alignItems="center" direction="row" gap={1}>
          <Box
            sx={{
              padding: 0.5,
              borderRadius: 1,
              backgroundColor: 'primary.100',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <HourglassEmpty color="primary" />
          </Box>

          <Typography color="textSecondary">Horas totales</Typography>

          <Typography variant="h6">{humanizeHoursTrackingTime(hoursCount)}</Typography>
        </Stack>
      </Stack>

      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'time-tracking-by-group',
          },
          noRowsOverlay: {
            variant: 'time-tracking-by-group',
          },
        }}
        getRowId={(row) => row.assigned.id}
        loading={loading}
        rowCount={(data.hoursTrackingPerUser ?? []).length}
        rows={data.hoursTrackingPerUser ?? []}
        sx={{
          '& .MuiDataGrid-virtualScroller': {
            pb: 6
          }
        }}
      />
    </Stack>
  );
};

export default HoursWorkedPerMemberTable;
