import React from 'react';

import { SvgIcon } from '@mui/material';

const LSTodo = (props) => (
  <SvgIcon {...props}>
    <path
      opacity="0.3"
      d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z"
      fill="currentColor"
    />

    <path
      d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default LSTodo;
