import React from 'react';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { gql, useMutation } from '@apollo/client';
import { AssigneePopover } from 'src/components/v3/AssigneePopover';

export const UpdateExpenseResponsibleMutation = gql`
  mutation UpdateExpenseResponsibleMutation(
    $id: ID!
    $studioId: ID!
    $responsible: ID!
  ) {
    updateExpense(
      expenseId: $id
      studio: $studioId
      responsible: $responsible
    ) {
      id
      studioId
      updatedAt
      responsible {
        id
        name
        picture
      }
    }
  }
`;

export const ResponsibleCell = ({ row, ...rest }) => {
  const studioId = useStudioId();
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.EXPENSES.UPDATE,
  ]);

  const [updateExpenseAssignees] = useMutation(
    UpdateExpenseResponsibleMutation,
  );

  const handleAssignedSubmit = async (responsible) => {
    await updateExpenseAssignees({
      variables: {
        id: row.id,
        studioId,
        responsible: responsible,
      },
    });
  };

  return (
    <AssigneePopover
      selectedAssignee={row.responsible}
      touchable={hasPermissions}
      onSubmit={handleAssignedSubmit}
      {...rest}
    />
  );
};
