import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from 'src/components/v2/Page';
import GoogleCalendarIMG from 'src/assets/images/googlecalendar.png';

import { useGoogleCalendar } from 'src/utils/hooks/useGoogleCalendar';
import { DesyncGoogleCalendarDialog } from './DesyncGoogleCalendarDialog';
import { GoogleCalendarSuccessSyncDialog } from './GoogleCalendarSuccessSyncDialog';

const GoogleCalendarSetting = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [desyncGoogleCalendarOpen, setDesyncGoogleCalendarOpen] =
    useState(false);
  const [googleCalendarSyncOpen] = useState(location.state?.success);
  const {
    loading,
    hasGoogleCalendar,
    redirectToGoogleCalendarAuthorzation,
    unsyncGoogleScope,
  } = useGoogleCalendar();

  const handleGoogleCalendarDesync = () =>
    unsyncGoogleScope().finally(() => setDesyncGoogleCalendarOpen(false));

  useEffect(() => {
    if (searchParams.has('redirectSuccess')) {
      redirectToGoogleCalendarAuthorzation({
        redirectSuccess: searchParams.get('redirectSuccess'),
      });
    }
    // We only want to run this once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DesyncGoogleCalendarDialog
        loading={loading}
        open={desyncGoogleCalendarOpen}
        onAccept={handleGoogleCalendarDesync}
        onCancel={() => setDesyncGoogleCalendarOpen(false)}
      />

      <GoogleCalendarSuccessSyncDialog open={googleCalendarSyncOpen} />

      <Page title="Integrar Google Calendar | LegalSurf">
        <Card sx={{ width: 800, maxWidth: '100%', margin: '0 auto', mt: 6 }}>
          <CardContent>
            <Box
              component="img"
              src={GoogleCalendarIMG}
              sx={{ display: 'flex', height: 120, mx: 'auto', mb: 3 }}
            />

            <Typography align="center" sx={{ px: [null, 12] }} variant="h5">
              Conecta tu cuenta de Google Calendar para manejar tus eventos,
              notificaciones y actividades junto a LegalSurf
            </Typography>

            <Box sx={{ p: 3, mt: 2 }}>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
                variant="body1"
              >
                {hasGoogleCalendar
                  ? 'Ya estas vinculado a google calendar ✅'
                  : 'Asociar tu cuenta de google calendar'}
              </Typography>

              <Typography variant="body2">
                Con Google Calendar, puedes programar tus reuniones y eventos
                rápidamente, y recibir recordatorios para estar siempre al tanto
                de lo que va a ocurrir. Calendar se ha diseñado para equipos,
                por lo que es muy fácil compartir agendas con los demás y crear
                varios calendarios que tú y tu equipo podéis utilizar
                conjuntamente.
              </Typography>

              <Box sx={{ mt: 4, textAlign: 'center' }}>
                {!hasGoogleCalendar ? (
                  <LoadingButton
                    loading={loading}
                    startIcon={<Avatar src={GoogleCalendarIMG} />}
                    sx={{ textTransform: 'none' }}
                    variant="text"
                    onClick={redirectToGoogleCalendarAuthorzation}
                  >
                    Vincular Google Calendar
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    loading={loading}
                    onClick={() => setDesyncGoogleCalendarOpen(true)}
                  >
                    Desvincular Google Calendar
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Page>
    </>
  );
};

export default GoogleCalendarSetting;
