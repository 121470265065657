import React from 'react';
import { SvgIcon } from '@mui/material';

const LSActivity = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2187 6.00078C14.2187 5.33804 14.756 4.80078 15.4187 4.80078H22.5281C23.1908 4.80078 23.7281 5.33804 23.7281 6.00078V13.1082C23.7281 13.7709 23.1908 14.3082 22.5281 14.3082C21.8653 14.3082 21.3281 13.7709 21.3281 13.1082V8.89728L15.573 14.6507C14.179 16.0444 11.9189 16.0444 10.5248 14.6507L9.13663 13.2629C8.67977 12.8062 7.93893 12.8062 7.48207 13.2629L2.04841 18.6951C1.57972 19.1637 0.81992 19.1636 0.351355 18.6949C-0.117209 18.2262 -0.117105 17.4664 0.351588 16.9978L5.78525 11.5656C7.17931 10.172 9.43939 10.172 10.8335 11.5656L12.2216 12.9534C12.6785 13.4102 13.4193 13.4102 13.8762 12.9534L19.6304 7.20078H15.4187C14.756 7.20078 14.2187 6.66352 14.2187 6.00078Z"
    />
  </SvgIcon>
);

export default LSActivity;
