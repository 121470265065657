export const downloadFileByBlobStrategy = (fileLink, { name, ext }) => {
  fetch(fileLink)
    .then((blob) => blob.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      const filename = name + ext;
      link.setAttribute('download', filename);
      link.download = filename;

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
};
