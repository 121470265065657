import React, { useRef } from 'react';

import Form, { CommentField } from 'src/components/ui-components/Form';
import { Formik } from 'formik';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useMutation } from '@apollo/client';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { CreateComment } from 'src/graphql/mutations';

export const CommentForm = ({ expenseId }) => {
  const studioId = useStudioId();
  const richEditorRef = useRef(null);
  const [createComment] = useMutation(CreateComment, {
    update: (cache, result) => {
      cache.modify({
        id: cache.identify({
          __typename: 'Expense',
          id: result.data.createComment.expenseId,
        }),
        fields: {
          comments(existingComments = []) {
            return [
              ...existingComments,
              { __ref: cache.identify(result.data.createComment) },
            ];
          },
        },
      });
    },
    onCompleted: () => {
      if (richEditorRef.current) {
        const editor = richEditorRef.current;

        // NO TE VUELVAS LOCO MI PANA, TRANQUILO
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND);
      }
    },
  });

  const handleSubmitComment = async (values) => {
    await createComment({
      variables: {
        content: values.content,
        studioId,
        expenseId,
      },
    });
  };

  const initialValues = {
    content: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmitComment}>
      <Form id="commentForm">
        <CommentField
          name="content"
          ref={richEditorRef}
          sx={{ bgcolor: 'background.input', p: 2, borderRadius: 2 }}
        />
      </Form>
    </Formik>
  );
};
