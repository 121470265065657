import { useQuery, QueryHookOptions } from '@apollo/client';
import { SalesQuery } from '@legalsurf/queries';

import { SalesQueryVariables, SalesQueryData } from './types';

export const useSales = (
  options: QueryHookOptions<SalesQueryData, SalesQueryVariables>,
) => {
  const query = useQuery(SalesQuery, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return {
    ...query,
    data: query.data?.sales ?? [],
  };
};

export * from './types';
