import React from 'react';
import dayjs from 'dayjs';
import { FormControl, MenuItem, selectClasses, Select } from '@mui/material';

const options = Array(96)
  .fill(null)
  .map((_, i) => {
    const date = dayjs()
      .set('hour', Math.floor(i / 4))
      .set('minute', (i % 4) * 15)
      .set('milliseconds', 0)
      .set('seconds', 0);

    return { date, value: date.toISOString(), label: date.format('HH:mm') };
  });

const isDisableFromPast = (disabledFromDate, value, option) =>
  disabledFromDate.isSameOrAfter(
    value.hour(option.date.hour()).minute(option.date.minute()),
  );

const HoursField = ({ onChange, value, disabled, disablePastFrom }) => (
  <FormControl>
    <Select
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 33 * 4.5 + 8,
            width: 140,
            borderRadius: 5,
          },
        },
      }}
      disabled={disabled}
      inputProps={{
        sx: {
          paddingRight: '0 !important',
        },
      }}
      sx={{
        borderRadius: 0.2,
        backgroundColor: 'grey.100',
        cursor: 'text',
        width: 100,

        [`& .${selectClasses.filled}`]: {
          padding: '8px',
          cursor: 'text',
        },

        '&:before': {
          borderColor: 'transparent',
        },

        '&:hover': {
          '&:before': {
            borderColor: 'transparent',
          },
        },
      }}
      value={
        options.find(
          (option) =>
            option.date.hour() === value.hour() &&
            option.date.minute() === value.minute(),
        )?.value
      }
      variant="filled"
      onChange={(event) => onChange(event.target.value)}
    >
      {options.map((option) => (
        <MenuItem
          disabled={
            disablePastFrom && isDisableFromPast(disablePastFrom, value, option)
          }
          key={option.value}
          value={option.value}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default HoursField;
