import React, { useState } from 'react';

import { useDialog } from 'src/dialogs/Dialogs';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@mui/material';
import WhatsAppChat from 'src/popovers/NotificationTemplatesPopover/NotificationTemplatesPopoverBody/WhatsAppChat';
import { exampleEntityForWhatsApp } from 'src/utils/notificationTemplates';
import { LSAdd, LSSendTemplate } from 'src/components/icons';
import Page from 'src/components/v2/Page';
import { useNotificationTemplates } from './queries';

export const NotificationTemplatesTable = () => {
  const { loading, templates } = useNotificationTemplates();
  const dispatchNotificationTemplateFormDialog = useDialog(
    'notificationTemplateFormDialog',
  );
  const [hoveredNotificationTemplate, setHoveredNotificationTemplate] =
    useState(null);

  return (
    <Page>
      <Card sx={{ display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              p: 2,
              display: 'flex',
            }}
          >
            <Box>
              <Typography variant="h6">Plantillas</Typography>

              <Typography variant="subtitle1">
                Totales: {templates.length || 0}
              </Typography>
            </Box>

            <Tooltip title="Crear plantilla">
              <Button
                color="primary"
                endIcon={<LSAdd />}
                onClick={() =>
                  dispatchNotificationTemplateFormDialog(
                    'NotificationTemplatesTable',
                  )
                }
              >
                Crear plantilla
              </Button>
            </Tooltip>
          </Box>

          <Divider />

          <MenuList onMouseLeave={() => setHoveredNotificationTemplate(null)}>
            {!loading &&
              templates.map((template) => (
                <MenuItem
                  key={template.id}
                  onClick={() =>
                    dispatchNotificationTemplateFormDialog(
                      template.id,
                      template,
                    )
                  }
                  onMouseEnter={() => setHoveredNotificationTemplate(template)}
                >
                  <ListItemIcon>
                    <LSSendTemplate color="primary" />
                  </ListItemIcon>

                  <ListItemText>{template.name}</ListItemText>
                </MenuItem>
              ))}

            {!loading && !templates.length && (
              <MenuItem
                disabled
                sx={{ display: 'flex', justifyContent: 'center', py: 3 }}
              >
                <Typography>No encontramos plantillas :(</Typography>
              </MenuItem>
            )}

            {loading && (
              <MenuItem
                disabled
                sx={{ justifyContent: 'center', display: 'flex' }}
              >
                <CircularProgress color="primary" size={25} />
              </MenuItem>
            )}
          </MenuList>
        </Box>

        <Box>
          <WhatsAppChat
            data={{ entity: exampleEntityForWhatsApp }}
            entity={exampleEntityForWhatsApp}
            message={hoveredNotificationTemplate || undefined}
          />
        </Box>
      </Card>
    </Page>
  );
};
