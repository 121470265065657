import { gql, useQuery } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const GetWorkflowsQuery = gql`
  query GetWorkflowsQuery($studioId: ID!) {
    getWorkflows(studioId: $studioId) {
      id
      name
      description

      steps {
        id
        index
        description
        type
        data
      }
    }
  }
`;

export const useWorkflows = () => {
  const { data, loading, error, ...rest } = useQuery(GetWorkflowsQuery, {
    variables: {
      studioId: useStudioId(),
    },
  });

  return {
    workflows: data?.getWorkflows || [],
    loading,
    error,
    ...rest,
  };
};
