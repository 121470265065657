import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_EDITOR } from 'lexical';
import { useEffect } from 'react';

export const EditorBlurPlugin = ({ onBlur }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (onBlur) {
      editor.registerCommand(BLUR_COMMAND, onBlur, COMMAND_PRIORITY_EDITOR);
    }
  }, [editor, onBlur]);

  return null;
};
