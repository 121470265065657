import { useEffect, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export const createHeaderFiltersStore = (name, initialValues) =>
  create(
    persist(
      (set) => ({
        filters: initialValues,
        resetFilters: () => set({ filters: initialValues }),
        setFilters: (newFilters) =>
          set((state) => ({
            ...state,
            filters: {
              ...state.filters,
              ...newFilters,
            },
          })),
      }),
      {
        name,
        storage: createJSONStorage(() => localStorage),
      },
    ),
  );

// Helper functions
const isEmptyObject = (obj) => Object.keys(obj).length === 0;

const validateSearchParamValue = (val) =>
  Array.isArray(val) ? val.every(Boolean) : val != null;

const normalizeValue = (val) => {
  if (val === null || val === '') return undefined; // Convert empty values to undefined
  if (Array.isArray(val)) {
    return val.length === 0
      ? undefined
      : val.map((item) =>
          typeof item === 'string' ? item : (item?.value ?? item?.id ?? item),
        );
  }
  return val?.value ?? val?.id ?? val;
};

export const useSearchFilterValues = (useFiltersStore, paramConfigMap) => {
  const {
    filters: storedFilters,
    setFilters,
    resetFilters,
  } = useFiltersStore();

  const [urlParams, setUrlParams] = useQueryParams(paramConfigMap, {
    updateType: 'replaceIn',
    includeAllParams: true,
    removeDefaultsFromUrl: true,
    enableBatching: true,
  });

  // Check if URL has any valid params
  const hasUrlParams = useMemo(
    () =>
      Object.keys(paramConfigMap).some((key) =>
        validateSearchParamValue(urlParams[key]),
      ),
    [urlParams, paramConfigMap],
  );

  useEffect(() => {
    if (hasUrlParams) {
      const normalizedParams = Object.entries(urlParams)
        .filter(
          ([key, val]) =>
            validateSearchParamValue(val) && key in paramConfigMap,
        )
        .reduce(
          (acc, [key, val]) => ({
            ...acc,
            [key]: normalizeValue(val),
          }),
          {},
        );

      setFilters(normalizedParams);
    }
  }, []);

  useEffect(() => {
    if (!hasUrlParams && Object.keys(storedFilters).length > 0) {
      setUrlParams(storedFilters, 'replaceIn');
    }
  }, []);

  const handleSetFilters = (newParams) => {
    if (isEmptyObject(newParams)) {
      // Clear all params from URL and reset store
      const clearParams = Object.keys(paramConfigMap).reduce(
        (acc, key) => ({
          ...acc,
          [key]: undefined,
        }),
        {},
      );

      resetFilters();
      setUrlParams(clearParams, 'replace');
      return;
    }

    // Create a normalized params object that explicitly sets undefined for removed values
    const normalizedParams = Object.keys(paramConfigMap).reduce((acc, key) => {
      const newValue = newParams[key];
      acc[key] = newValue == null ? undefined : normalizeValue(newValue);
      return acc;
    }, {});

    setFilters(normalizedParams);
    setUrlParams(normalizedParams);
  };

  // Use URL params if they exist, otherwise use stored filters
  const currentFilters = hasUrlParams ? urlParams : storedFilters;

  return [currentFilters, handleSetFilters];
};
