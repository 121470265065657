import { gql } from '@apollo/client';

export const CREATE_STATE = gql`
  mutation createState($studioId: ID!, $name: String!, $color: String!) {
    createState(studioId: $studioId, name: $name, color: $color) {
      id
      name
      color
      studioId
      updatedAt
    }
  }
`;

export const UPDATE_STATE = gql`
  mutation updateState(
    $studioId: ID!
    $id: ID!
    $name: String!
    $color: String
  ) {
    updateState(studioId: $studioId, id: $id, name: $name, color: $color) {
      id
      name
      color
      studioId
      updatedAt
    }
  }
`;

export const DELETE_STATE = gql`
  mutation deleteState($studioId: ID!, $id: ID!) {
    deleteState(studioId: $studioId, id: $id) {
      id
    }
  }
`;
