import { gql } from '@apollo/client';

export const GET_MY_STUDIOS = gql`
  query {
    getMyStudios {
      id
      name
      picture
    }
  }
`;

export const GET_STUDIO_MEMBERS = gql`
  query getStudioMembers(
    $status: [MemberStatus!] = [ACTIVE, PENDING, INACTIVE]
    $studio: ID!
  ) {
    members(studio: $studio, status: $status) {
      id
      name
      email
      picture
      status
      role
    }
  }
`;

export const GET_STUDIO_MEMBER_DATA = gql`
  query getStudioMember($studio: ID!, $user: ID!) {
    member(studio: $studio, user: $user) {
      id
      name
      email
      picture
      status
      role
      permissions
    }
  }
`;

export const IS_INVITATION_VALID = gql`
  query isInvitationValid($studio: ID!) {
    isInvitationValid(studio: $studio) {
      id
      name
    }
  }
`;

export const GET_CURRENT_MEMBER_WITH_PERMISSIONS = gql`
  query getMemberWithPermissions($studioId: ID) {
    getUserInfo {
      id
      name
      email
      phone
      picture
      timezone
      toc {
        id
        signed
        createdAt
      }
      member(studioId: $studioId) {
        id
        role
        permissions
      }
    }
  }
`;
