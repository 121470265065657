import React from 'react';

import { ManageMembersPopoverBody } from './ManageMembersPopoverBody';
import { Dialog, DialogContent } from '@mui/material';

export const ManageMembersModal = ({ open, onClose, ...props }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <DialogContent>
      <ManageMembersPopoverBody {...props} />
    </DialogContent>
  </Dialog>
);
