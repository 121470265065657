import React, { useRef } from 'react';
import { FilledInput, filledInputClasses } from '@mui/material';

const DateField = ({ inputRef, inputProps, sx, ...props }) => {
  const ref = useRef();

  return (
    <FilledInput
      inputProps={inputProps}
      inputRef={ref}
      sx={{
        borderRadius: 0.2,
        backgroundColor: 'grey.100',

        [`&.${filledInputClasses.root}`]: {
          width: inputProps.value?.length ? inputProps.value.length * 8 : 114,

          '&:before': {
            borderColor: 'transparent',
          },
        },

        '& input': {
          padding: 1,
          width: 'auto',
        },
        ...sx,
      }}
      {...props}
      ref={inputRef}
    />
  );
};

export default DateField;
