import React from 'react';
import { Box, Card, CardContent, CircularProgress } from '@mui/material';

const DetailsColumnSkeleton = () => (
  <Card sx={{ width: '100%' }}>
    <CardContent>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </Box>
    </CardContent>
  </Card>
);

export default DetailsColumnSkeleton;
