import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import CrashErrorMessage from 'src/components/v2/CrashErrorMessage';

const SingleFilecaseDocumentsErrorBoundary = ({ children }) => (
  <SentryErrorBoundary fallback={CrashErrorMessage}>
    {children}
  </SentryErrorBoundary>
);

export default SingleFilecaseDocumentsErrorBoundary;
