import React from 'react';
import { Button, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export const UploadButton = ({
  disabled,
  getRootProps,
  isDragActive,
  children,
  ...props
}) => (
  <Button
    fullWidth
    color="primary"
    disabled={disabled}
    startIcon={<AttachFileIcon />}
    sx={{
      borderStyle: 'dashed',
      borderWidth: isDragActive ? 2 : 1,
      borderColor: 'primary.main',
      backgroundColor: 'primary.200',
      boxShadow: 0,
      flexWrap: 'wrap',
      py: 3,
    }}
    {...getRootProps()}
    {...props}
  >
    Agregar documentos
    <Typography
      color="grey.500"
      fontSize={10}
      sx={{ width: '100%' }}
      variant="caption"
    >
      Puedes arrastrar tus archivos aca
    </Typography>
    {children}
  </Button>
);
