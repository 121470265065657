import React from 'react';

import { RichEditorSpecialKeywordProvider } from './RichEditorSpecialKeywordProvider';
import { ContractPreviewDialogBody } from './ContractPreviewDialogBody';

export const ContractPreviewDialog = ({ initialState, ...props }) => (
  <RichEditorSpecialKeywordProvider initialState={initialState}>
    <ContractPreviewDialogBody {...props} />
  </RichEditorSpecialKeywordProvider>
);
