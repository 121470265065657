import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ClerkProvider } from '@clerk/clerk-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { esES } from '@clerk/localizations';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import { PostHogProvider } from 'posthog-js/react';
import { ErrorBoundaryFallback } from './pages/Error/ErrorBoundaryFallback/ErrorBoundaryFallback';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import 'dayjs/locale/es';

import App from './App';
import { ApolloCustomProvider } from './graphql';
import muiTheme from './config/mui-theme';
import { SnackbarProvider } from './components/v3/Snackbar';
import { ShareWithFriendsModal } from './components/ShareWithFriendsModal';

dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.locale('es');

if (import.meta.env.VITE_SENTRY) {
  Sentry.init({
    environment: import.meta.env.VITE_SENTRY_ENV ?? 'production',
    // ignoreErrors: [
    //   /invalid_token/i,
    //   /invalid_code/i,
    //   /User not authenticated/i,
    // ],
    // beforeBreadcrumb: excludeGraphQLFetch,
    dsn: import.meta.env.VITE_SENTRY,
    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    // integrations: [new TracingIntegrations.BrowserTracing()],

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // To set a uniform sample rate
    tracesSampleRate: 1,
  });
}

const queryClient = new QueryClient();

const clerkLocalization = {
  ...esES,
  userProfile: {
    ...esES.userProfile,
    start: {
      ...esES.userProfile.start,
      profileSection: {
        ...esES.userProfile.start.profileSection,
        primaryButton: 'Editar',
      },
    },
  },
};

const All = () => (
  <PostHogProvider
    apiKey={import.meta.env.VITE_APP_PUBLIC_POSTHOG_KEY}
    options={{
      autocapture: {
        dom_event_allowlist: [],
        element_allowlist: [],
        css_selector_allowlist: [],
      },
      person_profiles: 'identified_only',
      api_host: import.meta.env.VITE_APP_PUBLIC_POSTHOG_HOST,
    }}
  >
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      <QueryClientProvider client={queryClient}>
        <ClerkProvider
          localization={clerkLocalization}
          publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}
          supportEmail="soporte@legal.surf"
        >
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <ApolloCustomProvider>
                <LocalizationProvider
                  adapterLocale="es"
                  dateAdapter={AdapterDayjs}
                  // dateLibInstance={timezone}
                >
                  {/* TODO: We have to many ifcking theme providers, get rid of those and leave only one, jesus */}
                  {/* Binnacle 14/10/21: First attempt to remove the providers, everything fuck up. We will continue to inform */}
                  <MuiThemeProvider theme={muiTheme}>
                    <SnackbarProvider>
                      <CssBaseline />

                      <App />

                      {/* <ShareWithFriendsModal /> */}
                    </SnackbarProvider>
                  </MuiThemeProvider>
                </LocalizationProvider>
              </ApolloCustomProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </ClerkProvider>
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </PostHogProvider>
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <All />
  </React.StrictMode>,
);
