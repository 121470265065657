import { useMutation, MutationHookOptions } from '@apollo/client';
import { UpdateCalendarEventStatus } from '@legalsurf/queries';

import { EventVariables } from '../@types';

export const useUpdateCalendarEventStatus = (
  options: MutationHookOptions<any, EventVariables>,
) => {
  return useMutation(UpdateCalendarEventStatus, {
    ...options,
  });
};
