import React from 'react';
import { SvgIcon } from '@mui/material';

const LSStats = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M5.34283 5.34283C4.25773 6.42793 3.75 8.27793 3.75 11.5C3.75 14.7221 4.25773 16.5721 5.34283 17.6572C6.42793 18.7423 8.27793 19.25 11.5 19.25C14.7221 19.25 16.5721 18.7423 17.6572 17.6572C18.7423 16.5721 19.25 14.7221 19.25 11.5C19.25 8.27793 18.7423 6.42793 17.6572 5.34283C16.5721 4.25773 14.7221 3.75 11.5 3.75C8.27793 3.75 6.42793 4.25773 5.34283 5.34283ZM4.28217 4.28217C5.82207 2.74227 8.22207 2.25 11.5 2.25C14.7779 2.25 17.1779 2.74227 18.7178 4.28217C20.2577 5.82207 20.75 8.22207 20.75 11.5C20.75 14.7779 20.2577 17.1779 18.7178 18.7178C17.1779 20.2577 14.7779 20.75 11.5 20.75C8.22207 20.75 5.82207 20.2577 4.28217 18.7178C2.74227 17.1779 2.25 14.7779 2.25 11.5C2.25 8.22207 2.74227 5.82207 4.28217 4.28217ZM11.5 6.75C11.9142 6.75 12.25 7.08579 12.25 7.5V15.5C12.25 15.9142 11.9142 16.25 11.5 16.25C11.0858 16.25 10.75 15.9142 10.75 15.5V7.5C10.75 7.08579 11.0858 6.75 11.5 6.75ZM15.5 10.75C15.9142 10.75 16.25 11.0858 16.25 11.5V15.5C16.25 15.9142 15.9142 16.25 15.5 16.25C15.0858 16.25 14.75 15.9142 14.75 15.5V11.5C14.75 11.0858 15.0858 10.75 15.5 10.75ZM7.5 12.75C7.91421 12.75 8.25 13.0858 8.25 13.5V15.5C8.25 15.9142 7.91421 16.25 7.5 16.25C7.08579 16.25 6.75 15.9142 6.75 15.5V13.5C6.75 13.0858 7.08579 12.75 7.5 12.75Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSStats;
