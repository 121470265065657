import React from 'react';

import { formatBytes } from 'src/utils/formatters';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

// Document table cells
import DocumentCell from './DocumentCell';
import FilecaseCell from './FilecaseCell';
import ActionsCell from './ActionsCell';
import TypeCell from './TypeCell';

export const useDocumentsTableColumns = () => {
  const { hasPermissions: canDelete, isHighRole } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.DELETE,
  ]);

  const { hasPermissions: canEdit } = usePermissions([
    LS_PERMISSIONS_MAP.DOCUMENTS.UPDATE,
  ]);

  return [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 80,
      headerName: 'Tipo',
      field: 'type',
      renderCell: (cell) => <TypeCell {...cell} />,
    },
    {
      sortable: true,
      editable: canEdit || isHighRole,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      isCellEditable: ({ row: document }) => document.kind === 'spaces',
      headerName: 'Documento',
      field: 'name',
      flex: 1,
      renderCell: (cell) => <DocumentCell {...cell} />,
    },
    {
      sortable: true,
      hideable: false,
      filterable: false,
      width: 150,
      disableColumnMenu: true,
      headerName: 'Tamaño',
      field: 'size',
      renderCell: ({ row }) => (row?.isFolder ? '' : formatBytes(row.size)),
    },
    {
      sortable: false,
      hideable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: 'Expedientes vinculados',
      field: 'filecase.title',
      flex: 1,
      renderCell: (cell) => <FilecaseCell {...cell} />,
    },
    ...(canDelete || isHighRole
      ? [
          {
            sortable: false,
            filterable: false,
            hideable: false,
            // Empty headerName cuz we don't want to show it
            headerName: '',
            field: 'actions',
            width: 150,
            renderCell: (cell) => <ActionsCell {...cell} />,
            align: 'right',
            headerAlign: 'right',
          },
        ]
      : []),
  ];
};
