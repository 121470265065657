import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

const CalendarDayChip = ({ active, events, date, onClick, fade, sx }) => (
  <Tooltip title={`Tienes ${events?.length} eventos hoy`}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: events?.length && 'primary.200',
        border: date.isToday() && '1px dashed grey',
        borderRadius: '50%',
        padding: 4,
        boxSizing: 'border-box',
        opacity: fade ? 0.4 : 1,
        width: 60,
        height: 60,
        transition: '200ms ease all',
        cursor: 'pointer',

        '&:hover': {
          backgroundColor: 'grey.300',

          ...(active && {
            backgroundColor: 'primary.dark',
          }),
        },

        ...(active && {
          backgroundColor: 'primary.main',
        }),
        ...sx,
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          ...(active && {
            backgroundColor: 'primary.main',
            color: 'white',
          }),
        }}
      >
        {date.format('dd').toUpperCase()}
      </Typography>

      <Typography
        sx={{
          fontWeight: 'bold',
          ...(active && {
            backgroundColor: 'primary.main',
            color: 'white',
          }),
        }}
      >
        {date.date()}
      </Typography>
    </Box>
  </Tooltip>
);

export default CalendarDayChip;
