import React from 'react';
import { Box, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

import LSClock from 'src/components/icons/LSClock';
import Comment from 'src/components/v3/Comment';
import { CommentTooltip } from 'src/components/v3/Comment/CommentTooltip';

const InactivityCell = ({ row }) => row.latestEntry && (
  <CommentTooltip
    placement="left"
    title={
      <Comment
        readOnly
        comment={row.latestEntry}
        formatComment={() => dayjs(row.latestEntry.date).format('LL')}
        key={row.latestEntry.id}
      />
    }
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'primary.main',
        gap: 0.5,
      }}
    >
      <LSClock color="primary" />

      {dayjs(row.latestEntry.date).fromNow(true)}
    </Box>
  </CommentTooltip>
);

export default InactivityCell;
