import React from 'react';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { stringAvatar } from 'src/utils/formatters';
import { useUser } from 'src/utils/hooks/useUser';
import { populateSpecialKeyWords } from 'src/utils/notificationTemplates';
import { useStudio } from 'src/utils/hooks/useStudio';

const defaultMessage = {
  id: 1,
  body: `
    Hola [persona_nombre]

    Desde el equipo de [estudio_nombre] queremos saludarte y agradecerte por tu preferencia.
  `,
};

const WhatsAppChat = ({ entity, data, message = defaultMessage }) => {
  const { user } = useUser();
  const { studio } = useStudio();

  return (
    <Paper
      sx={{
        height: '100%',
        display: 'flex',
        width: 300,
        flexDirection: 'column',
        borderRadius: 0,
        backgroundImage:
          'url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)',
        backgroundSize: 'cover',
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: 2,
          borderBottom: '1px solid #ccc',
          backgroundColor: '#f0f2f5',
        }}
      >
        <Avatar
          variant="squared"
          {...stringAvatar(entity.displayName, {
            fontSize: '1rem',
          })}
        />

        <Box
          sx={{
            marginLeft: 2,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            {entity.phone}
          </Typography>

          <Typography variant="subtitle2" color="gray" fontSize={11}>
            {entity.displayName}
          </Typography>
        </Box>
      </Box>

      {/* Chat box */}
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: 2, pt: 6 }}>
        <List>
          <ListItem
            key={message.id}
            alignItems="flex-start"
            sx={{
              backgroundColor: '#d9fdd3',
              boxShadow: '0 1px .5px rgba(11,20,26,.13)',
              borderRadius: 1,
              borderTopRightRadius: 0,
              padding: '6px 7px 8px 9px',
            }}
          >
            <ListItemText
              primary={user.name}
              secondary={
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    whiteSpace: 'pre-line',
                    div: { textAlign: 'left' },
                  }}
                >
                  {populateSpecialKeyWords(message.body, {
                    studio,
                    user,
                    ...data,
                  })}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Box>
    </Paper>
  );
};

export default WhatsAppChat;
