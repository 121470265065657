import React from 'react';
import { SvgIcon } from '@mui/material';

const LSCalendar = (props) => (
  <SvgIcon {...props}>
    <path
      clipRule="evenodd"
      d="M9.25 2C9.66421 2 10 2.33579 10 2.75V3.59667C10.6974 3.52952 11.4473 3.5 12.25 3.5C13.0527 3.5 13.8026 3.52952 14.5 3.59667V2.75C14.5 2.33579 14.8358 2 15.25 2C15.6642 2 16 2.33579 16 2.75V3.81869C17.3028 4.09088 18.3734 4.55786 19.2153 5.29576C20.7812 6.66819 21.3759 8.81307 21.4821 11.7226C21.4942 12.0535 21.5 12.3958 21.5 12.75C21.5 16.0279 21.0077 18.4279 19.4678 19.9678C17.9279 21.5077 15.5279 22 12.25 22C8.97207 22 6.57207 21.5077 5.03217 19.9678C3.49227 18.4279 3 16.0279 3 12.75C3 12.396 3.00576 12.0536 3.01783 11.7226C3.12401 8.81306 3.71873 6.66819 5.28467 5.29576C6.1266 4.55786 7.19723 4.09088 8.5 3.81869V2.75C8.5 2.33579 8.83579 2 9.25 2ZM8.5 5.35652C7.54173 5.59137 6.82 5.94471 6.27334 6.42382C5.29324 7.2828 4.7337 8.67591 4.55951 11H19.9404C19.7662 8.6759 19.2067 7.28279 18.2266 6.42382C17.68 5.94471 16.9583 5.59137 16 5.35653V6.75C16 7.16421 15.6642 7.5 15.25 7.5C14.8358 7.5 14.5 7.16421 14.5 6.75V5.10466C13.8359 5.03447 13.0894 5 12.25 5C11.4106 5 10.6641 5.03447 10 5.10466V6.75C10 7.16421 9.66421 7.5 9.25 7.5C8.83579 7.5 8.5 7.16421 8.5 6.75V5.35652ZM19.999 12.5H4.50103C4.50034 12.5825 4.5 12.6658 4.5 12.75C4.5 15.9721 5.00773 17.8221 6.09283 18.9072C7.17793 19.9923 9.02793 20.5 12.25 20.5C15.4721 20.5 17.3221 19.9923 18.4072 18.9072C19.4923 17.8221 20 15.9721 20 12.75C20 12.6658 19.9997 12.5825 19.999 12.5Z"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default LSCalendar;
