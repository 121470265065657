import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { TextField, Tooltip } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
// import { DELETE_STATE, UPDATE_STATE } from 'src/graphql/mutations/state';
import { useEntityStatuses } from 'src/components/v3/EntityStatusPopover';
import {
  DELETE_ENTITY_STATE,
  UPDATE_ENTITY_STATE,
} from 'src/graphql/mutations/entityState';

const Actions = ({ row }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteEntityState] = useMutation(DELETE_ENTITY_STATE, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteEntityState));
      cache.gc();
    },
  });

  return [
    <Tooltip title="Para editar, doble click en los campos editables que deseas actualizar en la tabla">
      <GridActionsCellItem
        color="inherit"
        icon={<LSEdit color="primary" fontSize="small" />}
        label="Edit"
      />
    </Tooltip>,
    <Tooltip title="Eliminar estado">
      <GridActionsCellItem
        color="inherit"
        icon={<LSTrash color="error" fontSize="small" />}
        label="Delete"
        onClick={() =>
          dispatchWarningPromptDialog(`FilecaseCategoriesTab-${row.id}`, {
            onAccept: () =>
              deleteEntityState({
                variables: {
                  id: row.id,
                  studioId,
                },
              }),
            title: 'Eliminar estado',
          })
        }
      />
    </Tooltip>,
  ];
};

const ColorCell = ({ row, handleUpdate }) => {
  const [color, setColor] = useState(row.color);

  return (
    <TextField
      fullWidth
      sx={{ input: { padding: 1 } }}
      type="color"
      value={color}
      onBlur={(event) =>
        handleUpdate({ ...row, color: event.target.value }, row)
      }
      onChange={(event) => setColor(event.target.value)}
    />
  );
};

export const DirectoryStatusesTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const { value } = useTabContext();
  const studioId = useStudioId();
  const { statuses, loading } = useEntityStatuses({ skip: value !== index });
  const [updateEntityState] = useMutation(UPDATE_ENTITY_STATE);

  const handleUpdate = async (val, oldValue) => {
    if (val.name === oldValue.name && val.color === oldValue.color) {
      return oldValue;
    }

    const res = await updateEntityState({
      variables: {
        studioId,
        id: val.id,
        color: val.color,
        name: val.name,
      },
    });

    return res.data.updateEntityState;
  };

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: true,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 200,
      headerName: 'Color',
      field: 'color',
      editable: false,
      renderCell: (row) => ColorCell({ ...row, handleUpdate }),
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 150,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Acciones',
      width: 100,
      editable: false,
      getActions: Actions,
    },
  ];

  return (
    <TabPanel value={index}>
      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        editMode="row"
        loading={loading}
        pageSize={10}
        processRowUpdate={handleUpdate}
        rowCount={statuses.length}
        rows={statuses}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe un estado con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
      />
    </TabPanel>
  );
};
