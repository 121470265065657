import React from 'react';
import { NotificationTemplatesPopoverBody } from './NotificationTemplatesPopoverBody';
import { PopoverBase } from '../PopoverBase';

export const NotificationTemplatesPopover = ({
  onClick,
  entity,
  anchorEl,
  ...props
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
    {...props}
  >
    <NotificationTemplatesPopoverBody
      entity={entity}
      onClick={onClick}
      {...props}
    />
  </PopoverBase>
);
