import React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { FilecasesAutocomplete } from 'src/autocompletes';
import Form from 'src/components/ui-components/Form';
import { Formik } from 'formik';

export const FilecasesPopoverBody = ({
  onSubmit,
  multiple = false,
  filecases = [],
  ...rest
}) => (
  <Box {...rest}>
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Vincular expedientes</Typography>
    </Box>

    <Divider />

    <Formik
      initialValues={{
        filecases,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form sx={{ p: 2 }}>
          <FilecasesAutocomplete
            label="Expedientes"
            multiple={multiple}
            name="filecases"
          />

          <Grid item xs={12}>
            <Box sx={{ textAlign: 'right' }}>
              <LoadingButton
                color="primary"
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Vincular
              </LoadingButton>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  </Box>
);
