import React, { useMemo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'src/components/v3/Snackbar';

import { Box, Card, Button, Typography, Divider } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { useCalendars } from '@legalsurf/hooks';

import { LSAdd as LSAddIcon } from 'src/components/icons';
import WarningDialog from 'src/dialogs/WarningDialog';
import { DELETE_CALENDAR } from 'src/graphql/mutations/calendars';
import CalendarCard from 'src/components/v3/CalendarCard';

import { GET_CALENDARS } from 'src/graphql/queries/calendars';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import CalendarForm from '../CalendarForm';
import { CalendarPageQuery } from '../queries';
import { CalendarAsideSkeleton } from './CalendarAsideSkeleton';
import { CalendarAsideQuery } from '../gql';
import { CalendarAsideDay } from './CalendarAsideDay';
import { useCalendarTableEvents } from '../utils';

const noop = () => {};

const CalendarAside = ({
  selectedCalendars,
  setSelectedCalendars,
  selectedDate,
  onSelectDate,
  weekly,
}) => {
  const studioId = useStudioId();
  const { data: calendars, loading } = useCalendars({
    studioId,
  });
  const { data: events, refetch } = useCalendarTableEvents(
    selectedDate,
    selectedCalendars,
    {},
    {
      fetchPolicy: 'cache-only',
    },
  );

  const { openSnackbar } = useSnackbar();
  const [deleteCalendar, { loading: isDeleting }] = useMutation(
    DELETE_CALENDAR,
    {
      refetchQueries: [
        { query: GET_CALENDARS, variables: { studio: studioId } },
        CalendarAsideQuery,
        CalendarPageQuery,
      ],
    },
  );

  // MOVE TO FUNCTION
  const daysWithEvents = useMemo(
    () =>
      events.reduce((acc, event) => {
        const dateStr = event.allDay
          ? dayjs(event.start, { utc: true })
          : dayjs(event.start).format('YYYY-MM-DD');

        if (!acc[dateStr]) {
          acc[dateStr] = 0;
        }

        acc[dateStr] += 1;

        return acc;
      }, {}),
    [events],
  );

  const [calendarForm, setCalendarForm] = useState(false);
  const [calendarToDelete, setCalendarToDelete] = useState(null);
  const [calendarToUpdate, setCalendarToUpdate] = useState(null);

  const handleCalendarClick = (calendarId) => {
    if (!calendarId) {
      setSelectedCalendars(calendars.map((calendar) => calendar.id));
      return;
    }

    if (selectedCalendars.includes(calendarId)) {
      const arr = new Set(selectedCalendars);
      arr.delete(calendarId);

      setSelectedCalendars([...arr]);
    } else {
      setSelectedCalendars([...new Set([...selectedCalendars, calendarId])]);
    }
  };

  const handleClickAction = (calendar, action) => {
    if (action === 'edit') setCalendarToUpdate(calendar);
    else setCalendarToDelete(calendar);
  };

  const handleActionsMenuClose = () => {
    setCalendarToDelete(null);
    setCalendarToUpdate(null);
  };

  const handleCalendarFormClose = () => {
    handleActionsMenuClose();
    setCalendarForm(false);
  };

  const handleDeleteEvent = useCallback(async () => {
    try {
      await deleteCalendar({
        variables: {
          id: calendarToDelete.id,
        },
      });
      handleActionsMenuClose();
      openSnackbar('El calendario se elimino con exito.', {
        severity: 'success',
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar(
        'Hubo un error al intentar eliminar el calendario. Intente mas tarde.',
        { severity: 'error' },
      );
    }
  }, [deleteCalendar, calendarToDelete?.id, openSnackbar]);

  return (
    <>
      <Card>
        <Box
          sx={{
            '& .Calendar': {
              backgroundColor: 'paper',
              width: '100%',
            },
          }}
        >
          <DateCalendar
            slotProps={{
              day: { weekly, selectedDate, onSelectDate, daysWithEvents },
            }}
            slots={{
              day: CalendarAsideDay,
            }}
            onChange={noop}
            onMonthChange={(month) => {
              refetch({
                end: month.endOf('month'),
                start: month.startOf('month'),
              });
            }}
          />
        </Box>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            gap: 2,
            py: 2,
            px: 4,
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5">Mis calendarios</Typography>

          <Button
            fullWidth
            size="large"
            variant={
              selectedCalendars.length === calendars?.length ||
              !selectedCalendars.length
                ? 'contained'
                : 'outlined'
            }
            onClick={() => handleCalendarClick(null)}
          >
            Todos los calendarios
          </Button>

          {loading && <CalendarAsideSkeleton />}

          {!loading &&
            calendars?.map((calendar) => {
              const active =
                selectedCalendars.includes(calendar.id) ||
                !selectedCalendars.length;

              return (
                <CalendarCard
                  active={active}
                  calendar={calendar}
                  key={calendar.id}
                  onClick={() => handleCalendarClick(calendar.id)}
                  onClickAction={(action) =>
                    handleClickAction(calendar, action)
                  }
                />
              );
            })}

          <Button
            fullWidth
            size="large"
            startIcon={<LSAddIcon />}
            variant="text"
            onClick={() => setCalendarForm(true)}
          >
            Crear calendario
          </Button>
        </Box>
      </Card>

      <WarningDialog
        loading={isDeleting}
        open={Boolean(calendarToDelete)}
        onAccept={handleDeleteEvent}
        onClose={handleActionsMenuClose}
      />

      {(!!calendarToUpdate || calendarForm) && (
        <CalendarForm
          calendar={calendarForm ? undefined : calendarToUpdate}
          show={!!calendarToUpdate || calendarForm}
          onHide={handleCalendarFormClose}
        />
      )}
    </>
  );
};

export default CalendarAside;
