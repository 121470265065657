import React from 'react';
import { useMutation, MutationHookOptions } from '@apollo/client';
import { CreateStudioMutation } from '@legalsurf/queries';

import { Studio } from '../types';

export type CreateStudioMutationVariables = {
  name: string;
  address?: string;
  country?: string;
  phone?: string;
  photo?: File;
  metadata?: any;
};

export type CreateStudioMutationOptions = MutationHookOptions<
  { createStudio: Studio },
  CreateStudioMutationVariables
>;

export const useCreateStudio = (options: CreateStudioMutationOptions) => {
  return useMutation<{ createStudio: Studio }, CreateStudioMutationVariables>(
    CreateStudioMutation,
    options,
  );
};
