import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $applyNodeReplacement,
  $insertNodes,
  COMMAND_PRIORITY_EDITOR,
  DecoratorNode,
  createCommand,
} from 'lexical';

import { RichEditorSpecialKeyword } from './RichEditorSpecialKeyword';

export const INSERT_SPECIAL_KEYWORD_COMMAND = createCommand(
  'INSERT_SPECIAL_KEYWORD_COMMAND',
);

export class SpecialKeywordNode extends DecoratorNode {
  static getType() {
    return 'specialKeyword';
  }

  static clone(node) {
    return new SpecialKeywordNode(node.__payload, node.__format, node.__key);
  }

  static importJSON(serializedNode) {
    // eslint-disable-next-line no-use-before-define
    return $createSpecialKeywordNode(serializedNode);
  }

  constructor(payload, key) {
    super(key);
    this.__payload = payload;
  }

  updateDOM() {
    return false;
  }

  createDOM() {
    const element = document.createElement('span');

    element.setAttribute(
      'data-legalsurf-special-keyword-key',
      this.__payload?.key,
    );

    element.setAttribute(
      'data-legalsurf-special-keyword-model',
      this.__payload?.model,
    );

    if (!Number.isNaN(Number(this.__payload?.index))) {
      element.setAttribute(
        'data-legalsurf-special-keyword-index',
        this.__payload?.index,
      );
    }

    return element;
  }

  getId() {
    return [this.__payload.key, this.__payload.model, this.__payload.index]
      .filter((x) => !!x)
      .join('-');
  }

  isIsolated() {
    return false;
  }

  isInline() {
    return true;
  }

  isKeyboardSelectable() {
    return true;
  }

  exportJSON() {
    return {
      id: this.getId(),
      type: 'specialKeyword',
      version: 1,
    };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-legalsurf-special-keyword-key')) {
          return null;
        }

        return {
          // eslint-disable-next-line no-use-before-define
          conversion: convertSpecialKeywordElement,
          priority: 2,
        };
      },
    };
  }

  exportDOM() {
    const element = document.createElement('span');

    element.setAttribute(
      'data-legalsurf-special-keyword-key',
      this.__payload?.key,
    );

    element.setAttribute(
      'data-legalsurf-special-keyword-model',
      this.__payload?.model,
    );

    if (!Number.isNaN(Number(this.__payload?.index))) {
      element.setAttribute(
        'data-legalsurf-special-keyword-index',
        this.__payload?.index,
      );
    }

    return { element };
  }

  decorate() {
    return <RichEditorSpecialKeyword payload={this.__payload} />;
  }
}

function $createSpecialKeywordNode(payload) {
  const node = new SpecialKeywordNode(payload);

  return $applyNodeReplacement(node);
}

function convertSpecialKeywordElement(domNode) {
  const key = domNode.getAttribute('data-legalsurf-special-keyword-key');
  const model = domNode.getAttribute('data-legalsurf-special-keyword-model');
  const index = domNode.getAttribute('data-legalsurf-special-keyword-index');

  if (key) {
    const node = $createSpecialKeywordNode({ key, model, index });

    return { node };
  }
  return null;
}

export const SpecialKeywordPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([SpecialKeywordNode])) {
      throw new Error(
        'SpecialKeywordPlugin: SpecialKeywordNode not registered on editor (initialConfig.nodes)',
      );
    }

    return editor.registerCommand(
      INSERT_SPECIAL_KEYWORD_COMMAND,
      (payload) => {
        $insertNodes([$createSpecialKeywordNode(payload)]);

        return true;
      },
      COMMAND_PRIORITY_EDITOR,
    );
  }, [editor]);

  return null;
};
