import React from 'react';
import { Popover, ClickAwayListener } from '@mui/material';

import { AssigneesPopoverContent } from './AssigneesPopoverContent';

export const AssigneesPopoverBody = ({
  open,
  anchorEl,
  children,
  onClickAway,
  onSubmit,
  selectedAssignees,
  ...props
}) => (
  <>
    {children}

    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      {...props}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <span>
          <AssigneesPopoverContent
            selectedAssignees={selectedAssignees}
            onClickAway={onClickAway}
            onSubmit={onSubmit}
          />
        </span>
      </ClickAwayListener>
    </Popover>
  </>
);
