import React from 'react';
import { Formik } from 'formik';

import { DialogContent, DialogActions, Button } from '@mui/material';

import Form, {
  TextField,
  SubmitButton,
} from 'src/components/ui-components/Form';

import { generateCoolColor } from '@legalsurf/common';
import StateFormSchema from './schema';

const defaultValues = {
  color: generateCoolColor(),
  name: '',
};

const StateForm = ({
  onSubmit,
  onCancel,
  initialValues = defaultValues,
  ...rest
}) => (
  <Formik
    {...rest}
    initialValues={initialValues}
    validationSchema={StateFormSchema}
    onSubmit={onSubmit}
  >
    {({ values, isSubmitting }) => (
      <>
        <DialogContent>
          <Form id="stateForm">
            <TextField autoFocus name="name" placeholder="ej. Pendiente" />

            <TextField
              label="Seleccionar color"
              name="color"
              placeholder="Color"
              type="color"
            />
          </Form>
        </DialogContent>

        <DialogActions>
          <Button fullWidth disabled={!!isSubmitting} onClick={onCancel}>
            Cancelar
          </Button>

          <SubmitButton fullWidth form="stateForm">
            {values.id ? 'Actualizar Estado' : 'Crear Estado'}
          </SubmitButton>
        </DialogActions>
      </>
    )}
  </Formik>
);

export default StateForm;
