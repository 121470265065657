import React from 'react';
import {
  Box,
  Avatar,
  AvatarGroup,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  lighten,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SettingOption = ({
  title,
  subtitle,
  image,
  buttonLabel,
  buttonProps,
  background,
  backgroundImage,
  icon,
  to,
  onClick,
  sx,
}) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ borderRadius: 1 }}>
      <CardContent
        sx={{
          backgroundColor: background,
          flexDirection: 'column',
          display: 'flex',
          gap: 1,
          color: 'white',
          px: 4,
          pt: 4,

          backgroundImage: `url(${backgroundImage})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',

          '& .MuiAvatar-root': {
            borderColor: lighten(background, 0.9),
            height: 32,
            width: 32,
            fontSize: 12,
          },

          '& button': {
            textTransform: 'none',
          },

          ...sx,
        }}
      >
        <Stack alignItems="center" direction="row" gap={1}>
          {image &&
            (Array.isArray(image) ? (
              <AvatarGroup
                max={4}
                sx={{
                  div: { borderColor: 'transparent !important' },
                }}
              >
                {image.map((img) => (
                  <Avatar
                    key={img}
                    src={img}
                    style={{
                      width: 44,
                      height: 44,
                    }}
                  />
                ))}
              </AvatarGroup>
            ) : (
              <Avatar
                src={image}
                style={{
                  width: 54,
                  height: 54,
                }}
              />
            ))}

          {icon}

          {title && (
            <Typography color="white" variant="body1">
              {title}
            </Typography>
          )}
        </Stack>

        <Typography color="white" variant="body2">
          {subtitle}
        </Typography>

        <Box>
          <Button
            sx={{
              ...buttonProps?.sx,
            }}
            variant="contained"
            onClick={onClick ?? (() => navigate(to))}
            {...buttonProps}
          >
            {buttonLabel}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SettingOption;
