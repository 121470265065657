import React, { useMemo } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';

import LSClock from 'src/components/icons/LSClock';

const EndingCell = ({ row: event, tooltipProps }) => {
  const dayjsEvent = useMemo(() => dayjs(event.end), [event]);

  const strComponent = useMemo(() => {
    if (dayjs().isBefore(dayjsEvent)) {
      if (dayjsEvent.isToday()) {
        return (
          <>
            <LSClock color="error" />

            <Typography color="error">Hoy 🔥</Typography>
          </>
        );
      }

      return (
        <>
          <LSClock color="primary" />

          <Typography color={(q) => q.palette.info.main}>
            {dayjsEvent.fromNow(true)}
          </Typography>
        </>
      );
    }

    return (
      <>
        <LSClock sx={{ color: 'grey.200' }} />

        <Typography color={(q) => q.palette.grey[200]}>Finalizó</Typography>
      </>
    );
  }, [dayjsEvent]);

  const tooltipStr = `El evento ${
    dayjs().isBefore(dayjsEvent)
      ? `termina en ${dayjsEvent.fromNow(true)}. El ${dayjsEvent.format('LLL')}`
      : `terminó hace ${dayjsEvent.toNow(true)}`
  } `;

  return (
    <Box sx={{ flex: 1 }}>
      <Tooltip placement="left" title={tooltipStr} {...tooltipProps}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          {strComponent}
        </Box>
      </Tooltip>
    </Box>
  );
};

export default EndingCell;
