const MuiDialogActions = {
  styleOverrides: {
    root: {
      paddingBottom: 32,
      paddingLeft: 48,
      paddingRight: 56,
    },
  },
};

export default MuiDialogActions;
