import React from 'react';
import { Typography, Chip, Stack } from '@mui/material';

export const ConceptCell = ({ row }) => {
  const title =
    row.items?.map((item) => item.description).join(', ') ?? row.concept;

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        overflow: 'hidden',
        flexWrap: 'wrap',
      }}
    >
      <Typography
        role="button"
        sx={{
          fontWeight: '500',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title}
      </Typography>

      {!!row.tags?.length && (
        <Stack direction="row" sx={{ gap: 0.5 }}>
          {row.tags?.map((tag) => (
            <Chip
              key={tag.name}
              label={tag.name}
              size="small"
              sx={{
                color: 'primary.dark',
                backgroundColor: 'primary.100',
              }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};
