import React from 'react';

import { Box, Skeleton } from '@mui/material';

export const CalendarAsideSkeleton = () => (
  <Box
    sx={{
      padding: 2,
      '& > * + *': {
        marginTop: 2,
      },
    }}
  >
    {Array(3)
      .fill(null)
      .map((...[, idx]) => (
        <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Skeleton height={16} variant="squared" width={16} />
          <Skeleton height={12} width={150} />
          <Skeleton
            height={16}
            sx={{ marginLeft: 'auto' }}
            variant="circular"
            width={16}
          />
        </Box>
      ))}
  </Box>
);
