export const fileTypes = {
  // Documents
  txt: 'text/plain',
  doc: 'application/msword',
  docm: 'application/vnd.ms-word.document.macroenabled.12',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  odt: 'application/vnd.oasis.opendocument.text',
  rtf: 'text/rtf',

  // PDF
  pdf: 'application/pdf',

  // EXCEL's
  csv: 'text/csv',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  ods: 'application/vnd.oasis.opendocument.spreadsheet',

  // Google files
  spreadsheet: 'application/vnd.google-apps.spreadsheet',
  document: 'application/vnd.google-apps.document',

  // // Powerpoint
  ppt: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroenabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroenabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroenabled.12',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  odp: 'application/vnd.oasis.opendocument.presentation',

  // Folder
  folder: 'application/vnd.google-apps.folder',

  // Images
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  bmp: 'image/x-ms-bmp',

  // Audio
  mp3: 'audio/mpeg',
  mp4: 'audio/mp4',
  ogg: 'audio/ogg',
};
