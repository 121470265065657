import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useStudioMembers } from '@legalsurf/hooks';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { Save } from '@mui/icons-material';

export const AssigneePopoverContent = ({
  selectedAssignee = null,
  onClickAway,
  onSubmit,
  ...props
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { data: members, loading } = useStudioMembers({
    variables: {
      studio: useStudioId(),
      status: ['ACTIVE'],
    },
  });
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [newAssignee, setNewAssignee] = useState(selectedAssignee?.id || null);

  const handleAssigneeSelection = (assignee) => {
    setNewAssignee(assignee.id);
    setHasUnsavedChanges(true);
  };

  const handleSubmit = async (event) => {
    setLoadingSubmit(true);
    await onSubmit(newAssignee).finally(() => {
      setLoadingSubmit(false);
      setHasUnsavedChanges(false);
    });

    onClickAway(event);
  };

  return (
    <Box {...props}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        p={2}
        py={1.5}
        gap={2}
      >
        <Typography variant="h6">Asignados</Typography>

        <LoadingButton
          variant="contained"
          size="small"
          onClick={handleSubmit}
          loading={loadingSubmit}
          disabled={!hasUnsavedChanges}
          startIcon={<Save />}
          color="primary"
        >
          Guardar
        </LoadingButton>
      </Stack>

      <Divider />

      {loading ? (
        <Box
          sx={{
            minHeight: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress color="primary" size={32} />
        </Box>
      ) : (
        <MenuList sx={{ overflowY: 'scroll', maxHeight: 400 }}>
          {members?.map((member) => (
            <MenuItem
              key={member.id}
              onClick={() => handleAssigneeSelection(member)}
            >
              <ListItemIcon>
                <Avatar
                  alt="Avatar"
                  size="small"
                  src={member.picture}
                  sx={{ margin: 1, width: 28, height: 28 }}
                />
              </ListItemIcon>

              <ListItemText>{member.name}</ListItemText>

              <ListItemIcon>
                <Checkbox checked={newAssignee === member.id} />
              </ListItemIcon>
            </MenuItem>
          ))}
        </MenuList>
      )}
    </Box>
  );
};
