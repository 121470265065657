import { Box, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

import { LSArrowDown, LSArrowUp } from 'src/components/icons';
import ChartTooltip from '../ChartTooltip';
import ChunkCard from '../../ChunkCard';

const salesLabels = [
  { key: 'sales', name: 'Honorarios', color: '#4ee5cf' },
  { key: 'charges', name: 'Cobros', color: '#7363d8' },
];

const expensesLabels = [
  { key: 'expenses', name: 'Gastos', color: '#f0416c' },
  { key: 'payments', name: 'Pagos', color: '#9beee2' },
];

const dataKey = 'formatedDate';

const TreasuryReportsBarChart = ({
  reportData,
  loading,
  variant = 'sales',
}) => {
  const labels = variant === 'sales' ? salesLabels : expensesLabels;

  const [barProps, setBarProps] = useState(
    labels.reduce(
      (a, { key }) => {
        // eslint-disable-next-line no-param-reassign
        a[key] = false;
        return a;
      },
      { hover: null },
    ),
  );

  const handleLegendMouseEnter = (e) => {
    if (!barProps[e.dataKey]) {
      setBarProps({ ...barProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeave = () => {
    setBarProps({ ...barProps, hover: null });
  };

  const selectBar = (e) => {
    setBarProps({
      ...barProps,
      [e.dataKey]: !barProps[e.dataKey],
      hover: null,
    });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 200,
        }}
      >
        <CircularProgress color="primary" size={30} />
      </Box>
    );
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
        }}
      >
        <ChunkCard
          amount={
            variant === 'sales'
              ? reportData.totalSaled
              : reportData.totalExpended
          }
          icon={
            variant === 'sales' ? (
              <Box color="success.main">
                <LSArrowUp />
              </Box>
            ) : (
              <Box color="error.main">
                <LSArrowDown />
              </Box>
            )
          }
          title={variant === 'sales' ? 'Honorarios totales' : 'Total gastado'}
        />

        <ChunkCard
          // amount={allStats[variant === 'sales' ? 'charges' : 'payments']}
          amount={
            variant === 'sales' ? reportData.totalCharged : reportData.totalPaid
          }
          icon={
            variant === 'sales' ? (
              <Box color="primary.main">
                <LSArrowUp />
              </Box>
            ) : (
              <Box color="success.main">
                <LSArrowDown />
              </Box>
            )
          }
          title={variant === 'sales' ? 'Total cobrado' : 'Total pagado'}
        />

        <ChunkCard
          amount={reportData.totalRemaining}
          sx={{
            borderColor: variant === 'sales' ? 'primary.100' : 'error.200',
            backgroundColor: variant === 'sales' ? 'primary.100' : 'error.200',

            '& h4, & h6': {
              color: variant === 'sales' ? 'primary.light' : 'error.light',
            },
          }}
          title={variant === 'sales' ? 'Por cobrar' : 'Por pagar'}
          variant="solid"
        />
      </Box>

      <ResponsiveContainer height={300} width="100%">
        <BarChart
          data={reportData?.data}
          margin={{ top: 30, left: 30, right: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis dataKey={dataKey}>
            <Label dx={20} dy={10} position="insideBottomleft" />
          </XAxis>

          <YAxis type="number">
            <Label position="center" />
          </YAxis>

          <Tooltip content={<ChartTooltip />} />

          <Legend
            onClick={selectBar}
            onMouseOut={handleLegendMouseLeave}
            onMouseOver={handleLegendMouseEnter}
          />

          {labels.map((label) => (
            <Bar
              dataKey={label.key}
              fill={label.color}
              fillOpacity={Number(
                barProps.hover === label.key || !barProps.hover ? 1 : 0.6,
              )}
              hide={barProps[label.key] === true}
              key={label.key}
              name={label.name}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TreasuryReportsBarChart;
