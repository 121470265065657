import React from 'react';

import { useSubscription } from 'src/components/SubscriptionWatcher/useSubscription';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';

import { AccountBalance } from '@mui/icons-material';

import { SubscriptionManagementSkeleton } from './Skeleton';
import { SubscriptionDetails } from '../SubscriptionDetails';
import { subscriptionStatusesMap } from '../utils';

export const SubscriptionManagementForm = () => {
  const { studio, loading } = useSubscription();

  if (loading) {
    return <SubscriptionManagementSkeleton />;
  }

  return (
    <Box
      sx={{
        width: studio.subscription ? 800 : 1200,
        maxWidth: '100%',
        margin: '0 auto',
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography fontWeight={600} variant="h4">
        Estado de cuenta
      </Typography>

      <Card>
        <CardContent sx={{ padding: [3, 6] }}>
          <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <Avatar src={studio.picture} sx={{ height: 64, width: 64 }}>
              <AccountBalance sx={{ fontSize: 32 }} />
            </Avatar>

            <Box>
              <Typography fontWeight={600} variant="h4">
                {studio.name}
              </Typography>

              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Typography fontWeight={500}>
                  Estado de suscripción:{' '}
                </Typography>
                <Typography
                  color={
                    subscriptionStatusesMap[studio?.subscription?.status]
                      ?.color || 'primary'
                  }
                >
                  {subscriptionStatusesMap[studio?.subscription?.status]
                    ?.label || 'Inactiva'}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider sx={{ my: 4 }} />

          <SubscriptionDetails />
        </CardContent>
      </Card>
    </Box>
  );
};
