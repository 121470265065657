import React from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from 'src/utils/hooks/usePermissions';

const PermissionGuard = ({
  behavior = 'hide',
  to,
  all = false,
  permissions,
  children,
}) => {
  const { permissions: userPermissions, role } = usePermissions();
  // const { subscription } = useSubscription();
  const hasSomePermission = userPermissions.some(
    (userPermission) => permissions?.includes(userPermission) ?? true,
  );

  const isAdmin = role === 'admin';
  const isOwner = role === 'owner';

  // if (subscription?.users > 0) {
  // TODO: Ver que mierda hacemos para  mostrar los ugpgrades y los limites
  // if (true) {
  //   return (
  //     <>
  //       <Tooltip>{children}</Tooltip>
  //     </>
  //   );
  // }

  if (!permissions?.length > 0) {
    return children;
  }

  if (all && !isAdmin && !isOwner) {
    const hasAllPermission = permissions.every((permission) =>
      userPermissions?.includes(permission),
    );

    if (hasAllPermission) {
      return children;
    }

    return null;
  }

  if (behavior === 'hide' && !hasSomePermission && !isOwner && !isAdmin) {
    return null;
  }

  if (behavior === 'redirect' && to) {
    return <Navigate to={to} />;
  }

  return children;
};

export default PermissionGuard;
