import React, { memo, useState, useRef } from 'react';
import {
  IconButton,
  Grid,
  Box,
  TextField,
  InputAdornment,
  useTheme,
  Grow,
  FormControl,
} from '@mui/material';
import { LSX as LSXIcon } from 'src/components/icons';
import { useField, useFormikContext } from 'formik';

import StatusPopover, {
  useFilecaseStatuses,
} from 'src/components/v3/StatusPopover';

const StatusAutocomplete = ({
  disabled,
  label = 'Estado',
  placeholder = 'Ej. Cerrado',
  multiple,
  gridProps = { xs: 12 },
  name,
  autoSubmit,
  InputProps = {},
  ...rest
}) => {
  const anchorEl = useRef(null);
  const { statuses } = useFilecaseStatuses();
  const [openStatusPopover, setOpenStatusPopover] = useState(false);
  const [field, , helpers] = useField(name);
  const { submitForm } = useFormikContext();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const theme = useTheme();

  const value = statuses?.find(
    (status) =>
      status.name === (field.value?.name ?? field.value?.value ?? field.value),
  );

  const handleTogglePop = () => {
    setOpenStatusPopover((old) => !old);

    if (anchorEl.current) {
      anchorEl.current.blur();
    }
  };

  return (
    <Grid item {...gridProps}>
      <Box sx={{ position: 'relative' }}>
        <FormControl sx={{ width: '100%' }}>
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Grow in={value && isMouseOver}>
                    <IconButton
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation?.();
                        helpers.setValue(null);
                      }}
                    >
                      <LSXIcon />
                    </IconButton>
                  </Grow>
                </InputAdornment>
              ),
              ...InputProps,
            }}
            autoComplete="off"
            autoCorrect="off"
            focused={openStatusPopover}
            inputRef={(node) => {
              anchorEl.current = node;
            }}
            label={value?.name ? undefined : label}
            name={name}
            placeholder={placeholder}
            sx={{
              '& input': {
                color: value?.color
                  ? theme.palette.computeContrast(value.color)
                  : '#5E6278',
              },
              '& fieldset': {
                backgroundColor: value?.color ?? 'background.input',
              },
            }}
            value={value?.name ?? ''}
            variant="outlined"
            onClick={handleTogglePop}
            onFocus={field.onFocus}
            onMouseLeave={() => setIsMouseOver(false)}
            onMouseOver={() => setIsMouseOver(true)}
            {...rest}
          />
        </FormControl>

        <StatusPopover
          anchorEl={anchorEl.current}
          open={openStatusPopover}
          onClickStatus={(option) => {
            helpers.setValue({ ...option, value: option?.name });

            if (option.value === null) {
              helpers.setValue(null);
            }

            if (autoSubmit) {
              submitForm();
            }
          }}
          onClose={handleTogglePop}
        />
      </Box>
    </Grid>
  );
};

export default memo(StatusAutocomplete);
