import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { slim, mobile, desktop, wide, between } from 'src/utils/responsive';

export const useDelayedHook = (callback, watchers) => {
  useEffect(() => {
    if (watchers.some((value) => !value || value.length < 3)) {
      return;
    }

    const timer = setTimeout(callback, 1500);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [callback, watchers]);
};

const useIsBetween = (start, end) => useMediaQuery(between(start, end));

export const useBreakpoints = () => ({
  isSlim: useMediaQuery(slim),
  isMobile: useMediaQuery(mobile),
  isDesktop: useMediaQuery(desktop),
  isWide: useMediaQuery(wide),
  isBetween: useIsBetween,
});
