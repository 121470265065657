import React from 'react';
import dayjs from 'dayjs';
import { Box, IconButton, Link, Stack, Tooltip } from '@mui/material';
import { UserProfile } from '@clerk/clerk-react';

import Page from 'src/components/v2/Page';
import { useUser } from 'src/utils/hooks/useUser';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const termsAndConditionsLink = `${
  import.meta.env.VITE_LS_LANDING_URL
}/terms.html`;

const rightToForgottenText = `Para aplicar al derecho al olvido, debes enviar un correo a soporte@legal.surf, el asunto del correo debe ser "Derecho al olvido" o "Eliminar mi cuenta". Asegurate de que el correo que utilizaste para contactar a soporte sea el mismo de tu usuario. Una vez confirmado todos los datos vamos a gestionar los datos que deseas eliminar`;

export const Profile = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Page title="Configuracion Profile | LegalSurf">
      <Box display="table" mx="auto">
        <IconButton sx={{ mb: 0.5 }} onClick={handleGoBack}>
          <ArrowBack />
        </IconButton>

        <UserProfile
          appearance={{
            elements: {
              card: {
                boxShadow: 'none',
              },
            },
          }}
        />

        <Stack direction="column" gap={0.5} pb={5} pl={3}>
          <Box sx={{ gap: 0.5, display: 'flex', mt: 2 }}>
            <Link
              color="#000"
              href={termsAndConditionsLink}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              target="_blank"
            >
              Terminos y condiciones
            </Link>

            <span>
              aceptados el {dayjs(Number(user?.toc?.createdAt)).format('LL')}
            </span>
          </Box>

          <Box>
            <Tooltip title={rightToForgottenText}>
              <Link href="mailto:soporte@legal.surf?subject=Derecho al olvido">
                Deseo aplicar el derecho al olvido
              </Link>
            </Tooltip>
          </Box>
        </Stack>
      </Box>
    </Page>
  );
};
