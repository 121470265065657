import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CloseRounded } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import { useSubscriptionStore } from './useSubscriptionStore';

const whatsappLink =
  'https://api.whatsapp.com/send?phone=+5491168380180&text=Hola%20%F0%9F%91%8B%20quiero%20informaci%C3%B3n%20sobre%20planes%20y%20precios!%20No%20borre%20este%20mensaje,%20para%20una%20mejor%20gesti%C3%B3n%20%F0%9F%98%89';

// TODO: Define whether we'll use stripe's pricing table or what
export const PricingTableDialog = () => {
  const state = useSubscriptionStore();
  const studioId = useStudioId();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      key={studioId}
      maxWidth="xs"
      open={state.showPricingTable}
      onClose={() => state.setPricingTable(false)}
    >
      <DialogContent
        sx={{ backgroundColor: 'white', p: 0, position: 'relative' }}
      >
        {/* <Grid container spacing={2}>
          <Grid item md={5} xs={12}> */}
        <Box
          sx={{
            backgroundColor: 'primary.700',
            height: '100%',
            p: 6,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography color="white" sx={{ mb: 2, px: 3, pt: 4 }} variant="h2">
            ¡¡Pruébalo por 7 días y obtén 50% off por 3 meses!!
          </Typography>

          <Typography
            color="white"
            fontSize={16}
            sx={{ px: 3, mb: 4, fontWeight: 500 }}
          >
            <b>Aprovecha esta promoción limitada utilizando el cupón 3M50.</b>
            <p>Lleva la gestión de tu despacho legal a otro nivel.</p>
            <p>
              Transforma tu gestión legal y maximiza tu productividad con
              legalsurf.
            </p>
            <p>
              Descubre cómo legalsurf puede revolucionar tu forma de trabajar y
              lleva tu negocio al siguiente nivel.
            </p>
          </Typography>

          <Button
            color="inherit"
            href={whatsappLink}
            rel="noreferrer"
            sx={{ mt: 'auto' }}
            target="_blank"
            variant="contained"
          >
            Contactar con un asesor
          </Button>
        </Box>
        {/* </Grid>

          <Grid item md={7} xs={12}>
            <stripe-pricing-table
              allow-top-navigation
              client-reference-id={[user.id, studioId].join('_')}
              customer-email={user.email}
              key={studioId}
              pricing-table-id="prctbl_1NGRukL3rAO9CGUvKcN52gh3"
              publishable-key="pk_live_51K7LDhL3rAO9CGUvO0XM46rCrigJOSW2qbfCeKD0jo9ZIXtGkJsDQPOEgB2aL0x2gn1yPFKAG2ThtwY8HKfn6LU300zLhzQvzP"
            />
          </Grid>
        </Grid> */}

        <IconButton
          sx={{
            position: 'absolute',
            right: '2rem',
            top: '2rem',
            color: 'white',
          }}
          onClick={() => state.setPricingTable(false)}
        >
          <CloseRounded color="primary" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};
