import { ButtonBase } from '@mui/material';
import React from 'react';

export const Pressable = React.forwardRef(
  ({ children, sx = {}, ...props }, ref) => (
    <ButtonBase
      ref={ref}
      sx={{
        display: 'block',
        width: 'inherit',
        height: 'inherit',
        textAlign: 'inherit',
        ...sx,
      }}
      {...props}
    >
      {children}
    </ButtonBase>
  ),
);
