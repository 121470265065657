import React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Form, { SelectField } from 'src/components/ui-components/Form';
import { Formik } from 'formik';
import { Save } from '@mui/icons-material';

export const FilecaseCategoriesPopoverBody = ({ onSubmit, categories, ...rest }) => (
  <Box {...rest}>
    <Box sx={{ p: 2 }}>
      <Typography variant="h6">Categorías</Typography>
    </Box>

    <Divider />

    <Formik
      initialValues={{
        categories,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form sx={{ p: 2 }}>
          <SelectField
            creatable
            multiple
            fixedOptions={categories.map((val) => val.value)}
            getOptionDisabled={(option) => categories.some((val) => val.value === option.value)}
            helperText="Crea una categoría para cada tipo de expediente y categoriza mejor tu información."
            label="Categorías"
            name="categories"
            options={values.categories}
          />

          <Grid item xs={12}>
            <Box sx={{ textAlign: 'right' }}>
              <LoadingButton
                color="primary"
                endIcon={<Save />}
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Crear
              </LoadingButton>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  </Box>
);
