import React, { useMemo } from 'react';
import {
  Avatar,
  Stack,
  Card,
  Box,
  CardContent,
  Typography,
} from '@mui/material';

import ImageStudio from 'src/assets/images/avatar2.png';
import { Pressable } from 'src/components/ui-components/Pressable';
import { subscriptionStatusesMap } from 'src/pages/Dashboard/Settings/SubscriptionManagement/utils';

const StudioSubtitle = ({ subscription }) => {
  const subtitle = useMemo(() => {
    if (!subscription || subscription?.name === 'Surfer Free') {
      return `Surfer Free`;
    }

    return subscription.name;
  }, [subscription]);

  return (
    <Typography
      align="left"
      color={subscriptionStatusesMap[subscription?.status]?.color || 'primary'}
    >
      {subtitle}
    </Typography>
  );
};

const StudioCard = ({ studio, onClick, sx, ...props }) => (
  <Box sx={{ width: '100%' }}>
    <Pressable onClick={(event) => onClick(studio.id, event)}>
      <Card
        sx={{
          backgroundColor: 'primary.900',
          ...sx,
        }}
        {...props}
      >
        <Box
          sx={{
            p: 2,
            height: '100%',
            width: '100%',
          }}
        >
          <CardContent>
            <Stack alignItems="center" direction="row" gap={2}>
              <Avatar
                src={studio.picture || ImageStudio}
                sx={{ width: 74, height: 74 }}
              />

              <Stack direction="column" gap={1}>
                <Typography
                  fontWeight={600}
                  sx={{ color: 'common.white' }}
                  variant="h4"
                >
                  {studio.name}
                </Typography>

                <StudioSubtitle subscription={studio.subscription} />
              </Stack>
            </Stack>
          </CardContent>
        </Box>
      </Card>
    </Pressable>
  </Box>
);

export default StudioCard;
