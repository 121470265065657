import React, { useMemo } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';

import TodoRow from 'src/dialogs/calendar/CalendarEventDetailsDialog/CheckListSection/TodoRow';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { PopoverBase } from '../PopoverBase';

const CheckListPopoverBody = ({
  todos,
  loading,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const areAllChecked = useMemo(
    () => todos.length > 0 && todos.every((todo) => todo.done),
    [todos],
  );

  if (loading) {
    return (
      <Stack spacing={2}>
        <CircularProgress />
      </Stack>
    );
  }

  if (!todos.length) {
    return (
      <CreationInfoBanner
        buttonStr="Cargar subtarea"
        description="Crear una lista de subtareas y organiza tus procesos."
        title="Lista de tareas"
        onClick={onCreate}
      />
    );
  }

  return (
    <Stack
      sx={{
        p: 2,
        borderRadius: 1,
        position: 'relative',
        backgroundColor: areAllChecked ? 'success.200' : 'primary.100',
        overflow: 'hidden',

        ...(!todos.length
          ? {
              borderStyle: 'dashed',
              borderWidth: 2,
              borderColor: 'primary.300',
              transition: 'all 100ms linear',
              ':hover': {
                borderColor: 'primary.main',
                backgroundColor: 'primary.300',
              },
            }
          : undefined),
      }}
    >
      {/* <Fade in=}> */}
      {/* <LinearProgress
            color={areAllChecked ? 'success' : 'primary'}
            sx={{
              borderRadius: 0.5,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        */}
      {/* </Fade> */}

      {!!todos.length && (
        <Stack spacing={0.5}>
          <Typography fontSize={16} fontWeight={600}>
            Lista de tareas
          </Typography>

          <Stack>
            {todos.map((todo, index) => (
              <TodoRow
                key={todo.id}
                onBlur={(values) => onUpdate(values, index)}
                onDelete={(e) => {
                  e.stopPropagation();

                  onDelete(todo, index);
                }}
                {...todo}
                title={todo.title || `Tarea #${index + 1}`}
              />
            ))}
          </Stack>
        </Stack>
      )}

      <Stack spacing={0.5}>
        {todos.length > 0 && (
          <Box pl={1}>
            <IconButton
              disabled={loading}
              size="small"
              sx={{ borderRadius: '1px', padding: 0.2 }}
              onClick={onCreate}
            >
              <Add fontSize="small" />
            </IconButton>
          </Box>
        )}

        {!todos.length && (
          <CreationInfoBanner
            buttonStr="Cargar subtarea"
            description="Crear una lista de subtareas y organiza tus procesos."
            title="Lista de subtareas"
            onClick={onCreate}
          />
        )}
      </Stack>
    </Stack>
  );
};

export const CheckListPopover = ({
  loading,
  anchorEl,
  onCreate,
  onDelete,
  onUpdate,
  open,
  todos: defaultTodos = [],
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
}) => (
  <PopoverBase
    anchorEl={anchorEl}
    anchorOrigin={anchorOrigin}
    open={open}
    transformOrigin={transformOrigin}
    onClose={(event) => {
      event?.stopPropagation();

      return onClose(event);
    }}
  >
    <Box sx={{ minWidth: 400 }}>
      <CheckListPopoverBody
        loading={loading}
        todos={defaultTodos}
        onCreate={onCreate}
        onDelete={onDelete}
        onUpdate={onUpdate}
      />
    </Box>
  </PopoverBase>
);
