import React from 'react';
import { Stack } from '@mui/material';
import { useMutation } from '@apollo/client';

import Comment from 'src/components/v3/Comment';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CommentForm } from './CommentForm';
import { EmptyComments } from './EmptyComments';
import {
  DeleteCommentMutation,
  UpdateCommentMutation,
} from 'src/graphql/mutations';

export const Comments = ({ expense }) => {
  const studioId = useStudioId();

  const [updateComment] = useMutation(UpdateCommentMutation);
  const [deleteComment] = useMutation(DeleteCommentMutation, {
    update: (cache, result) =>
      cache.evict({
        id: cache.identify(result.data.deleteComment),
      }),
  });

  const handleCommentDelete = (commentId) =>
    deleteComment({
      variables: {
        expenseId: expense.id,
        commentId,
        studioId,
      },
    });

  const handleCommentUpdate = ({ commentId, content }) =>
    updateComment({
      variables: {
        commentId,
        expenseId: expense.id,
        studioId,
        content,
      },
    });

  return (
    <Stack gap={2}>
      <Stack
        gap={2}
        sx={{
          pt: 1,
          '& > *:not(:last-child)': {
            borderBottom: '1px dashed',
            borderColor: 'divider',
          },
        }}
      >
        {expense.comments?.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            onRemove={handleCommentDelete}
            onUpdate={handleCommentUpdate}
          />
        ))}
      </Stack>

      {!expense.comments.length && <EmptyComments />}

      <CommentForm expenseId={expense.id} />
    </Stack>
  );
};
