import React from 'react';
import { Box, Button } from '@mui/material';
import dayjs from 'dayjs';

import { CalendarAgendaDays } from './CalendarAgendaDays';

const useIsToday = (date) => {
  const today = dayjs();

  return date.isSame(today, 'day');
};

const CalendarAgenda = ({
  selectedCalendars,
  filters,
  selectedDate,
  onSelectDate,
  onWeeklyClick,
  weekly,
}) => {
  const isToday = useIsToday(selectedDate);

  const handleTodayClick = () => {
    onSelectDate(dayjs().locale('es'));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'
      }}
    >
      <CalendarAgendaDays
        filters={filters}
        selectedCalendars={selectedCalendars}
        selectedDate={selectedDate}
        weekly={weekly}
        onSelectDate={onSelectDate}
      />

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, ml: 'auto' }}>
        <Button
          color="primary"
          size="small"
          variant={isToday ? 'contained' : 'outlined'}
          onClick={handleTodayClick}
        >
          Hoy
        </Button>

        <Button
          size="small"
          variant={weekly ? 'contained' : 'outlined'}
          onClick={onWeeklyClick}
        >
          Vista semanal
        </Button>
      </Box>
    </Box>
  );
};

export default CalendarAgenda;
