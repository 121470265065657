import { useQuery, QueryHookOptions } from '@apollo/client';
import { MyStudiosQuery } from '@legalsurf/queries';

export type MyStudiosHookQueryVariables = {
  studio: string;
};

export type MyStudiosQueryData = {
  getMyStudios: any[];
};

export const useMyStudios = (
  options: QueryHookOptions<MyStudiosQueryData, MyStudiosHookQueryVariables>,
) => {
  const query = useQuery(MyStudiosQuery, options);

  return {
    ...query,
    data: query.data?.getMyStudios ?? [],
  };
};
